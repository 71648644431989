import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from '../services';
import { DocumentWhitelistPayload } from '../services/models/';

@Injectable()
export class ResolveDocumentWhitelistPayload implements Resolve<DocumentWhitelistPayload> {
  constructor(private adminService: AdminService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): DocumentWhitelistPayload | Observable<DocumentWhitelistPayload> | Promise<DocumentWhitelistPayload> {
     const documentId:string = route.params['id'];
     return this.adminService.getDocumentWhitelist(documentId);
  }
}