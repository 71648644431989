<div>
    <div class="loadingContainer" *ngIf='hasError===false'>
        <dx-load-indicator [width]="60" [height]="60"></dx-load-indicator>
    </div>
    
</div>
<dx-popup [visible]="hasError" title="Login Error" height="250" width="400" (onHidden)="login()">
    <dxi-toolbar-item [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Login Again', onClick: login }">
    </dxi-toolbar-item>
    <div class="popup-content">
        <dx-scroll-view>
            <strong>{{ error }}</strong>
        </dx-scroll-view>
    </div>  
</dx-popup>