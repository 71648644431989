import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import {  HtmlTransformerService, ResizingService  } from '../../core/services';
import { CCDADocumentDetailView } from '../../core/services/models';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-clinical-summaries-detail-popup',
  templateUrl: './clinical-summaries-detail-popup.component.html',
  styleUrls: ['./clinical-summaries-detail-popup.component.scss']
})
export class ClinicalSummariesDetailPopupComponent extends Popup implements 
  OnInit,AfterViewInit, OnDestroy {

  @ViewChild('popup', { static: true }) popup : DxPopupComponent;

  constructor(
    router: Router,
    route: ActivatedRoute,
    private transformer: HtmlTransformerService,
    resizingService:ResizingService    
  ) {
    super(router, route, resizingService);
  }

  document: CCDADocumentDetailView;
  title: string;

  ngOnInit() {
    this.document = this.route.snapshot.data.document;
    this.title = this.document.title;
  }

  ngAfterViewInit() {    
    super.init(this.popup);
  }

  ngOnDestroy() {
    super.destroy();
  }

  loadContent(event) {
    const element = event.component.content().querySelector('#modalContent');
    element.innerHTML = '';
    let content = atob(this.document.content);
    content = content.split('#777').join('#333');
    this.transformer
      .load(content)
      .bindToHashLinksToSpans(hash => {
        document.getElementById(hash).scrollIntoView();
      })
      .appendTo(element);
  }

  download = () => {
    this.router.navigate(['encryption-download'], { relativeTo: this.route });    
  };

  transmit = () => {
    this.router.navigate(['transmit'], { relativeTo: this.route });
  };
}
