import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/services';
import { BaseComponent } from '../core/component/base.component';

@Component({
  selector: 'app-silent-renew-callback',
  template: ''
})
export class SilentRenewCallbackComponent extends BaseComponent implements  OnInit {
  constructor(private authService: AuthenticationService) { super();}

  ngOnInit(): void {
    this.authService.authenticateCallback();
  }
}
