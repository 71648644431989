<div class="app-container">
  <div class="layout-container">
    <div class="header">
      <img class="ctca-logo" src="assets/images/ctca-coh-logo.png?1" width="184" height="59" alt="CTCA Logo"/>
    </div>

    <div class="content" *ngIf="success && currentUrl=='email-verification-success'">
      <div class="checkmarkContainer">
        <img class="checkmark" alt="Checkmark"/>
      </div>
      <h1>Success!</h1>
      <p>
        An account has been created for<br/>
        <b>{{email}}</b>
      </p>
      <dx-button text="Go to Portal" type="default" (click)="goPortalHome()"></dx-button>

      <p class="footer">
        Need Technical Support?<br/>
        <a href="tel:+18002340482">Call 1-800-234-0482</a>
      </p>
    </div>

    <div class="content" *ngIf="!success && currentUrl=='email-verification-success'">
      <p>
        Your link has expired. Please log in again to receive a new link.
      </p>
      <dx-button text="Log In" type="default" (click)="goPortalHome()"></dx-button>
      <p class="footer">
        Need Technical Support?<br/>
        <a href="tel:+18002340482">Call 1-800-234-0482</a>
      </p>
    </div>

    <div class="content" *ngIf="currentUrl=='verify-your-email'">
      <p>
        Please check your email inbox for a verification link to complete setting up your account.
      </p>
      <p class="footer">
        Need Technical Support?<br/>
        <a href="tel:+18002340482">Call 1-800-234-0482</a>
      </p>
    </div>
    
  </div>
</div>