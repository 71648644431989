import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { dateOrEmptyValidator } from '../../shared/validators/date-or-empty.validator';
import { BaseComponent } from '../../core/component/base.component';
import * as formHelper from '../../shared/helpers/form.helper';
import * as moment from 'moment';
import { ReportingService, LoadingService } from '../../core/services';
import { MuLocationVisitCounts } from '../../core/services/models';

@Component({
  selector: 'app-location-visits',
  templateUrl: './location-visits.component.html',
  styleUrls: ['./location-visits.component.scss']
})
export class LocationVisitsComponent extends BaseComponent implements OnInit {

  visits:Array<MuLocationVisitCounts> = new Array<MuLocationVisitCounts>();
  max:Date = new Date();
  min:Date = new Date('January 1, 1900');

  visitsForm = new FormGroup({
    startDate : new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator])),
    endDate: new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator]))  
  });

  constructor(private reportingService:ReportingService, private loadingService:LoadingService) { 
    super();
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
  
    FormControlName.prototype.ngOnChanges = function () {
      let result;
      if (arguments) {
        result = originFormControlNameNgOnChanges.apply(this, arguments);
      }
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };
  }

  ngOnInit() {
    formHelper.markControlAndChildControlsAsDirty(this.visitsForm);
  }

  wrongOrder():boolean {

    const startDate = new Date(this.visitsForm.get('startDate').value);
    const endDate = new Date(this.visitsForm.get('endDate').value);

    return moment(startDate).isAfter(moment(endDate));

  }

  inRange():boolean {
    
    const startDate = new Date(this.visitsForm.get('startDate').value);
    const endDate = new Date(this.visitsForm.get('endDate').value);

    return moment(startDate).isValid() && moment(startDate).isBetween(this.min,this.max) && moment(endDate).isValid() && moment(endDate).isBetween(this.min,this.max);

  }

  minDate():string {
    return moment(this.min).format("MM[/]DD[/]YYYY");
  }

  maxDate():string {
    return moment(this.max).format("MM[/]DD[/]YYYY");
  }

  startDateValueChanged() {
    this.visitsForm.get('startDate').updateValueAndValidity();
  }

  endDateValueChanged() {
    this.visitsForm.get('endDate').updateValueAndValidity();    
  }

  lookupVisits() {
    
    if(this.visitsForm.valid && !this.wrongOrder() && this.inRange()) {
      this.loadingService.loading = true;
      const startDate: string = new Date(this.visitsForm.get('startDate').value).toDateString();
      const endDateAfter: Date = new Date(this.visitsForm.get('endDate').value);
      endDateAfter.setDate(endDateAfter.getDate() + 1 );
      const endDate: string = endDateAfter.toDateString();
      this.reportingService.getMuLocationVisitCounts(startDate,endDate).subscribe((data:Array<MuLocationVisitCounts>) => {
        this.loadingService.loading = false;
        this.visits = data;        
      }, error => {
        this.loadingService.loading = false;
        throw(error);
      });
    }
  }

}
