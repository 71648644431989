import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import { LoadingService, AdminService, UserProfileService, ResizingService, SupportService } from '../../core/services';
import { AlertMessagePayload, ControllerMethod, AlertMessage, UserProfile, FacilityInfo } from '../../core/services/models';
import { FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { alert } from 'devextreme/ui/dialog';
import { datetimeOrEmptyValidator } from '../../shared/validators/datetime-or-empty.validator';
import * as moment from 'moment';
import { PermissionLevel } from '../../core/permissions/permission-levels.enum';
import { DxSelectBoxComponent, DxDateBoxComponent, DxTextAreaComponent, DxCheckBoxComponent, DxPopupComponent, DxTextBoxComponent } from 'devextreme-angular';
import ArrayStore from 'devextreme/data/array_store';
import { integer } from 'app/shared/validators/integer.validator';

@Component({
  selector: 'app-alert-messages-popup',
  templateUrl: './alert-messages-popup.component.html',
  styleUrls: ['./alert-messages-popup.component.scss']
})
export class AlertMessagesPopupComponent extends Popup implements OnInit, AfterViewInit,
  OnDestroy, AfterViewChecked {

  @ViewChild("controller", { static: false }) view_controller: DxSelectBoxComponent;
  @ViewChild('method', { static: false }) view_method: DxSelectBoxComponent;
  @ViewChild('startDate', { static: false }) view_startDate: DxDateBoxComponent;
  @ViewChild('endDate', { static: false }) view_endDate: DxDateBoxComponent;
  @ViewChild('title', { static: false }) view_title: DxTextAreaComponent;
  @ViewChild('message', { static: false }) view_message: DxTextAreaComponent;
  @ViewChild('disableFunction', { static: false }) view_disableFunction:DxCheckBoxComponent;
  @ViewChild("facility", { static: false }) view_facility: DxSelectBoxComponent;
  @ViewChild('displayOrder', { static: false }) view_displayOrder: DxTextBoxComponent;
  @ViewChild('priority', { static: false }) view_priority: DxCheckBoxComponent;
  @ViewChild('popup', { static: true }) popup: DxPopupComponent;

  private readonly SELECT_CONTROLLER:string = "-- Select a controller --";
  private readonly SELECT_METHOD:string = "-- Optional Pick a Method --";
  public actionButtonText:string;

  public messageId:string;
  public alertMessagePayload:AlertMessagePayload;
  public title:string;
  public updating:boolean;
  
  private controllerMethods:Array<{ controller:string,id:string }> = new Array<{ controller:string,id:string }>();
  private availableMethods:Array<{ method: string, id: string }> = new Array<{ method: string, id: string }>();
  private controllerDictionary:Object = new Object();
  private messageForm:FormGroup;

  public facilities: FacilityInfo[];
  public facilitiesData: any;

  constructor(private adminService:AdminService,
    router: Router,
    route: ActivatedRoute,
    public loadingService:LoadingService,
    public profileSvc:UserProfileService,
    resizingService: ResizingService,
    private supportService: SupportService,
    private changeDetectorRef: ChangeDetectorRef) { 
    super(router, route, resizingService);

    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
  
    FormControlName.prototype.ngOnChanges = function () {
      let result;
      if (arguments) {
        result = originFormControlNameNgOnChanges.apply(this, arguments);
      }
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };

  }
 

  ngOnInit() {   
    
    this.messageId = this.route.snapshot.params['id'];
    this.alertMessagePayload = this.route.snapshot.data.message;   
    this.updating = this.messageId && this.alertMessagePayload.message ? true : false;

    if (this.alertMessagePayload?.controllersMethods) {
      for (let i = 0; i < this.alertMessagePayload.controllersMethods.length; i++) {
        const controllerMethod: ControllerMethod = this.alertMessagePayload.controllersMethods[i];
        const id: string = (controllerMethod.controllerName === this.SELECT_CONTROLLER) ? "" : controllerMethod.controllerName;
        this.controllerMethods.push({ controller: controllerMethod.controllerName, id: id });
        const methods: Array<{ method: string, id: string }> = new Array<{ method: string, id: string }>();
        for (let j = 0; j < controllerMethod.availableMethods.length; j++) {
          const methodName: string = controllerMethod.availableMethods[j];
          methods.push({ method: methodName, id: methodName });
        }
        this.controllerDictionary[id] = methods;
      }
    }

    this.supportService.getAllFacilitiesInfoForTreamentFacilitySelect().subscribe((facilities: FacilityInfo[]) =>{
      this.facilities = facilities;

      if(this.alertMessagePayload.message && this.alertMessagePayload.message.facility !== null){
        const nullFacility = { 'name': 'Select...' };
        this.facilities.unshift(nullFacility);
      }

      this.facilitiesData = new ArrayStore({
        data: this.facilities,
        key: 'name'
      });
    })

    this.messageForm = new FormGroup({
      controller : new FormControl('',[Validators.required]),
      method : new FormControl(''),
      startDate: new FormControl('',Validators.compose([Validators.required,datetimeOrEmptyValidator])),
      endDate: new FormControl('',Validators.compose([Validators.required,datetimeOrEmptyValidator])),
      message: new FormControl('',[Validators.required]),
      title: new FormControl('', []),
      disableFunction: new FormControl(false),
      facility: new FormControl(''),
      priority: new FormControl(false),
      displayOrder: new FormControl('', [integer]),
    });
    
    if (this.updating) {

      const message: AlertMessage = this.alertMessagePayload.message;
      const controller: string = (message.controllerName === this.SELECT_CONTROLLER || !this.controllerMethods.find(x => x.id === message.controllerName)) ? "" : message.controllerName;
      this.messageForm.get('controller').setValue(controller);
      this.getAvailableMethods();
      if(!message.methodName) message.methodName = this.SELECT_METHOD;
      this.messageForm.get('method').setValue(message.methodName);
      this.messageForm.get('startDate').setValue(message.startDate);
      this.messageForm.get('endDate').setValue(message.endDate);
      this.messageForm.get('title').setValue(message.messageTitle);
      this.messageForm.get('message').setValue(message.messageText);
      this.messageForm.get('disableFunction').setValue(message.disableFunction);
      this.messageForm.get('facility').setValue(message.facility);
      this.messageForm.get('displayOrder').setValue(message.displayOrder);
      this.messageForm.get('priority').setValue(message.priority);
      this.title = this.actionButtonText = "Update Message";

    } else {

      this.messageForm.get('controller').setValue("");
      this.getAvailableMethods();
      this.messageForm.get('method').setValue(this.SELECT_METHOD);
      this.messageForm.get('startDate').setValue(new Date());
      this.messageForm.get('endDate').setValue(new Date());
      this.messageForm.get('title').setValue("");
      this.messageForm.get('message').setValue("");
      this.messageForm.get('disableFunction').setValue(false);
      this.messageForm.get('facility').setValue(null);
      this.messageForm.get('displayOrder').setValue(0);
      this.messageForm.get('priority').setValue(false);
      this.title = this.actionButtonText = "Add Message";

    }
  }

  ngAfterViewInit() {
    this.profileSvc.getUserProfile().subscribe((profile:UserProfile) => {
      let permission = false;
      permission = profile.userPermissions!=null && profile.userPermissions.indexOf(PermissionLevel.MANAGE_SYSTEM_ALERT_MESSAGES) >= 0;
      setTimeout(() => {
        this.view_controller.disabled = !permission;
        this.view_method.disabled = !permission;
        this.view_startDate.disabled = !permission;
        this.view_endDate.disabled = !permission;
        this.view_title.disabled = !permission;
        this.view_message.disabled = !permission;
        this.view_disableFunction.disabled = !permission;
        this.view_facility.disabled = !permission;
        this.view_displayOrder.disabled = !permission;
        this.view_priority.disabled = !permission;
        this.popup.disabled = !permission;
      },0);
    });

    super.init(this.popup);
  }

  ngAfterViewChecked(){
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(){
    super.destroy();
  }
  
  getAvailableMethods():Array<{ method: string, id: string }> {
    const controller: string = this.messageForm.get('controller').value;
    this.availableMethods = this.controllerDictionary[controller];
    this.messageForm.get('method').setValue(this.SELECT_METHOD);
    return this.availableMethods;
  }

  validateComponent(control:string) {

    this.messageForm.get(control).updateValueAndValidity();

  }

  wrongOrder():boolean {

    const startDate = new Date(this.messageForm.get('startDate').value);
    const endDate = new Date(this.messageForm.get('endDate').value);

    return moment(startDate).isAfter(moment(endDate));

  }

  action = () => {

    if( this.messageForm.valid && !this.wrongOrder() ) {

      let id = "";      

      if(this.updating) {
        id = this.alertMessagePayload.message.id;        
      }

      const controllerName: string = this.messageForm.get('controller').value;
      const methodName: string = this.messageForm.get('method').value;
      const startDate: string = this.messageForm.get('startDate').value;
      const endDate: string = this.messageForm.get('endDate').value;
      const messageTitle: string = this.messageForm.get('title').value;
      const messageText: string = this.messageForm.get('message').value;
      const disableFunction: boolean = this.messageForm.get('disableFunction').value;
      const displayOrder: number = this.messageForm.get('displayOrder').value;
      const priority: boolean = this.messageForm.get('priority').value;
      const facility: string = (this.messageForm.get('facility').value === 'Select...') ? null : this.messageForm.get('facility').value;

      if(this.updating) {

        const message: AlertMessage = new AlertMessage(id, controllerName, methodName, messageTitle, messageText, startDate, endDate, disableFunction,
          facility, displayOrder, priority);
        this.loadingService.loading = true;
        this.adminService.saveMessage(message).subscribe((result:string)=>{
          this.loadingService.loading = false;
          alert("Alert Message has been saved","Information");          
          this.navigateToParent();
        },error=>{
          this.loadingService.loading = false;
          throw(error);
        });

      } else {

        const message: any = {
          controllerName,
          methodName,
          startDate,
          endDate,
          messageTitle,
          messageText,
          disableFunction,
          facility,
          displayOrder,
          priority
        };

        this.loadingService.loading = true;
        this.adminService.addMessage(message).subscribe((result:string)=>{
          this.loadingService.loading = false;
          alert("Alert Message has been saved","Information");          
          this.navigateToParent();
        },error=>{
          this.loadingService.loading = false;
          throw(error);
        });


      }

    }

  };

}
