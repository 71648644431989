<div class="flexV">
    <h1 class="mainTitle">{{title}}</h1>
    <hr>
    <h2 class="subtitle">Please review</h2>

    <div class="cards">
        <ng-container *ngFor="let card of cards">
            <div class="card">
                <div class="flexH">
                    <div class="title">{{card.title}}</div>
                    <div class="edit" *ngIf="card.editPath" (click)="onEditClick(card)">
                        <img src="assets/images/icons/SVG/edit-pencil.svg" alt="Edit button"/>
                    </div>
                </div>
                <div class="text" *ngIf="card.text">{{card.text}}</div>
                <ng-container *ngIf="card.textArray">
                    <div class="text" *ngFor="let text of card.textArray">{{text}}</div>
                </ng-container>
                
            </div>
        </ng-container>
    </div>
   
    <div class="buttons">
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            text="Send Message"
            type="success"
            (onClick)="onSendMessageClick($event)"
        ></dx-button>
    </div>

    <div class="successContainer" *ngIf="showSuccess">
        <div class="successBox">
            <div class="successContent">
                <img class="closeButton" (click)="onCloseSuccess()"  src="assets/images/icons/SVG/close.svg" alt="Close success message"/>
                <h1>Your message has been sent to the {{state.question.value}} Team.</h1>
                <img class="plane" src="assets/images/plane.png" alt="Message successfully sent icon"/>
                <p>
                    The {{state.question.value}} Team will {{deliveryMethod}} you at:<br/>
                    <b>{{deliveryValue}}</b><br/>
                    within 24 hours.
                </p>
            </div>
        </div>
    </div>
</div>