<app-card label="New Care Team Messages" actionLabel="View all messages" actionLabelMobile="View all" (onActionClick)="viewAllMessages()">
  <div class="new-messages">
    <div class="msg" *ngIf="newMessages?.length; else noMessages">
      <div *ngFor="let msg of newMessages">
        <div class="message" (click)="viewMessageDetails(msg)">
          <img class="message-user" src="/assets/images/icons/SVG/shm-user.svg" alt="User icon"/>
          <div class="message-content">
            <div class="msg-from">{{msg.from}}</div>
            <div class="msg-date">{{msg.sent | date:'MMM d'}}</div>
            <div class="clearfix"></div>
            <div class="msg-subject">{{msg.subject}}</div>
            <div class="msg-text">{{msg.comments}}</div>
          </div>
        </div>
      </div>
      <hr class="new-messages-separator" *appShowFor="PermissionLevel.SEND_SECURE_MESSAGES"/>
    </div>
    <dx-button *appShowFor="PermissionLevel.SEND_SECURE_MESSAGES" class="white margin0auto action-button" icon="ctca-messages" text="Compose Message" (onClick)="composeMessage()"></dx-button>
  </div>
</app-card>

<ng-template #noMessages>
  <div class="new-messages-info-empty">
    No new messages.
  </div>
</ng-template>
