<app-card label="Patient Safety Notification">
    
      <p class="center" style="font-size: 17px;">
        Patient Safety During the Current COVID-19 Outbreak
      </p>      
      <br/>      
      <p>
        <strong>
          We are taking extraordinary precautions to protect our patients, caregivers and employees against COVID-19, 
          while actively working to prevent interruptions or delays in your critical care and treatment.
        </strong>
      </p>    
      <p>
        You are not alone in navigating these times. From day one of this pandemic, Cancer Treatment Centers of America® (CTCA) 
        has made it our priority to maintain a safe environment for patients who need critical cancer screenings and lifesaving cancer care.
      </p>
      <p>
        While the world continues to learn more about COVID-19 every day, we can be certain this virus is not going away soon. We also know 
        that cancer doesn’t wait for COVID. To help you feel comfortable getting the care you need without disruption, we want to share some 
        of the precautionary measures CTCA® is taking to protect our patients’ health and safety.
      </p>
      <div class="safety-measures">
        <strong>CTCA Safety Measures:</strong>
        <ul>
          <li>Facility Practices:
            <ul>
              <li>We have expanded our already-rigorous cleaning and disinfecting practices.</li>
              <li>We have removed and spaced furniture throughout each cancer center to enable social-distancing.</li>
              <li>
                Universal masking is strictly enforced and we are providing masks for all patients and 
                caregivers to wear throughout their time in any of our facilities and until they get back home.
              </li>
              <li>
                All CTCA employees are provided appropriate masks based on their patient responsibilities.
              </li>
            </ul>
          </li>
          <li>Screening:
            <ul>
              <li>Our cancer centers have implemented a single point of entry, with 24-hour, mandatory screening of all patients and caregivers.</li>
              <li>All CTCA employees are self-screening for symptoms twice a day to protect the health and safety of the patients in their care.</li>
            </ul>
          </li>
          <li>Visitation Guidelines:
            <ul>
              <li>Each patient will still be allowed one caregiver over the age of 18 in outpatient areas of the cancer center.</li>
              <li>At some locations, caregivers will not be allowed into inpatient units, pre-op and PACU areas. In those 
                facilities, these areas will be accessible only to necessary employees and providers.
              </li>
            </ul>
          </li>
          
        </ul>
      </div>        
         
      <p>
        To view FAQs on what to expect at your next visit, as well as policies our sites have implemented to maintain patient safety, 
        as well as policies our sites have implemented to maintain patient safety, visit 
        <a href="http://www.cancercenter.com/covid19/patient-information" target="_blank" class="dead-link">cancercenter.com/covid19/patient-information</a>.
        You can also call <a href="tel:888-325-4754">888-325-4754</a> to reach your care team with any questions.
      </p>
      <p>
        These are unsettling times for us all, but I don’t know of a more resilient group of people than patients engaged in the battle 
        against cancer and those committed to their care. We want you to know that every team at our cancer centers, from surgery to 
        sanitation, is dedicated to fighting with you and for you. 
      </p>
      <p>
        We know that cancer doesn’t stop for COVID-19, and CTCA won’t stop fighting for our patients. 
      </p>
      <p>Sincerely,</p>
      <p>
        Pat Basu<br/>President & CEO
      </p>      
</app-card>