import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ServiceEndpoint } from './service-endpoint.enum';
import { Router } from '@angular/router';
import { AccountTotals, EventCounter, RegistrationsBySite, 
		MuLocationVisitCounts, 
		MuLocationEventCounts,
		EventCounterPivot,
		MuPatientByLocationEventCounts} from './models';


@Injectable()
export class ReportingService extends BaseService {
  constructor(router: Router, httpClient: HttpClient) {
		super(router, httpClient);
	}	
	
	getAccountTotals():Observable<Array<AccountTotals>> {
		return this.get({
			endpoint:ServiceEndpoint.REPORTING_GETACCOUNTTOTALS
		});
	}

	getEventCounts(startDate:string, endDate:string,userType:string):Observable<Array<EventCounter>> {
		return this.get({
			endpoint:ServiceEndpoint.REPORTING_GETEVENTCOUNTS,
			params: {
				startDate:startDate,
				endDate:endDate,
				filterRoleType:userType
			}
		});
	}

	getEventCountsPivot(startDate:string, endDate:string, userType:string):Observable<Array<EventCounterPivot>> {
		return this.get({
			endpoint:ServiceEndpoint.REPORTING_GETEVENTCOUNTSPIVOT,
			params: {
				startDate:startDate,
				endDate:endDate,
				filterRoleType:userType
			}
		});
	}

	getRegistrationsBySite(startDate:string, endDate:string):Observable<Array<RegistrationsBySite>> {
		return this.get({
			endpoint:ServiceEndpoint.REPORTING_GETREGISTRATIONBYSITE,
			params: {
				startDate:startDate,
				endDate:endDate
			}
		});	
	}

	getMuLocationVisitCounts(startDate:string, endDate:string):Observable<Array<MuLocationVisitCounts>> {
		return this.get({
			endpoint:ServiceEndpoint.REPORTING_GETMULOCATIONVISITCOUNTS,
			params: {
				startDate:startDate,
				endDate:endDate
			}
		});	
	}

	getMuLocationDistinctEventCounts(startDate:string, endDate:string):Observable<Array<MuLocationEventCounts>> {
		return this.get({
			endpoint:ServiceEndpoint.REPORTING_GETMULOCATIONDISTINCTEVENTCOUNTS,
			params: {
				startDate:startDate,
				endDate:endDate
			}
		});	
	}

	getMuPatientByLocationEventCounts(startDate:string, endDate:string):Observable<Array<MuPatientByLocationEventCounts>> {
		return this.get({
			endpoint:ServiceEndpoint.REPORTING_GETMUEVENTCOUNTSBYPATIENT,
			params: {
				startDate:startDate,
				endDate:endDate
			}
		});	
	}

	
}
