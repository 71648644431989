<div class="overlay-background">
    <dx-load-panel    
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: Window }"
        [(visible)]="isLoading"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false">
    </dx-load-panel>
    <div id="contactOverlay" class="overlay">
        <div class="header" (click)="close()">
            <img src="assets/images/icons/SVG/phone-white.svg" alt="Phone icon"/>
            Important CTCA Phone Numbers
            <img src="assets/images/icons/SVG/close-white.svg" alt="Close button"/>
        </div>
        <ng-container *ngFor="let facilityGroup of facilityGroups">
            <h1>{{facilityGroup?.title}}</h1>
            <div *ngIf="facilityGroup?.address" class="facility-address" (click)="openGoogleMaps(facilityGroup.address)">
                <div>{{ facilityGroup?.address?.line1 }}</div>
                <div *ngIf="facilityGroup?.address?.line2">{{ facilityGroup?.address?.line2 }}</div>
                <div>
                    <span>{{ facilityGroup?.address?.city }},</span>&nbsp;
                    <span>{{ facilityGroup?.address?.state }}</span>&nbsp;
                    <span>{{ facilityGroup?.address?.postalCode }}</span>
                </div>
                <div *ngIf="facilityGroup?.address?.country">{{ facilityGroup?.address?.country }}</div>
            </div>
            <div class="phone-card" *ngFor="let phoneCard of facilityGroup.phoneCards">
                <div class="title">{{ phoneCard.title }}</div>
                <div *ngIf="phoneCard.description" class="description">{{ phoneCard.description }}</div>
                <div *ngIf="phoneCard?.address" class="facility-address" (click)="openGoogleMaps(phoneCard.address)">
                    <div>{{ phoneCard?.address?.line1 }}</div>
                    <div *ngIf="phoneCard?.address?.line2">{{ phoneCard?.address?.line2 }}</div>
                    <div>
                        <span>{{ phoneCard?.address?.city }},</span>&nbsp;
                        <span>{{ phoneCard?.address?.state }}</span>&nbsp;
                        <span>{{ phoneCard?.address?.postalCode }}</span>
                    </div>
                    <div *ngIf="phoneCard?.address?.country">{{ phoneCard?.address?.country }}</div>
                </div>
                <div class="phone" (click)="call(phoneCard)">
                    <img src="/assets/images/icons/SVG/phone-white.svg" width="15" height="22" [alt]="phoneCard.phone"/>
                    {{ phoneCard.phone }}
                </div>
                <div *ngIf="phoneCard.secondPhone" class="phone second-phone" (click)="call(phoneCard,true)">
                    <img src="/assets/images/icons/SVG/phone-white.svg" width="15" height="22" [alt]="phoneCard.phone"/>
                    {{ phoneCard.secondPhone }}
                </div>
            </div>
            <br/>
            <br/>
        </ng-container>
    </div>
</div> 