import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ImagingResult } from '../services/models';
import { Observable } from 'rxjs';
import { MedicalDocumentsService } from '../services';

@Injectable()
export class ResolveImagingDocumentListGuard implements Resolve<ImagingResult[]> {
  constructor(private docSvc: MedicalDocumentsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): ImagingResult[] | Observable<ImagingResult[]> | Promise<ImagingResult[]> {
    const docs =  this.docSvc.getImagingDocuments();
    return docs.pipe(()=>{
      localStorage.setItem('startTimeofImaging',Date.now().toString());
      return docs;
    })
  }
}
