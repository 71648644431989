import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../component/base.component';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent extends BaseComponent implements OnInit {

    @Input() text: string;
    @Input() leftIcon: string;
    @Input() rightIcon: string;
    @Input() buttonStyle: string;
    @Input() disabled: boolean;
    @Output() onClick: any = new EventEmitter<any>();

    constructor() {
        super();
    }
    ngOnInit(): void {
    }

    clicked = () => {
        if (!this.disabled) {
            this.onClick.emit();
        }
    }

    wrapperClass() {
        let classes = 'button ' + (this.buttonStyle || 'primary');
        if (this.disabled) {
            classes += ' disabled';
        }
        return classes;
    }
}