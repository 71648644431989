
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { UserProfile } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable()
export class UserProfileAvoidCacheService extends BaseService {
	
	constructor(router: Router, httpClient: HttpClient) {
		super(router, httpClient);
	}

	getUserProfile(): Observable<UserProfile> {
		return this.get({
			endpoint: ServiceEndpoint.USERPROFILE_GETUSERPROFILE
		});
	}
}
