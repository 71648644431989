import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StringHelper } from '../../shared/helpers/string.helper';
import { Popup } from '../../core/popup/popup';
import { AuthenticationService, UserProfileService, LoadingService, ResizingService } from '../../core/services';
import { Proxy, UserProfile } from '../../core/services/models';
import { alert } from 'devextreme/ui/dialog';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
	selector: 'app-change-proxy-popup',
	templateUrl: './change-proxy-popup.component.html',
	styleUrls: ['./change-proxy-popup.component.scss']
})
export class ChangeProxyPopupComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
	
	proxies: Array<Proxy>;
	activeProxy: Proxy;
	selectedProxy: string;
	adminImpersonation = false;
		
	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

	constructor(
		router: Router,
		route: ActivatedRoute,
		private authSvc: AuthenticationService,
		private profileSvc: UserProfileService,
		private loadingService:LoadingService,
		resizingService: ResizingService) {
		super(router, route, resizingService);
	}

	ngOnInit() {

		this.profileSvc.getUserProfileHardCache().subscribe((profile:UserProfile) => {				
			
			if(profile.proxies && profile.proxies.length > 0) {
				this.proxies = profile.proxies.filter(proxy => proxy.relationshipType !== "Self").map(proxy => ({
					...proxy,
					fullName: `${proxy.firstName} ${proxy.lastName}`
				}));
				if(this.proxies && this.proxies.length > 0) {
					const active = this.proxies.filter(proxy => proxy.isImpersonating);
					if(active && active.length > 0) {
						this.activeProxy = active[0];
					}
					const selected = this.proxies[0];
					if (selected) {
						this.selectedProxy = selected.toCtcaUniqueId;
					}
				}				
				this.adminImpersonation = profile.isSuperUser && this.activeProxy == null && this.proxies.length === 0;
			}
		});
	}

	ngAfterViewInit(){
		super.init(this.popup);
	}

	ngOnDestroy(){
		super.destroy();
	}

	isAdminImpersonationOrHasProxies() {
		return this.adminImpersonation || this.proxies.length > 0;
	}

	isIntegerProxy() {
		return StringHelper.isInteger(this.selectedProxy);
	}	

	changeProxy = () => {
		if (StringHelper.isInteger(this.selectedProxy)) {
			this.loadingService.loading = true;
			this.profileSvc.canPatientBeImpersonatedBy(this.selectedProxy).subscribe((impersonationAllowed:boolean) => {
				if(impersonationAllowed) {
					this.authSvc.authenticateProxy(this.selectedProxy);	
				} else {
					this.loadingService.loading = false;
					alert("Impersonation is not allowed for requested user","Information");
				}
			});	
		}		
	};
}
