import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { AppointmentsService, ContactInfoService, ContactUsStateService, LoadingService, SupportService } from 'app/core/services';
import { AppointmentDetail, ContactUsFormType, PatientContactInfo } from 'app/core/services/models';
import { SendAppointmentsSchedule } from 'app/core/services/models/send-appointments-schedule.model';
import { schedulingHoursPipeTransform } from 'app/shared/pipes/scheduling-hours.pipe';
import * as moment from 'moment';


@Component({
  selector: 'app-ask-question-non-medical-review',
  templateUrl: './ask-question-non-medical-review.component.html',
  styleUrls: ['./ask-question-non-medical-review.component.scss']
})



export class AskQuestionNonMedicalReviewComponent extends BaseComponent implements OnInit {


  state: any;
  cards: {
    title: string,
    text?: string,
    textArray?: string[],
    editPath?: string,
  }[] = [];
  showSuccess = false;
  patientContactInfo: PatientContactInfo;
  title: string;
  deliveryMethod: string;
  deliveryValue: string;


  constructor(
    // private route: ActivatedRoute,
    private router: Router,
    private contactUsState: ContactUsStateService,
    private supportService: SupportService,
    private loadingService: LoadingService,
    private appointmentService: AppointmentsService,
    private contactInfoService: ContactInfoService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
      this.patientContactInfo = patientContactInfo;
    });
    const state = this.contactUsState.getState();
    this.state = state;
    this.cards = [];
    this.cards.push({
      title: 'ABOUT',
      text: state.question.value,
    });

    this.cards.push({
      title: 'SUBJECT',
      text: state.subject.value,
    });
    if (state.subject) {
      this.title = state.subject.value;
    } else if (state.question) {
      this.title = state.question.value;
    }
    const formType: ContactUsFormType = state.subject.formType;

    if (formType === ContactUsFormType.CancelAppointment) {
      const appt: AppointmentDetail = state.appointment;
      this.cards.push({
        title: 'APPOINTMENT',
        text: appt.description + ', ' + moment(appt.startDateTime).format('MMMM Do YYYY, h:mm') + ' ' + appt.facilityTimeZone,
        editPath: '/app/ask-question/non-medical/select-appointment',
      });

      let contactPreference = '';
      const contact = state.contact;
      if (contact.phoneChecked) {
        contactPreference = 'Phone call to ' + contact.phoneValue;
      } else if (contact.emailChecked) {
        contactPreference = 'Email to ' + contact.emailValue;
      }
      this.cards.push({
        title: 'CONTACT PREFERENCE',
        text: contactPreference,
        editPath: '/app/ask-question/non-medical/appointment-contact',
      });

      this.cards.push({
        title: 'REASON',
        text: state.reason,
        editPath: '/app/ask-question/non-medical/reason',
      });


    } else if (formType === ContactUsFormType.RescheduleAppointment) {
      const appt: AppointmentDetail = state.appointment;
      this.cards.push({
        title: 'APPOINTMENT',
        text: appt.description + ', ' + moment(appt.startDateTime).format('MMMM Do YYYY, h:mm') + ' ' + appt.facilityTimeZone,
        editPath: '/app/ask-question/non-medical/select-appointment',
      });

      const preferredDatesAndTimes: string[] = [];
      state.preferredDatesTimes.forEach(p => {
        preferredDatesAndTimes.push(moment(p.date).format('dddd MMMM Do YYYY') + ', ' + schedulingHoursPipeTransform(p.time));
      });
      this.cards.push({
        title: 'PREFERRED DATES AND TIMES',
        textArray: preferredDatesAndTimes,
        editPath: '/app/ask-question/non-medical/appointment-time',
      });

      let contactPreference = '';
      const contact = state.contact;
      if (contact.phoneChecked) {
        contactPreference = 'Phone call to ' + contact.phoneValue;
      } else if (contact.emailChecked) {
        contactPreference = 'Email to ' + contact.emailValue;
      }
      this.cards.push({
        title: 'CONTACT PREFERENCE',
        text: contactPreference,
        editPath: '/app/ask-question/non-medical/appointment-contact',
      });

      this.cards.push({
        title: 'REASON',
        text: state.reason,
        editPath: '/app/ask-question/non-medical/reason',
      });
    } else if (formType === ContactUsFormType.RequestAppointment) {

      const preferredDatesAndTimes: string[] = [];
      state.preferredDatesTimes.forEach(p => {
        preferredDatesAndTimes.push(moment(p.date).format('dddd MMMM Do YYYY') + ', ' + schedulingHoursPipeTransform(p.time));
      });
      this.cards.push({
        title: 'PREFERRED DATES AND TIMES',
        textArray: preferredDatesAndTimes,
        editPath: '/app/ask-question/non-medical/appointment-time',
      });

      let contactPreference = '';
      const contact = state.contact;
      if (contact.phoneChecked) {
        contactPreference = 'Phone call to ' + contact.phoneValue;
      } else if (contact.emailChecked) {
        contactPreference = 'Email to ' + contact.emailValue;
      }
      this.cards.push({
        title: 'CONTACT PREFERENCE',
        text: contactPreference,
        editPath: '/app/ask-question/non-medical/appointment-contact',
      });

      this.cards.push({
        title: 'REASON',
        text: state.reason,
        editPath: '/app/ask-question/non-medical/reason',
      });

    } else if (formType === ContactUsFormType.Basic) {
      this.cards.push({
        title: 'MESSAGE',
        text: state.message,
        editPath: '/app/ask-question/non-medical/subject',
      });
      this.cards.push({
        title: 'EMAIL',
        text: state.email,
        editPath: '/app/ask-question/non-medical/email',
      });

    } else if (formType === ContactUsFormType.RequestAppointmentSchedule) {
      const range = state.range;
      const delivery = state.delivery;
      let rangeText = range.title;
      if (range.id === 'custom') {
        const startDate = moment(range.startDate).format("MMM D, YYYY");
        const endDate = moment(range.endDate).format("MMM D, YYYY");
        rangeText = `Custom range: ${startDate} - ${endDate}`
      }
      this.cards.push({
        title: 'DATE RANGE',
        text: rangeText,
        editPath: '/app/ask-question/non-medical/schedule-range',
      });
      this.cards.push({
        title: 'DELIVERY',
        text: `Via ${delivery.sendBy}: ${delivery.value}`,
        editPath: '/app/ask-question/non-medical/schedule-delivery',
      });
    }

  }

  onSendMessageClick = (e: any) => {
    // this.router.navigate(['non-medical', 'success'], { relativeTo: this.route.parent });
    const formType: ContactUsFormType = this.state.subject.formType;
    if (formType === ContactUsFormType.Basic) {
      this.loadingService.loading = true;
      this.supportService.sendFeedbackFormData({
        QuestionType: 'Non-Medical',
        QuestionAbout: this.state.question.value,
        QuestionSubject: this.state.subject.value,
        QuestionText: this.state.message,
        EmailAddress: this.state.email
      }).subscribe(() => {
        this.loadingService.loading = false;
        this.setShowSuccessTrue();
      });
    } else if (formType === ContactUsFormType.RescheduleAppointment) {
      this.loadingService.loading = true;
      this.appointmentService.rescheduleAppointmentv2({
        appointmentId: this.state.appointment.appointmentId,
        appointmentDateTimes: this.state.preferredDatesTimes.map((dateTime) => {
          // JSON.stringify converts dates into UTC, need to subtract the timezoneoffset to correct for this
          const tempDate = new Date(dateTime.date);
          tempDate.setHours(tempDate.getHours() - tempDate.getTimezoneOffset() / 60);
          return {
            date: dateTime.date,
            timePreference: dateTime.time
          };
        }),
        subject: 'Appointment Reschedule',
        communicationPreference: this.state.contact.emailChecked ? 'EMAIL' : 'CALL',
        phoneNumber: this.state.contact.phoneChecked ? this.state.contact.phoneValue : undefined,
        email: this.state.contact.emailChecked ? this.state.contact.emailValue : undefined,
        reason: this.state.reason
      })
        .subscribe(null, null, () => {
          this.loadingService.loading = false;
          this.setShowSuccessTrue();
        });
    } else if (formType === ContactUsFormType.CancelAppointment) {
      this.appointmentService.cancelAppointmentv2({
        appointmentId: this.state.appointment.appointmentId,
        subject: 'Appointment Cancellation',
        communicationPreference: this.state.contact.emailChecked ? 'EMAIL' : 'CALL',
        phoneNumber: this.state.contact.phoneChecked ? this.state.contact.phoneValue : undefined,
        email: this.state.contact.emailChecked ? this.state.contact.emailValue : undefined,
        reason: this.state.reason
      })
        .subscribe(null, null, () => {
          this.loadingService.loading = false;
          this.setShowSuccessTrue();
        });
    } else if (formType === ContactUsFormType.RequestAppointment) {
      this.appointmentService.requestAppointmentv2({
        appointmentId: '',
        appointmentDateTimes: this.state.preferredDatesTimes.map((dateTime) => {
          // JSON.stringify converts dates into UTC, need to subtract the timezoneoffset to correct for this
          const tempDate = new Date(dateTime.date);
          tempDate.setHours(tempDate.getHours() - tempDate.getTimezoneOffset() / 60);
          return {
            date: dateTime.date,
            timePreference: dateTime.time
          };
        }),
        subject: 'Appointment Request',
        communicationPreference: this.state.contact.emailChecked ? 'EMAIL' : 'CALL',
        phoneNumber: this.state.contact.phoneChecked ? this.state.contact.phoneValue : undefined,
        email: this.state.contact.emailChecked ? this.state.contact.emailValue : undefined,
        reason: this.state.reason
      })
        .subscribe(null, null, () => {
          this.loadingService.loading = false;
          this.setShowSuccessTrue();
        });
    } else if (formType === ContactUsFormType.RequestAppointmentSchedule) {
      const delivery = this.state.delivery;
      const range = this.state.range;
      const startDate = moment(range.startDate).format("MMM D, YYYY");
      const endDate = moment(range.endDate).format("MMM D, YYYY");
      let request: SendAppointmentsSchedule;
      if (delivery.sendBy === 'email') {
        request = {
          startDate,
          endDate,
          sendBy: 'email',
          email: delivery.value
        };
      } else if (delivery.sendBy === 'mail') {
        request = {
          startDate,
          endDate,
          sendBy: 'mail',
          address: {
            address1: delivery.address1,
            address2: delivery.address2,
            city: delivery.city,
            state: delivery.state,
            postalCode: delivery.zip,
            country: delivery.country
          }
        };
      }
      this.loadingService.loading = true;
      this.appointmentService.sendAppointmentsSchedule(request).subscribe(() => {
        this.loadingService.loading = false;
        this.setShowSuccessTrue();
      });
    }
  }

  onEditClick = (card: any) => {
    if (card.editPath) {
      this.router.navigateByUrl(card.editPath + '?edit=true');
    }
  }

  onCloseSuccess = () => {
    // this.showSuccess = false;
    this.router.navigateByUrl('/app/ask-question');
  }

  setShowSuccessTrue = () => {
    if (this.state?.contact?.phoneChecked) {
      this.deliveryMethod = 'call';
      this.deliveryValue = this.state.contact.phoneValue;
    } else if (this.state?.contact?.emailChecked) {
      this.deliveryMethod = 'email';
      this.deliveryValue = this.state.contact.emailValue;
    } else if (this.state?.delivery?.value) {
      this.deliveryMethod = 'email';
      this.deliveryValue = this.state.delivery?.value;
    } else if (this.state?.email) {
      this.deliveryMethod = 'email';
      this.deliveryValue = this.state.email;
    } else {
      this.deliveryMethod = 'email';
      this.deliveryValue = this.patientContactInfo?.emailAddress;
    }
    this.showSuccess = true;
  }
}
