import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import *  as CustomValidator from '../../shared/validators/integer.validator';
import { UserPreferencesService, LoadingService } from '../../core/services';
import { UserPreference } from '../../core/services/models';
import { alert } from 'devextreme/ui/dialog'; 
import { Router } from '@angular/router';
import { trackPageLoadingTime } from '../../../app/shared/helpers/tracking.helper';
import { BaseComponent } from '../../../app/core/component/base.component';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent extends BaseComponent implements OnInit {

  preferencesForm:FormGroup;
  allPreferences:Array<UserPreference>;
  errors:Array<string>;
  static APPOINTMENTSDAYS = "AppointmentsDays";
  static DEFAULT_DAYS_AHEAD = 14;

  constructor(private userPreferencesService:UserPreferencesService,private loadingService:LoadingService, private router:Router) { 
    super();
    this.errors = new Array<string>();
  }

  ngOnInit() {

    this.preferencesForm = new FormGroup({
      lookAhead : new FormControl('',Validators.compose([Validators.required, CustomValidator.integer, Validators.min(14),Validators.max(365)])),
      termsOfUse : new FormControl({value: true, disabled: true})      
    });
    const startTime = Date.now();
    this.loadingService.loading = true;
    this.userPreferencesService.getUserPreferences().subscribe((preferences:Array<UserPreference>) => {
      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elapsedTime);
      this.allPreferences = preferences;
      const preference: UserPreference = this.allPreferences.find(p => p.userPreferenceType === PreferencesComponent.APPOINTMENTSDAYS);
      if(preference) {
        this.preferencesForm.get('lookAhead').setValue(preference.userPreferenceValue);
      } else {
        this.preferencesForm.get('lookAhead').setValue(PreferencesComponent.DEFAULT_DAYS_AHEAD);
      }
    }, error => {
      this.loadingService.loading = false;
      throw(error);
    });    
  }

  getErrors():Array<string>
  {
    this.errors = new Array<string>();
    if(this.preferencesForm.controls.lookAhead.errors) {
      for (const errorItem of Object.keys(this.preferencesForm.controls.lookAhead.errors)) {
        const error = this.preferencesForm.controls.lookAhead.errors[errorItem]; 
        if(errorItem === 'required') {
          this.errors.push("Field is required");          
        } else if (errorItem === 'integer') {
          this.errors.push("Field has to be a valid integer");
        } else if (errorItem === 'min') {
          this.errors.push("Field can not be less than " + error.min.toString());
        } else if (errorItem === 'max') {
          this.errors.push("Field can not be greater than " + error.max.toString());
        }
      }
    }
    return this.errors;
  }

  goToTermsOfUse() {
    this.router.navigateByUrl("/app/terms-of-use/terms-of-use-agreement");
  }

  updatePreferences() {
    
    if(this.preferencesForm.valid) {

      this.loadingService.loading = true;
      const index = this.allPreferences.findIndex(p => p.userPreferenceType === PreferencesComponent.APPOINTMENTSDAYS);
      if(index >= 0) {
      
        this.allPreferences[index].userPreferenceValue = this.preferencesForm.get('lookAhead').value.trim();
        this.userPreferencesService.saveUserPreferences(this.allPreferences).subscribe((result:string) => {
          this.loadingService.loading = false;

          if(result==='Success') {
            alert("Your preferences were saved successfully","Information");
          } else {
            alert("An unexpected error occurred while saving your preferences, If the error persist please contact technical support for assistance","Information");
          }

        }, error => {
          this.loadingService.loading = false;
          throw(error);
        });

      }

    } else {

      this.getErrors();

    }
  }

}
