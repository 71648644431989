import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { SupportService } from '../../core/services';
import { AlertMessage } from '../../core/services/models';

@Component({
  selector: 'app-alert-messages-card',
  templateUrl: './alert-messages-card.component.html',
  styleUrls: ['./alert-messages-card.component.scss']
})
export class AlertMessagesCardComponent extends BaseComponent implements OnInit {

  Messages: Array<AlertMessage> = [];
  firstSentences: string[] = [];

  constructor(private supportService:SupportService) { 
    super();
  }

  ngOnInit() {
    this.supportService.getAlertMessages().subscribe((messages: AlertMessage[]) => {
      this.Messages = messages || [];
      this.firstSentences = [];
      if (messages.length > 0) {
        const topMessages = messages.filter(m => m.priority);
        if (topMessages.length === 0) {
          topMessages.push(messages[0]);
        }
        this.firstSentences = topMessages.map(m => {
          if (m.messageTitle) {
            return m.messageTitle;
          } else {
            const sentences = (m.messageText || '').split('.');
            if (sentences.length > 0) {
              return sentences[0] + '.';
            } else {
              return '';
            }
          }
        });
      }
    });
  }
  

}
