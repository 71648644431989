import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { environment } from "../../../environments/environment";

export const logger = datadogLogs.logger;

const circularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
        if (typeof (value) === "object"
            && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const logLevel = (message: string, context?: any, status?: StatusType) => {
    let cleanContext = context;
    if (cleanContext) {
        try {
            cleanContext = JSON.parse(JSON.stringify(context, circularReplacer()));
        } catch (e) {
            // Nothing to do here
        }
    }
    logger.log(message, cleanContext, status);
}
export const logInfo = (message: string, context?: any) => { logLevel(message, context, StatusType.info) }
export const logWarn = (message: string, context?: any) => { logLevel(message, context, StatusType.warn) }
export const logDebug = (message: string, context?: any) => { logLevel(message, context, StatusType.debug) }
export const logError = (message: string, context?: any) => { logLevel(message, context, StatusType.error) }



function takeOverConsole() {
    const console = window.console as any;
    if (!console) return
    function intercept(method: string) {
        const original = console[method]
        console[method] = function () {
            // do sneaky stuff
            const logArgs = {};
            for (let i = 1; i < arguments.length; i++) {
                logArgs['arg' + i] = arguments[i];
            }
            logLevel(arguments[0], logArgs, (StatusType as any)[method]);
            // original(arguments[0], arguments[1]);
            if (original.apply) {
                // Do this for normal browsers
                original.apply(console, arguments)
            } else {
                // Do this for IE
                const message = Array.prototype.slice.apply(arguments).join(' ')
                original(message)
            }
        }
    }
    const methods = ['log', 'warn', 'error', 'debug'];
    for (let i = 0; i < methods.length; i++)
        intercept(methods[i])
}


export const initDatadog = () => {
    takeOverConsole();
    datadogLogs.init({
        clientToken: environment.datadogClientToken,
        forwardReports: 'all',
        sampleRate: 100,
        env: environment.environment,
        service: 'ctca.myctca.web',
        telemetrySampleRate: 100,
    });
}
