<app-card label="Prescriptions">
  <div card-buttons-desktop>
    <dx-button text="Download as PDF" icon="fa fa-download" [disabled]="!canDownload()" (onClick)="download()"></dx-button>
    <ng-container *ngIf="somethingToRenew && hasCareTeam">
      <dx-button *appShowFor="PermissionLevel.REQUEST_PRESCRIPTION_REFILL" text="Request Prescription Renewal"
        icon="fa fa-refresh" (onClick)="prescriptionRenewal()"></dx-button>
    </ng-container>
  </div>
  
  <div card-buttons-mobile >
    <dx-button  id="more-button" icon="fa fa-ellipsis-v" (onClick)="showMoreOptions($event)"></dx-button>
    <dx-context-menu #moreContextMenu [dataSource]="moreOptions" target="#more-button"
      [position]="{ at: 'bottom left' }" (onItemClick)="onMoreContextMenuItemClick($event)">
    </dx-context-menu>
  </div>

  <div *ngIf="!hasCareTeam && prettyPhone" class="noCareTeamPrescriptions">
    Renewing Your Prescription? Call your Care Team <a href="tel:{{cleanPhone}}">{{ prettyPhone }}</a>
    <hr/>
  </div>

  <dx-data-grid [dataSource]="data" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }"
    [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }"
    [wordWrapEnabled]="true" [rowAlternationEnabled]="true" [showColumnLines]="false"
    [searchPanel]="{ visible: true, placeholder: 'Search...' }" (onRowClick)="viewDetail($event)">

    <dxi-column dataField="drugName" caption="Medication" cellTemplate="drugNameTemplate"></dxi-column>

    <div *dxTemplate="let drug of 'drugNameTemplate'">
      <a (click)="viewDetail(drug)" class="dead-link">{{ drug.data.drugName }}</a>
    </div>

    <dxi-column dataField="statusType" caption="Status" width="100"></dxi-column>
    <dxi-column dataField="prescriptionType" caption="Prescribed By"></dxi-column>

  </dx-data-grid>
</app-card>
<router-outlet></router-outlet>