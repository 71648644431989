import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UppercasePipe } from '../shared/pipes/uppercase.pipe';
import { DxPopupModule, DxScrollViewModule } from 'devextreme-angular';

import { AlertComponent } from './alert/alert.component';
import { CardComponent } from './card/card.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { PermissionsDirectivesModule } from '../permissions-directives/permissions-directives.module';
import { ValidFormControlDirective } from './valid-form-control/valid-form-control.directive';
import { SmartlookDirective } from './smartlook-directive/smartlook.directive';
import { SchedulingHoursPipe } from './pipes/scheduling-hours.pipe';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, DxPopupModule, DxScrollViewModule],
  exports: [
    ReactiveFormsModule,
    CardComponent,
    AlertComponent,
    PhoneNumberPipe,
    UppercasePipe,
    SchedulingHoursPipe,
    CommonModule,
    PermissionsDirectivesModule,
    ValidFormControlDirective,
    SmartlookDirective
  ],
  declarations: [CardComponent, AlertComponent, PhoneNumberPipe, UppercasePipe, ValidFormControlDirective, SmartlookDirective, SchedulingHoursPipe],
  providers: [PhoneNumberPipe, UppercasePipe]
})
export class SharedModule {}
