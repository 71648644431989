import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StringHelper } from '../../shared/helpers/string.helper';
import { BaseComponent } from '../../core/component/base.component';
import { LabResultsService } from '../../core/services';
import { LabResultsCollection } from '../shared/models';
import { DxContextMenuComponent } from 'devextreme-angular';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

export enum MoreOption {
	ConsolidatedReport
}

@Component({
	selector: 'app-lab-results-list',
	templateUrl: './lab-results-list.component.html',
	styleUrls: ['./lab-results-list.component.scss']
})
export class LabResultsListComponent extends BaseComponent implements OnInit {
	
	@ViewChild('moreContextMenu', { static: true }) moreContextMenu: DxContextMenuComponent;
	labResultsDataSource: Array<LabResultsCollection>;

	moreOptions = [
		{ id: MoreOption.ConsolidatedReport, 
			text: 'Consolidated Report', 
			icon: 'fa fa-table' 
		}
 	];

	constructor(private labResultsService: LabResultsService, private route: ActivatedRoute, private router:Router) {
		super();
	}

	ngOnInit() {
		const data = this.route.snapshot.data.labResults;
		if (data && data.length > 0) {		
			this.labResultsDataSource  = this.labResultsService.mapLabResults(data);
			if(window.location.pathname.split('/').length<=3 && window.location.pathname.includes('lab-results'))
			this.calElapsedTime();
		} else {
			this.labResultsService.removeLabResultsFromCache();
			this.labResultsDataSource = new  Array<LabResultsCollection>();
			if(window.location.pathname.split('/').length<=3 && window.location.pathname.includes('lab-results'))
			this.calElapsedTime();
		}		
	}
	calElapsedTime()
	{
		const startTime = parseInt(localStorage.getItem('labResultsStartTime'), 10) || Date.now();
		const endTime = Date.now();
		const elapsedTime = (endTime-startTime)/1000;
		trackPageLoadingTime(window.location.pathname,elapsedTime);
		localStorage.removeItem('labResultsStartTime');
	}
	showMoreOptions(event: any) {
		this.moreContextMenu.instance.show();
	}

	onMoreContextMenuItemClick(event: any) {
		
		if (event && event.itemData.id === MoreOption.ConsolidatedReport) {
			this.viewConsolidatedLabReport();
		}
		
	}

	labSetsFilterExpression(filterValue: any, selectedFilterOperation: String) {
		const getLabSetsByRowData = (rowData: any) => {
			let labSets = '';
			for (const labSet of rowData.labSets) {
				labSets += `${labSet.name} `;
			}

			return labSets;
		};

		return [getLabSetsByRowData, selectedFilterOperation || 'contains', filterValue];
	}

	onLabResultSelected(data) {		
		this.router.navigate([StringHelper.replaceAll(data.dateCollected,"-",""),data.collectedBy],{relativeTo:this.route.parent});
	}

	viewConsolidatedLabReport() {
		this.router.navigate(['consolidated'],{relativeTo:this.route});
	}
}
