import { NgModule } from '@angular/core';
import { PhoneFieldModule } from '../core/phone-field/phone-field.module';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTabsModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxLoadPanelModule,
  DxContextMenuModule,
  DxTagBoxModule,
  DxSelectBoxModule,
  DxDateBoxModule,
  DxCheckBoxModule
} from 'devextreme-angular';
import { SubmitROIAuthorizationOnlineFormComponent } from './submit-roi-authorization-online-form.component';
import { SubmitROIAuthorizationOnlineFormRoutingModule } from './submit-roi-authorization-online-form.routing.module';


@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxTabsModule,
    DxListModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxLoadPanelModule,
    DxContextMenuModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    SubmitROIAuthorizationOnlineFormRoutingModule,
    PhoneFieldModule,
  ],
  declarations: [SubmitROIAuthorizationOnlineFormComponent],
  exports: [SubmitROIAuthorizationOnlineFormComponent]
})
export class SubmitROIAuthorizationOnlineFormModule { }
