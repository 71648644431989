import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, EMPTY, throwError as observableThrowError } from 'rxjs';

import { DocumentExchangeService } from '../services';
import { CCDADocumentDetailView } from '../services/models';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ResolveCcdaDocumentGuard implements Resolve<CCDADocumentDetailView> {
  constructor(private docXSvc: DocumentExchangeService, private router: Router) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): CCDADocumentDetailView | Observable<CCDADocumentDetailView> | Promise<CCDADocumentDetailView> {
    const id = route.params['id'];
    return this.docXSvc.getCCDADocumentDetail({ documentId: [id] }).pipe(catchError((error:HttpErrorResponse) => {
      if(error.status === 404) {
        this.router.navigate(['app','medical-documents','clinical-summaries']);
        return EMPTY;
      } else {
        return observableThrowError(error);
      }
    }));
  }
}
