<div class="flexV">
    <h1 class="mainTitle">{{title}}</h1>
    <hr>
    <h2 class="subtitle">Please enter your new email or edit the current one:</h2>
    <form novalidate>
    <dx-text-box class="emailField" placeholder="Email" [inputAttr]="{ id: 'email' }" [formControl]="emailControl" valueChangeEvent="keyup"></dx-text-box>
    </form>
    <div class="buttons" *ngIf="!editing">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            [disabled]="!emailControl.valid"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>
    <div class="buttons" *ngIf="editing">
        <dx-button
            text="Save"
            type="success"
            [disabled]="!emailControl.valid"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>
</div>