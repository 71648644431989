import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../core/component/base.component';
import {
	SupportService, UserProfileService, 
	UserPreferencesService, ResizingService, AppointmentsService } from '../core/services';
import { UserProfile, Appointment, UserPreference, FacilityInfo } from '../core/services/models';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { SITSurveyCommunicationService } from '../patient-reported/sit-survey/sit-survey-communication.service';
import { trackPageLoadingTime } from '../shared/helpers/tracking.helper';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {

	isTablet = false;
	isMobile = false;
	isMobileDevice = false;
	popupVisible = false;
	tulsaPopupVisible = false;
	philiPopupVisible = false;
	alertMessagesCount = 0;
	tabletSubscription:Subscription;
	mobileSubscription:Subscription;
	userProfile:UserProfile;
	upcomingAppointments: Array<Appointment>;
	facilityInfo: FacilityInfo;
	daysAhead = 0;
	pageSize = 3;
	static APPOINTMENTSDAYS = "AppointmentsDays";
	static DEFAULT_DAYS_AHEAD = 14;
	sitSurveyUrl = '';
	startTime = 0;
	endTime = 0;
	constructor(private supportService: SupportService, 
				private userProfileService:UserProfileService,
				private userPreferencesService: UserPreferencesService,
				private resizingService:ResizingService,
				private appointmentsSvc: AppointmentsService,
				private sitSurveyCommunicationService: SITSurveyCommunicationService) {
		super();
		this.startTime = Date.now();
		this.userProfile = null;		
		this.getInitialData();		
	}

	private getInitialData():void {
		// it will fire a subject to calc the amount of alert messages
		
		this.supportService.getAlertMessagesCount().subscribe((count:number) => {			
			this.alertMessagesCount = count;
		});
		this.supportService.getPrimaryFacilityInfo().subscribe((facilityInfo) => {
			this.facilityInfo = facilityInfo;
		});
		this.userProfileService.getUserProfileHardCache().subscribe((profile:UserProfile) => {
			this.userProfile = profile;			
			if(this.userProfile.userPermissions.indexOf(this.PermissionLevel.VIEW_APPOINTMENTS) > -1){
				if (profile.userPermissions.indexOf(this.PermissionLevel.VIEW_PREFERENCES) > -1) {
					this.userPreferencesService.getUserPreferences().subscribe((preferences:Array<UserPreference>) => {
						const preference: UserPreference = preferences.find(p => p.userPreferenceType === HomeComponent.APPOINTMENTSDAYS);
						this.daysAhead = preference ? parseInt(preference.userPreferenceValue, 10) : HomeComponent.DEFAULT_DAYS_AHEAD;
					});	
				} else {
					throw(new Error("Current user can not Manage Preferences"));
				}
				this.getAppointments();
			}
			
			if(this.userProfile.primaryFacility === "SRMC"){
				this.tulsaPopupVisible = true;
			} else if(this.userProfile.primaryFacility === 'ERMC'){
				this.philiPopupVisible = true;
			} else if(this.userProfile.userPermissions.indexOf(this.PermissionLevel.SUBMIT_PATIENT_REPORTED_DOCUMENTS) > -1){        
				this.checkSITSurveyDue();
			}
		});		
	}

	hasPermission(permission: PermissionLevel) {
		if (this.userProfile && this.userProfile.userPermissions) {
			return this.userProfile.userPermissions.indexOf(permission) > -1;
		}
		return false;
	}

	getAppointments() {
		const now = moment();
		const today = now.format('YYYY[-]MM[-]DD');
		const ahead = now.add(this.daysAhead + 1, 'd').format('YYYY[-]MM[-]DD');
		this.appointmentsSvc
		.getAppointmentsByDate(today,ahead)
		.subscribe((appts:Array<Appointment>) => {
			
			this.upcomingAppointments = appts;
			if (this.endTime === 0 && !localStorage.getItem('startTimeFromAuth'))
			{
				this.endTime = Date.now();
				const elapsedTime = (this.endTime-this.startTime)/1000;
				if(window.location.pathname.includes('app/home'))
				trackPageLoadingTime("/app/home/{time-took-to-load-home-page}",elapsedTime);
			}
		});
	}

	public showAlertMessages():string {
		return this.alertMessagesCount > 0 ? "block" : "none";
	}

	ngOnInit() {
		this.tabletSubscription = this.resizingService.IsTablet().subscribe((result:boolean) => {
			this.isTablet = result;
			this.isMobileDevice = this.isTablet || this.isMobile;
		});

		this.mobileSubscription = this.resizingService.IsMobile().subscribe((result:boolean) => {
			this.isMobile = result;
			this.isMobileDevice = this.isTablet || this.isMobile;
		});
		if(localStorage.getItem('startTimeFromAuth'))
		{	
			let elapsedTime = Date.now() - parseInt(localStorage.getItem('startTimeFromAuth'), 10);
			elapsedTime = elapsedTime/1000;
			if(window.location.pathname.includes('app/home'))
			trackPageLoadingTime("/app/home/{route-from-login-to-home}",elapsedTime);
			localStorage.removeItem('startTimeFromAuth');
		}
	}

	closePopup(){
		this.popupVisible = false;
	}

	checkSITSurveyDue(){
		this.sitSurveyCommunicationService.receivedUrl.subscribe((url: string) =>{
			if (this.endTime === 0)
			{
				this.endTime = Date.now();
				const elap = (this.endTime-this.startTime)/1000;
				if(window.location.pathname.includes('app/home'))
				trackPageLoadingTime("/app/home/{time-took-to-load-home-page}",elap);
			}
			this.sitSurveyUrl = url;
			if(this.sitSurveyUrl !== ''){
				this.popupVisible = false;
			}
		});
	}
	
	goToSITSurvey(){    
		if(this.sitSurveyUrl){
			window.open(this.sitSurveyUrl, "_blank");
			this.popupVisible = false;
		}
	}

	ngOnDestroy() {
		if (this.tabletSubscription) this.tabletSubscription.unsubscribe();
		if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
	}
}
