import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { ServiceEndpoint } from '../../core/services';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthenticationService } from '../../core/services';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-user-logs',
  templateUrl: './user-logs.component.html',
  styleUrls: ['./user-logs.component.scss']
})
export class UserLogsComponent implements OnInit {

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  logs:CustomStore;

  constructor(private authService:AuthenticationService) {
     
    const serviceUrl = `${environment.portalServiceBaseUrl}${ServiceEndpoint.USERPROFILE_GETACTIVITYLOGS}`;

    this.logs = AspNetData.createStore({
        loadUrl:serviceUrl,
        onBeforeSend: (operation, ajaxSettings) => {
          const authSignature: string = this.authService.getAuthSignature();
          if(authSignature) {
            ajaxSettings.headers = {};
            ajaxSettings.headers["Authorization"] = authSignature;
          }
        }
    });

  }
  
  clearFilters() {
    this.dataGrid.instance.clearFilter();
  }

  ngOnInit() {
  }

}
