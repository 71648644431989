import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';

import { BaseService } from './base.service';
import {
	Allergy,
	HealthIssue,
	Immunization,
	Prescription,
	VitalSign,
	VitalSignGraphData
} from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class HealthHistoryService extends BaseService {
	getAllergies(): Observable<Allergy[]> {
		return this.get({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETALLERGIES,
			cacheInvalidator: interval(50000)
		});
	}

	getAllergiesReport(params: HttpParams): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETALLERGIESREPORT,
			params: { sortOrder: params.get('sortOrder'), 
				sortColumn: params.get('sortColumn'), searchValue: params.get('searchValue') }
		});
	}

	getHealthIssues(): Observable<HealthIssue[]> {
		return this.get({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETHEALTHISSUES,
			cacheInvalidator: interval(50000)
		});
	}

	getHealthIssuesReport(): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETHEALTHISSUESREPORT
		});
	}

	getImmunizations(): Observable<Immunization[]> {
		return this.get({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETIMMUNIZATIONS,
			cacheInvalidator: interval(50000)
		});
	}

	getImmunizationsReport(): Observable<Blob> {
		return this.getBlob({ endpoint: ServiceEndpoint.HEALTHHISTORY_GETIMMUNIZATIONSREPORT });
	}

	getPrescriptions(): Observable<Prescription[]> {
		return this.get({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETPRESCRIPTIONS,
			cacheInvalidator: interval(50000)
		});
	}

	getPrescription(presciptionId: string): Observable<Prescription> {
		return this.post({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETPRESCRIPTION,
			body: { prescriptionId: presciptionId },
			cacheInvalidator: interval(50000),
			cacheKey: presciptionId
		});
	}

	getPrescriptionsReport(): Observable<Blob> {
		return this.getBlob({ endpoint: ServiceEndpoint.HEALTHHISTORY_GETPRESCRIPTIONSREPORT });
	}

	getVitals(): Observable<VitalSign[]> {
		return this.get({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETVITALS,
			cacheInvalidator: interval(50000)
		});
	}

	getVitalsReport(): Observable<Blob> {
		return this.getBlob({ endpoint: ServiceEndpoint.HEALTHHISTORY_GETVITALSREPORT });
	}

	getVitalsGraphItems(): Observable<{ graphItemsList: string[] }> {
		return this.get({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETVITALSGRAPHITEMS,
			cacheInvalidator: interval(50000)
		});
	}

	getVitalsGraphData(graphItem: string): Observable<VitalSignGraphData[]> {
		return this.get({
			endpoint: ServiceEndpoint.HEALTHHISTORY_GETVITALSGRAPHDATA,
			params: { graphItem: graphItem },
			cacheInvalidator: interval(50000),
			cacheKey: graphItem
		});
	}
}
