<div class="flexV">
    <h1 class="mainTitle">{{title}}</h1>
    <hr>
    <h2 class="subtitle">What is the reason for your appointment? (required)</h2>

    <dx-text-area class="reasonField" placeholder="(e.g Nutrition, Lab Follow-Up, Infusion etc.)"
    [height]="100" [inputAttr]="{ id: 'message' }" [(value)]="reason" valueChangeEvent="keyup"></dx-text-area>
   
    <div class="buttons" *ngIf="!editing">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            [disabled]="reason.length==0"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>
    <div class="buttons" *ngIf="editing">
        <dx-button
            text="Save"
            type="success"
            [disabled]="reason.length==0"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>
</div>