import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubmitROIAuthorizationOnlineFormComponent } from './submit-roi-authorization-online-form.component';

const routes: Routes = [
  {
    path: '',
    component: SubmitROIAuthorizationOnlineFormComponent,
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubmitROIAuthorizationOnlineFormRoutingModule { }
