import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { UserNotification } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';

@Injectable()
export class UserNotificationsService extends BaseService {
	
	getUserNotifications(): Observable<Array<UserNotification>> {
		return this.get({
			endpoint: ServiceEndpoint.USERPROFILE_GETUSERNOTIFICATIONS
		});
	}

	saveUserNotifications(userNotifications:Array<UserNotification>):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.USERPROFILE_SAVEUSERNOTIFICATIONS,
			body:userNotifications
		});
	}
	
}