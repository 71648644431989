import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { LabResultsService } from '../services';
import { LabResult } from '../services/models';

@Injectable({
  providedIn: 'root'
})
export class ResolveLabResultsGuard implements Resolve<LabResult[]> {
  constructor(private labResultsService: LabResultsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): LabResult[] | Observable<LabResult[]> | Promise<LabResult[]> {
    const labResults =  this.labResultsService.getLabResults();
    return labResults.pipe(()=>{
      localStorage.setItem('labResultsStartTime',Date.now().toString());
      return labResults;
    })
  }
}
