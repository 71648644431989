import { environment } from "../../../environments/environment";
import { logger, logInfo } from "./logging.helper";

let lastPagePath: string | undefined;
let lastPageTime: number | undefined;

export const trackEvent = (event: string, category?: string, label?: string, value?: number) => {
    const gtag = (window as any).gtag;
    logInfo('Track Event', {
        event,
        category,
        label,
        value
    });

    if (gtag) {
        gtag('event', event, {
            'event_category': category,
            'event_label': label,
            'value': value
        });
    }
}

export const trackPageLoadingTime = (path:string, time:number) => {
    trackEvent(path, 'Page Loading Time', undefined, time);
}

export const trackPageTime = () => {
    const gtag = (window as any).gtag;
    if (gtag && lastPagePath && lastPageTime) {
        const time = (Date.now() - lastPageTime) / 1000;
        trackEvent("Time on page", 'Time', lastPagePath, time);
        lastPagePath = undefined;
        lastPageTime = undefined;
    }
}

export const trackPageView = (path: string) => {
    const gtag = (window as any).gtag;
    logInfo('Track Page View', {
        event: 'Track Page View',
        page_path: path
    });
    logger.addContext("currentPage", path);
    trackPageTime();
    if (gtag) {
        gtag('config', environment.gaId, { 'page_path': path });
    }
    lastPagePath = path;
    lastPageTime = Date.now();
}