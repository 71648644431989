import {  HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
	FileDownloadService,
	LoadingService,
	ResizingService,
	SupportService,
} from 'app/core/services';
import { ClaimsService } from 'app/core/services/claims.service';
import { ClaimsDocument, FacilityInfo } from 'app/core/services/models';
import { DxDataGridComponent, DxSelectBoxComponent } from 'devextreme-angular';
import * as moment from 'moment-timezone';
import {  Subscription } from 'rxjs';
import { BaseComponent } from '../core/component/base.component';
import { DocumentSelectionType } from './document-selection-types.enum';
import { trackEvent } from '../shared/helpers/tracking.helper';

@Component({
	selector: 'app-claims',
	templateUrl: './claims.component.html',
	styleUrls: ['./claims.component.scss'],
})

export class ClaimsComponent extends BaseComponent implements OnInit, OnDestroy {
	@ViewChild('timeframeSelectBox', { static: false }) timeframeSelectBox: DxSelectBoxComponent;
	@ViewChild(DxDataGridComponent, {static: false}) dataGrid: DxDataGridComponent;
	@ViewChild('documentTypesSelectBox', { static: false }) documentTypesSelectBox: DxSelectBoxComponent;

	documents: ClaimsDocument[];
	filteredDocuments: ClaimsDocument[];
	
	printDocumentsSelected: string[] = [];
	emailDocumentsSelected: string[] = [];

	documentTypes = [
		{ documentType: 'Hospital Insurance Claim', selected: true },
		{ documentType: 'Professional Insurance Claim', selected: true },
		{ documentType: 'Itemized Billing Statement', selected: true },
	];
	defaultDocumentTypes = this.documentTypes.filter((d) => d.selected).map((d) => d.documentType);
	documentTypesSelected = [...this.defaultDocumentTypes];

	documentTypesButton: any;

	timeframes = [
		{
			showHeader: false,
			items: [
				{ name: 'Last day', time: 1 },
				{ name: 'Last 7 days', time: 7 },
				{ name: 'Last 30 days', time: 30 },
				{ name: 'Last quarter', time: 90, selected: true },
				{ name: 'Last year', time: 365 },
				{ name: 'All', time: 36500 },
			],
		},
		{
			showHeader: true,
			items: [],
		},
	];
	defaultTimeframe = this.timeframes[0].items.find((t) => t.selected)?.time;
	timeframeSelected = this.defaultTimeframe;

	mobileSubscription: Subscription;
	isMobile = false;
	uppercasePipe: any;

	// properties for the popup
	popupVisible: boolean;
	positionOf: string;
	closeButtonOptions: any;
	responseText: string = "";

	facilityInfo: FacilityInfo;

	constructor(
		public loadingService: LoadingService,
		public claimsService: ClaimsService,
		private downloadService: FileDownloadService,
		private resizingService: ResizingService,
		private supportService: SupportService, 
	) {
		super();

		this.mobileSubscription = this.resizingService.IsMobile().subscribe((result: boolean) => {
			this.isMobile = result;
		});

		setTimeout(() => {
			this.timeframeSelectBox.instance.open();
			this.timeframeSelectBox.instance.close();
			this.timeframeSelectBox.instance.repaint();
		}, 0);

		this.popupVisible = false;

		const that = this;

		this.closeButtonOptions = {
			text: 'Close',
			onClick(e: any) {
				that.popupVisible = !that.popupVisible;
			},
		};

		this.supportService.getPrimaryFacilityInfo().subscribe((facilityInfo) => {
			if (Array.isArray(facilityInfo)) {
				// default to the first item in the array
				this.facilityInfo = facilityInfo[0];
			} else {
				this.facilityInfo = facilityInfo;
			}
		});

		this.documentTypesButton = {
			icon: 'spindown',
			stylingMode: 'text',
			onClick: () => {
				// open the dropdown
				this.documentTypesSelectBox.instance.open();
			},
		}
 
		this.reloadData();
	}

	ngOnInit(): void {

	}

	ngOnDestroy(): void {
		if (this.mobileSubscription) {
			this.mobileSubscription.unsubscribe();
		}
	}

	/**
	 * Called by the filter dropdown
	 * @param e Value changed event
	 */
	documentTypeChanged(e: any) {
		this.documentTypesSelected = e.value;
		this.filterDocuments();
		// close drowdown
		this.documentTypesSelectBox.instance.close();
	}

	/**
	 * Call when the time period is changed
	 * @param e Value changed event
	 */
	timeframeChanged(e: any) {
		this.timeframeSelected = e.value;
	}

	/**
	 * Obtains a single or multiple documents from the API.
	 * @returns void
	 */
	downloadSelected = () => {

		// check how many rows where selected
		if (this.printDocumentsSelected.length === 1) {
			// if one selected then call the single document download

			this.loadingService.loading = true;
			this.claimsService.getDocument(this.printDocumentsSelected[0], DocumentSelectionType.Print).subscribe((response: HttpResponse<Blob>) => {
				this.loadingService.loading = false;
				
				// tracking
				trackEvent("Claims and Statements - Download of single document");

				//let fileType = this.getFileType(response.body.type);
				let fileName = response.headers.get('Content-Disposition')?.split(';')[1].split('=')[1];
				let blob: Blob = response.body as Blob;

				this.downloadService.downloadAnyFileType(blob, fileName);
			});
		} else {
			// if more than one then call the multiple download endpoint

			this.loadingService.loading = true;
			this.claimsService.getMultipleDocuments(this.printDocumentsSelected, DocumentSelectionType.Print).subscribe((response: HttpResponse<Blob>) => {
				this.loadingService.loading = false;

				// tracking
				trackEvent("Claims and Statements - Download zip file with multiple documents");

				let fileName = response.headers.get('Content-Disposition')?.split(';')[1].split('=')[1];
				let blob: Blob = response.body as Blob;

				this.downloadService.downloadAnyFileType(blob, fileName);
			});
		}

		return;
	};

	/**
	 * Makes a call the the Portal API with the selected document Id's so they can be emailed.
	 * @returns 
	 */
	emailSelected = () => {
		// check how many rows where selected
		if (this.emailDocumentsSelected.length === 1) {
			this.loadingService.loading = true;
			this.claimsService.emailDocument(this.emailDocumentsSelected[0], DocumentSelectionType.Email).subscribe((response: string) => {
				this.loadingService.loading = false;

				// tracking
				trackEvent("Claims and Statements - Email single document");
				
				this.responseText = response;
				this.popupVisible = true;
			});
		} else {
			this.loadingService.loading = true;
			this.claimsService.emailMultipleDocuments(this.emailDocumentsSelected, DocumentSelectionType.Email)
			.subscribe((response: string) => {
				this.loadingService.loading = false;

				// tracking
				trackEvent("Claims and Statements - Email zip file with multiple documents");

				this.responseText = response;
				this.popupVisible = true;
			})
		}

		return;
	}

	/**
	 * Raised when multiple tags are selected in the UI
	 * @param args 
	 */
	onMultiTagPreparing(args: any) {
		const selectedItemsLength = args.selectedItems.length;
		if (selectedItemsLength === this.documentTypes.length) {
			args.text = `All selected (${selectedItemsLength})`;
		} else if (selectedItemsLength === 1) {
			args.cancel = true;
		} else {
			args.text = `${selectedItemsLength} selected`;
		}
	}

	/**
	 * Raised when timeframe is selected
	 */
	applyTimeFilter = () => {
		this.timeframeSelectBox.instance.close();
		this.reloadData();
	};

	/**
	 * Makes the call to the Portal API to get the list of documents
	 */
	reloadData = () => {
		this.loadingService.loading = true;
		const start = moment().subtract(this.timeframeSelected, 'days').format('YYYY-MM-DD');
		const end = moment().add(1, 'days').format('YYYY-MM-DD');
		this.claimsService.getDocumentsList(0, start, end).subscribe((claims) => {
			// loop to add default values in object
			claims.forEach(claim => {
				claim.print = false;
				claim.email = false;

				// adjust the document date to the patient primary facility
				// convert the offset to a time zone
				let timeZone: string = this.findFacilityTimeZone(this.facilityInfo.name);
				let documentMoment = moment.tz(claim.documentDate, timeZone).format();
				if (this.facilityInfo?.timeZoneOffset) {
					// date will be displayed based in the locale of the browser, i.e. if you are in Phoenix, it will show AZ time.
					claim.documentDate = new Date(documentMoment);
				}
			});

			this.loadingService.loading = false;
			this.documents = claims;
			this.filterDocuments();
		});
	};

	/**
	 * Discards the documents that don't match the selected filter
	 */
	filterDocuments = () => {
		if (this.documents) {
			this.filteredDocuments = this.documents.filter(
				(d) => this.documentTypesSelected.indexOf(d.documentType) >= 0
			);
			
		}
	};


	/**
	 * Keeps track of the document records that are been selected
	 * @param newData Selected document record
	 * @param value new entered value
	 * @param currentRowData not modified document record
	 */
	setEmailCheckboxValue = (newData: any, value: any, currentRowData: object) => {
		
		newData.print = value;
		if (value) {
			newData.email = !value;
	
			// add to selection array
			this.addNewDocumentIdSelection(currentRowData['documentId'], DocumentSelectionType.Print);

			// remove from email selection list if unchecked
			if (!newData.email) {
				this.removeDocumentIdFromSelection(currentRowData['documentId'], DocumentSelectionType.Email);
			}
		} else {
			// remove from selection
			this.removeDocumentIdFromSelection(currentRowData['documentId'], DocumentSelectionType.Print);
		}
	}

	/**
	 * Keep control of the documents selected to be printed
	 * @param newData Selected document record
	 * @param value New entered value
	 * @param currentRowData Document record that hasn't been modified
	 */
	setPrintCheckboxValue = (newData: any, value: any, currentRowData: object) => {

		newData.email = value;
		if (value) {
			newData.print = !value;
			// add to selection array
			this.addNewDocumentIdSelection(currentRowData['documentId'], DocumentSelectionType.Email);

			// remove from print selection list if unchecked
			if (!newData.print) {
				this.removeDocumentIdFromSelection(currentRowData['documentId'], DocumentSelectionType.Print);
			}
		}
		else {
			// remove from selection
			this.removeDocumentIdFromSelection(currentRowData['documentId'], DocumentSelectionType.Email);
		}
	}

	/**
	 * Logic to add to selected array the end user options
	 * @param documentId Document Id to add
	 * @param selection Print or Email
	 */
	addNewDocumentIdSelection = (documentId: string, selection: DocumentSelectionType) => {
		let documentFoundFlag: boolean = false;
		documentFoundFlag = this.checkIfDocumentIdIsInSelection(documentId, selection);

		if (!documentFoundFlag) {
			if (selection === DocumentSelectionType.Print) {
				this.printDocumentsSelected.push(documentId);
			} else if (selection === DocumentSelectionType.Email) {
				this.emailDocumentsSelected.push(documentId);
			}
		} else {
			if (selection === DocumentSelectionType.Print) {
				this.printDocumentsSelected = [documentId];
			} else if (selection === DocumentSelectionType.Email) {
				this.emailDocumentsSelected = [documentId]
			}
		}
		//this.debugDocumentSelected();
	}

	/**
	 * Debug utility
	 */
	debugDocumentSelected = () => {
		console.log('printDocumentSelected...');
		this.printDocumentsSelected.forEach(item => {
			console.log(item);
		});

		console.log('emailDocumentsSelected...');
		this.emailDocumentsSelected.forEach(item => {
			console.log(item);
		});

	}

	/**
	 * Remove a document Id from the selection list
	 * @param documentId Document Id to be removed
	 * @param documentSelectedType Print or Email
	 */
	removeDocumentIdFromSelection = (documentId: string, documentSelectedType: DocumentSelectionType) => {
		let foundDocumentFlag: boolean = false;
		foundDocumentFlag = this.checkIfDocumentIdIsInSelection(documentId, documentSelectedType);
		// search for document Id in array
		if (foundDocumentFlag) {
			// remove from array
			let index: number = 0;
			switch(documentSelectedType) {
				case DocumentSelectionType.Print: {
					index = this.printDocumentsSelected.indexOf(documentId);
					this.printDocumentsSelected.splice(index, 1);
					break;
				}
	
				case DocumentSelectionType.Email: {
					index = this.emailDocumentsSelected.indexOf(documentId);
					this.emailDocumentsSelected.splice(index, 1);
					break;
				}
			}
		}

		// debug purposes
		//this.debugDocumentSelected();
	}

	/**
	 * Checks in the selection list for a document Id 
	 * @param documentId Document Id to search
	 * @param documentSelectedType Print or Email
	 * @returns True or False
	 */
	checkIfDocumentIdIsInSelection = (documentId: string, documentSelectedType: DocumentSelectionType): boolean => {
		let result: boolean = false;

		switch(documentSelectedType) {
			case DocumentSelectionType.Print: {
				result = this.printDocumentsSelected.includes(documentId);
				break;
			}

			case DocumentSelectionType.Email: {
				result = this.emailDocumentsSelected.includes(documentId);
				break;
			}
		}

		return result;
	}

	/**
	 * Reads the Facility code and returns the corresponding Time Zone
	 * @param facilityCode Facility Code
	 * @returns Time Zone region
	 */
	findFacilityTimeZone = (facilityCode: string): string => {
		let resultTimeZone: string;

		switch(facilityCode) {
			case "ONCORDIS":
			case "MRMC": {
				resultTimeZone = "America/Chicago";
				break;
			}
			case "SERMC": {
				resultTimeZone = "America/New_York";
				break;
			}
			case "OCC":
			case "WRMC": {
				resultTimeZone = "America/Phoenix";
				break;
			}
		}

		return resultTimeZone;
	}

}

