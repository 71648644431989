import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule
} from 'devextreme-angular';

import { SharedModule } from '../shared/shared.module';
import { LabResultsDetailComponent } from './lab-results-detail/lab-results-detail.component';
import { LabResultsListComponent } from './lab-results-list/lab-results-list.component';
import { LabResultsRoutingModule } from './lab-results-routing.module';
import { ConsolidatedLabReportComponent } from './consolidated-lab-report/consolidated-lab-report.component';

@NgModule({
  imports: [
    SharedModule,
    DxDataGridModule,
    DxButtonModule,
    DxContextMenuModule,
    DxPopupModule,
    DxScrollViewModule,
    LabResultsRoutingModule
  ],
  declarations: [LabResultsListComponent, LabResultsDetailComponent, ConsolidatedLabReportComponent],
  providers: [DatePipe]
})
export class LabResultsModule {}
