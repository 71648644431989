import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTabsModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxLoadPanelModule,
  DxContextMenuModule,
  DxTagBoxModule,
  DxSelectBoxModule,
  DxDateBoxModule,
  DxCheckBoxModule
} from 'devextreme-angular';
import { AdvancedNoticeNonCoverageFormComponent } from './advanced-notice-non-coverage-form.component';
import { AdvancedNoticeNonCoverageFormRoutingModule } from './advanced-notice-non-coverage-form.routing.module';


@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxTabsModule,
    DxListModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxLoadPanelModule,
    DxContextMenuModule,
    DxTagBoxModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    DxCheckBoxModule,
    AdvancedNoticeNonCoverageFormRoutingModule
  ],
  declarations: [AdvancedNoticeNonCoverageFormComponent],
  exports: [AdvancedNoticeNonCoverageFormComponent]
})
export class AdvancedNoticeNonCoverageFormModule { }
