import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { first, map } from 'rxjs/operators';

import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { UserProfileService } from '../core/services';
import { UserProfile } from '../core/services/models';

@Directive({
	selector: '[appShowFor]'
})
export class ShowForDirective {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainerRef: ViewContainerRef,
		private profileSvc: UserProfileService
	) {}

	@Input()
	set appShowFor(permissionLevel: PermissionLevel) {
		this.profileSvc.getUserProfileHardCache().pipe(
				map((profile:UserProfile):boolean => {
					if (permissionLevel === PermissionLevel.NO_REQUIREMENTS) {
						return true;
					}
					if (profile.userPermissions.indexOf(permissionLevel) > -1) {
						return true;
					}
					return false;
				}),
				first()
			)
			.subscribe((hasPermission:boolean) => {
				if (hasPermission) {
					this.viewContainerRef.createEmbeddedView(this.templateRef);
				}
			});
	}
}
