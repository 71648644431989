import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseService } from './base.service';
import { UserProfile } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { logger } from '../../shared/helpers/logging.helper';

@Injectable()
export class UserProfileService extends BaseService {

	private hardCache:UserProfile;

	constructor(router: Router, httpClient: HttpClient, private authSvc: AuthenticationService) {
		super(router, httpClient);
		this.hardCache = null;
	}

	getUserProfile(): Observable<UserProfile> {
		
		return this.get({
			endpoint: ServiceEndpoint.USERPROFILE_GETUSERPROFILE,
			cacheInvalidator: this.authSvc.activeTokenChanged$
		}).pipe(tap((userprofile:UserProfile)=>{
			this.hardCache = userprofile;

			logger.addContext("user", {
				isSuperUser: userprofile.isSuperUser,
				epiId: userprofile.epiId,
				primaryFacility: userprofile.primaryFacility,
				userType: userprofile.userType,
				roles: userprofile.roles,
				ctcaId: userprofile.ctcaId
			});
			const proxy = userprofile.proxies?.find(p => p.isImpersonating);
			if (proxy) {
				logger.addContext("proxy", {
					ctcaId: proxy.toCtcaUniqueId,
					relationshipType: proxy.relationshipType
				});
			}
		}));
		
	}

	getUserProfileHardCache(): Observable<UserProfile> {
		if (this.hardCache) return of(this.hardCache);
		return this.getUserProfile();
	}
	
	canPatientBeImpersonatedBy(patientId:string):Observable<boolean> {
		return this.get({
			endpoint: ServiceEndpoint.USERPROFILE_CANPATIENTBEIMPERSONATEDBY,
			params:{				
				patientId:patientId
			}
		});
	}
}
