import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxDateBoxModule,
  DxListModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabsModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule  
} from 'devextreme-angular';

import { SharedModule } from '../shared/shared.module';
import { FinancialComponent } from './financial/financial.component';
import { FormLibraryRoutingModule } from './form-library-routing.module';
import { FormLibraryComponent } from './form-library.component';
import { ReleaseOfInformationComponent } from './release-of-information/release-of-information.component';
import { AdvancedNoticeNonCoverageComponent } from './advanced-notice-non-coverage/advanced-notice-non-coverage.component';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxTabsModule,
    DxListModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    DxRadioGroupModule,
    DxLoadPanelModule,
    FormLibraryRoutingModule
  ],
  declarations: [
    FormLibraryComponent,
    FinancialComponent,
    ReleaseOfInformationComponent,
    AdvancedNoticeNonCoverageComponent
  ],
  exports: []
})
export class FormLibraryModule {}
