<app-card label="Prescription Renewal Request">
  <div class="popup-content">
    <form *ngIf="prescriptionForm" [formGroup]="prescriptionForm" novalidate>
      <div class="info shorter">
        <i class="fa fa-info-circle fa-3x"></i>
        <p>
          If this is an existing prescription, please contact your pharmacy directly. 
          Please use this prescription request form for new prescriptions, changes in prescriptions, 
          and/or change in pharmacy. Please allow 1 business day for your care team to contact you upon prescription request.
        </p>
      </div>
      <div *ngIf="!careteamsExist" class="alert alert-warning shorter">
        <div class="content">
          We are unable to determine the appropriate clinical personnel to route your message to, 
          please contact your treatment facility at {{ primaryFacility?.mainPhone }} for assistance.
        </div>
      </div>
      <div class="dx-fieldset appointment-details">
        <div class="dx-field shorter">
          <div class="dx-field-label">From</div>
          <div class="dx-field-value-static">{{profile?.firstName}} {{profile?.lastName}}</div>
        </div>
        <div *ngIf="careteamsExist" class="dx-field shorter">
          <div class="dx-field-label">To</div>
          <div class="dx-field-value-static">
            <dx-tag-box
              [dataSource]="careteams"
              [showSelectionControls]="true"
              displayExpr="name"
              valueExpr="systemId"
              (onValueChanged)="itemSelectionChanged($event)"
              [inputAttr]="{title:'Recipients'}"
              [elementAttr]="{title:'Recipients'}"
            >
            </dx-tag-box>
          </div>
        </div>
        <div class="dx-field shorter">
          <div class="dx-field-label">Subject</div>
          <div class="dx-field-value-static">Refill Request</div>
        </div>
        <div class="dx-field shorter">
          <div class="dx-field-label">Prescriptions</div>
          <div formArrayName="prescriptions">
            <div class="dx-field-value">
              <span *ngFor="let prescription of prescriptions.controls;  let i = index" [formGroupName]="i">
                <dx-check-box class="display-table-cell" style="padding-top:10px;display: block;" [formControlName]="getKey(prescription['controls'])"  
                text="{{checkBoxName(prescription)}}" (onValueChanged)="onPrescriptionSelectionChange(prescriptionDictionary[getKey(prescription['controls'])].prescriptionId)" [isValid]="prescriptionSelectionIsValid" [ValidFormControl]="prescriptionSelectionIsValid"
                [validationError]="{ message: 'Selecting a prescription is required. Only one prescription can be renewed at a time.' }"></dx-check-box>
              </span>
            </div>
          </div>
        </div>
        <div class="dx-field shorter">
          <div class="dx-field-label"><label for="patientPhone">My Phone Number</label></div>
          <div class="dx-field-value">
            <app-phone-field [inputAttr]="{ id: 'patientPhone' }" formControlName="patientPhone" 
              [isValid]="prescriptionForm.get('patientPhone').valid || prescriptionForm.get('patientPhone').pristine" (change)="markAsDirtyForm()">                
            </app-phone-field>
          </div>
        </div>
        <div class="dx-field shorter">
          <div class="dx-field-label"><label for="pharmacyName">Pharmacy Name</label></div>
          <div class="dx-field-value">
            <dx-text-box [inputAttr]="{ id: 'pharmacyName' }" formControlName="pharmacyName" [isValid]="prescriptionForm.get('pharmacyName').valid || prescriptionForm.get('pharmacyName').pristine" [validationError]="{ message: 'Pharmacy name is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>
          </div>
        </div>
        <div class="dx-field shorter">
          <div class="dx-field-label"><label for="pharmacyPhone">Pharmacy Phone</label></div>
          <div class="dx-field-value">
            <app-phone-field [inputAttr]="{ id: 'pharmacyPhone' }" formControlName="pharmacyPhone" 
              [isValid]="prescriptionForm.get('pharmacyPhone').valid || prescriptionForm.get('pharmacyPhone').pristine" (change)="markAsDirtyForm()">
            </app-phone-field>
          </div>
        </div>
        <div class="dx-field shorter">
          <div class="dx-field-label"><label for="comments">Comments</label></div>
        </div>
        <div class="dx-field shorter">
          <dx-text-area [inputAttr]="{ id: 'comments' }" formControlName="comments" height="175px" (change)="markAsDirtyForm()"></dx-text-area>
        </div>
      </div>
      <dx-toolbar>
        <dxi-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
        </dxi-item>
        <dxi-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Send', onClick: send }">
        </dxi-item>
      </dx-toolbar>
    </form>
  </div>
</app-card>
<router-outlet></router-outlet>