<div class="flexV">
    <h1 class="mainTitle">Request Appointment Schedule</h1>
    <hr>
    <h2 class="subtitle">Which date range?</h2>

    <div class="cards">
        <ng-container *ngFor="let range of ranges">
            <app-selectable-item 
                [hasCheckbox]="true" 
                [checked]="selectedRange?.id==range.id" 
                (onClick)="onSelectRange(range)" 
                [clickable]="true" 
                [title]="range.title" 
                [subtitle]="range.text"
                [hasEdit]="range.hasEdit"
                (onEdit)="openCalendar()"
            ></app-selectable-item>
        </ng-container>
    </div>
   
    <div class="buttons" *ngIf="!editing">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            [disabled]="!(selectedRange && (selectedRange.id!='custom' || (selectedRange.id=='custom' && customDates)))"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>

    <div class="buttons" *ngIf="editing">
        <dx-button
            text="Save"
            type="success"
            [disabled]="!(selectedRange && (selectedRange.id!='custom' || (selectedRange.id=='custom' && customDates)))"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>


    <div class="sidePopup calendar" *ngIf="showCalendar">
        <img class="closeButton" (click)="closeCalendar()" src="assets/images/icons/SVG/close.svg" alt="Close calendar"/>
        <h2>Select the time period for your schedule</h2>
        <div class="dates">
            <div [class]="selectingDate=='start'?'date selected':'date'">
                <div class="label">Start</div>
                <div class="value">{{startDate | date:'mediumDate'}}&nbsp;</div>
            </div>
            <div [class]="selectingDate=='end'?'date selected':'date'">
                <div class="label">End</div>
                <div class="value">{{endDate | date:'mediumDate'}}&nbsp;</div>
            </div>
        </div>
        <h2>Preferred Period</h2>
        <div class="calendarWrapper"
        >
            <dx-calendar #calendar [(value)]="dateSelected" [disabledDates]="disabledDates" (onValueChanged)="onDateChanged()">
                <span *dxTemplate="let cellData of 'cell'; let i = index" [class]="dateClass(cellData)">
                    {{cellData.text}}
                </span>
            </dx-calendar>
        </div>
        
        <div class="spacer"></div>
        <app-button [disabled]="!(startDate && endDate)" text="Save" (onClick)="saveDateRange()"></app-button>
    </div>
</div>