<div class="flexV">
    <h1 class="mainTitle">Request Medical Records</h1>
    <hr>
    <p class="center">There are two ways to obtain a <b>Release of your Medical Information.</b></p>

    <div class="buttons">
        <dx-button
            [elementAttr]="{class:'downloadButton'}"
            icon="ctca-download-white"
            text="Download ROI Authorization Form"
            type="success"
            (onClick)="onDownloadRoiClick($event)"
        ></dx-button>
    </div>

    <p class="center">
        Bring the completed form to your upcoming visit and deliver it to the ROI office at your treatment facility.<br/>
        <br/>
        or
    </p>

    <div class="buttons">
        <dx-button
            text="Complete ROI Authorization Online"
            type="success"
            (onClick)="onCompleteRoiClick($event)"
        ></dx-button>
    </div>

    <p class="center">
        For further questions regarding the document or form, please contact the Health Information Management (ROI) team at your treatment facility at 1 (800) 111 2345.
    </p>

    <div class="buttons">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close($event)"
        ></dx-button>
    </div>
</div>