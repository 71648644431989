<app-card label="SIT Data Graphs">

  <form *ngIf="symptomForm" [formGroup]="symptomForm" novalidate>
    <div class="dx-fieldset">
      <div class="dx-field">
        <label class="visuallyhidden" for="Symptom">Pick a Symptom</label>
        <dx-select-box [inputAttr]="{ id: 'Symptom' }" placeholder="Pick a Symptom" [dataSource]="graphs" displayExpr="Name" valueExpr="Name" formControlName="selectedSymptom"
            [isValid]="symptomForm.controls['selectedSymptom'].valid || symptomForm.controls['selectedSymptom'].pristine"
            [validationError]="{ message: 'Symptom is required' }"
            (onSelectionChanged)="updateGraph($event)">
        </dx-select-box>            
      </div>
    </div>
  </form>

  <div *ngIf="isGraphDataAvailable()">
    <dx-chart id="chart" [dataSource]="selectedGraph.data" title="Reported Scores - {{selectedGraph.Name}}" palette="green">
        <dxi-series argumentField="dateEntered" valueField="value" [name]="selectedGraph.Name"  type="line" color="#1db2f5"></dxi-series>
        <dxi-value-axis valueType="numeric" [valueMarginsEnabled]="false" title="Severity" wholeRange="[0, 10]"></dxi-value-axis>
        <dxo-argument-axis argumentType="datetime" [valueMarginsEnabled]="false" discreteAxisDivisionMode="crossLabels" title="Date">          
          <dxo-grid [visible]="true"></dxo-grid>
        </dxo-argument-axis>
        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="right">
            <dxo-border [visible]="false"></dxo-border>
        </dxo-legend>
        <dxo-export [enabled]="true"></dxo-export>
        <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip"></dxo-tooltip>
    </dx-chart>
  </div>

</app-card>
