import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { CancelAppointmentV2Component } from './cancel-appointmentv2.component';

const routes: Routes = [
	{
		path: '',
		component: CancelAppointmentV2Component,
		data: { permissionLevel: PermissionLevel.REQUEST_APPOINTMENT }
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class CancelAppointmentV2RoutingModule { }
