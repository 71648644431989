<div class="card-row">
  <div class="card-col">
    <app-card label="Bill Pay">
      <div class="bill-pay">
        <div class="bill-pay-row">
          <div class="bill-pay-col">
            <img src="assets/images/patient-portal-me-logo.png" class="bill-pay-logo" alt="PatientPortal.me"/>
          </div>
          <div class="bill-pay-col">
            <ul class="bill-pay-text">
              <li>Make a secure online payment</li>
              <li>View your balance</li>
              <li>View past invoices</li>
              <li>Get help with billing</li>
            </ul>
          </div>
          <div class="bill-pay-col">
            <div class="bill-pay-button">
              <dx-button text="Pay Your Bill Online" type="default" (onClick)="openBillPay()"></dx-button>
            </div>
          </div>
        </div>
      </div>
    </app-card>
  </div>
</div>
