export class StringHelper {

    public static isNumeric(x):boolean {
        return !isNaN(parseFloat(x)) && isFinite(x);
    }

    public static isInteger(x):boolean {
        const reg = new RegExp('^[1-9][0-9]*$');
        return reg.test(x);       
    }
    
    public static sharedStart(array):string {
        const A = array.concat().sort();
        const a1 = A[0];
        const a2 = A[A.length - 1];
        const L = a1.length;
        let i= 0;
        while(i < L && a1.charAt(i) === a2.charAt(i)) i++;
        return a1.substring(0, i);
    }

    public static replaceAll(s:string,search:string, replace:string):string {
        return s.split(search).join(replace);
    }

    public static nonce(length: number) {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
}