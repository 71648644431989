import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SendMessageTechnicalSupportModalComponent } from '../send-message-to-technical-support-modal/send-message-to-technical-support-modal.component';
import { BaseComponent } from '../../core/component/base.component';

@Component({
  selector: 'app-contact-technical-support',
  templateUrl: './contact-technical-support.component.html',
  styleUrls: ['./contact-technical-support.component.scss']
})
export class ContactTechnicalSupportComponent extends BaseComponent implements OnInit {
  @ViewChild(SendMessageTechnicalSupportModalComponent, { static: false })
  sendMessageModal: SendMessageTechnicalSupportModalComponent;
  constructor(private router: Router) {
    super();
  }
  ngOnInit(): void {}

  onSecureMailLinkClick() {
    this.router.navigateByUrl(`/app/send-message`);
  }

  sendEmailToTechSupport() {}
}
