import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent extends BaseComponent implements  OnInit {
  @Input() level = 'info';
  icon: string;
  levelCSSClass: string;

  ngOnInit() {
    switch (this.level.toLowerCase()) {
      case 'success':
        this.icon = 'fa-check';
        this.levelCSSClass = 'alert-success';
        break;
      case 'warning':
        this.icon = 'fa-exclamation-triangle';
        this.levelCSSClass = 'alert-warning';
        break;
      case 'danger':
        this.icon = 'fa-times';
        this.levelCSSClass = 'alert-danger';
        break;
      default:
        this.icon = 'fa-info-circle';
        this.levelCSSClass = 'alert-info';
    }
  }
}
