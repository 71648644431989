import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { AppointmentsService, ContactUsStateService, LoadingService } from 'app/core/services';
import { AppointmentDetail, ContactUsFormType, ContactUsSubject } from 'app/core/services/models';


@Component({
  selector: 'app-ask-question-non-medical-select-appointment',
  templateUrl: './ask-question-non-medical-select-appointment.component.html',
  styleUrls: ['./ask-question-non-medical-select-appointment.component.scss']
})
export class AskQuestionNonMedicalSelectAppointmentComponent extends BaseComponent implements OnInit {

  selectedAppointment: AppointmentDetail;
  appointments: AppointmentDetail[] = [];
  title: string;
  subtitle: string;

  constructor(private route: ActivatedRoute, private router: Router, private apptSvc: AppointmentsService, private contactUsState: ContactUsStateService, private loadingService: LoadingService) {
    super();
  }



  ngOnInit(): void {
    const state = this.contactUsState.getState();
    const appointment: AppointmentDetail = state.appointment;
    const subject: ContactUsSubject = state.subject;
    this.title = subject.value;
    if (subject.formType === ContactUsFormType.CancelAppointment) {
      this.subtitle = 'Which appointment would you like to cancel?';
    } else if (subject.formType === ContactUsFormType.RescheduleAppointment) {
      this.subtitle = 'Which appointment would you like to reschedule?';
    }
    console.log('appointment:', appointment);
    this.selectedAppointment = appointment;

    this.loadingService.loading = true;
    this.apptSvc.getAppointments().subscribe((appointments: Array<AppointmentDetail>) => {
      this.loadingService.loading = false;
      this.appointments = appointments.filter(a => a.isUpcoming);
    });
  }

  onNextClick = (e: any) => {
    this.contactUsState.setState({ appointment: this.selectedAppointment });
    const state = this.contactUsState.getState();
    const subject: ContactUsSubject = state.subject;
    if (subject.formType === ContactUsFormType.RescheduleAppointment) {
      this.router.navigate(['non-medical', 'appointment-time'], { relativeTo: this.route.parent });
    } else {
      this.router.navigate(['non-medical', 'appointment-contact'], { relativeTo: this.route.parent });
    }
  }

  close = () => {
    window.history.back();
  }

  selectAppointment = (appointment: AppointmentDetail) => {
    this.selectedAppointment = appointment;
  }

}
