export const environment = {
	version: '3.5-prod',
	cacheLogging: false,
	production: true,
	portalServiceBaseUrl: 'https://service.myctca.com/api/v1/',
	billPayUrl: 'https://www.patientportal.me/CTCA/Patient/login.html ',
	ctcaIdentityUrl: 'https://accounts.myctca.com/user/detail',
	securityTab: 'userdetails-securityprivacy',
	profileTab: 'userdetails-details',
	medicalProfileTab: 'userdetails-medicalprofile',
	labResultsReviewWarning: "24",
	appointmentActionDisclaimer: "24",
	outageMessage: " ",
	gaId: "UA-23384102-1",
	smartlookKey: "cb1859de6a163bc6bc5d08054c7c6895f980f279",
	launchDarklyKey: "6127df81d159f3262c229311",
	chatBotUrl: "https://ctca-hc-covidassessmentbot-dev.azurewebsites.net/",
	sitSurveyUrl: 'https://portal.myctca.com/myProsk/SITInterface/BypassLogin.aspx',
	datadogClientToken: 'pub9b57b7280265fa6e9d1a918bb914cebe',
	environment: 'prod',
	oidc: {
		authority: 'https://accounts.myctca.com',
		client_id: 'CTCA.Portal.UI.Web',
		redirect_uri: 'https://portal.myctca.com/auth',
		post_logout_redirect_uri: 'https://portal.myctca.com/',
		silent_redirect_uri: 'https://v3.myctca.com/silent-renew',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
		automaticSilentRenew: false,
		monitorSession: true,
		loadUserInfo: false,
		revokeAccessTokenOnSignout:
			false,
		authenticationLoggingEnabled:
			true
	},
	auth0: {
		domain: 'auth.myctca.com',
		audience: 'http://identityservice.ctca.prod/api',
		client_id: 'jFbRXAZCxo3Nce8kRkAurrwLXNW0zObz',
		redirect_uri: 'https://portal.myctca.com/callback',
		post_logout_redirect_uri: 'https://portal.myctca.com/',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
	}
};
