<app-card class="become-patient-card">
    <h1>Are you a CTCA Patient?</h1>
    <p class="subtitle">You can access all your health information on this portal.</p>
    <dx-button class="button" text="Yes, create a patient account" (onClick)="navigateToPatientValidation()"></dx-button>
    <p class="footer">Make sure you have your <span class="link" (click)="showMRN(true)">MRN</span> ready.</p>
    <dx-popup class="become-patient-popup" [visible]="mrnPopupVisible" (onHidden)="showMRN(false)" title="Find your Medical Record Number (MRN)" width="600" height="500">
        <div class="become-patient-popup-content">
            Look for a 8 digit number often located on:
            <ul>
                <li>CTCA Registration documents</li>
                <li>Clinical documents</li>
                <li>Wristband provided to you during your visit</li>
            </ul>
            You can also call us at: <a href="tel:+18002340482">1-800-234-0482</a>
        </div>
    </dx-popup>
</app-card>