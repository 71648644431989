import { Directive, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[ValidFormControl]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidFormControlDirective), multi: true }
]
})
export class ValidFormControlDirective implements Validator, OnChanges {

  @Input() ValidFormControl: boolean;
  
  constructor() { }

  validate(c: AbstractControl): { [key: string]: any } {
    
    if(!this.ValidFormControl) return {
      ValidFormControl: false
    };

    return null;
  }

  registerOnValidatorChange(fn: () => void): void { this._onChange = fn; }
 
  private _onChange: () => void;

  ngOnChanges(changes: SimpleChanges): void {
  
      if ('ValidFormControl' in changes) {
        
        if (this._onChange) this._onChange();
      }
    }

}
