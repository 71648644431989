import { Component, OnInit, ViewChild } from '@angular/core';

import { FileDownloadService, HealthHistoryService, LoadingService, SecureMailService } from '../../core/services';
import { FacilityInfo, Prescription } from '../../core/services/models';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../core/component/base.component';
import { DxContextMenuComponent } from 'devextreme-angular';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

export enum MoreOption {
	DownloadAsPDF,
	PrescriptionRenewal
}

@Component({
  selector: 'app-prescriptions',
  templateUrl: './prescriptions.component.html',
  styleUrls: ['./prescriptions.component.scss']
})
export class PrescriptionsComponent extends BaseComponent implements  OnInit {

  @ViewChild('moreContextMenu', { static: true }) moreContextMenu: DxContextMenuComponent;  
  data: Array<Prescription>;
  
  moreOptions = [
    { id: MoreOption.DownloadAsPDF, 
      text: 'Download as PDF', 
      icon: 'fa fa-download' },

  ];

  somethingToRenew = false;
  hasCareTeam = false;
  facilityInfo: FacilityInfo;
  prettyPhone: string;
  cleanPhone: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hhSvc: HealthHistoryService,
    private downloadSvc: FileDownloadService,
    private loadingService: LoadingService,
    private secureMailSvc: SecureMailService
  ) {
    super();

    this.secureMailSvc.hasCareTeam().then((hasCareTeam) => {
      this.hasCareTeam = hasCareTeam;

      if (this.hasCareTeam) {
        this.moreOptions.push({
          id: MoreOption.PrescriptionRenewal,
          text: 'Request Prescription Renewal',
          icon: 'fa fa-refresh'
        });
      }
    });
  }

  
  ngOnInit() {
    this.facilityInfo = this.route.snapshot.data.facilityInfo;
    this.prettyPhone = this.facilityInfo.careManagementPhone;
    this.cleanPhone = this.prettyPhone ? '+1' + this.prettyPhone.replace(/[^0-9]/g, '') : '';

    const startTime = Date.now();
    this.loadingService.loading = true;
    this.hhSvc.getPrescriptions().subscribe((data:Array<Prescription>) => {
      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      if(window.location.pathname.split('/').length<=4 && window.location.pathname.includes('health-history/prescriptions'))
      trackPageLoadingTime(window.location.pathname,elapsedTime);
      if(data) {
        const p = data.find(ps => ps.allowRenewal);
        this.somethingToRenew = (p!=null);
        if(!this.somethingToRenew) {
          this.moreOptions = this.moreOptions.filter(option => option.id !== MoreOption.PrescriptionRenewal);
        }
        this.data = data;
      } else {
        this.somethingToRenew = false;
        this.moreOptions = this.moreOptions.filter(option => option.id !== MoreOption.PrescriptionRenewal);
      }
    });    

  }

  showMoreOptions(event: any) {
      this.moreContextMenu.instance.show();
  }
  
  onMoreContextMenuItemClick(event: any) {
		
    if (event && event.itemData.id === MoreOption.DownloadAsPDF) {
			this.download();
    } else if (event && event.itemData.id === MoreOption.PrescriptionRenewal) {
			this.prescriptionRenewal();
		}
	}

  download = () => {
    this.loadingService.loading = true;
    this.hhSvc
      .getPrescriptionsReport()
      .subscribe((report:Blob) => {
        this.loadingService.loading = false;
        this.downloadSvc.downloadPdf(report, 'prescriptions');
      });
  };

  canDownload = () => {
    return this.data && this.data.length > 0;
  }

  prescriptionRenewal = () => {
    this.router.navigate(['app', 'prescriptions', 'request-renewal']);
  };

  viewDetail(e) {
    localStorage.setItem('startTimeOfPrescriptionDetail',Date.now().toString());
    this.router.navigate([e.data.prescriptionId], { relativeTo: this.route });
  }
}
