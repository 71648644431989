import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../component/base.component';
import { ContactUsQuestion } from '../services/models';

@Component({
    selector: 'app-tags',
    templateUrl: './tags.component.html',
    styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends BaseComponent implements OnInit {

    @Input() tags: ContactUsQuestion[];
    @Input() selectedTag: ContactUsQuestion;
    @Output() onSelectTag: any = new EventEmitter<ContactUsQuestion>();

    constructor() {
        super();
    }
    ngOnInit(): void {
    }

    selectTag = (tag: ContactUsQuestion) => {
        if (this.onSelectTag) {
            this.onSelectTag.emit(tag);
        }
    }

}