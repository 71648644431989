<ng-container *ngIf="userProfile">

  <ng-container>
    <div class="card-row" smartlook-safe>
      <div class="card-col full-width">
        <app-quick-links-card *ngIf="!isMobile && hasPermission(PermissionLevel.VIEW_QUICK_LINKS)" smartlook-safe></app-quick-links-card>
        <app-alert-messages-card [style.display]="showAlertMessages()"></app-alert-messages-card>
        <app-select-patient-card></app-select-patient-card>
        <app-become-patient-card *appShowFor="PermissionLevel.VIEW_CONVERT_ACCOUNT_SECTION"></app-become-patient-card>
        <app-upcoming-appointments-card [daysAhead]="daysAhead" [appointments]="upcomingAppointments" [facilityInfo]="facilityInfo" *appShowFor="PermissionLevel.VIEW_APPOINTMENTS"></app-upcoming-appointments-card>
        <app-new-messages-card *appShowFor="PermissionLevel.VIEW_SECURE_MESSAGES"></app-new-messages-card>
        <app-quick-links-card *ngIf="isMobile && hasPermission(PermissionLevel.VIEW_QUICK_LINKS)" smartlook-safe></app-quick-links-card>
        <app-interactive-resource-card *appShowFor="PermissionLevel.VIEW_INTERACTIVE"></app-interactive-resource-card>
        <app-external-links *appShowFor="PermissionLevel.VIEW_EXTERNAL_LINKS" smartlook-safe></app-external-links>
        <app-latest-ccda *appShowFor="PermissionLevel.VIEW_CCDA_DOCUMENTS"></app-latest-ccda>
      </div>
    </div>
  </ng-container>
  
  <dx-popup
          [width]="500"
          [height]="225"
          [showTitle]="true"
          title="Symptom Inventory Survey"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [(visible)]="popupVisible">
          <div *dxTemplate="let data of 'content'">
            <p>
              In preparation for your upcoming appointment, you have a Symptom Inventory Survey that is available to be completed. 
              Would you like to take the survey now?
            </p>
            <div class="flex-center">
              <div class="toolbar-buttons-bottom">            
                <dx-button
                  class="dx-button-mode-contained"
                  text="Cancel"
                  (onClick)="closePopup()">
                </dx-button>
                <dx-button
                  class="dx-button-default"
                  text="Take Survey"                  
                  (onClick)="goToSITSurvey()">
                </dx-button>
              </div>
            </div>
            
          </div>
          
      </dx-popup>

      <dx-popup
          [width]="750"
          [height]="825"
          [showTitle]="true"
          title="CTCA Tulsa Update"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [(visible)]="tulsaPopupVisible">
          <div *dxTemplate="let data of 'content'">
            <dx-scroll-view>
            <p>
              <strong>Cancer Treatment Centers of America® (CTCA) Tulsa closed operations on June 1, 2021.</strong>
            </p>
            <p>
              <strong>Important Information</strong>
            </p>            
            <strong>Transferring care:</strong><br/>
            <ul>
              <li>
                If you wish to continue your care at one of our other CTCA locations in Atlanta, Chicago or Phoenix, 
                please call <a class="dead-link" href="tel:1-877-381-3982">1-877-381-3982</a> to discuss your transfer options. 
              </li>
              <li>
                If you wish to continue your care with a local provider, you may request records be sent to your new 
                physician through your CTCA patient portal account or by contacting the Health Information Management 
                team at (847) 872-6321 and <a class="dead-link" href="mailto:chicagomedicalrecords@ctca-hope.com">chicagomedicalrecords@ctca-hope.com</a>. 
              </li>
            </ul>
            <p>
              <strong>Accessing medical records:</strong>
            </p>
            <p>
              Your medical records will be available to you for a minimum of ten years from the date of service and 
              you may request records at any time in the future in two ways:
            </p>
            <ul>
              <li>
                <strong>myCTCA Patient Portal</strong><br/>
                All patients can use the myCTCA Patient Portal to access, manage and share information related to 
                your cancer treatment with loved ones and hometown physicians, including accessing your medical 
                records, viewing lab results, and connecting with your care team. The myCTCA Patient Portal is the 
                fastest and easiest way to access your medical records. If you are not already a myCTCA user, you 
                can easily register for myCTCA 
                <a class="dead-link" href="https://cdn.cancercenter.com/-/media/ctca/files/pdfs/medical-records/myctca-patient-portal-flyer-2021.pdf" target="_blank">
                  by completing these steps</a>.
                  <ul>
                    <li>For assistance with registering your Portal account, please call Technical Support at (800) 234-0482.</li>
                    <li>
                      <i>Please note that the Secure Health Message function in the Portal will not work for patients who have not 
                        transitioned to another CTCA facility.</i>
                    </li>
                  </ul>
              </li>
              <li>
                <strong>Health Information Management Team</strong><br/>
                Your CTCA Health Information Management teams will continue be the custodian of your CTCA medical records. 
                In addition to the myCTCA Patient Portal, you may also contact the following CTCA Health Information 
                Management Team with future medical records needs:<br/>
                CTCA Chicago<br/>
                Health Information Management<br/>
                Phone: (847) 872-6321<br/>
                <a class="dead-link" href="mailto:chicagomedicalrecords@ctca-hope.com?subject=My Medical Records">chicagomedicalrecords@ctca-hope.com</a>
              </li>
            </ul>
            <p>
              <strong>Active prescriptions at CTCA/Rx:</strong><br/>
              CTCA/Rx will remain operational, and for active prescriptions at CTCA/Rx, the CTCA/Rx team can continue to refill 
              your prescription as long as the provider of record on the prescription is a CTCA provider. If you transfer care 
              to a non-CTCA provider, CTCA/Rx will no longer be able to fill your prescription and can assist in transferring 
              active prescriptions to another pharmacy. 
            </p>
            <ul>
              <li style="list-style-type: disc;margin-left:10px;">
                <i>Please note that the Prescription Renewal function in the Portal will not work for patients who have not transitioned to another CTCA facility.</i>
              </li>
            </ul>
          </dx-scroll-view>
          </div>
      </dx-popup>

      <dx-popup
          [width]="750"
          [height]="825"
          [showTitle]="true"
          title="CTCA Philadelphia Update"
          [dragEnabled]="false"
          [hideOnOutsideClick]="false"
          [(visible)]="philiPopupVisible">
          <div *dxTemplate="let data of 'content'">
            <dx-scroll-view>
            <p>
              <strong>Cancer Treatment Centers of America® (CTCA) Philadelphia was acquired by Temple Health on
                June 30, 2021.</strong>
            </p>
            <p>
              <strong>Important Information</strong>
            </p>            
            <strong>Transferring care:</strong><br/>
            <ul>
              <li>
                If you wish to continue your care at one of our other CTCA locations in Atlanta, Chicago or Phoenix, 
                please call <a class="dead-link" href="tel:1-844-703-1019">1-844-703-1019</a> to discuss your transfer options. 
              </li>
              <li>
                If you wish to continue your care with a local provider, you may request records be sent to your new 
                physician through your CTCA patient portal account or by contacting the Health Information Management 
                team at (770) 400-6100 and <a class="dead-link" href="mailto:atlantamedicalrecords@ctca-hope.com">AtlantaMedicalRecords@ctca-hope.com</a>. 
              </li>
            </ul>
            <p>
              <strong>Accessing medical records:</strong>
            </p>
            <p>
              Your medical records will be available to you for a minimum of ten years from the date of service and 
              you may request records at any time in the future in two ways:
            </p>
            <ul>
              <li>
                <strong>myCTCA Patient Portal</strong><br/>
                All patients can use the myCTCA Patient Portal to access, manage and share information related to 
                your cancer treatment with loved ones and hometown physicians, including accessing your medical 
                records, viewing lab results, and connecting with your care team. The myCTCA Patient Portal is the 
                fastest and easiest way to access your medical records. If you are not already a myCTCA user, you 
                can easily register for myCTCA 
                <a class="dead-link" href="https://cdn.cancercenter.com/-/media/ctca/files/pdfs/medical-records/myctca-patient-portal-flyer-2021.pdf" target="_blank">
                  by completing these steps</a>.
                  <ul>
                    <li>For assistance with registering your Portal account, please call Technical Support at (800) 234-0482.</li>
                    <li>
                      <i>Please note that the Secure Health Message function in the Portal will not work for patients who have not 
                        transitioned to another CTCA facility.</i>
                    </li>
                  </ul>
              </li>
              <li>
                <strong>Health Information Management Team</strong><br/>
                Your CTCA Health Information Management teams will continue be the custodian of your CTCA medical records. 
                In addition to the myCTCA Patient Portal, you may also contact the following CTCA Health Information 
                Management Team with future medical records needs:<br/>
                CTCA Chicago<br/>
                Health Information Management<br/>
                Phone: (770) 400-6100<br/>
                <a class="dead-link" href="mailto:atlantamedicalrecords@ctca-hope.com?subject=My Medical Records">AtlantaMedicalRecords@ctca-hope.com</a>
              </li>
            </ul>
            <p>
              <strong>Active prescriptions at CTCA/Rx:</strong><br/>
              CTCA/Rx will remain operational, and for active prescriptions at CTCA/Rx, the CTCA/Rx team can continue to refill 
              your prescription as long as the provider of record on the prescription is a CTCA provider. If you transfer care 
              to a non-CTCA provider, CTCA/Rx will no longer be able to fill your prescription and can assist in transferring 
              active prescriptions to another pharmacy. 
            </p>
            <ul>
              <li style="list-style-type: disc;margin-left:10px;">
                <i>Please note that the Prescription Renewal function in the Portal will not work for patients who have not transitioned to another CTCA facility.</i>
              </li>
            </ul>
          </dx-scroll-view>
          </div>
      </dx-popup>
  <router-outlet></router-outlet>

</ng-container>
