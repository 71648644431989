import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { ContactUsStateService } from 'app/core/services';
import { emailOrEmptyValidator } from 'app/shared/validators/email-or-empty.validator';


@Component({
  selector: 'app-ask-question-non-medical-email',
  templateUrl: './ask-question-non-medical-email.component.html',
  styleUrls: ['./ask-question-non-medical-email.component.scss']
})
export class AskQuestionNonMedicalEmailComponent extends BaseComponent implements OnInit {

  // email = '';
  editing = false;
  title: string;
  emailControl = new FormControl('', Validators.compose([Validators.required, emailOrEmptyValidator]));

  constructor(private route: ActivatedRoute, private router: Router, private contactUsState: ContactUsStateService) {
    super();
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.editing = params.edit === 'true'
    });
    const state = this.contactUsState.getState();
    const email = state.email;
    console.log('email:', email);
    // this.email = email || '';
    this.title = state.subject.value;
    this.emailControl.setValue(email);
  }

  onNextClick = (e: any) => {
    this.contactUsState.setState({ email: this.emailControl.value });
    this.router.navigate(['non-medical', 'review'], { relativeTo: this.route.parent });
  }

  close = () => {
    window.history.back();
  }

}
