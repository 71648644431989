import { Injectable, EventEmitter } from '@angular/core';


@Injectable()
export class SITSurveyCommunicationService {
    sitSurveyUrl: string;
    receivedUrl: EventEmitter<string>;

    constructor(){
        this.receivedUrl = new EventEmitter<string>();
    }

    raiseEvent(url: string): void {
        this.sitSurveyUrl = url;
        this.receivedUrl.emit(url);
    }
}