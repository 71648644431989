<app-card label="Appointments">
  <div card-buttons-desktop>
    <dx-button *appShowFor="PermissionLevel.VIEW_APPOINTMENTS" text="Download my Schedule" icon="fa fa-download" (click)="downloadSchedule()"></dx-button>
    <dx-button *appShowFor="PermissionLevel.REQUEST_APPOINTMENT" text="Request Appointment" icon="fa fa-calendar-plus-o" (click)="scheduleAppointment()"></dx-button>
  </div>
  <div card-buttons-mobile>
    <dx-button *appShowFor="PermissionLevel.VIEW_APPOINTMENTS" class="square-corners" icon="ctca-download" (onClick)="downloadSchedule()"></dx-button>
  </div>
  <ng-container *appShowFor="PermissionLevel.JOIN_TELEHEALTH_MEETING">
    <div *ngIf="teleHealthApptExists">
        <p *ngIf="!teleHealthUrl || !showTeleJoinLink">
          Please refer to the following link if you have been scheduled for a telehealth appointment to 
          assist in preparing your device ahead of your appointment: 
          <a href="https://www.cancercenter.com/telehealth-setup-guide" target="_blank">TeleHealth Setup Guide</a>
        </p>
        <div *ngIf="teleHealthUrl && showTeleJoinLink" class="telehealth-yellow">
          You have a <b>Telehealth Appointment</b> occurring soon.  You can join the scheduled meeting now.<br/>
          <dx-button text="Join Telehealth Appointment" (click)="openTelehealthAppointment()" class="stretch-button"></dx-button>
        </div>
    </div>
  </ng-container>
  <dx-scheduler [dataSource]="appointmentsData" currentView="agenda" cellDuration="15" [useDropDownViewSwitcher]="false"
    timeCellTemplate="timeCellTemplate" dateCellTemplate="dateCellTemplate" resourceCellTemplate="appointmentTemplate"
    [showAllDayPanel]="false" (onInitialized)="onSchedulerInitialized($event)" (onContentReady)="onSchedulerContentReady($event)"
    (onAppointmentClick)="onAppointmentClick($event)" (onAppointmentDblClick)="onAppointmentDblClick($event)"
    [editing]="false" noDataText="No Appointments">
    <div *dxTemplate="let dateCell of 'dateCellTemplate'" class="dx-scheduler-agenda-date">
      <div class="dx-scheduler-agenda-day">{{dateCell.date | date:"d"}}</div>
      <div class="dx-scheduler-agenda-weekday">{{dateCell.date | date:"EEE"}}</div>
    </div>
    <div *dxTemplate="let timeCell of 'timeCellTemplate'" class="dx-scheduler-agenda-time">
      <div class="dx-scheduler-agenda-hour">{{timeCell.date | date:"h:mm"}}</div>
      <div class="dx-scheduler-agenda-ampm">{{timeCell.date | date:"a"}}</div>
    </div>
    <div *dxTemplate="let appointment of 'appointmentTemplate'">
      <div class="dx-scheduler-appointment-title">
        <span>{{ appointment.text }}</span>
        <span *ngIf="appointment.location"> - {{ appointment.location }}</span>
      </div>
      <div class="dx-scheduler-appointment-content-details">
        <span class="dx-scheduler-appointment-content-date">{{ appointment.startDate | date: 'shortTime' }}</span>
        <span class="dx-scheduler-appointment-content-date"> - </span>
        <span class="dx-scheduler-appointment-content-date">{{ appointment.endDate | date: 'shortTime' }}</span>&nbsp;
        <span class="dx-scheduler-appointment-content-date">{{ appointment.facilityTimeZone }}</span>
      </div>
    </div>
  </dx-scheduler>
  <dx-button *appShowFor="PermissionLevel.REQUEST_APPOINTMENT" class="floating-button mobile-only" text="Request Appointment" icon="ctca-request-appointment" (click)="scheduleAppointment()"></dx-button>
</app-card>
<router-outlet></router-outlet>
