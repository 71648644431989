import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Optional } from '@angular/core';
import { Popup } from '../../core/popup/popup';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DxPopupComponent } from 'devextreme-angular';
import { ResizingService } from '../../../app/core/services';

@Component({
  selector: 'app-appointment-late-rescheduling',
  templateUrl: './appointment-late-rescheduling.component.html',
  styleUrls: ['./appointment-late-rescheduling.component.scss']
})
export class AppointmentLateReschedulingComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {

  appointmentActionDisclaimer:string = environment.appointmentActionDisclaimer;
  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

  constructor(@Optional() router?: Router, @Optional() route?: ActivatedRoute, @Optional() resizingService?: ResizingService) { 
    super(router, route, resizingService);
    localStorage.removeItem('startTimeOfRescheduleAppt');
    localStorage.removeItem('startTimeOfRescheduleApptV2');
    localStorage.removeItem('startTimeOfCancelAppt');
    localStorage.removeItem('startTimeOfCancelApptV2');
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }
}
