import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { BaseComponent } from '../core/component/base.component';
import { Router } from '@angular/router';
import { ServiceEndpoint, LoadingService } from '../core/services';
import { trackEvent } from 'app/shared/helpers/tracking.helper';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent extends BaseComponent implements OnInit {
  
  public Copyright = "";
  public outageMessage = "";

  constructor(private router: Router, public loadingService: LoadingService) {
    super();
  }

  goPortalHome() {
    this.loadingService.loading = true;
    this.router.navigate(['app', 'home', 'alerts']);    
  }

  openUserGuideWindow() {
    trackEvent("Landing Page - Open User Guide Window");
    window.open(environment.portalServiceBaseUrl+ServiceEndpoint.SUPPORT_GETUSERGUIDE, "_blank","width=600,height=800");
  }

  openRegistrationGuideWindow() {
    window.open(environment.portalServiceBaseUrl+ServiceEndpoint.SUPPORT_GETREGISTRATIONGUIDE, "_blank","width=600,height=800");
  }

  getYear():number {
    return new Date().getFullYear();
  }
  
  ngOnInit() {
    this.Copyright = "\u00A9" + this.getYear() + " COH HoldCo Inc.";
    this.outageMessage = environment.outageMessage;
  }

  openBillPay() {
    trackEvent("Landing Page - Pay Your Bill Online");
    window.open(environment.billPayUrl, '_blank');
  }

  showOutageMessage():boolean {
    return this.outageMessage == null || this.outageMessage.trim().length === 0;
  }

  get Window() {
    return window;
  }

}
