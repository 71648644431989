<app-card label="Notice of Privacy Practices">
    
      <p class="center" style="font-size: 17px;">
        CTCA HIPAA NOTICE OF PRIVACY PRACTICES
      </p>

      <p>
        <span class="right"><b>EFFECTIVE DATE:</b>  September 23, 2013</span><br />
        <span class="right"><b>REVISED:</b>  January 17, 2022</span>
      </p>

      <br />

      <p>
        <b>THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION.  PLEASE REVIEW IT CAREFULLY.</b>
      </p>

      <p><b>Who We Are</b></p>

      <p>
        This Notice describes the privacy practices of the Cancer Treatment Centers of America
        Organized Health Care Arrangement, which consists of medical centers, physician practices,
        outpatient clinics, and pharmacies (collectively, the “Facilities”) and our respective physicians,
        nurses, naturopathic practitioners, nutritionists, pharmacists and other personnel (collectively for
        purposes of this Notice, "we" or "us"). You may access a list of Facilities that participate in the
        Cancer Treatment Centers of America Organized Health Care Arrangement at
        <a href="https://www.cancercenter.com/ohca/">https://www.cancercenter.com/ohca/</a> or by requesting a copy at any our Facilities. As an Organized
        Health Care Arrangement, our Facilities cooperate with one another to provide an organized
        system of care. This Notice applies to the services we furnish to you and the activities we perform
        as an Organized Health Care Arrangement.
      </p>

      <p><b>Our Privacy Obligations</b></p>

      <p>
        We are committed to protecting information about you regarding your health and related health
        care services (“protected health information” or “PHI”). We create a record of the care and
        services you receive at our Facilities for use in your care and treatment. This Notice tells you
        about the ways in which we may use or disclose your protected health information. It also
        describes your rights and certain obligations we have regarding the use and disclosure of your
        protected health information. We are required by law to:
      </p>

      <ul>
        <li>Maintain the privacy of your protected health information;</li>
        <li>Give you this Notice describing our legal duties and privacy practices with respect to your protected health information;</li>
        <li>Comply with the terms of this Notice, or, if this Notice is later revised, a future notice then in effect; and</li>
        <li>Notify you in the event of a breach of unsecured protected health information.</li>
      </ul>

      <p>
        Generally, when this Notice uses the words “you” or “your”, it is referring to the patient who is the
        subject of PHI. However, when this Notice discusses rights regarding PHI, including rights to
        access or authorize the disclosure of PHI, “you” and “your” may refer to a patient’s parent(s), legal
        guardian or other personal representative.
      </p>

      <p><b>How We May Use or Disclose Your Protected Health Information</b></p>

      <p>
        The following categories describe different ways in which we may use or disclose your PHI without
        your written authorization. The examples provided under the categories below are not intended
        to be comprehensive, but instead, to identify the most common types of uses and disclosures of
        PHI that do not require prior authorization from you. If you are receiving care from a Facility
        located in a state that does not permit the use or disclosure of PHI for the purposes listed below,
        or other purposes, without your prior consent, we will ask you to sign a general release, consent,
        or other authorization as is required by state law so that we may use or disclose your PHI for such
        purposes.
      </p>

      <p>
        <b>For Treatment.</b>
        We may use or disclose your PHI to provide treatment and other services
        to you (for example, to diagnose and treat your injury or illness). We may also disclose
        PHI to other providers involved in your treatment, or to people outside of the Facilities who
        may be involved in your continuing health or medical care after you are no longer receiving
        treatment at the Facilities, such as other health care providers, transportation companies,
        community agencies, and family members. We may also use your PHI to contact you so
        that we can remind you that you have an appointment with a provider or for other permitted
        purposes related to your health or medical care.
      </p>

      <p>
        <b>For Payment.</b>
        We may use or disclose your PHI to bill and obtain payment for services
        that we provide to you, such as disclosures to file a claim on your behalf and obtain
        payment from a third party payor, such as Medicare, Medicaid, an employer group health
        plan, or another health insurer, health plan or program that arranges for or pays the cost
        of some or all of your health care. We may also tell your health plan or other payor about
        a proposed treatment to determine whether your payor will cover the cost of treatment.
      </p>

      <p>
        <b>For Health Care Operations.</b>
        We may use or disclose your PHI for our health care
        operations, which include internal administration and planning and various activities that
        improve the quality and cost effectiveness of the care that we deliver to our patients. For
        example, we may use PHI to evaluate the quality and competence of our physicians,
        nurses and other health care workers. We may use your PHI to contact you to tell you
        about or recommend possible treatment options or alternatives that may be of interest to
        you or to let you know about other benefits and services that we provide. We may disclose
        PHI to our patient relations team to help make sure the care you receive is of the highest
        quality. We may also disclose your PHI to other Facilities within our Organized Health
        Care Arrangement to assist them to carry out certain health care operations activities. For
        example, we may disclose PHI to other Facilities so that they may use it to improve the
        care they provide to other patients receiving care at those Facilities.
      </p>

      <p>
        <b>Business Associates.</b>
        We may contract with certain outside persons or organizations to
        perform certain services on our behalf, such as billing and transcription services. At times,
        it may be necessary for us to provide your PHI to one or more of these outside persons or
        organizations. In such cases, we require these business associates and any of their
        subcontractors to appropriately safeguard the privacy of your information.
      </p>

      <p>
        <b>Health Information Exchange Activities.</b>
        We may participate with one or more secure
        health information organization networks (each, an “HIO”), which makes it possible for us
        to share your PHI electronically through a secure connected network. We may share or
        disclose your PHI to these HIOs. Physicians, hospitals and other health care providers
        that are connected to the same HIO network as the Facilities can access your PHI for
        treatment, payment and other authorized purposes, to the extent permitted by law. You
        may have the right to “opt-out” or decline to participate in networked HIOs. The Facilities
        will provide you with additional information about the HIOs that they participate in,
        including information about your right to opt-out of the HIO, as applicable. If you choose
        to opt-out of data-sharing through HIOs, we will no longer share your PHI through an HIO
        network.
      </p>

      <p>
        <b>Inclusion in Facility Directory and Disclosures to Individuals Involved in Your Care.</b>
        We may include your name, general health condition (e.g., fair, stable, etc.) and religious
        affiliation in a patient directory. Except for religious affiliation, which may be given to
        members of the clergy only, the information in the directory may be released so that your
        family, friends, and others who ask for you by name, can visit you in the hospital and
        generally know how you are doing, unless you restrict or prohibit the use or disclosure of
        this information. We may tell your family, other relative, a close personal friend or any
        other person identified by you about your general condition and that you are in the hospital,
        unless you request that we do not provide this information or are unable to authorize the
        release of such information and we determine it is in your best interests to do so. Upon
        request of a family member, we may provide information regarding your release, transfer,
        serious illness, injury or death, unless you request that this information not be provided.
      </p>

      <p>
        <b>Disaster Relief Organizations.</b>
        We may disclose your PHI to disaster relief organizations
        so that your family or other persons responsible for your care can be notified of your
        location and general condition, unless you request that this information not be provided.
      </p>

      <p>
        <b>Fundraising Activities.</b>
        We may contact you to provide information about our sponsored
        activities, including fundraising programs and events. We may only use limited
        information, such as your name, address, phone number and the dates you received
        treatment or services at our Facilities, to send you fundraising communications. Please
        contact our Privacy Office if you do <u>not</u> wish to receive fundraising communications;
        subsequently, if you wish to opt back in to receiving such communications, you will need
        to notify the Privacy Office in writing. Your written authorization is required before we use
        additional information, such as the department where you were seen or the name of your
        physician, for fundraising purposes.
      </p>

      <p>
        <b>Research.</b>
        We may use or disclose PHI about our patients for research purposes, subject
        to the confidentiality provisions of state and federal law. Researchers may contact you
        regarding your interest in participating in certain research studies after receiving your
        authorization or approval to contact you from an institutional review board. When
        approved by an institutional review board, we may perform other studies using your PHI
        without your authorization.
      </p>

      <p>
        <b>Limited Data Set.</b>
        We may create a “limited data set” (i.e., a limited amount of medical
        information from which almost all identifying information such as your name, address,
        Social Security number and medical record number have been removed) and share it with
        those who have signed a contract promising to use it only for research, public health, or
        healthcare operations purposes and to protect its confidentiality.
      </p>

      <p><b>As Required by Law.</b>  We will disclose your PHI when required and/or authorized to do so by federal or state law.</p>

      <p>
        <b>To Avert a Serious Threat to Health or Safety.</b>
        We may use or disclose your PHI when
        necessary to prevent or lessen a serious and imminent threat to your health and safety or
        the health and safety of the public or another person.
      </p>

      <p>
        <b>Public Health Activities.</b>
        We may disclose your PHI for public health purposes, which
        generally include the following: (1) for the purpose of preventing or controlling disease
        (e.g., cancer or tuberculosis), injury, or disability; (2) to report child abuse or neglect; (3)
        to report adverse events or surveillance related to food, medications or defects or
        problems with products; (4) to alert persons who may have been exposed to a
        communicable disease or may otherwise be at risk of contracting or spreading a disease
        or condition; (5) to report findings to your employer concerning a work-related illness or
        injury or workplace related medical surveillance; (6) to help device manufacturers notify
        you of recalls, repairs or replacements of products you may be using; (7) to report vital
        events such as births and deaths; and (8) to notify the appropriate government authority
        as authorized or required by law if we believe a patient has been the victim of abuse,
        neglect or domestic violence.
      </p>

      <p>
        <b>Health Oversight Activities.</b>
        We may disclose your PHI to governmental, licensing,
        auditing and accrediting agencies as authorized or required by law.
      </p>

      <p>
        <b>Lawsuits and Other Legal Actions.</b>
        In connection with lawsuits or other legal
        proceedings, we may disclose your PHI in response to a court or administrative order, or
        in response to a subpoena, warrant, summons or other lawful process.
      </p>

      <p>
        <b>Law Enforcement.</b>
        We may release health information to law enforcement officials as
        required or permitted by law or in compliance with a court order or a grand jury or
        administrative subpoena.
      </p>

      <p><b>Coroners, Medical Examiners and Funeral Directors.</b>  We may disclose your PHI to a coroner or medical examiner as authorized by law.</p>

      <p><b>Organ and Tissue Procurement.</b>  We may disclose your PHI to organizations that facilitate organ, eye or tissue procurement, banking or transplantation.</p>

      <p><b>National Security and Intelligence Activities.</b>  We may use or disclose your PHI to units of the government with special functions, such as the U.S. military or the U.S. Department of State under certain circumstances.</p>

      <p>
        <b>De-Identification.</b>
        We may de-identify your PHI and tissue samples and use such deidentified health information and de-identified tissue samples for research, analysis, or
        other purposes, to the extent permitted by law.
      </p>

      <p><b>Uses or Disclosures Requiring Your Written Authorization</b></p>

      <p>
        For any purpose other than described in the categories above, we will ask you to provide your
        written authorization before using or disclosing your PHI. If you provide us authorization to use
        or disclose your PHI, you may revoke that authorization, in writing, at any time. Your revocation
        will be effective except to the extent that we have already acted in reliance upon your
        authorization. We will obtain your written authorization prior to using or disclosing your PHI for
        the following purposes:
      </p>

      <p>
        <b>Marketing.</b>
        We may ask you to sign an authorization to use or disclose your PHI to send
        you any marketing materials. The authorization will state if we received any direct or
        indirect compensation for such marketing. Your authorization is not needed for face-toface communications we make to you, for promotional gifts of nominal value, or for
        communications about prescriptions that are prescribed to you. In addition, we may
        communicate with you about products or services relating to your treatment, case
        management or care coordination, or alternative treatments, therapies, providers or care
        settings without your authorization.
      </p>

      <p><b>Sale of PHI.</b>  We may ask you to sign an authorization to sell your PHI.</p>

      <p>
        <b>Psychotherapy Notes.</b>
        In the event we maintain psychotherapy notes relating to your
        treatment, we will obtain your written authorization prior to disclosing such information,
        except as permitted or required by law.
      </p>

      <p>
        <b>Usage Information.</b>
        We collect certain information automatically through your use of our application, such as which pages on our application you access, the frequency of access, and what you click on while on our application. 
      </p>

      <p>
        <b>Mobile Device Information.</b>
        We may collect information about the device you are using, such as hardware model, operating system, and application version number. 
      </p>

      <p>
        <b>Mobile Device Access.</b>
        We may request access to certain features from your mobile device, including permission for storing copies of medical documents, schedules, and access to microphone and camera to participate in telehealth calls with your Provider. If you wish to change our access or permissions, you may do so in your device’s settings.
      </p>

      <p><b>Your Rights Regarding Your Protected Health Information</b></p>

      <p>
        You have several rights related to your Protected Health Information. You may obtain the forms
        needed to exercise the following rights by contacting the Privacy Office using the contact
        information below:
      </p>

      <p>
        <b>Right to Request Restrictions.</b>
        You have a right to request restrictions or limitations on
        our use or disclosure of your PHI. If you wish to request such restrictions, please obtain a
        request form from our Privacy Office and submit the completed form to the Privacy Office.
        While we will consider all requests for additional restrictions carefully, we are not required
        to agree to all requested restrictions; provided, however, that we must agree to a request
        to restrict disclosures of PHI to your health plan for payment or health care operations
        purposes if you have paid us out-of-pocket for the item or service that is the subject of the
        PHI. If we do agree to a restriction, we will inform you of our agreement in writing and we
        will comply with your request, unless the information is needed to provide you emergency
        treatment.
      </p>

      <p>
        <b>Right to Request Confidential Communications.</b>
        You have a right to request that we
        communicate with you about medical matters in a certain way or at a certain location. We
        will accommodate all reasonable written requests. You must specify how or where you
        wish to be contacted (alternative means of communication or at alternative locations).
      </p>

      <p>
        <b>Right to Inspect and Copy Your Health Information.</b>
        You have a right to access and
        inspect your medical and billing records maintained by us and to request copies of the
        records in either paper or electronic form. Under limited circumstances, we may deny you
        access to a portion of your records. If you desire access to your records, please obtain a
        record request form from the Privacy Office and submit the completed form to the Privacy
        Office or HIM. We may charge you a reasonable cost-based fee for our labor, supplies,
        and postage costs if you request that we mail the copies to you.
      </p>

      <p>
        <b>Right to Amend Your Records.</b>
        If you believe that the medical information that we have
        about you is incorrect or incomplete, you have the right to request that we amend PHI
        maintained in your medical record or billing records, or add an addendum (addition to the
        record). If you desire to amend your records, you will need to complete and submit a form
        for requesting amendments, which is available from HIM or the Privacy Office. We wiII
        comply with your request unless we believe that the current information (i) is accurate and
        complete, (ii) was not created by our healthcare team, (iii) is not part of the information
        kept at our Facilities, or (iv) other special circumstances apply. Even if we accept your
        request, we are not required to delete any information from your medical record.
      </p>

      <p>
        <b>Right to Receive an Accounting of Disclosures.</b>
        You have the right to receive a list of
        certain disclosures of your PHI made by us during the six-year period prior to the date of
        your request. We will provide this list of disclosures to you once during any 12-month
        period without charge. We may charge a reasonable, cost-based fee for each subsequent
        request for an accounting during the same 12-month period.
      </p>

      <p><b>Right to Receive Paper Copy of this Notice.</b>  Upon request, you have a right to a paper copy of this Notice, even if you have agreed to receive this Notice electronically.</p>

      <p><b>Right to Change Terms; Questions or Complaints; Contact:</b></p>

      <p>
        <b>Right to Change Terms of this Notice.</b>
        We may change the terms of this Notice at any
        time. If we change this Notice, we may make the new notice terms effective for all PHI that
        we maintain, including any information created or received prior to issuing the new notice.
        If we change this Notice, we will post the new notice in common areas in our Facilities and
        on our website. You also may obtain any new notice by contacting the Privacy Office.
      </p>

      <p>
        <b>Questions or Complaints.</b>
        If you have any questions about this Notice, please contact
        the Privacy Office at the number listed below. If you believe your privacy rights have been
        violated, you may file a complaint with the Facilities or with the US Secretary of the
        Department of Health and Human Services, Office for Civil Rights. To file a complaint with
        the Facilities, please contact the Privacy Office. We will not retaliate or take action against
        you for filing a complaint.
      </p>

      <p><b>Privacy Office</b></p>

      <p>You may contact the Privacy Office at:</p>

      <p>
        Privacy Office<br />
        <b>800-234-7139</b>
      </p>

      <p><b>This Notice replaces all earlier versions.</b></p>
      
</app-card>