export class IdentityLogEvent {
    constructor(
        public eventTask?: string,
        public userName?: string,
        public ctcaId?: string,
        public epiId?: string,
        public adminCtcaId?: string,
        public adminUserName?: string,
        public originalValue?: string
    ){}
}