import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { AlertMessagesComponent } from './alert-messages/alert-messages.component';
import { AlertMessagesPopupComponent } from './alert-messages-popup/alert-messages-popup.component';
import { DocumentsWhitelistComponent } from './documents-whitelist/documents-whitelist.component';
import { ResolveAlertMessagePayload, ResolveAlertMessagesPayload, ResolveAddAlertMessagePayload, ResolveDocumentsWhitelistPayload, ResolveDocumentWhitelistPayload } from '../core/guards';
import { DocumentsWhitelistPopupComponent } from './documents-whitelist-popup/documents-whitelist-popup.component';
import { ActivityLogsComponent } from './activity-logs/activity-logs.component';
import { LoadNpiFilesComponent } from './load-npi-files/load-npi-files.component';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'alert-messages',
				resolve: { messages: ResolveAlertMessagesPayload },
				component: AlertMessagesComponent,
				data: { permissionLevel: PermissionLevel.VIEW_SYSTEM_ALERT_MESSAGES },
				runGuardsAndResolvers: 'always',
				children: [
					{
						path: 'add-message',
						resolve: { message: ResolveAddAlertMessagePayload },
						component: AlertMessagesPopupComponent,
						data: { permissionLevel: PermissionLevel.MANAGE_SYSTEM_ALERT_MESSAGES }
					},
					{
						path: ':id',
						resolve: { message: ResolveAlertMessagePayload },
						component: AlertMessagesPopupComponent,
						data: { permissionLevel: PermissionLevel.VIEW_SYSTEM_ALERT_MESSAGES }
					}					
				]
			},
			{
				path: 'documents-whitelist',
				resolve: { documents: ResolveDocumentsWhitelistPayload },
				component: DocumentsWhitelistComponent,
				data: { permissionLevel: PermissionLevel.VIEW_DOCUMENTS_WHITELIST },
				runGuardsAndResolvers: 'always',
				children: [
					{
						path: 'add-document',
						component: DocumentsWhitelistPopupComponent,
						data: { permissionLevel: PermissionLevel.MANAGE_DOCUMENTS_WHITELIST }
					},
					{
						path: ':id',
						resolve: { document: ResolveDocumentWhitelistPayload },
						component: DocumentsWhitelistPopupComponent,
						data: { permissionLevel: PermissionLevel.VIEW_DOCUMENTS_WHITELIST }
					}					
				]
			},
			{
				path: 'activity-logs',
				component:ActivityLogsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_ACTIVITY_LOGS }
			},
			{
				path: 'load-npi-files',
				component:LoadNpiFilesComponent,
				data: { permissionLevel: PermissionLevel.LOAD_NPI_FILES }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AdminRoutingModule {}
