<app-card label="Event Counts Pivot">

  <form *ngIf="eventsForm" [formGroup]="eventsForm" novalidate>
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label"><label for="startDate">Start Date:</label></div>
        <div class="dx-field-value">
          <div style="max-width:229px;width:100%;">
            <dx-date-box #startDate [inputAttr]="{ id: 'startDate' }" formControlName="startDate" [max]="max" [min]="min" (onFocusOut)="startDateValueChanged()" placeholder="mm/dd/yyyy" displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format" [isValid]="eventsForm.get('startDate').valid || eventsForm.get('startDate').pristine"></dx-date-box>
          </div>  
        </div>                      
      </div>
      <div class="dx-field">
        <div class="dx-field-label"><label for="endDate">End Date:</label></div>
        <div class="dx-field-value">
          <div style="max-width:229px;width:100%;">
            <dx-date-box #endDate [inputAttr]="{ id: 'endDate' }" formControlName="endDate" [max]="max" [min]="min" (onFocusOut)="endDateValueChanged()" placeholder="mm/dd/yyyy" displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format" [isValid]="eventsForm.get('endDate').valid || eventsForm.get('endDate').pristine"></dx-date-box>
          </div>
        </div>                      
      </div>
      <div class="dx-field">
        <div class="dx-field-label"><label for="userType">User Type:</label></div>
        <div class="dx-field-value">
          <div style="max-width:229px;width:100%;">
            <dx-select-box #userType
            [inputAttr] = "{id: 'userType'}"
            formControlName="userType"
            [items]="userTypes"
            [isValid]="eventsForm.controls['userType'].valid || eventsForm.controls['userType'].pristine"
            [validationError]="{ message: 'User Type is required' }">
          </dx-select-box>
          </div>
        </div>
      </div>
      <div *ngIf="wrongOrder()" class="dx-field">
          <div class="dx-field-label"></div>
          <div class="dx-field-value">
              <span class="red">
                  Start Date can't be after End Date
              </span>
          </div>
      </div>  
      <div *ngIf="!inRange()" class="dx-field">
          <div class="dx-field-label"></div>
          <div class="dx-field-value">
              <span class="red">
                  Date value outside range {{minDate()}} - {{maxDate()}}
              </span>
          </div>
      </div>      
      <div class="dx-field">
        <div class="dx-field-label"></div>
        <div class="dx-field-value">
            <dx-button class="left" text="Lookup Event Counts" (onClick)="lookupEvents()"></dx-button>
        </div> 
      </div>
    </div>
  </form>

  <div *ngIf="events.length > 0">
    <dx-data-grid [dataSource]="events" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [searchPanel]="{ visible: true, placeholder: 'Search...' }" [wordWrapEnabled]="false" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="false">
  
      <dxi-column dataField="eventDescription" [fixed]="true" fixedPosition="left" caption="Event"></dxi-column>
      <dxi-column dataField="year" [fixed]="true" fixedPosition="left" caption="Year"></dxi-column>
      <dxi-column dataField="january" caption="Jan"></dxi-column>
      <dxi-column dataField="february" caption="Feb"></dxi-column>
      <dxi-column dataField="march" caption="Mar"></dxi-column>
      <dxi-column dataField="april" caption="Apr"></dxi-column>
      <dxi-column dataField="may" caption="May"></dxi-column>
      <dxi-column dataField="june" caption="Jun"></dxi-column>
      <dxi-column dataField="july" caption="Jul"></dxi-column>
      <dxi-column dataField="august" caption="Aug"></dxi-column>
      <dxi-column dataField="september" caption="Sep"></dxi-column>
      <dxi-column dataField="october" caption="Oct"></dxi-column>
      <dxi-column dataField="november" caption="Nov"></dxi-column>
      <dxi-column dataField="december" caption="Dec"></dxi-column>       
  
    </dx-data-grid>
  </div>

</app-card>
<router-outlet></router-outlet>
