import { Component, OnInit, OnDestroy } from '@angular/core';
import { DocumentWhitelistPayload } from '../../core/services/models';
import { Subscription } from 'rxjs';
import { LoadingService, AdminService } from '../../core/services';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BaseComponent } from '../../core/component/base.component';
import { confirm, alert } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-documents-whitelist',
  templateUrl: './documents-whitelist.component.html',
  styleUrls: ['./documents-whitelist.component.scss']
})
export class DocumentsWhitelistComponent extends BaseComponent implements OnInit, OnDestroy {

  documents:Array<DocumentWhitelistPayload> = [];
  navigationSubscription:Subscription;
  
  constructor(public loadingService:LoadingService,public adminService:AdminService,private router: Router, private route: ActivatedRoute) { 
    super(); 
    
    this.navigationSubscription = this.router.events.subscribe((e:any) => {
      if(e instanceof NavigationEnd) {       
        if(this.route.snapshot.data.documents) {
          this.documents = this.route.snapshot.data.documents;         
        }
      }
    });
    
  }
  
  ngOnInit() {
  }

  addDocumentPopUp() {
    this.router.navigate(["add-document"], { relativeTo: this.route });
  }

  deleteDocumentConfirmation($event,data) {

    if($event) {      
      $event.event.stopPropagation();
    }

    const result: Promise<boolean> = confirm("Are you sure?", "Confirmation");
    result.then((dialogResult) => {
        if(dialogResult) {
          this.loadingService.loading = true;
          this.adminService.deleteDocumentWhitelist(data.typeId).subscribe(() => {
            this.loadingService.loading = false;
            alert("Document has been removed","Information");
            this.router.navigate(['documents-whitelist'], { relativeTo: this.route.parent });
          });
        }
    });

  }

  onDocumentSelected(data) {
    this.router.navigate([data.typeId], { relativeTo: this.route });
  }

  ngOnDestroy() {    
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }

}
