import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { ContactUsStateService } from 'app/core/services';

enum TimeOfDay {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  ALL_DAY = 'ALL_DAY'
}
interface DateTime {
  date: Date;
  time: TimeOfDay;
}

@Component({
  selector: 'app-ask-question-non-medical-appointment-time',
  templateUrl: './ask-question-non-medical-appointment-time.component.html',
  styleUrls: ['../../request-appointmentv2/request-appointmentv2.component.scss', './ask-question-non-medical-appointment-time.component.scss']
})
export class AskQuestionNonMedicalAppointmentTimeComponent extends BaseComponent implements OnInit {

  preferredDatesTimes: DateTime[] = [
    // {
    //   date: new Date(),
    //   time: TimeOfDay.MORNING
    // },
    // {
    //   date: new Date(),
    //   time: TimeOfDay.AFTERNOON
    // }
  ];
  showCalendar = false;
  dateSelected?: Date;
  timeAMChecked = false;
  timePMChecked = false;
  editingDateIndex: number;
  editing = false;
  title: string;

  constructor(private route: ActivatedRoute, private router: Router, private contactUsState: ContactUsStateService) {
    super();
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.editing = params.edit === 'true'
    });

    const state = this.contactUsState.getState();
    const preferredDatesTimes = state.preferredDatesTimes || [];
    this.preferredDatesTimes = preferredDatesTimes;
    this.title = state.subject.value;

  }

  scrollToTop() {
    document.getElementById('content-area').scroll(0, 0);
  }

  addDatesTimes = () => {
    this.scrollToTop();
    this.editingDateIndex = undefined;
    this.dateSelected = undefined;
    this.showCalendar = true;
  }

  closeCalendar = () => {
    this.scrollToTop();
    this.showCalendar = false;
  }

  editDateTime = (i: number) => {
    this.scrollToTop();
    this.editingDateIndex = i;
    const dateTime = this.preferredDatesTimes[i];
    let isAM = true;
    let isPM = true;
    if (dateTime.time === TimeOfDay.AFTERNOON) {
      isAM = false;
    } else if (dateTime.time === TimeOfDay.MORNING) {
      isPM = false;
    }
    this.dateSelected = dateTime.date;
    this.timeAMChecked = isAM;
    this.timePMChecked = isPM;
    this.showCalendar = true;
  }

  deleteDateTime = (i: number) => {
    this.preferredDatesTimes.splice(i, 1);
  }

  onDateChanged = () => {
    console.log('onDateChanged', this.dateSelected);
    const contentArea = document.getElementById('content-area');
    contentArea.scroll({
      top: contentArea.scrollHeight,
      behavior: 'smooth'
    });
  }

  addDateTimeOption = () => {
    const isAM = this.timeAMChecked;
    const isPM = this.timePMChecked;
    if (this.dateSelected && (isAM || isPM)) {
      const timeOfDay: TimeOfDay = (isAM && isPM) ? TimeOfDay.ALL_DAY : (isAM ? TimeOfDay.MORNING : TimeOfDay.AFTERNOON);
      const dateTime: DateTime = {
        date: this.dateSelected,
        time: timeOfDay
      };
      if (this.editingDateIndex >= 0) {
        this.preferredDatesTimes.splice(this.editingDateIndex, 1, dateTime);
      } else {
        this.preferredDatesTimes.push(dateTime);
      }
      this.timeAMChecked = false;
      this.timePMChecked = false;
      this.closeCalendar();
    }
  }

  isWeekend = (d: Date) => {
    const day = d.getDay();
    return day === 0 || day === 6;
  }

  isPastCutoff = (d: Date) => {
    const todayDate = new Date();
    const todayMs = todayDate.getTime();
    if (todayDate.getDay() === 5 && ((d.getTime() - todayMs) < 86400000 * 3)) {
      return true;
    } else if (todayDate.getDay() === 6 && ((d.getTime() - todayMs) < 86400000 * 2)) {
      return true;
    } else {
      return (d.getTime() - todayMs) < 86400000;
    }
  }

  disabledDates = (o: any) => {
    const d: Date = o.date;
    if (o.view === 'month') {
      return this.isWeekend(d) || this.isPastCutoff(d);
    } else {
      return false
    }
  }

  onToggleAMCheckbox = () => {
    this.timeAMChecked = !this.timeAMChecked;
  }
  onTogglePMCheckbox = () => {
    this.timePMChecked = !this.timePMChecked;
  }

  onNextClick = (e: any) => {
    this.contactUsState.setState({ preferredDatesTimes: this.preferredDatesTimes });
    this.router.navigate(['non-medical', this.editing ? 'review' : 'appointment-contact'], { relativeTo: this.route.parent });
  }

  close = () => {
    window.history.back();
  }

}
