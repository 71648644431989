import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../core/component/base.component';

@Component({
  selector: 'app-help',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent extends BaseComponent implements OnInit {

  constructor(private router: Router){
    super();
  }

  ngOnInit() : void {
    // this.openContactForm();
  }

  openContactForm(){
    this.router.navigateByUrl('app/contact-us/contact-technical-support');
  }

}
