<app-card label="Frequently Asked Questions">
  <div class="faq">

    <div class="faq-question">
      <span>Q: </span>
      <span>Why has the portal been redesigned?</span>
    </div>

    <div class="faq-answer">
      <span>A: </span>
      <span>The primary reason for the redesign is to give CTCA patients a better experience, as the new design is easier to use, includes more resources and is mobile friendly. We plan to make ongoing improvements in both the look and functionality of the portal as technology continues to advance.</span>
    </div>

    <div class="faq-question">
      <span>Q: </span>
      <span>Who should patients call if they have questions/issues with the portal?</span>
    </div>
  
    <div class="faq-answer">
      <span>A: </span>
      <span>We continue to offer a 24/7 IT Help Line if you have technical issues with your portal. Technical Support can be reached at 800-234-0482. Please use this number only for technical issues, such as changing your password or log-in support. The operators are NOT able to answer medical-related questions.</span>
    </div>

    <div class="faq-question">
      <span>Q: </span>
      <span>Will patient schedules be formatted differently?</span>
    </div>
    
    <div class="faq-answer">
      <span>A: </span>
      <span>Yes. The schedules printed from your portal will now match the easier readability of the format used when you request a hard copy onsite. Each appointment is also clearly separated by color bars, and you’ll be able to click on each appointment for additional detail and/or to request changes.</span>
    </div>

    <div class="faq-question">
      <span>Q: </span>
      <span>Will someone really respond to messages sent via the portal? Are they secure? Is it ok for patients to still email their care team members directly?</span>
    </div>
      
    <div class="faq-answer">
      <span>A: </span>
      <span>Yes, your care team stands ready to read your secure message and respond within ONE business day. We do NOT recommend communicating with your care team members directly through regular email, as those messages will NOT be secure, and we cannot guarantee protection of your private information sent through public email systems. Therefore, please communicate with us directly through your portal. If you need to reach someone right away, please call the care management line at your CTCA location. In a true emergency, call 911.</span>
    </div>

    <div class="faq-question">
      <span>Q: </span>
      <span>Will I be able to get notifications via email when something has changed on my portal?</span>
    </div>
        
    <div class="faq-answer">
      <span>A: </span>
      <span>Yes, customizing notifications to your individual preferences is easy.  Current options for notifications include Lab Results and Secure Messages from your Care Team.  (please see User Guide for detailed instructions)</span>
    </div>

    <div class="faq-question">
      <span>Q: </span>
      <span>Will the process remain the same for giving non-CTCA providers access to the portal?</span>
    </div>
        
    <div class="faq-answer">
      <span>A: </span>
      <span>Yes. You simply click on “Share My Records” under “Quick Links” on your home page, then under the Security & Privacy tab in the Shared Information section you can manage that access.</span>
    </div>

    <div class="faq-question">
      <span>Q: </span>
      <span>Will I be able to make appointments online?</span>
    </div>
          
    <div class="faq-answer">
      <span>A: </span>
      <span>You will continue to be able to request appointments and changes via your portal, but a CTCA scheduler will work directly with you to make any necessary changes to your schedule.</span>
    </div>

    <div class="faq-question">
      <span>Q: </span>
      <span>If I need my medical information sent to another provider, how do I request that?</span>
    </div>
            
    <div class="faq-answer">
      <span>A: </span>
      <span>Under “Forms Library”, there is a Release of Information (ROI) section where the request can be downloaded or completed and submitted online.</span>
    </div>

  </div>
</app-card>