import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Popup } from '../../core/popup/popup';
import { Router, ActivatedRoute } from '@angular/router';
import { ResizingService } from '../../../app/core/services';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-unstable-connection-error',
  templateUrl: './unstable-connection-error.component.html',
  styleUrls: ['./unstable-connection-error.component.scss']
})
export class UnstableConnectionErrorComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

  constructor(router:Router, route:ActivatedRoute, resizingService: ResizingService) { 
    super(router, route, resizingService);
  }

  ngOnInit() {
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }

  navigateToParent(levels = 1) {
    this.close(); 
  }

  goHome = () => {
    this.close();   
    this.router.navigate(['app','home'],{relativeTo:this.route.parent});
  };

}
