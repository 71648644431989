<dx-popup #labResultsPopup [visible]="visible$ | async" [title]="'Lab Results - ' + (labResultsCollection?.dateCollected | date: 'shortDate')"
  (onHidden)="navigateToParent()">

  <dxi-toolbar-item [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ icon: 'fa fa-download', text: 'Download as PDF', type: 'default', onClick: onDownloadAsPDFClicked }">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view [useNative]="true">

      <dx-data-grid id="lab-results-grid" [dataSource]="labsFlattened" [wordWrapEnabled]="true" [showColumnLines]="false" [grouping]="{ allowCollapsing: false, autoExpandAll: true }"
        [paging]="{ enabled: false }" [summary]="{ groupItems: [{ column: 'labSetDateCollected', summaryType: 'max', valueFormat: 'date', showInGroupFooter: true }] }"
        [sortByGroupSummaryInfo]="[{ summaryItem: 'labSetDateCollected', sortOrder: 'desc' }]" [masterDetail]="{ enabled: true, template: 'detail' }"
        (onCellPrepared)="onCellPrepared($event)">

        <dxi-column dataField="labSetGroupLabel" caption="Lab Set" [allowSorting]="false" groupIndex="0"></dxi-column>
        <dxi-column dataField="labSetDateCollected" dataType="date" [visible]="false"></dxi-column>
        <dxi-column dataField="name" caption="Specific Lab" [allowSorting]="false"></dxi-column>
        <dxi-column dataField="result" caption="Result" [allowSorting]="false" cellTemplate="resultCellTemplate"></dxi-column>
        <dxi-column dataField="normalRange" caption="Reference Range" [allowSorting]="false" hidingPriority="1"></dxi-column>
        <dxi-column dataField="sortOrder" sortOrder="asc" [visible]="false"></dxi-column>

        <div *dxTemplate="let lab of 'resultCellTemplate'">
          <span [class.result-warning]="lab.data.isResultOutOfNormalRange">{{ lab.data.result }}</span>
          <span *ngIf="lab.data.isResultOutOfNormalRange" class="result-warning-tag">{{ lab.data.resultOutOfNormalRangeWarning }}</span>
        </div>

        <div *dxTemplate="let lab of 'detail'">
          <div [innerHTML]="lab.data.notes"></div>
        </div>
      </dx-data-grid>

      <app-alert *ngIf="labResultsCollection?.isCollectedWithinLast24Hours" level="info">
        <div>
          You have selected documents which include results posted in the last {{ labResultsReviewWarning }} hours. These results may not have been reviewed by
          your care team yet, and may require further discussion between members of your care team for clarification before
          finalization. Those final results will be discussed with you by your care team during your upcoming scheduled appointment.
          If you do not have an appointment scheduled, or wish to discuss these results earlier, please contact your Care
          Manager for assistance.
        </div>
      </app-alert>

      <app-alert *ngIf="labResultsCollection?.collectedBy?.toLowerCase() === 'external provider'" level="info">
        <div>These lab results were collected by an external provider and reported to CTCA.</div>
      </app-alert>

      <app-alert level="info">
        <div>This is not an official lab report. Please contact your Care Management team if you have any questions.</div>
      </app-alert>

    </dx-scroll-view>
  </div>
</dx-popup>
