import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { ServiceEndpoint } from './service-endpoint.enum';
import { Router } from '@angular/router';
import { ClaimsDocument } from './models';
@Injectable()
export class ClaimsService extends BaseService {
	constructor(router: Router, httpClient: HttpClient) {
		super(router, httpClient);
	}

	/**
	 * Get list of Hyland documents
	 * @param epi Epi Number
	 * @param startDate Start date range
	 * @param endDate End date range
	 * @returns 
	 */
	getDocumentsList(epi: number | string, startDate: string, endDate: string): Observable<Array<ClaimsDocument>> {
		const body = {
			epi, startDate, endDate
		};
		//console.log('getDocumentsList', body);
		return this.post<ClaimsDocument[]>({
			endpoint: ServiceEndpoint.CLAIMS_DOCUMENTS_LIST,
			body: body
		});
	}

	/**
	 * Downloads single Hyland document
	 * @param documentId Hyland unique document Id
	 * @param requestType Print or Email
	 * @returns 
	 */
	getDocument(documentId: string, requestType: string): Observable<HttpResponse<Blob>> {
		// create endpoint params
		let queryParams = {"RequestType": requestType};

		return this.getBlobWithFilename({
			endpoint: `${ServiceEndpoint.CLAIMS_SINGLE_DOCUMENT}/${documentId}`,
			params: queryParams
		});
	}

	/**
	 * Downloads from Hyland a list of documents
	 * @param documentIdList Array of Hyland unique Id's
	 * @param documentRequestType Print or Email
	 * @returns 
	 */
	getMultipleDocuments(documentIdList: Array<string>, documentRequestType: string): Observable<HttpResponse<Blob>> {
		let documentId = documentIdList;

		const body = {
			documentId, documentRequestType
		};

		return this.postBlobWithFilename({
			endpoint: ServiceEndpoint.CLAIMS_MULTIPLE_DOCUMENTS,
			body: body
		});
	}

	/**
	 * Makes a call to the Hyland API endpoint to email a single document
	 * @param documentId Single Hyland document Id
	 * @param requestType Print or Email
	 * @returns 
	 */
	emailDocument(documentId: string, requestType: string): Observable<string> {
		// create endpoint params
		let queryParams = {"RequestType": requestType};

		return this.get({
			endpoint: `${ServiceEndpoint.CLAIMS_SINGLE_DOCUMENT}/${documentId}`,
			params: queryParams
		});
	}

	/**
	 * Call to Portal API to email the Hyland documents based in an array of document Id's
	 * @param documentId Array of document Id's
	 * @param documentRequestType Print or Email
	 * @returns 
	 */
	emailMultipleDocuments(documentId: Array<string>, documentRequestType: string) {
		const body = {
			documentId, documentRequestType
		};

		return this.post({
			endpoint: ServiceEndpoint.CLAIMS_MULTIPLE_DOCUMENTS,
			body: body
		});
	}

}
