<app-card label="Interactive Resources">
  <div class="quick-link-icons">

    <div class="quick-link-icon" [routerLink]="['interactive-chatbot']" (keyup.enter)="loadChatBot()">
      <div class="quick-link-icon-icon">
        <img src="assets/images/icons/chat-bot.png" width="55px" height="55px" alt="COVID-19 Assessment">
      </div>
      <div class="quick-link-icon-label">COVID-19<br/>Assessment</div>
    </div>

  </div>
</app-card>
