import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../core/services';
import { BaseComponent } from '../core/component/base.component';

@Component({
  selector: 'app-auth0-login-callback',
  templateUrl: './auth0-login-callback.component.html',
  styleUrls: ['./auth0-login-callback.component.scss']
})
export class Auth0LoginCallbackComponent extends BaseComponent implements  OnInit {

  error: string;
  hasError = false;

  constructor(private authService: AuthenticationService) {super();}

  async ngOnInit(): Promise<void> {
    localStorage.setItem('startTimeFromAuth',Date.now().toString());
    const result = await this.authService.newAuthenticateCallback();
    if (!result.success) {
      this.hasError = true;
      this.error = result.error_description || 'There was an error during login';
    }
  }

  login(): void {
    this.authService.authenticatePrimary();
  }
}
