import { Component, OnInit, ViewChild } from '@angular/core';

import { FileDownloadService, FormLibraryService, LoadingService } from '../../core/services';
import { SubmitROIAuthorizationOnlineFormComponent } from '../../submit-roi-authorization-online-form/submit-roi-authorization-online-form.component';
import { BaseComponent } from '../../core/component/base.component';

@Component({
  selector: 'app-form-release-of-information',
  templateUrl: './release-of-information.component.html',
  styleUrls: ['./release-of-information.component.scss']
})
export class ReleaseOfInformationComponent extends BaseComponent implements  OnInit {
  @ViewChild(SubmitROIAuthorizationOnlineFormComponent, { static: false })
  submitRoiAuthOnlineModal: SubmitROIAuthorizationOnlineFormComponent;

  constructor(
    private formLibraryService: FormLibraryService,
    private downloadService: FileDownloadService,
    private loadingService:LoadingService
  ) { super(); }

  ngOnInit() {}

  downloadROIAuthorizationFormAsPDF = () => {
    this.loadingService.loading = true;
    this.formLibraryService
      .getRoiFormAsPDF()
      .subscribe(data => {
        this.loadingService.loading = false;
        this.downloadService.downloadPdf(data, `ROI Authorization Form`)
    });
  };
}
