<div class="flexV">
    <h1 class="mainTitle">{{title}}</h1>
    <hr>
    <h2 class="subtitle">Contact Preference</h2>
    <form [formGroup]="requestForm" novalidate>
        <app-selectable-item [hasCheckbox]="true" (onClick)="onTogglePhoneCheckbox()" [clickable]="true" [checked]="phoneChecked" title="Call me" [subtitle]="editingPhone?undefined:phoneValue" (onEdit)="editPhone()" [hasEdit]="!editingPhone" [hasClose]="editingPhone" (onClose)="closeEditing()">
            <div *ngIf="editingPhone" class="inlineEdit">
                <app-phone-field [formControl]="phoneControl" [isValid]="true"></app-phone-field>
                <app-button [disabled]="!phoneControl.valid" text="Save" (onClick)="savePhone()"></app-button>
            </div>
        </app-selectable-item>
        <app-selectable-item [hasCheckbox]="true" (onClick)="onToggleEmailCheckbox()" [clickable]="true" [checked]="emailChecked" title="Email me" [subtitle]="editingEmail?undefined:emailValue" (onEdit)="editEmail()" [hasEdit]="!editingEmail" [hasClose]="editingEmail" (onClose)="closeEditing()">
            <div *ngIf="editingEmail" class="inlineEdit">
                <input type="email"  [formControl]="emailControl"/>
                <app-button [disabled]="!emailControl.valid" text="Save" (onClick)="saveEmail()"></app-button>
            </div>
        </app-selectable-item>
    
        <div class="buttons" *ngIf="!editing">
            <dx-button 
                [elementAttr]="{class:'backButton'}"
                icon="fa fa-angle-left"
                text="Back" 
                type="success"
                (onClick)="close()"
            ></dx-button>
            <dx-button
                [elementAttr]="{class:'nextButton'}"
                icon="fa fa-angle-right"
                text="Next"
                type="success"
                [disabled]="!((phoneChecked && phoneValue) || (emailChecked && emailValue)) || editingEmail || editingPhone"
                (onClick)="onNextClick($event)"
            ></dx-button>
        </div>
        <div class="buttons" *ngIf="editing">
            <dx-button
                text="Save"
                type="success"
                [disabled]="!((phoneChecked && phoneValue) || (emailChecked && emailValue))"
                (onClick)="onNextClick($event)"
            ></dx-button>
        </div>
    </form>
</div>