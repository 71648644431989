import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResolveCareTeamsGuard } from '../core/guards';
import { CanActivatePrescriptionGuard } from '../core/guards';
import { ResolveFacilityInfoGuard } from '../core/guards';
import { PrescriptionRenewalRequestComponent } from './prescription-renewal-request.component';

const routes: Routes = [
  {
    path: '',
    component: PrescriptionRenewalRequestComponent,
    children: [],
    resolve: { careteams: ResolveCareTeamsGuard, facilityInfo: ResolveFacilityInfoGuard },
    canActivate: [CanActivatePrescriptionGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrescriptionRenewalRequestRoutingModule { }
