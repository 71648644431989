import { Component, OnInit } from '@angular/core';
import { FEATURE_FLAG_PORTAL_OUTAGE, LaunchDarklyService } from '../../core/services';
import { Outage } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';

@Component({
  selector: 'app-outage',
  templateUrl: './outage.component.html',
  styleUrls: ['./outage.component.scss']
})
export class OutageComponent extends BaseComponent implements OnInit {
  outage: Outage = {};
  constructor(private ld: LaunchDarklyService) {
    super();
  }

  call = () => {
    window.location.href = 'tel:' + this.outage.phoneNumber;
  }

  ngOnInit(): void {
    this.ld.flagValue(FEATURE_FLAG_PORTAL_OUTAGE, {}).then(outage => this.outage = outage);
  }

}
