<div class="app-container">
  <dx-load-panel    
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: Window }"
    [(visible)]="loadingService.loading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>
  <div class="layout-container">
    <div class="header">
      <img class="ctca-logo" src="assets/images/ctca-coh-logo.png?1" width="184" height="59" alt="CTCA Logo"/>
    </div>
    <div class="content" *ngIf="confirmed">
      <h1>Thank you for confirming<br/>your appointment!</h1>
      <div class="calendar">
        <img src="assets/images/icons/SVG/calendar.svg" width="37" height="37"/>
      </div>
      <p>We'll see you soon.</p>
      <dx-button text="View in My Portal" type="default" (click)="goPortalHome()"></dx-button>
    </div>
    <div class="content" *ngIf="failed">
      <h1>Unable to confirm appointment</h1>
      <div class="calendar">
        <img src="assets/images/icons/SVG/calendar.svg" width="37" height="37"/>
      </div>
      <p>Please contact scheduling team directly to confirm.</p>
      <dx-button text="Open My Portal" type="default" (click)="goPortalHome()"></dx-button>
    </div>
  </div>
</div>