import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileService } from '../services';
import { UserProfile } from '../services/models';
import { map } from 'rxjs/operators';


@Injectable()
export class ProxiesGuard implements CanActivate {
  
  constructor(private userSrv: UserProfileService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.doActivationCheck(route, state);    
  }
  
  private doActivationCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    return this.userSrv.getUserProfileHardCache().pipe(map((profile:UserProfile) => {
      const allProxies = profile.proxies;
      if(allProxies && allProxies.length > 0) {
        const proxies = allProxies.filter(proxy => proxy.relationshipType !== "Self");
        const impersonating = proxies.filter(proxy => proxy.isImpersonating).length > 0;
        return ((proxies.length > 0 || profile.isSuperUser) && !impersonating);
      } else {
        return false;
      }
    }));
    
  } 
  
}
