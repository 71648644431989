<app-card class="claimsContainer">
	<div class="title">Claims and Statements</div>
	<div class="filters">
		<div class="filterContainer">
			<label>Document Type</label>
			<dx-tag-box #documentTypesSelectBox [dataSource]="documentTypes" [showSelectionControls]="true"
				displayExpr="documentType" valueExpr="documentType" maxDisplayedTags="1"
				(onValueChanged)="documentTypeChanged($event)" (onMultiTagPreparing)="onMultiTagPreparing($event)"
				[elementAttr]="{ class: 'documentTypesTagBox' }" [value]="defaultDocumentTypes"
				placeholder="Select document type...">
				<dxi-button name="selectDocumentTypes" location="after" [options]="documentTypesButton"></dxi-button>
				<dxo-drop-down-options [width]="240"></dxo-drop-down-options>
			</dx-tag-box>
		</div>
		<div class="filterContainer">
			<label>Timeframe</label>
			<dx-select-box #timeframeSelectBox [dataSource]="timeframes" [showSelectionControls]="true"
				displayExpr="name" valueExpr="time" maxDisplayedTags="1" (onValueChanged)="timeframeChanged($event)"
				[grouped]="true" [value]="defaultTimeframe" [width]="160">
				<div *dxTemplate="let data of 'group'">
					<div *ngIf="data.showHeader" class="overlayBottomButtonContainer">
						<dx-button type="default" text="Apply Filter" class="overlayBottomButton"
							(onClick)="applyTimeFilter()"></dx-button>
					</div>
				</div>
			</dx-select-box>
		</div>

		<div class="filterContainer">
			<dx-button type="default" [elementAttr]="{ class: 'downloadButton' }" icon="fa fa-download" text="Download"
				(onClick)="downloadSelected()" [disabled]="printDocumentsSelected.length == 0" margin-right="1px"
				[width]="130"></dx-button>
		</div>
		<div class="filterContainer">
			<dx-button type="default" [elementAttr]="{ class: 'downloadButton' }" icon="fa fa-envelope" text="Email"
				(onClick)="emailSelected()" [disabled]="emailDocumentsSelected.length == 0" [width]="100"></dx-button>

		</div>



	</div>
	<dx-data-grid [dataSource]="filteredDocuments" [columnHidingEnabled]="false" [hoverStateEnabled]="true"
		[paging]="{ pageSize: 10 }" [pager]="{
			showPageSizeSelector: true,
			allowedPageSizes: [5, 10, 20],
			showInfo: true,
			showNavigationButtons: true
		}" [wordWrapEnabled]="true" [rowAlternationEnabled]="false" [showColumnLines]="false" [repaintChangesOnly]="true">
		<dxo-editing mode="cell" [allowUpdating]="true" [allowAdding]="false" [allowDeleting]="false"></dxo-editing>

		<dxi-column *ngIf="isMobile" dataField="documentDate" caption="Date" sortOrder="desc"
			cellTemplate="dateAndNameTemplate" [allowEditing]="false"></dxi-column>
		<dxi-column *ngIf="!isMobile" dataField="documentDate" caption="Date" sortOrder="desc" width="120px"
			cellTemplate="dateTemplate" [allowEditing]="false"></dxi-column>
		<dxi-column *ngIf="!isMobile" dataField="documentType" caption="Document" [allowSorting]="false"
			[allowSearch]="true" [allowEditing]="false"></dxi-column>
		<dxi-column caption="Download" dataField="print" [allowSorting]="false" [allowSearch]="false" width="90px"
			dataType="boolean" [allowEditing]="true" [setCellValue]="setEmailCheckboxValue">
		</dxi-column>
		<dxi-column caption="Email" dataField="email" [allowSorting]="false" [allowSearch]="false" width="60px"
			dataType="boolean" [allowEditing]="true" [setCellValue]="setPrintCheckboxValue">
		</dxi-column>

		<div *dxTemplate="let document of 'dateTemplate'">
			<b>{{ document.data.documentDate | date : 'MMM d, YYYY' }}</b>
		</div>

		<div *dxTemplate="let document of 'dateAndNameTemplate'">
			<b>{{ document.data.documentDate | date : 'MMM d, YYYY' }}</b>
			<div>{{ document.data.documentType }}</div>
		</div>
	</dx-data-grid>
	<dx-popup [width]="300" [height]="200" [showTitle]="true" title="Information" [(visible)]="popupVisible"
		contentTemplate="popup-content" position="center" [hideOnOutsideClick]="true" [showCloseButton]="false">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="closeButtonOptions">
		</dxi-toolbar-item>

		<div *dxTemplate="let data of 'popup-content'">
			<p>{{ responseText }}</p>
		</div>
	</dx-popup>
</app-card>
<router-outlet></router-outlet>