import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxSelectBoxModule,
  DxDateBoxModule  
} from 'devextreme-angular';


import { ReportingRoutingModule } from './reporting-routing.module';
import { ActiveAccountsComponent } from './active-accounts/active-accounts.component';
import { EventCountsComponent } from './event-counts/event-counts.component';
import { RegistrationsBySiteComponent } from './registrations-by-site/registrations-by-site.component';
import { LocationVisitsComponent } from './location-visits/location-visits.component';
import { LocationEventsComponent } from './location-events/location-events.component';
import { EventCountsPivotComponent } from './event-counts-pivot/event-counts-pivot.component';
import { EventsByPatientComponent } from './events-by-patient/events-by-patient.component';


@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxChartModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    ReportingRoutingModule
  ],
  declarations: [    
    ActiveAccountsComponent,
    EventCountsComponent,
    RegistrationsBySiteComponent,
    LocationVisitsComponent,
    LocationEventsComponent,
    EventCountsPivotComponent,
    EventsByPatientComponent
  ]
})
export class ReportingModule {}
