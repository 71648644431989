<div class="flexV">
    <h1 class="mainTitle">Non-Medical Question</h1>
    <hr>
    <h2 class="subtitle">What is your question about?</h2>
    
    <app-tags [tags]="questions" [selectedTag]="selectedQuestion" (onSelectTag)="selectQuestion($event)"></app-tags>
   
    <div class="buttons">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            [disabled]="!selectedQuestion"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>
</div>