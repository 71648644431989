import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, EMPTY, throwError as observableThrowError } from 'rxjs';
import { AppointmentDetail } from '../services/models';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { AppointmentsService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ResolveAppointmentRedirectGuard implements Resolve<AppointmentDetail> {
  constructor(private apptSvc: AppointmentsService,private router: Router) {}
  resolve(route: ActivatedRouteSnapshot,  state: RouterStateSnapshot ): AppointmentDetail | Observable<AppointmentDetail> | Promise<AppointmentDetail> {
    const id = route.parent.params['id'] || route.params["id"];
    
    return this.apptSvc.getAppointment(id).pipe(tap((appointment:AppointmentDetail) => {      
      const startDate = moment(appointment.startDateTime);
      const hoursAhead = moment().add(parseInt(environment.appointmentActionDisclaimer, 10), "h");
      if(hoursAhead.isAfter(moment(startDate))) {
        this.router.navigateByUrl(`/app/appointments/${id}/late-rescheduling`);
      }
		}),catchError((error:HttpErrorResponse) => {
      if(error.status === 404) {
        return EMPTY;
      } else {
        return observableThrowError(error);
      }
    }));
  }
}
