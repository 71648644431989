import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppointmentDetailModalComponent } from './appointment-detail-modal/appointment-detail-modal.component';
import { AppointmentsListComponent } from './appointments-list/appointments-list.component';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { DownloadScheduleComponent } from './download-schedule/download-schedule.component';
import { AppointmentLateReschedulingComponent } from './appointment-late-rescheduling/appointment-late-rescheduling.component';


const routes: Routes = [
	{
		path: '',		
		component: AppointmentsListComponent,
		data: { permissionLevel: PermissionLevel.VIEW_APPOINTMENTS },		
		children: [
			{
		        path: 'download-schedule',
		        component: DownloadScheduleComponent,
		        data: { permissionLevel: PermissionLevel.VIEW_APPOINTMENTS }
		    },
		    {
		        path: ':id',
				component: AppointmentDetailModalComponent,				
				data: { permissionLevel: PermissionLevel.VIEW_APPOINTMENTS },
				children: [
					{
						path: 'late-rescheduling',
						component: AppointmentLateReschedulingComponent,
						data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
					}
				]
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AppointmentsRoutingModule {}
