import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContactUsComponent } from './contact-us.component';
// import { SendMessageTechnicalSupportModalComponent } from './send-message-to-technical-support-modal/send-message-to-technical-support-modal.component';
// import { PermissionLevel } from '../core/permissions/permission-levels.enum';
// import { UnsavedChangesGuard } from '../core/guards';

const routes: Routes = [
	{
		path: '',
		component: ContactUsComponent,
		// children: [
		// 	{
		// 		path: 'contact-technical-support',
		// 		component: SendMessageTechnicalSupportModalComponent,
		// 		data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS },
		// 		runGuardsAndResolvers : 'always',
		// 		canDeactivate : [UnsavedChangesGuard]
		// 	}
		// ]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ContactUsRoutingModule {}
