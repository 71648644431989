<dx-popup [visible]="visible$ | async" title="Appointment Detail" height="660" (onHidden)="navigateToParent()">

  
  <dxi-toolbar-item [toolbar]="'bottom'" [visible]="!isMobile" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Close', onClick: close}">
  </dxi-toolbar-item>

  <ng-container *ngIf="appointment" smartlook-safe>
    <dxi-toolbar-item *appShowFor="PermissionLevel.RESCHEDULE_APPOINTMENT"  [visible]="!isMobile && !inThePast" [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'"
      [options]="{ text: 'Reschedule', icon: 'fa fa-clock-o', type: 'default', onClick: onRescheduleClick }">
    </dxi-toolbar-item>

    <dxi-toolbar-item *appShowFor="PermissionLevel.CANCEL_APPOINTMENT"  [visible]="!isMobile && !inThePast" [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'"
      [options]="{ text: 'Cancel Appointment', icon: 'fa fa-calendar-times-o', type: 'default', onClick: onCancelAppointmentClick }">
    </dxi-toolbar-item>

    <dxi-toolbar-item *appShowFor="PermissionLevel.RESCHEDULE_APPOINTMENT"  [visible]="isMobile && !inThePast" [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'"
      [options]="{ text: 'Reschedule', type: 'default', onClick: onRescheduleClick }">
    </dxi-toolbar-item>

    <dxi-toolbar-item *appShowFor="PermissionLevel.CANCEL_APPOINTMENT" [visible]="isMobile && !inThePast" [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'"
      [options]="{ text: 'Cancel Appointment', type: 'default', onClick: onCancelAppointmentClick }">
    </dxi-toolbar-item>
  </ng-container>  
  
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-load-panel    
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: Window }"
        [(visible)]="isLoading"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false">
    </dx-load-panel>
    <dx-scroll-view [useNative]="true">
      <ng-container *appShowFor="PermissionLevel.JOIN_TELEHEALTH_MEETING">
        <div *ngIf="appointment?.isTelehealthAppointment && appointment?.isUpcoming">
          <p *ngIf="!appointment?.teleHealthUrl || !showTeleJoinLink">
            Please refer to the following link if you have been scheduled for a telehealth appointment to 
            assist in preparing your device ahead of your appointment: 
            <a href="https://www.cancercenter.com/telehealth-setup-guide" target="_blank">TeleHealth Setup Guide</a>
          </p>
          <p *ngIf="appointment?.teleHealthUrl && showTeleJoinLink">
            <label class="telehealth-red">
              You have a Telehealth Appointment occurring soon.  You can join the scheduled meeting here:
            </label>
            <a (click)="openTelehealthAppointment()" class="dead-link" routerLinkActive="active">CTCA Telehealth Appointment</a>
          </p>  
        </div>
      </ng-container>
      <div class="dx-fieldset appointment-details" *ngIf="appointment">
        <div class="dx-field">
          <div class="dx-field-label">Name</div>
          <div class="dx-field-value-static">{{ appointment?.description }}</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Location</div>
          <div class="dx-field-value-static">{{ appointment?.location }}</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Date</div>
          <div class="dx-field-value-static">{{ appointment?.startDateTime | date: 'fullDate' }}</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Time</div>
          <div class="dx-field-value-static">{{ appointment?.startDateTime | date: 'shortTime' }} - {{ appointment?.endDateTime | date: 'shortTime' }}
            &nbsp;{{ appointment.facilityTimeZone }}
          </div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Provider</div>
          <div class="dx-field-value-static">{{ providerName }}</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Patient Instructions</div>
          <div class="dx-field-value-static">{{ appointment?.patientInstructions ? appointment.patientInstructions : 'None' }}</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Scheduler Notes</div>
          <div class="dx-field-value-static">{{ appointment?.schedulerNotes ? appointment.schedulerNotes : 'None' }}</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Additional Info</div>
          <div class="dx-field-value-static">{{ appointment?.additionalInfo ? appointment.additionalInfo : 'None' }}</div>
        </div>
      </div>

      <div class="dx-fieldset treatment-facility" *ngIf="appointment">
        <div class="dx-fieldset-header">Treatment Facility</div>
        <div class="dx-field">
          <div class="dx-field-label">Name</div>
          <div class="dx-field-value-static">{{ appointment?.facilityName }}</div>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Address</div>
          <div class="dx-field-value-static" *ngIf = "!appointment?.isTelehealthAppointment && (appointment?.facilityAddress1 || appointment?.facilityAddress2 || appointment?.facilityCity || appointment?.facilityState || appointment?.facilityPostalCode); else noAddress">
            <div>{{ appointment?.facilityAddress1 }}</div>
            <div>{{ appointment?.facilityAddress2 }}</div>
            <div>{{ appointment?.facilityCity }}, {{ appointment?.facilityState }} {{ appointment?.facilityPostalCode }}</div>
          </div>
          <ng-template #noAddress>
            <div class="dx-field-value-static">
              None
            </div>
          </ng-template>
        </div>
        <div class="dx-field">
          <div class="dx-field-label">Phone Number</div>
          <div class="dx-field-value-static">
            <div class="phone-number" *ngIf="appointment?.facilityMainPhone">Main: {{ appointment?.facilityMainPhone }}</div>
            <div class="phone-number" *ngIf="appointment?.facilitySchedulingPhone">Scheduling: {{ appointment?.facilitySchedulingPhone }}</div>
            <div class="phone-number" *ngIf="appointment?.facilityAccommodationsPhone">Accommodations: {{ appointment?.facilityAccommodationsPhone }}</div>
            <div class="phone-number" *ngIf="appointment?.facilityTransportationPhone">Transportation: {{ appointment?.facilityTransportationPhone }}</div>
          </div>
        </div>
      </div>

    </dx-scroll-view>
  </div>
</dx-popup>
<router-outlet></router-outlet>
