import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Lab, LabResultsCollection, LabSet } from '../../lab-results/shared/models';
import { BaseService } from './base.service';
import { LabResult } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { Router } from '@angular/router';
import { StringHelper }  from '../../shared/helpers/string.helper';
import { SupportService } from './index';

@Injectable()
export class LabResultsService extends BaseService {

	constructor(private supportService: SupportService, router: Router, httpClient: HttpClient) {
		super(router, httpClient);
	}

	getLabResults() {
		return this.get({
			endpoint: ServiceEndpoint.LABRESULTS_GETLABRESULTS,
			cacheInvalidator: interval(100000)
		}).pipe(
			map((labResults: LabResult[]) => {
				// Replace the encoded line breaks with HTML <br />
				this.supportService.updateBadgesCount();
					labResults.forEach(labResult => {
						if (labResult.notes != null) {
							labResult.notes = labResult.notes.replace(new RegExp('\r\n', 'g'), '<br />');
						}
					});
					
					return labResults;
				
			})
		);
	}
	
	removeLabResultsFromCache() {
		this.removeFromCache(ServiceEndpoint.LABRESULTS_GETLABRESULTS + "_");
		if (environment.cacheLogging) {
			console.log('%cCACHE INVALIDATE', 'background-color: #f00; color: #fff', ServiceEndpoint.LABRESULTS_GETLABRESULTS + "_");
		}
	}

	getLabResultsForDate(dateCollected: string, collectedBy: string): Observable<LabResult[]> {
		
		return this.get({
			endpoint: ServiceEndpoint.LABRESULTS_GETLABRESULTSFORDATE,
			params: {
				performeddate: dateCollected,
				collectedby: collectedBy
			},
			cacheInvalidator: interval(60000),
			cacheKey: `${collectedBy}${dateCollected}`
		});
	}

	getLabResultsReport(dateCollected: string, collectedBy: string): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.LABRESULTS_GETLABRESULTSREPORT,
			params: {
				performeddate: dateCollected,
				collectedby: collectedBy
			}
		});
	}

	mapLabResults(labResults: LabResult[]): LabResultsCollection[] {
		// Map the data returned by the service into our view models
		const labResultsCollections = [];
		
		labResults.forEach((labResult:LabResult) => {

			const performedDateTime = labResult.performedDateTime;
			let performedDateNoTime = moment(labResult.performedDateTime,"YYYY[-]MM[-]DD[T]HH[:]mm[:]ss").format("YYYY[-]MM[-]DD");

			// some browsers are not working with moment nicely
			if(StringHelper.isInteger(performedDateNoTime)) {				
				const tIndex = performedDateTime.indexOf("T");
				if (tIndex > 0) {
					performedDateNoTime = performedDateTime.slice(0,tIndex);
				}
			}

			// Group the lab results by the day they were collected and who they were collected by (CTCA or external provider)
			let labResultsCollection = labResultsCollections.find(
				(currentLabResultsCollection: LabResultsCollection) => {
					return (
						currentLabResultsCollection.dateCollected === performedDateNoTime &&
						currentLabResultsCollection.collectedBy === labResult.collectedBy
					);
				}
			);

			
			if (!labResultsCollection) {
				labResultsCollection = {
					dateCollected: performedDateNoTime,
					isCollectedWithinLast24Hours: false,
					collectedBy: labResult.collectedBy,
					labSets: []
				};
				labResultsCollections.push(labResultsCollection);
			}

			// Within the lab results collection, group by name of the lab set and the date it was collected
			// Note that the same lab set can be collected more than once the same day, so it's important to factor
			// in the time the lab set was collected when grouping lab results
			let labSet: LabSet = labResultsCollection.labSets.find(
				currentLabSet =>
					currentLabSet.name === labResult.name &&
					currentLabSet.dateCollected === performedDateTime
			);

			if (!labSet) {
				labSet = {
					name: labResult.name,
					dateCollected: performedDateTime,
					labs: []
				};
				labResultsCollection.labSets.push(labSet);
			}

			// Add the individual lab result to the lab set
			const lab: Lab = {
				labSetGroupLabel: `${labSet.name} (${moment(labSet.dateCollected).format("h:mm A")})`,
				labSetDateCollected: labSet.dateCollected,
				name: labResult.itemName,
				result: labResult.result,
				normalRange: labResult.normalRange,
				isResultOutOfNormalRange:
					labResult.abnormalityCodeCalculated &&
					labResult.abnormalityCodeCalculated.toLowerCase() !== 'n',
				resultOutOfNormalRangeWarning: labResult.abnormalityCodeDescription,
				sortOrder: labResult.displaySequence,
				notes: labResult.notes
			};
			labSet.labs.push(lab);
		});

		labResultsCollections.forEach((labResultsCollection: LabResultsCollection) => {
			// Set isCollectedWithinLast24Hours based on if any of the lab sets within the collection were collected within the last 24 hours
			labResultsCollection.isCollectedWithinLast24Hours =
				labResultsCollection.labSets.findIndex(
					(labSet: LabSet) => moment(labSet.dateCollected).isSameOrAfter(moment().subtract(parseInt(environment.appointmentActionDisclaimer),"h"))
				) >= 0;

			// Sort the labsets by time collected in descending order (newest to oldest), then by lab set name alphabetically
			labResultsCollection.labSets.sort((a: LabSet, b: LabSet) => {
				if (a.dateCollected !== b.dateCollected) {
					return a.dateCollected > b.dateCollected ? -1 : 1;
				} else {
					return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
				}
			});
		});

		return labResultsCollections;
	}
}
