import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '*:not([smartlook-safe])'
})
export class SmartlookDirective {

  constructor(el?: ElementRef) {
    if (el && el.nativeElement && el.nativeElement.setAttribute) {
      let skip = false;
      if (el.nativeElement.parentElement && el.nativeElement.parentElement.hasAttribute) {
        if (
          el.nativeElement.parentElement.hasAttribute('smartlook-safe')
          && !el.nativeElement.hasAttribute("data-recording-sensitive")
          && !el.nativeElement.hasAttribute("data-recording-disable")
        ) {
          skip = true;
          el.nativeElement.setAttribute('smartlook-safe', '');
        }
      }
      if (!skip) {
        el.nativeElement.setAttribute('data-recording-sensitive', '');
      }
    }
  }

}
