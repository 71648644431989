import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsOfUseAgreementComponent } from './terms-of-use-agreement/terms-of-use-agreement.component';
import { TermsOfUseRejectedComponent } from './terms-of-use-rejected/terms-of-use-rejected.component';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'terms-of-use-agreement',
				component: TermsOfUseAgreementComponent,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
            },
            {
				path: 'terms-of-use-rejected',
				component: TermsOfUseRejectedComponent,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TermsOfUseRoutingModule {}