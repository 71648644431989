<app-card label="User Guide">
  <div class="user-guide"> 
    <div class="user-guide-icon">
      <span class="fa fa-book fa-5x"></span>
    </div>
    <div class="user-guide-text">
      <div class="user-guide-icon-label">
        myCTCA User Guide
      </div>
      <div>
        The myCTCA User Guide contains information about many of the features that are available on the myCTCA.com Portal.
      </div>
      <div class="user-guide-download-btn">
        <dx-button text="View" icon="fa fa-file-pdf-o" type="default" (onClick)="openUserGuideWindow()"></dx-button>
      </div>
    </div>
  </div> 
</app-card>