<app-card label="Allergies">
  <div card-buttons-desktop>
    <dx-button text="Download as PDF" icon="fa fa-download" [disabled]="!canDownload()" (onClick)="download()"></dx-button>
  </div>
  <div card-buttons-mobile>
    <dx-button text="Download as PDF" icon="fa fa-download" [disabled]="!canDownload()" (onClick)="download()"></dx-button>
  </div>

  <dx-data-grid [dataSource]="data" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" 
    [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }"
    [wordWrapEnabled]="true" (onOptionChanged)="sortEvent($event)" [rowAlternationEnabled]="true" [showColumnLines]="false" 
    [searchPanel]="{ visible: true, placeholder: 'Search...' }">

    <dxi-column dataField="substance" caption="Substance"></dxi-column>
    <dxi-column dataField="reactionSeverity" caption="Reaction and Severity"></dxi-column>
    <dxi-column dataField="status" caption="Status"></dxi-column>

  </dx-data-grid>
</app-card>
