import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable ,  EMPTY } from 'rxjs';

import { anonymousEndpoints, AuthenticationService } from '../services';
import { UserToken, ActiveToken } from '../services/models';

@Injectable()
export class AddAuthenticationHeaderInterceptor implements HttpInterceptor {
	constructor(private auth: AuthenticationService) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let needsToken = false;
		if (req.url.startsWith(environment.portalServiceBaseUrl)) {
			const parts = req.url.split(environment.portalServiceBaseUrl);
			const endpoint = parts[1];
			if (anonymousEndpoints.indexOf(endpoint) === -1) {
				needsToken = true;
			}
		}

		if (needsToken) {
			let active_token: UserToken;
			switch (this.auth.activeToken) {
				case ActiveToken.None:
				case ActiveToken.Primary:
					active_token = this.auth.primary_token;
					if(!active_token || !this.auth.isTokenValid()) {
						this.auth.authenticatePrimary();
						return EMPTY;
					}
					break;
				case ActiveToken.Proxy:
					active_token = this.auth.proxy_token;
					if(!active_token || !this.auth.isTokenValid()) {
						this.auth.renewProxy();
						return EMPTY;
					}
					break;
				default:
					return EMPTY;
			}

			const duplicate = req.clone({
				headers: req.headers.set(
					'Authorization',
					this.auth.getAuthSignature()
				)
			});
			return next.handle(duplicate);
		} else {
			return next.handle(req);
		}
	}
}
