import { Component, OnInit } from '@angular/core';
import { AccountTotals } from '../../core/services/models';
import { ReportingService, LoadingService } from '../../core/services';
import { BaseComponent } from '../../core/component/base.component';

@Component({
  selector: 'app-active-accounts',
  templateUrl: './active-accounts.component.html',
  styleUrls: ['./active-accounts.component.scss']
})
export class ActiveAccountsComponent extends BaseComponent implements OnInit {

  accounts:Array<AccountTotals> = new Array<AccountTotals>();

  constructor(private reportingService:ReportingService,private loadingService:LoadingService) { 
    super();
  }

  ngOnInit() {
    this.loadingService.loading = true;
    this.reportingService.getAccountTotals().subscribe((data:Array<AccountTotals>) => {
      this.loadingService.loading = false;
      if(data) {
        this.accounts = data;
      }
    }, error => {
      this.loadingService.loading = false;
      throw(error);
    });
  }

}
