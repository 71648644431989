<app-card label="Advanced Notice of Non-Coverage">
  <div>
    <p>
      During the Public Health Emergency (PHE), many insurance payers were providing coverage for telemedicine visits. 
      However, this was only a temporary arrangement. It is possible that if your medical insurance doesn’t pay, you 
      may be financially liable for the non-covered service.  If you have received a request from our insurance 
      verification team, please complete the following Advanced Notice of Non-Coverage form at least 48 hours 
      BEFORE your scheduled telehealth visit, otherwise this telehealth visit will have to be canceled.
    </p>
  </div>
  <div>
      You can complete the following form online and submit it directly.
      <p>
        <dx-button text="Submit ANNC Form Online" icon="fa fa fa-check-square-o" 
        [routerLink]="['/app/advanced-notice-of-non-coverage']"></dx-button>
      </p>
  </div>
  <div *ngIf="anncFormExists">
    You can download your most recently submitted ANNC form here.
    <p>
      <dx-button text="Download ANNC Form" icon="fa fa-download" (onClick)="downloadAnncFormAsPDF()"></dx-button>
    </p>
  </div>
</app-card>
<router-outlet></router-outlet>