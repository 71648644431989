import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ImagingResult } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-imaging',
  templateUrl: './imaging.component.html',
  styleUrls: ['./imaging.component.scss']
})
export class ImagingComponent extends BaseComponent implements  OnInit {
  constructor(private router: Router, private route: ActivatedRoute) { super();}

  documents: ImagingResult[];
  ngOnInit() {
    const startTime = parseInt(localStorage.getItem('startTimeofImaging'), 10) || Date.now();
    this.documents = this.route.snapshot.data.documents;
    const endTime = Date.now();
    const elpasedTime = (endTime-startTime)/1000;
    if(window.location.pathname.split('/').length<=4 && window.location.pathname.includes('imaging'))
    trackPageLoadingTime(window.location.pathname,elpasedTime);
    localStorage.removeItem('startTimeofImaging');
  }

  onDocumentSelected = data => {
    this.router.navigate([data.id], { relativeTo: this.route });
  };
}
