import { Component, OnInit } from '@angular/core';
import { FileDownloadService, SupportService, LoadingService } from '../core/services';
import { BaseComponent } from '../core/component/base.component';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent extends BaseComponent implements  OnInit {
  constructor(
    private downloadService: FileDownloadService,
    private supportService: SupportService,
    private loadingService:LoadingService
  ) { super();}

  ngOnInit() {}

  downloadHippaNoticeAsPDF = () => {
    this.loadingService.loading = true;
    this.supportService
      .getHipaaNoticeAsPDF()
      .subscribe((data:Blob) => {
        this.downloadService.downloadPdf(data, `HIPAA_Notice_of_Privacy_Practices`)
        this.loadingService.loading = false;
      });
  };
  
}
