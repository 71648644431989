import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import { AppointmentDetail } from '../../core/services/models';
import { ResizingService, AppointmentsService, NavigationService } from '../../core/services';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { DxPopupComponent } from 'devextreme-angular';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-appointment-detail-modal',
  templateUrl: './appointment-detail-modal.component.html',
  styleUrls: ['./appointment-detail-modal.component.scss']
})
export class AppointmentDetailModalComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {

  appointment: AppointmentDetail;
  isMobile: boolean;
  inThePast = false;
  mobileSubscription: Subscription;
  navigationSubscription: Subscription;
  providerName: string;
  id: string;
  showTeleJoinLink = false;
  isLoading = true;

  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

  constructor(router: Router,
    route: ActivatedRoute,
    resizingService: ResizingService,
    private apptSvc: AppointmentsService,
    private navigationService: NavigationService
  ) {
    super(router, route, resizingService);

    this.navigationSubscription = this.navigationService.RouteChanged.subscribe(() => {
      if (!this.navigationSubscription) {
        const startTime = Date.now();
        this.isLoading = true;
        this.id = this.route.snapshot.params['id'];
        this.apptSvc.getAppointment(this.id).subscribe((appDetail: AppointmentDetail) => {
          this.appointment = appDetail;
          if (moment(appDetail.startDateTime).toDate().toDateString() === moment(Date.now()).toDate().toDateString())
          {
            this.showTeleJoinLink = true;
          }
          if (appDetail.resources && appDetail.resources.length > 0 && appDetail.resources.toLowerCase().indexOf('unassigned') < 0) {
            this.providerName = appDetail.resources;
          } else {
            this.providerName = 'Not Specified';
          }
          this.inThePast = moment(this.appointment.startDateTime).isBefore(moment(Date.now()));
          this.mobileSubscription = this.resizingService.IsMobile().subscribe((result: boolean) => {
            this.isMobile = result;
          });
          const endTime = Date.now();
          const elapsedTime = (endTime-startTime)/1000;
          trackPageLoadingTime('/app/appointments/:id',elapsedTime);
          this.isLoading = false;
        }, (error: HttpErrorResponse) => {
          if (error.status === 404) {
            this.router.navigate(['app', 'appointments']);
          }
          this.isLoading = false;
        });
      }
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
  }

  ngAfterViewInit() {
    super.init(this.popup);
  }

  ngOnDestroy(): void {
    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
    if (this.navigationSubscription) this.navigationSubscription.unsubscribe();
    super.destroy();
  }

  onRescheduleClick = () => {
    localStorage.setItem('startTimeOfRescheduleApptV2', Date.now().toString());
    this.router.navigate(['app', 'reschedule-appointmentv2', this.id]);
  };

  onCancelAppointmentClick = () => {
    localStorage.setItem('startTimeOfCancelApptV2', Date.now().toString());
    this.router.navigate(['app', 'cancel-appointmentv2', this.id]);
  };

  openTelehealthAppointment() {
    window.open(this.appointment.teleHealthUrl);
  }
}
