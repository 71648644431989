<app-card label="Clinical Summaries">
  <div class="blue-button">
    <img src="assets/images/blue-button.jpg" alt="blue button">
    <p>myCTCA supports the Blue Button initiative by providing you an easy way to download your personal health information
      to a file.
      <span class="blue-button-link" [routerLink]="['blue-button-initiative']">More Information</span>
    </p>
  </div>

  <form *ngIf="filterForm" [formGroup]="filterForm" novalidate>
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label"><label for="startDate">Start Date</label></div>
        <div class="dx-field-value">
          <dx-date-box  type="date" [inputAttr]="{ id: 'startDate' }" formControlName="startDate" placeholder="mm/dd/yyyy" [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" [isValid]="filterForm.get('startDate').valid || filterForm.get('startDate').pristine" (onFocusOut)="validateComponent('startDate')"></dx-date-box>
        </div>
      </div>
      <div class="dx-field">
          <div class="dx-field-label"><label for="endDate">End Date</label></div>
          <div class="dx-field-value">
            <dx-date-box  type="date" [inputAttr]="{ id: 'endDate' }" formControlName="endDate" placeholder="mm/dd/yyyy" [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" [isValid]="filterForm.get('endDate').valid || filterForm.get('endDate').pristine" (onFocusOut)="validateComponent('endDate')"></dx-date-box>
          </div>
      </div>
      <div *ngIf="wrongOrder()" class="dx-field">
          <div class="dx-field-label"></div>
          <div class="dx-field-value">
              <span class="red">
                  Start Date can't be after End Date
              </span>
          </div>
      </div>
    </div>
  </form>

  <div class="outer">
    <dx-button class="inner" type="default" text="Filter" (onClick)="filterCCDA()"></dx-button>   
  </div>

  <br />

  <div *ngIf="documents.length > 0">
    <dx-button style="float: left;"  text="Download" (onClick)="navigate('encryption-download-group')"></dx-button>   
    <dx-button style="float: right;" text="Transmit" (onClick)="navigate('transmit-group')"></dx-button>   
  </div>

  <br />
  <br />
  <br />
  <br />
  

  
  <dx-data-grid [dataSource]="documents" [hoverStateEnabled]="true"  [wordWrapEnabled]="true" keyExpr="uniqueId"  
  [selectionFilter]="null" [rowAlternationEnabled]="true" [showColumnLines]="false">

    <dxo-selection mode="multiple"  [deferred]="true"></dxo-selection>

    <dxi-column dataField="creationTime" caption="Date" dataType="date" sortOrder="desc" width="100px"></dxi-column>
    <dxi-column dataField="title" caption="Title" [allowSorting]="false" [allowSearch]="true" cellTemplate="titleTemplate"></dxi-column>
    <dxi-column dataField="facilityName" caption="Facility" hidingPriority="0" width="140px"></dxi-column>

    <div *dxTemplate="let document of 'titleTemplate'">
      <a (click)="onDocumentSelected(document.data)" class="dead-link">{{ document.data.title }}</a>
    </div>

  </dx-data-grid>
</app-card>

<router-outlet></router-outlet>
