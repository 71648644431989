import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';

@Component({
	selector: 'app-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent extends BaseComponent implements OnInit {
	@Input() label = "";
	@Input() button: any;
	@Input() height: string;
	@Input() width: string;
	@Input() actionLabel: string;
	@Input() actionLabelMobile: string;
	@Output() onActionClick: any = new EventEmitter();

	ngOnInit() {
		
	}

	clicked() {
		if (this.onActionClick && this.actionLabel) {
			this.onActionClick.emit();
		}
	}
}
