import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShowForDirective } from './show-for.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ShowForDirective],
  exports: [ShowForDirective]
})
export class PermissionsDirectivesModule {}
