import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from '../component/base.component';

@Component({
    selector: 'app-selectable-item',
    templateUrl: './selectable-item.component.html',
    styleUrls: ['./selectable-item.component.scss']
})
export class SelectableItemComponent extends BaseComponent implements OnInit {

    @Input() hasCheckbox: boolean;
    @Input() hasEdit: boolean;
    @Input() hasDelete: boolean;
    @Input() hasClose: boolean;
    @Input() checked: boolean;
    @Input() clickable: boolean;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() icon: string;


    @Output() onToggleCheckbox = new EventEmitter<any>();
    @Output() onEdit = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();
    @Output() onClose = new EventEmitter<any>();
    @Output() onClick = new EventEmitter<any>();

    constructor() {
        super();
    }

    ngOnInit(): void {

    }

    clicked(): void {
        if (this.clickable) {
            this.onClick.emit();
        }
    }

    containerClass() {
        let classes = 'container';
        if (this.checked) {
            classes += ' checked';
        }
        return classes;
    }

    wrapperClass() {
        let classes = 'componentWrapper';
        if (this.hasCheckbox) {
            classes += ' hasCheckbox';
        }
        if (this.clickable) {
            classes += ' clickable';
        }
        if (this.icon) {
            classes += ' hasIcon';
        }
        return classes;
    }
}