<dx-popup #popup [visible]="visible$ | async" (onHidden)="navigateToParent()" [title]="title" height="600" width="510">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
  </dxi-toolbar-item>
  <dxi-toolbar-item *appShowFor="PermissionLevel.MANAGE_SYSTEM_ALERT_MESSAGES" toolbar="bottom" location="after" widget="dxButton"
    [options]="{ text: actionButtonText, type: 'default', onClick: action }">
  </dxi-toolbar-item>  
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view>
      <div id="modalContent">
          <form *ngIf="messageForm" [formGroup]="messageForm" novalidate>
            <div class="dx-fieldset">
                <div class="dx-field">
                    <div class="dx-field-label">Controller</div>
                    <div class="dx-field-value">
                        <dx-select-box #controller [ngClass]="{'opacity':controller.disabled}" formControlName="controller" (onValueChanged)="getAvailableMethods()" [dataSource]="controllerMethods"
                        [isValid]="messageForm.get('controller').valid" valueExpr="id" displayExpr="controller"></dx-select-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Method</div>
                    <div class="dx-field-value">
                        <dx-select-box #method [ngClass]="{'opacity':method.disabled}" formControlName="method" [dataSource]="availableMethods" valueExpr="id" displayExpr="method"></dx-select-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Start Date Time</div>
                    <div class="dx-field-value">
                        <dx-date-box #startDate [ngClass]="{'opacity':startDate.disabled}" formControlName="startDate" type="datetime" [isValid]="messageForm.get('startDate').valid || messageForm.get('startDate').pristine" (onFocusOut)="validateComponent('startDate')"></dx-date-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">End Date Time</div>
                    <div class="dx-field-value">
                        <dx-date-box #endDate [ngClass]="{'opacity':endDate.disabled}" formControlName="endDate" type="datetime" [isValid]="messageForm.get('endDate').valid || messageForm.get('endDate').pristine" (onFocusOut)="validateComponent('endDate')"></dx-date-box>
                    </div>
                </div>
                <div *ngIf="wrongOrder()" class="dx-field">
                    <div class="dx-field-label"></div>
                    <div class="dx-field-value">
                        <span class="red">
                            Start Date Time can't be after End Date Time
                        </span>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Title</div>
                    <div class="dx-field-value">
                        <dx-text-area #title [ngClass]="{'opacity':message.disabled}" formControlName="title" [height]="160" placeholder="Type a title here..." [isValid]="messageForm.get('title').valid"></dx-text-area>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Message</div>
                    <div class="dx-field-value">
                        <dx-text-area #message [ngClass]="{'opacity':message.disabled}" formControlName="message" [height]="160" placeholder="Type a text here..." [isValid]="messageForm.get('message').valid"></dx-text-area>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Disable Function</div>
                    <div class="dx-field-value">
                        <dx-check-box #disableFunction [ngClass]="{'opacity':disableFunction.disabled}" style="float:left;" formControlName="disableFunction"></dx-check-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Facility</div>
                    <div class="dx-field-value">
                        <dx-select-box #facility [inputAttr]="{ id: 'selectedFacility' }" 
                            [dataSource]="facilitiesData" displayExpr="name" 
                            valueExpr="name" formControlName="facility">
                        </dx-select-box>
                    </div>
                </div>     
                <div class="dx-field">
                    <div class="dx-field-label">Priority</div>
                    <div class="dx-field-value">
                        <dx-check-box #priority [ngClass]="{'opacity':priority.disabled}" style="float:left;" formControlName="priority"></dx-check-box>
                    </div>
                </div>   
                <div class="dx-field">
                    <div class="dx-field-label">Display Order</div>
                    <div class="dx-field-value">
                        <dx-text-box #displayOrder [ngClass]="{'opacity':displayOrder.disabled}" style="float:left;" formControlName="displayOrder"></dx-text-box>
                    </div>
                </div>
            </div>
          </form>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
<router-outlet></router-outlet>
