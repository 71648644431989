import { AfterViewInit, Component, OnInit } from '@angular/core';
import { LoadingService } from '../../core/services';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';
import { BaseComponent } from '../../core/component/base.component';

@Component({
    selector: 'app-sit-survey',
	templateUrl: './sit-survey.component.html',
	styleUrls: ['./sit-survey.component.scss']
})
export class SITSurveyComponent extends BaseComponent implements OnInit, AfterViewInit {
    startTime = 0;
    constructor(private loadingService:LoadingService){
        super();
    }
    
    ngOnInit() {    
        this.startTime = Date.now();
        this.loadingService.loading = true;
    }    
    
    ngAfterViewInit(){    
        this.loadingService.loading = false;
        const endTime = Date.now();
        const elapsedTime = (endTime-this.startTime)/1000;
        trackPageLoadingTime(window.location.pathname,elapsedTime);
        
    }
    
}