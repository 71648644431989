import { Component, OnInit } from '@angular/core';

import { HealthHistoryService, FileDownloadService, LoadingService } from '../../core/services';
import { Immunization } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-immunizations',
  templateUrl: './immunizations.component.html',
  styleUrls: ['./immunizations.component.scss']
})
export class ImmunizationsComponent extends BaseComponent implements  OnInit {
  constructor(private hhSvc: HealthHistoryService, 
              private downloadSvc: FileDownloadService,
              private loadingService:LoadingService) { super();}

  data: Immunization[];
  ngOnInit() {
    const startTime = Date.now();
    this.loadingService.loading = true;
    this.hhSvc.getImmunizations().subscribe((data:Array<Immunization>) => {
      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elapsedTime);
      this.data = data;
    });
  }

  download = () => {
    this.loadingService.loading = true;
    this.hhSvc
      .getImmunizationsReport()
      .subscribe((report:Blob) => {
        this.loadingService.loading = false;
        this.downloadSvc.downloadPdf(report, 'immunizations');
    });
  };

  canDownload = () => {
    return this.data && this.data.length > 0;
  }
}
