import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
	ResolveCcdaDocumentGuard,
	ResolveCcdaDocumentListGuard,
	ResolveImagingDocumentGuard,
	ResolveImagingDocumentListGuard,
	ResolveMedicalDocumentGuard,
	ResolveMedicalDocumentListGuard
} from '../core/guards';
import { MedicalDocumentType } from '../core/services/models';
import { BlueButtonPopupComponent } from './blue-button-popup/blue-button-popup.component';
import { CarePlanComponent } from './care-plan/care-plan.component';
import { ClinicalSummariesDetailPopupComponent } from './clinical-summaries-detail-popup/clinical-summaries-detail-popup.component';
import { ClinicalSummariesTransmitPopupComponent } from './clinical-summaries-transmit-popup/clinical-summaries-transmit-popup.component';
import { ClinicalSummariesComponent } from './clinical-summaries/clinical-summaries.component';
import { ImagingDetailPopupComponent } from './imaging-detail-popup/imaging-detail-popup.component';
import { ImagingComponent } from './imaging/imaging.component';
import { MedicalDocumentDetailsPopupComponent } from './medical-document-details-popup/medical-document-details-popup.component';
import { MedicalDocumentListComponent } from './medical-document-list/medical-document-list.component';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { ClinicalSummariesDetailEncryptionDownloadPopupComponent } from './clinical-summaries-detail-encryption-download-popup/clinical-summaries-detail-encryption-download-popup.component';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'care-plan',
				component: CarePlanComponent,
				data: { permissionLevel: PermissionLevel.VIEW_CARE_PLAN }
			},
			{
				path: 'clinical-summaries',
				resolve: { documents: ResolveCcdaDocumentListGuard },
				component: ClinicalSummariesComponent,
				data: { permissionLevel: PermissionLevel.VIEW_CCDA_DOCUMENTS },
				children: [
					{
						path: 'blue-button-initiative',
						component: BlueButtonPopupComponent,
						data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
					},
					{
						path: ':id',
						resolve: { document: ResolveCcdaDocumentGuard },
						component: ClinicalSummariesDetailPopupComponent,
						data: { permissionLevel: PermissionLevel.VIEW_CCDA_DOCUMENTS },
						children: [
							{
								path: 'transmit',
								component: ClinicalSummariesTransmitPopupComponent,
								data: { permissionLevel: PermissionLevel.TRANSMIT_CCDA_DOCUMENTS }
							},
							{
								path: 'encryption-download',
								component: ClinicalSummariesDetailEncryptionDownloadPopupComponent,
								data: { permissionLevel: PermissionLevel.VIEW_CCDA_DOCUMENTS }
							}
						]
					},
					{
						path: ':id',
						children: [
							{
								path: 'transmit-group',
								component: ClinicalSummariesTransmitPopupComponent,
								data: { permissionLevel: PermissionLevel.TRANSMIT_CCDA_DOCUMENTS }
							},
							{
								path: 'encryption-download-group',
								component: ClinicalSummariesDetailEncryptionDownloadPopupComponent,
								data: { permissionLevel: PermissionLevel.VIEW_CCDA_DOCUMENTS }
							}
						]
					}
				]
			},
			{
				path: 'clinical',
				component: MedicalDocumentListComponent,
				data: {
					documentType: MedicalDocumentType.Clinical,
					permissionLevel: PermissionLevel.VIEW_CLINICAL_DOCUMENTS
				},
				resolve: { documents: ResolveMedicalDocumentListGuard },
				children: [
					{
						path: ':id',
						resolve: { document: ResolveMedicalDocumentGuard },
						component: MedicalDocumentDetailsPopupComponent,
						data: { permissionLevel: PermissionLevel.VIEW_CLINICAL_DOCUMENTS }
					}
				]
			},
			{
				path: 'radiation',
				component: MedicalDocumentListComponent,
				data: {
					documentType: MedicalDocumentType.Radiation,
					permissionLevel: PermissionLevel.VIEW_RADIATION_DOCUMENTS
				},
				resolve: { documents: ResolveMedicalDocumentListGuard },
				children: [
					{
						path: ':id',
						resolve: { document: ResolveMedicalDocumentGuard },
						component: MedicalDocumentDetailsPopupComponent,
						data: { permissionLevel: PermissionLevel.VIEW_RADIATION_DOCUMENTS }
					}
				]
			},
			{
				path: 'integrative',
				component: MedicalDocumentListComponent,
				data: {
					documentType: MedicalDocumentType.Integrative,
					permissionLevel: PermissionLevel.VIEW_INTEGRATIVE_DOCUMENTS
				},
				resolve: { documents: ResolveMedicalDocumentListGuard },
				children: [
					{
						path: ':id',
						resolve: { document: ResolveMedicalDocumentGuard },
						component: MedicalDocumentDetailsPopupComponent,
						data: { permissionLevel: PermissionLevel.VIEW_INTEGRATIVE_DOCUMENTS }
					}
				]
			},
			{
				path: 'imaging',
				resolve: { documents: ResolveImagingDocumentListGuard },
				component: ImagingComponent,
				data: { permissionLevel: PermissionLevel.VIEW_IMAGING_DOCUMENTS },
				children: [
					{
						path: ':id',
						resolve: { document: ResolveImagingDocumentGuard },
						component: ImagingDetailPopupComponent,
						data: { permissionLevel: PermissionLevel.VIEW_IMAGING_DOCUMENTS }
					}
				]
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class MedicalDocumentsRoutingModule {}
