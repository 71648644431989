import { NgModule } from '@angular/core';
import { AppointmentsModule } from '../appointments/appointments.module';
import {
  DxButtonModule,
  DxDateBoxModule,
  DxListModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTooltipModule
} from 'devextreme-angular';

import { SecureMailModule } from '../secure-mail/secure-mail.module';
import { SharedModule } from '../shared/shared.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { MedicalContactsCardComponent } from './medical-contacts-card/medical-contacts-card.component';
import { NewMessagesCardComponent } from './new-messages-card/new-messages-card.component';
import { QuickLinksCardComponent } from './quick-links-card/quick-links-card.component';
import { UpcomingAppointmentsCardComponent } from './upcoming-appointments-card/upcoming-appointments-card.component';
import { ChangeProxyPopupComponent } from './change-proxy-popup/change-proxy-popup.component';
import { ExternalLinksComponent } from './external-links/external-links.component';
import { LatestCcdaComponent } from './latest-ccda/latest-ccda.component';
import { AlertMessagesCardComponent } from './alert-messages-card/alert-messages-card.component';
import { InteractiveResourceCardComponent } from './interactive-resource-card/interactive-resource-card.component';
import { UserPreferencesService } from '../core/services';
import { BecomePatientCardComponent } from './become-patient-card/become-patient-card.component';
import { SelectPatientCardComponent } from './select-patient-card/select-patient-card.component';
import { ChatBotModalComponent } from './chatbot-modal/chatbot-modal.component';
import { UpcomingAppointmentsListComponent } from '../home/upcoming-appointments-card/appointment-list/appointment-list.component';
import { GhostListComponent } from './upcoming-appointments-card/ghost-list/ghost-list.component';
import { AlertModalComponent } from './alert-popup/alert-modal.component';

@NgModule({
  imports: [
    HomeRoutingModule,
    SharedModule,
    AppointmentsModule,
    SecureMailModule,
    DxPopupModule,
    DxScrollViewModule,
    DxSelectBoxModule,
    DxTextBoxModule,
    DxButtonModule,
    DxDateBoxModule,
    DxSelectBoxModule,
    DxTooltipModule,
    DxLoadPanelModule,
    DxListModule
  ],
  declarations: [
    HomeComponent,
    QuickLinksCardComponent,
    MedicalContactsCardComponent,
    UpcomingAppointmentsCardComponent,
    NewMessagesCardComponent,
    ChangeProxyPopupComponent,
    ExternalLinksComponent,
    LatestCcdaComponent,
    AlertMessagesCardComponent,
    BecomePatientCardComponent,
    SelectPatientCardComponent,
    InteractiveResourceCardComponent,
    ChatBotModalComponent,
    AlertModalComponent,
    UpcomingAppointmentsListComponent,
    GhostListComponent
  ],
  providers:[
    UserPreferencesService
  ]
})
export class HomeModule {}
