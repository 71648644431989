import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { BillPayRoutingModule } from './bill-pay-routing.module';
import { BillPayComponent } from './bill-pay.component';
import {
  DxButtonModule
} from 'devextreme-angular';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    BillPayRoutingModule
  ],
  declarations: [BillPayComponent]
})
export class BillPayModule { }
