<dx-popup #popup [visible]="visible$ | async" title="Prescription Detail" height="46x0" width="500" (onHidden)="navigateToParent()">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [visible]="showClose()" [options]="{ text: 'Close', onClick: close }">
  </dxi-toolbar-item>
  <ng-container *ngIf="canRenew()">
    <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Request Prescription Renewal', onClick:requestPrescriptionRenewal  }">
    </dxi-toolbar-item>
  </ng-container>
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <div *ngIf="!hasCareTeam && prettyPhone" class="noCareTeamLine onlyDesktop">Have a Question? Call your Care Team <a href="tel:{{cleanPhone}}">{{ prettyPhone }}</a><hr/></div>
    <dx-scroll-view>
      <h3>{{ prescription?.drugName }}</h3>
      <div>
        <strong>Status:</strong> {{ prescription?.statusType }}
      </div>
      <div>
        <strong>Prescribed By:</strong> {{ prescription?.prescriptionType | prescriptionType }}
      </div>
      <div>
        <strong>Start Date:</strong> {{ prescription?.startDate | date }}
      </div>
      <div>
        <strong>End Date:</strong> {{ prescription?.expireDate | date }}
      </div>
      <p>
        <strong>Instructions:</strong>
      </p>
      <p [innerHtml]="prescription?.instructions"></p>
      
    </dx-scroll-view>
    <div class="noCareTeamFooter onlyMobile" *ngIf="!hasCareTeam && prettyPhone">
      Have a Question?<br/>
      Call your Care Team <a href="tel:{{cleanPhone}}">{{ prettyPhone }}</a>
    </div>
  </div>
</dx-popup>
