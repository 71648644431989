import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService, DocumentExchangeService, FileDownloadService, ResizingService } from '../../core/services';
import { Popup } from '../../core/popup/popup';
import { DxPopupComponent } from 'devextreme-angular';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-clinical-summaries-detail-encryption-download-popup',
  templateUrl: './clinical-summaries-detail-encryption-download-popup.component.html',
  styleUrls: ['./clinical-summaries-detail-encryption-download-popup.component.scss']
})
export class ClinicalSummariesDetailEncryptionDownloadPopupComponent extends Popup implements OnInit, 
  AfterViewInit, OnDestroy {

  documentId:Array<string>;
  action:string;
  
  downloadForm = new FormGroup({
    password : new FormControl('')   
  });  

  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

  constructor(router: Router, route: ActivatedRoute, private loadingService:LoadingService,private docXSvc: DocumentExchangeService,
    private downloadSvc: FileDownloadService,
    resizingService: ResizingService) {
    super(router, route, resizingService);
  }

  ngOnInit() {
    this.documentId = this.route.snapshot.parent?.params?.id.split("-") || [];
    this.action = this.route.snapshot.url[0]?.path || '';
  }

  close = () => {
    if (this.action === 'encryption-download-group') this.navigateToParent(2);
    else this.navigateToParent(1);
	};

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }

  download = () => {
    this.loadingService.loading = true;
    const payload: any = { documentId: this.documentId };
    const password: string = this.downloadForm.get('password').value;
    if(password.length > 0 ) {
      payload["filePass"] = password;
    }
    this.docXSvc
      .downloadCCDADocument(payload)
      .subscribe((doc:Blob) => {
        this.loadingService.loading = false;
        this.downloadSvc.downloadZip(doc, "CCDA");
        this.close();
      }, (error:HttpErrorResponse) => {
        this.loadingService.loading = false;
        if (error.status === 404) {
          this.router.navigate(['app','medical-documents','clinical-summaries']);
        }
      });
  };

}
