import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { SupportService } from 'app/core/services';
import { FacilityInfo } from 'app/core/services/models';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-ask-question',
  templateUrl: './ask-question.component.html',
  styleUrls: ['./ask-question.component.scss']
})
export class AskQuestionComponent extends BaseComponent implements OnInit {

  router: Router;
  isLoading = false;

  billingPhone: string;
  careManagementPhone: string;

  constructor(router: Router, private route: ActivatedRoute, private contactUsService: SupportService) {
    super();
    this.router = router;
  }

  ngOnInit() {
    this.isLoading = true;
    this.contactUsService
      .getPrimaryFacilityInfo()
      .pipe(map(facilityInfoArr => facilityInfoArr[0]))
      .subscribe((facilityInfo: FacilityInfo) => {
        this.isLoading = false;
        if (facilityInfo && facilityInfo.name !== 'OCC') {
          if (facilityInfo.billingPhone) {
            this.billingPhone = facilityInfo.billingPhone;
          }
          if (facilityInfo.careManagementPhone) {
            this.careManagementPhone = facilityInfo.careManagementPhone;
          }
        } else {
          this.isLoading = true;
          this.contactUsService.getAllFacilitiesInfoForTreamentFacilitySelect().subscribe((array: FacilityInfo[]) => {
            this.isLoading = false;
            const phoenix = array.find(fi => {
              return fi.name === 'WRMC';
            });
            if (phoenix && phoenix.billingPhone) {
              this.careManagementPhone = phoenix.billingPhone;
            }
            if (phoenix && phoenix.careManagementPhone) {
              this.careManagementPhone = phoenix.careManagementPhone;
            }
          });
        }
      });
  }

  askMedicalQuestion() {
    // track event
    //trackEvent();
    this.router.navigate(['app', 'send-message']);
  }

  askNonMedicalQuestion() {
    // track event
    //trackEvent();
    this.router.navigate(['non-medical'], { relativeTo: this.route.parent });
  }

  get Window() {
    return window;
  }

}
