import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { UserLogsComponent } from './user-logs/user-logs.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { NotificationsComponent} from './notifications/notifications.component';
import { ResolveUserNotificationsGuard } from '../core/guards';

const routes: Routes = [
	{
		path: '',
		children: [	
			{
				path: 'preferences',
				component: PreferencesComponent,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'notifications',
				resolve: { notifications: ResolveUserNotificationsGuard },
				component: NotificationsComponent,
				data: { permissionLevel: PermissionLevel.MANAGE_NOTIFICATIONS },
				runGuardsAndResolvers: 'always'
			},		
			{
				path: 'user-logs',
				component: UserLogsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_USER_LOGS }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class AccountRoutingModule {}
