<div class="flexV">
    <h1 class="mainTitle">Request Appointment Schedule</h1>
    <hr>
    <h2 class="subtitle">How would you like to receive your schedule?</h2>


    <app-selectable-item [hasCheckbox]="true" (onClick)="onToggleEmailCheckbox()" [clickable]="true" [checked]="emailChecked" title="Send by email" [subtitle]="editingEmail?undefined:emailValue" (onEdit)="editEmail()" [hasEdit]="!editingEmail" [hasClose]="editingEmail" (onClose)="closeEditing()">
        <div *ngIf="editingEmail" class="inlineEdit">
            <input type="email"  [formControl]="emailControl"/>
            <app-button [disabled]="!emailControl.valid" text="Save" (onClick)="saveEmail()"></app-button>
        </div>
    </app-selectable-item>

    
    <!--app-selectable-item [hasCheckbox]="true" (onClick)="onToggleAddressCheckbox()" [clickable]="true" [checked]="addressChecked" title="Send by mail" [subtitle]="editingAddress?undefined:addressText" (onEdit)="editAddress()" [hasEdit]="!editingAddress" [hasClose]="editingAddress" (onClose)="closeEditing()">
        <div *ngIf="editingAddress" class="inlineEdit">
            <input type="text"  [formControl]="address1Control" placeholder="Address line 1"/>
            <input type="text"  [formControl]="address2Control"  placeholder="Address line 2"/>
            <div class="cityStateZip">
                <input type="text"  [formControl]="addressCityControl" placeholder="City"/>
                <input type="text"  [formControl]="addressStateControl" placeholder="State"/>
                <input type="text"  [formControl]="addressZipControl" placeholder="Zip"/>
            </div>
            <input type="text"  [formControl]="addressCountryControl" placeholder="Country"/>
            <app-button [disabled]="!address1Control.valid" text="Save" (onClick)="saveAddress()"></app-button>
        </div>
    </app-selectable-item-->

    <app-selectable-item icon="/assets/images/icons/SVG/download-button.svg" (onClick)="onDownloadPdf()" [clickable]="true" title="Download PDF Now"></app-selectable-item>

    
    <div class="buttons" *ngIf="!editing">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>

    <div class="buttons" *ngIf="editing">
        <dx-button
            text="Save"
            type="success"
            [disabled]="false"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>

    <app-alertv2 
      *ngIf="showDownloadSuccess"
      [showCloseButton]="false"
      title="Your schedule has been downloaded." 
      buttonText="Ok" (onClose)="onCloseSuccess()"
    ></app-alertv2>
</div>