import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { SupportService, ContactUsStateService, LoadingService, UserProfileService, ContactInfoService } from 'app/core/services';
import { ContactUsFormType, ContactUsQuestion, PatientContactInfo, UserProfile } from 'app/core/services/models';

@Component({
	selector: 'app-ask-question-non-medical',
	templateUrl: './ask-question-non-medical.component.html',
	styleUrls: ['./ask-question-non-medical.component.scss'],
})
export class AskQuestionNonMedicalComponent extends BaseComponent implements OnInit {
	questions: ContactUsQuestion[] = [];
	profile: UserProfile;
	selectedQuestion: ContactUsQuestion;
	patientContactInfo: PatientContactInfo;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private supportService: SupportService,
    private profileSvc: UserProfileService,
		private contactUsState: ContactUsStateService,
		private loadingService: LoadingService,
		private contactInfoService: ContactInfoService
	) {
		super();
	}

	ngOnInit(): void {
		this.loadingService.loading = true;
		this.supportService.getContactUsQuestions().subscribe((contactUsQuestions) => {
			this.loadingService.loading = false;
			//console.log(contactUsQuestions);
			this.questions = contactUsQuestions.questions.filter(
				(q) => q.formType !== ContactUsFormType.Secure
			);
      // find out if end user belongs to an OCC
      this.profileSvc.getUserProfile().subscribe((profile:UserProfile) => {
		  this.profile = profile;
        if (profile.primaryFacility === 'OCC') {
          console.log(`Profile Primary facility is ${profile.primaryFacility}`);
          // remove from the array the Travel option
			let questionItem: ContactUsQuestion = this.questions.find(x => x.value === 'Travel');
			let questionIndex: number = this.questions.indexOf(questionItem);
          if (questionIndex !== -1) {
            this.questions.splice(questionIndex, 1);
          }
          // now remove accommodations
          questionItem = this.questions.find(x => x.value === 'Accommodations');
          questionIndex = this.questions.indexOf(questionItem);
          if (questionIndex !== -1) {
            this.questions.splice(questionIndex, 1);
          }
          //console.log(this.questions);
        }
      });
		});

		this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
			this.patientContactInfo = patientContactInfo;
		});
	}

	selectQuestion = (question: ContactUsQuestion) => {
		this.selectedQuestion = question;
	};

	onNextClick = (e: any) => {
		let email = this.profile.emailAddress || '';
		if (this.patientContactInfo && this.patientContactInfo.emailAddress) {
			email = this.patientContactInfo.emailAddress;
		}

		this.contactUsState.setState({ question: this.selectedQuestion, email }, true);
		if (this.selectedQuestion.formType === ContactUsFormType.ROI) {
			this.router.navigate(['roi'], { relativeTo: this.route });
		} else {
			this.router.navigate(['subject'], { relativeTo: this.route });
		}
	};

	close = () => {
		window.history.back();
	};
}
