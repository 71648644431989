import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../component/base.component';

@Component({
    selector: 'app-alertv2',
    templateUrl: './alertv2.component.html',
    styleUrls: ['./alertv2.component.scss']
})
export class AlertV2Component extends BaseComponent {
    @Input() title: string;
    @Input() description: string;
    @Input() buttonText: string;
    @Input() showCloseButton = true;
    @Output() onClose = new EventEmitter<any>();

    onPopupClick(event) {
        event.stopPropagation();
    }
}