import { Component, OnInit } from '@angular/core';

import { SupportService } from '../../core/services';
import { FacilityInfo } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { map } from 'rxjs/operators';

@Component({
	selector: 'app-contact-treatment-facility',
	templateUrl: './contact-treatment-facility.component.html',
	styleUrls: ['./contact-treatment-facility.component.scss']
})
export class ContactTreatmentFacilityComponent extends BaseComponent implements OnInit {

	primaryFacilityInfo: FacilityInfo;
	primaryFacilityInfoArray: Array<FacilityInfo>;

	constructor(private contactUsService: SupportService) {
		super();
	}

	ngOnInit() {
		this.contactUsService
			.getPrimaryFacilityInfo()
			.pipe(map(facilityInfoArr => facilityInfoArr[0]))
			.subscribe(facilityInfo => {
				if(facilityInfo) {
					this.primaryFacilityInfo = {
						name: facilityInfo.name,
						address: {
							name: facilityInfo.address ? facilityInfo.address.name : '',
							line1: facilityInfo.address ? facilityInfo.address.line1 : '',
							line2: facilityInfo.address ? facilityInfo.address.line2 : '',
							city: facilityInfo.address ? facilityInfo.address.city : '',
							state: facilityInfo.address ? facilityInfo.address.state : '',
							postalCode: facilityInfo.address ? facilityInfo.address.postalCode : '',
							country: facilityInfo.address ? facilityInfo.address.country : ''
						},
						mainPhone: facilityInfo.mainPhone,
						schedulingPhone: facilityInfo.schedulingPhone,
						accommodationsPhone: facilityInfo.accommodationsPhone,
						transportationPhone: facilityInfo.transportationPhone,
						himroiPhone: facilityInfo.himroiPhone
					};
				} else {
					this.contactUsService.getAllFacilitiesInfoForTreamentFacilitySelect().subscribe((array:Array<FacilityInfo>)=>{
						this.primaryFacilityInfoArray = array;	
					});	
				}
			});
	}

	onSecureMailLinkClick() {}
}
