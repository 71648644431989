<dx-popup #popup [visible]="visible$ | async" (onHidden)="close()" title="Download Document" height="550" width="550">
    <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
    </dxi-toolbar-item>
    <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Download', type: 'default', onClick: download }">
    </dxi-toolbar-item>
    <div *dxTemplate="let data of 'content'" class="popup-content">
      <dx-scroll-view>        
        <p>You can password protect the downloaded file by entering a password below. If you don't wish to password protect the file leave the password field blank.</p>
        <p>Click the download button to begin downloading the file.</p>
        <p><span class="red">Warning: </span>Do not download this file if you are using a public computer.</p>
        <p>Your health data will download as a ZIP file containing a human readable HTML file, as well as a machine readable XML file for importing into other systems.</p>
        <p>The encryption method used to password-protect the file(s) uses a more secure method that operating systems like Microsoft Windows may not support natively.  Many zip utility programs do support this method - an example of one that is available to download and install for free is called 7-Zip.  Your will need to have the more advanced version of this technology to open your password-protected file(s).</p>
        <form *ngIf="downloadForm" [formGroup]="downloadForm" novalidate>
          <div class="dx-fieldset">
            <div class="dx-field">
              <label for="password" class="label">Optional File Password:</label>              
              <dx-text-box [inputAttr]="{ id: 'password' }" mode="password" formControlName="password"></dx-text-box>              
            </div>
          </div>
        </form>
      </dx-scroll-view>
    </div>
  </dx-popup>
  
