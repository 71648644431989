import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AdminService, LoadingService } from '../../core/services';
import { alert } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-load-npi-files',
  templateUrl: './load-npi-files.component.html',
  styleUrls: ['./load-npi-files.component.scss']
})
export class LoadNpiFilesComponent implements OnInit {

  urlForm = new FormGroup({
    url : new FormControl('',Validators.required)   
  });

  dataForm = new FormGroup({
    directoryName : new FormControl('',Validators.required),
    fileName: new FormControl('',Validators.required)  
  });

  constructor(private adminService:AdminService,private loadingService:LoadingService) { }

  ngOnInit() {

  }

  uploadNPIData() {

    if(this.urlForm.valid) {
      this.loadingService.loading = true;
      const url: string = this.urlForm.get('url').value.trim();
      this.adminService.loadNpiFile(url).subscribe((result:string) => {
        this.loadingService.loading = false;
        if(result === 'Success') {          
          alert("Operation completed successfully","Information");
        } else {
          alert("An unexpected error occurred while processing the request","Information");
        }
      }, error => {
        this.loadingService.loading = false;
        throw(error);
      });
    }

  }

  splitNPIData() {

    if(this.dataForm.valid) {
      this.loadingService.loading = true;
      const directoryName: string = this.dataForm.get('directoryName').value.trim();
      const fileName: string = this.dataForm.get('fileName').value.trim();
      this.adminService.splitNpiFile(directoryName,fileName).subscribe((result:string) => {
        this.loadingService.loading = false;
        if (result === 'Success') {          
          alert("Operation completed successfully","Information");
        } else {
          alert("An unexpected error occurred while processing the request","Information");
        }
      }, error => {
        this.loadingService.loading = false;
        throw(error);
      });
    }

  } 

}
