import { Injectable } from '@angular/core';

@Injectable()
export class ContactUsStateService {
    private state: any;

    constructor() {
        const lsState = localStorage.getItem('contactUsState') || "{}";
        this.state = JSON.parse(lsState);
    }

    public getState(key?: string): any {
        if (key) {
            return this.state[key];
        } else {
            return this.state;
        }
    }

    public setState(newState: Partial<any>, reset?: boolean) {
        let newFullState = newState || {};
        if (!reset) {
            newFullState = {
                ...this.state,
                ...newFullState,
            };
        }
        this.state = newFullState
        localStorage.setItem('contactUsState', JSON.stringify(newFullState));
    }
}