<app-card label="Advanced Notice Of Non-Coverage">
  <div class="popup-content">
    <div class="header">
      <div class="title">
        Advanced Notice Of Non-Coverage
      </div>
      <div class="button" (click)="close()"><i class="dx-icon-close"></i></div>
    </div>


      <form *ngIf="anncForm" [formGroup]="anncForm" novalidate>
        <div class="dx-fieldset">
          
          <div class="dx-field shorter">
            <label for="selectedFacility" class="label">Treatment Facility</label>
            <dx-select-box [inputAttr]="{ id: 'selectedFacility' }" 
                [dataSource]="facilitiesData" displayExpr="displayName" 
                valueExpr="name" formControlName="selectedFacility"
                [isValid]="anncForm?.controls['selectedFacility'].valid || anncForm?.controls['selectedFacility'].pristine"
                [validationError]="{ message: 'Treatment Facility is required' }">
            </dx-select-box>            
          </div>

          <div class="dx-field shorter">
            <label for="txtInsuranceName" class="label">Insurance Name</label>
            <dx-text-box [inputAttr]="{ id: 'txtInsuranceName' }" formControlName="txtInsuranceName" 
                placeholder="Insurance Name"  [isValid]="anncForm?.controls['txtInsuranceName'].valid || anncForm?.controls['txtInsuranceName'].pristine"
              [validationError]="{ message: 'Insurance Name is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>
          </div>

          <div class="dx-field shorter">
            <div class="label">Patient's Name</div>            
            <label for="txtFirstName" class="visuallyhidden">First Name:</label>              
            <dx-text-box [inputAttr]="{ id: 'txtFirstName' }" formControlName="txtFirstName" placeholder="First Name" 
                [isValid]="anncForm?.controls['txtFirstName'].valid || anncForm?.controls['txtFirstName'].pristine"
              [validationError]="{ message: 'First Name is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>              
            <label for="txtLastName" class="visuallyhidden">Last Name:</label>
            <dx-text-box [inputAttr]="{ id: 'txtLastName' }" style="margin-top: 10px;" 
                formControlName="txtLastName" placeholder="Last Name" 
                [isValid]="anncForm?.controls['txtLastName'].valid || anncForm?.controls['txtLastName'].pristine"
              [validationError]="{ message: 'Last Name is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>         
          </div>

          <div class="dx-field shorter">
            <label for="txtMrn" class="label">Mrn</label>
            <dx-text-box [inputAttr]="{ id: 'txtMrn' }" formControlName="txtMrn" 
                placeholder="Identification Number" 
                [isValid]="anncForm?.controls['txtMrn'].valid || anncForm?.controls['txtMrn'].pristine"
              [validationError]="{ message: 'Mrn is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>
          </div>
 
          <div class="dx-field shorter">
            <label for="dpDateOfService" class="label">Date of Service</label>
            <dx-date-box [inputAttr]="{ id: 'dpDateOfService' }" formControlName="dpDateOfService" 
                type="date" [max]="dateNow" placeholder="mm/dd/yyyy" 
                invalidDateMessage="Must be in mm/dd/yyyy format"
                dateOutOfRangeMessage="Date of Service is not valid" 
                (onFocusOut)="dateOfServiceValueChanged(null)" 
                [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" 
                [isValid]="anncForm?.controls['dpDateOfService'].valid || anncForm?.controls['dpDateOfService'].pristine"
              [validationError]="{ message: 'Date of Service is required'}" style="width: 100%">
            </dx-date-box>           
          </div>
          <div *ngIf="_inThePast" class="dx-field shorter">              
            <span class="red">
              Date of Service should not be in the past
            </span>              
          </div>
        
          <div style="border-bottom:2px solid;">&nbsp;</div>

          <div class="dx-field shorter annc">
            <div style="text-align: center;">
                <h3>Advanced Notice Of Non-Coverage</h3>
            </div>
            <div style="padding: 5px;">
              <p>
                  <strong>
                      In some cases, your insurance benefits for telehealth services may be changing.
                      Please check with your insurance to determine if you have telehealth benefits for CTCA telehealth services
                      prior to booking your telehealth appointment.
                  </strong>
              </p>
              <p>
                  Medical insurance plans do not pay for everything, even some care that you or your health care provider have good reason to think
                  you need. We expect your medical insurance may not pay for the Telehealth below. If your medical insurance doesn't pay, you may
                  be financially liable for the one-covered service.
              </p>              
            </div>
            <table class="info-table">
                <thead>
                    <tr>
                        <th>Procedure</th>
                        <th>Reason Insurance May Not Pay</th>
                        <th>Estimated Cost</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Telehealth appointment</td>
                        <td>Does not have telehealth benefits for services provided by CTCA</td>
                        <td>Estimate price range:<br/>
                            New patient: $79.75-$495.55<br/>
                            Return patient: $26.95-$325.05
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="label">WHAT YOU NEED TO KNOW:</div>
            <div class="content-list">
                <ul>
                    <li>Read this notice, so you can make an informed decision about your care.</li>
                    <li>Ask us any questions that you may have after you finish reading.</li>
                    <li>Choose an option below about whether to receive the Telehealth listed above.</li>
                </ul>
            </div>
            <div class="label">Note:</div>
            <div class="content">
                If you choose Option 1 or 2, we may help you to use any other insurance that you might have, 
                but your medical insurance plan cannot require us to do this. 
            </div>


            <table class="options-table">
                <thead>
                    <tr>
                        <th colspan="2">OPTIONS:&nbsp;Choose an option. We cannot choose one for you.</th>
                    </tr>
                </thead>
                <tbody formArrayName="paymentOptions">
                  <tr *ngFor="let option of paymentOptions.controls;  let i = index" [formGroupName]="i">
                    <td>
                      <dx-check-box [formControlName]="getKey(option['controls'])" [id]="telehealthOptions[getKey(option['controls'])].id"
                        [isValid]="isOneOptionSelected" [ValidFormControl]="isOneOptionSelected" 
                        [validationError]="{ message: 'One option is required' }" 
                        (onValueChanged)="optionValueChange($event)">
                      </dx-check-box>
                      <p class="dx-checkbox-text" *ngIf="telehealthOptions[getKey(option['controls'])].id === 'option1'">Option 1. 
                        I <b>want</b> the Telehealth services listed above. You may ask to be paid now, but I also 
                        want my medical insurance billed for an official decision on payment, which 
                        is sent to me on an Explanation of Benefits (EOB). I understand that if my 
                        medical insurance doesn’t pay, I am responsible for payment, but <b>I can appeal</b> 
                        to my medical insurance by contacting my plan to inquire how. If my medical 
                        insurance does pay, you will refund any payments I made to you, less co-pays 
                        or deductibles.
                      </p>
                      <p class="dx-checkbox-text" *ngIf="telehealthOptions[getKey(option['controls'])].id === 'option2'">Option 2.
                        I <b>don’t want</b> the Telehealth services listed above.
                        I understand with this choice I am <b>not</b> responsible for payment,
                        and <b>I cannot appeal to see if my medical insurance would pay</b>.
                      </p>
                    </td>
                    <td>&nbsp;</td>
                  </tr> 
                  <tr>
                    <td>
                      <span *ngIf="onlyOneOptionAlert" class="red">
                        Date of Service should not be in the past
                      </span>
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  </tbody>           
            </table>
            <div class="label">Additional Information:</div>
            <div class="content-list">
                Based on the information we currently have, we are providing this notice. It is not 
                an official decision until the claim is submitted to your medical insurance plan.
            </div>
            <div>
                Signing below means that you have received and understand this notice. A copy of this notice can be made available at any time. 
            </div>
            
          </div>
          <div class="dx-field shorter">            
            <label for="patientResponsible" class="label">Patient/Legal Responsible Party</label>
            <dx-text-box [inputAttr]="{ id: 'patientResponsible' }" formControlName="txtSignature" [isValid]="anncForm?.controls['txtSignature'].valid || anncForm?.controls['txtSignature'].pristine"
              [validationError]="{ message: 'Patient/Legal Responsible Party is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>
          </div>
          <div class="dx-field shorter">
            <label for="txtPatientRelation" class="label">Relation to Patient, if signed by anyone other than the patient</label>
            <dx-text-box [inputAttr]="{ id: 'txtPatientRelation' }" formControlName="txtPatientRelation" (change)="markAsDirtyForm()">
            </dx-text-box>
          </div>
          <div class="dx-field shorter">
            <label for="dpDateSigned" class="label">Date</label>
            <dx-date-box [inputAttr]="{ id: 'dpDateSigned' }" formControlName="dpDateSigned" 
                type="date" [max]="dateNow" placeholder="mm/dd/yyyy" 
                invalidDateMessage="Must be in mm/dd/yyyy format"
                dateOutOfRangeMessage="Date is not valid" 
                (onFocusOut)="signedDateDateBoxValueChanged()" 
                [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" 
                [isValid]="anncForm?.controls['dpDateSigned'].valid || anncForm?.controls['dpDateSigned'].pristine"
              [validationError]="{ message: 'Date is required'}" style="width: 100%">
            </dx-date-box>           
          </div>
          
        </div>
      </form>
      <div class="buttons">
        <dx-button text="Cancel" (onClick)="close()"></dx-button>
        <dx-button icon="fa fa-check-square-o" class="dx-button-default" text="Send" (onClick)="onSubmitClick()"></dx-button>
      </div>
    </div>
</app-card>
<router-outlet></router-outlet>
