import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER, Observable, interval, BehaviorSubject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { BaseService } from './base.service';
import { AlertMessage, ContactUsMailMessage, FacilityInfo, KeyValuePair, Link } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { UserProfileService } from './user-profile.service';
import { Router } from '@angular/router';
import { ContactUsQuestionsResponse, FeedbackFormRequest } from './models';

@Injectable()
export class SupportService extends BaseService {

	private alertMessagesCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	private badgesCount: BehaviorSubject<any> = new BehaviorSubject<any>({});

  	constructor(router: Router, httpClient: HttpClient, private profileSvc: UserProfileService) {
		super(router, httpClient);
	}

	getExternalLinks(): Observable<Link[]> {
		return this.get({
			endpoint: ServiceEndpoint.SUPPORT_GETEXTERNALLINKS,
			cacheInvalidator: NEVER
		});
	}

	getUserGuideAsPDF(): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.SUPPORT_GETUSERGUIDE
		});
	}
	
	getHipaaNoticeAsPDF(): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.SUPPORT_GETHIPAANOTICE
		});
	}

	getComplianceCertificateAsPDF(): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.SUPPORT_GETCOMPLIANCECERTIFICATE
		});
	}

	getPrimaryFacilityInfo(): Observable<FacilityInfo> {
		return this.profileSvc.getUserProfile().pipe(
			switchMap(profile => {
				return this.get({
					endpoint: ServiceEndpoint.SUPPORT_GETFACILITYINFO,
					params: { facility: profile.primaryFacility },
					cacheInvalidator: interval(50000),
					cacheKey: profile.primaryFacility
				});
			})
		);
	}

	getAllFacilitiesInfoForTreamentFacilitySelect() {
		return this.get({
			endpoint: ServiceEndpoint.SUPPORT_GETFACILITYINFOALL,
			cacheInvalidator: NEVER
		});
	}

	getFeedbackAreasofConcern(): Observable<KeyValuePair[]> {
		return this.get({
			endpoint: ServiceEndpoint.SUPPORT_GETFEEDBACKAREASOFCONCERN,
			cacheInvalidator: NEVER
		});
	}

	sendContactUsEmail(message: ContactUsMailMessage) {
		return this.post({
			endpoint: ServiceEndpoint.SUPPORT_SENDFEEDBACK,
			body: message
		});
	}

	getAlertMessages(): Observable<Array<AlertMessage>> {
		return this.get({
			endpoint: ServiceEndpoint.SUPPORT_GETACTIVESYSTEMALERTMESSAGES
		}).pipe(tap((messages: Array<AlertMessage>) => {
			this.alertMessagesCount.next(messages ? messages.length : 0);
		}));
	}

	getAlertMessagesCount(): Observable<number> {
		return this.alertMessagesCount.asObservable();
	}

	updateBadgesCount() {
		this.get({
			endpoint: ServiceEndpoint.SUPPORT_GETBADGESCOUNT
		}).subscribe((badgesCount: any) => {
			this.badgesCount.next(badgesCount || {});
		});
	}

	getContactUsQuestions(): Observable<ContactUsQuestionsResponse> {
		return this.get({
			endpoint: ServiceEndpoint.SUPPORT_GETCONTACTUSQUESTIONS
		});
	}

	sendFeedbackFormData(request: FeedbackFormRequest) {
		const formData = new FormData();
		Object.keys(request).forEach(k => {
			formData.append(k, request[k]);
		});
		return this.post({
			endpoint: ServiceEndpoint.SUPPORT_SEND_FEEDBACK_FORM_DATA,
			body: formData,
		});
	}

	getBadgesCount(): Observable<any> {
		this.updateBadgesCount();
		return this.badgesCount.asObservable();
	}

}
