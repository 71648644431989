import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BaseComponent } from '../component/base.component';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent extends BaseComponent implements OnInit {

  copyrightYear = new Date().getFullYear();
  version = environment.version;

  constructor(){
    super();
  }
  
  ngOnInit() {
  }
}
