import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ButtonModule } from "../button/button.module";
import { AlertV2Component } from "./alertv2.component";

@NgModule({
    imports: [
        CommonModule,
        ButtonModule
    ],
    declarations: [
        AlertV2Component,
    ],
    exports: [AlertV2Component]
})
export class AlertV2Module { }