<dx-popup class="home" #popup [visible]="visible$ | async" title="Select Patient" height="300" width="400" (onHidden)="navigateToParent()">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'View Records', onClick: changeProxy }">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view>
      <p *ngIf="activeProxy; else noProxy">You are currently viewing records for
        <strong>{{activeProxy?.firstName}} {{activeProxy?.lastName}}.</strong>
      </p>
      <div class="dx-fieldset" *ngIf="isAdminImpersonationOrHasProxies()">
        <div class="dx-field">
          <div class="dx-field-label">View records for</div>
          <div class="dx-field-value" *ngIf="!adminImpersonation; else AdminImpersonation">
            <dx-select-box  [dataSource]="proxies" valueExpr="toCtcaUniqueId" [(value)]="selectedProxy" displayExpr="fullName"></dx-select-box>
          </div>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
<ng-template #noProxy>
  <p>You are currently viewing your own records.</p>
</ng-template>
<ng-template #AdminImpersonation>
  <div class="dx-field-value">
    <dx-text-box  placeholder="Type Unique CTCA ID" [isValid]="isIntegerProxy()" [(value)]="selectedProxy"></dx-text-box>
  </div>
</ng-template>
