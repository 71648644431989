import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { ClaimsRoutingModule } from './claims-routing.module';
import { ClaimsComponent } from './claims.component';
import {
  DxButtonModule, DxCheckBoxModule, DxContextMenuModule, DxDataGridModule, DxDateBoxModule, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTabsModule, DxTagBoxModule, DxTextBoxModule, DxValidatorModule
} from 'devextreme-angular';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    ClaimsRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTabsModule,
    DxDateBoxModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxTagBoxModule
  ],
  declarations: [
    ClaimsComponent
  ]
})
export class ClaimsModule { }
