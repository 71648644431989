<app-card label="Release of Information (ROI)">
  <div class="roi">
    <p>
      If you require a Release of your Medical Information, there are now two ways to speed up the process:
    </p>
    <div class="roi-list">
      <ol start="1" style="padding-inline-start: 1.4em;">
        <li>
          You can download and complete the following document prior to your upcoming visit and deliver it to the ROI office at your
          treatment facility.
          <p>
            <dx-button text="Download ROI Authorization Form" icon="fa fa-download" (onClick)="downloadROIAuthorizationFormAsPDF()"></dx-button>
          </p>
        </li>
        <li>
          You can also complete the following form online and submit it directly.
          <p>
            <dx-button text="Submit ROI Authorization Online" icon="fa fa fa-check-square-o" [routerLink]="['/app/release-of-information']"></dx-button>
          </p>
        </li>
      </ol>
    </div>
    <p>
      For further questions regarding the document or form, please contact the Health Information Management (ROI) team at your
      treatment facility.
    </p>
  </div>
</app-card>
<router-outlet></router-outlet>
