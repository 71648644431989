import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export function datetimeOrEmptyValidator(control: AbstractControl): ValidationErrors | null {
    
    if((<any>control).nativeElement) {
        
        let value = "";
        let type = "";

        try {

            value = (<any>control).nativeElement.children.item(0).children.item(1).children.item(0).value;
            type = (<any>control).nativeElement.children.item(0).children.item(1).children.item(0).type;

        } catch(error) {

            console.error('dxDateBox' + error.message);

            return {
                dateOrEmptyValidator:false
            }
            
        }

        if(value && type) {
            
            if( (type === 'datetime-local' || type === 'datetime')  && (!moment(value, "YYYY[-]MM[-]DD[T]HH[:]mm", false).isValid() || moment(value, "YYYY[-]MM[-]DD[T]HH[:]mm", false).year() <= 0))
            {
                return {
                    dateOrEmptyValidator:false
                }
            }
            else if(type === 'text' && (!moment(value, "M[/]D[/]YYYY[,] h[:]mm A", true).isValid() || moment(value, "M[/]D[/]YYYY[,] h[:]mm A", true).year() <= 0)) {

                return {
                    dateOrEmptyValidator:false
                }

            } else {

                return null;

            }
        } else {

            return null;

        }

    } else {

        return null;
    }

}