import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { DxTextBoxModule } from "devextreme-angular";
import { PhoneFieldComponent } from "./phone-field.component";

@NgModule({
    imports: [
        DxTextBoxModule,
        ReactiveFormsModule
    ],
    declarations: [
        PhoneFieldComponent,
    ],
    exports: [PhoneFieldComponent]
})
export class PhoneFieldModule { }