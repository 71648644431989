import { Phone } from '../../shared/models';
import { ChangeType } from '../../shared/enums/change-type.enum';
import { PatientContactInfo, PhoneNumber } from '../../../core/services/models';
import { AbstractControl } from '@angular/forms';

export function getPhoneNumberChanges(phones: Array<Phone>, updatedPhones: Array<any>) {
    const phoneNumbers = new Array<PhoneNumber>();

    // Handle phone numbers that have been added
    if (Array.isArray(updatedPhones)) {
        updatedPhones.forEach((updatedPhone: any) => {
            phoneNumbers.push({
                systemId: updatedPhone.phoneId,
                phone: updatedPhone.phoneNumber,
                phoneType: updatedPhone.phoneType,
                changeType: !phones || !phones.find((phone: Phone) => phone.phoneId === updatedPhone.phoneId) ? ChangeType.Add : null
            });
        });
    }

    // Handle phone numbers that have been deleted
    if (phones) {
        phones.forEach((phone: Phone) => {
            if (!Array.isArray(updatedPhones) || !updatedPhones.find((updatedPhone: any) => phone.phoneId === updatedPhone.phoneId)) {
                phoneNumbers.push({
                    systemId: phone.phoneId,
                    phone: phone.number,
                    phoneType: phone.type,
                    changeType: ChangeType.Delete
                });
            }
        });
    }

    // Note that we don't have to worry about marking phones as "Edit", the service will do that

    return phoneNumbers;
}

export function getPhoneDigits(phone: string) {
    return phone.replace(/[^0-9]/g, '');
}

export function getAutofillPhoneNumber(patientContactInfo: PatientContactInfo) {
    if (patientContactInfo.phoneNumbers) {
        const phoneTypes = ['HOME', 'CELLULAR', 'ALT', 'BUSINESS', 'INTERNATIONAL', 'OFFICE', 'TEMPORARY', 'OTHER', 'WORK'];
        let autoFillPhone: PhoneNumber;
        for (let i = 0; i < phoneTypes.length; i++) {
            const phoneType = phoneTypes[i];
            autoFillPhone = patientContactInfo.phoneNumbers.find(p => p.phoneType.toUpperCase() === phoneType);
            if (autoFillPhone) {
                break;
            }
        }
        if (autoFillPhone && autoFillPhone.phone) {
            const phoneString = autoFillPhone.phone.replace(/[^0-9]/g, '');
            return maskPhoneNumber(phoneString);
        }
    }
    return undefined;
}

export function maskPhoneNumber(input: string, isBackspace?: boolean) {
    const trimmed = input.trim();
    let numbers = getPhoneDigits(trimmed);
    if ((trimmed.length > 0 && trimmed[0] === '+') ||
        (numbers.length > 0 && numbers[0] === '0')
    ) {
        if (trimmed.length > 15) {
            return trimmed.substring(0, 15);
        } else {
            return trimmed;
        }
    }
    let mask = '';
    if (numbers.length > 0) {
        mask = '(###';
    }
    if (numbers.length > (isBackspace ? 3 : 2)) {
        mask += ') ###';
    }
    if (numbers.length > (isBackspace ? 6 : 5)) {
        mask += '-####';
    }
    while (numbers.length > 0) {
        const number = numbers[0];
        numbers = numbers.slice(1, numbers.length);
        mask = mask.replace('#', number);
    }
    mask = mask.split('#').join('');
    return mask;
}

export function phoneNumberValidator(control: AbstractControl) {
    const phoneNumber = control && control.value && control.value || '';
    if (isValidPhoneNumber(phoneNumber)) {
        return null;
    } else {
        return { phoneNumber: { value: phoneNumber } };
    }
}

export function isValidPhoneNumber(phoneNumber: string) {
    const trimmed = phoneNumber && phoneNumber.trim() || '';
    const digits = getPhoneDigits(trimmed);
    return ((digits.length === 10 || (digits.length > 7 && digits[0] === '0') || (digits.length > 5 && trimmed[0] === '+')) && digits.length <= 25) || trimmed.length === 0;
}