import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { AppointmentsService, ContactInfoService, ContactUsStateService, FileDownloadService, LoadingService } from 'app/core/services';
import { PatientContactInfo } from 'app/core/services/models';
import { emailOrEmptyValidator } from 'app/shared/validators/email-or-empty.validator';


@Component({
  selector: 'app-ask-question-non-medical-schedule-delivery',
  templateUrl: './ask-question-non-medical-schedule-delivery.component.html',
  styleUrls: ['./ask-question-non-medical-schedule-delivery.component.scss']
})
export class AskQuestionNonMedicalScheduleDeliveryComponent extends BaseComponent implements OnInit {

  addressChecked = false;
  emailChecked = false;
  emailValue: string;

  address1Control = new FormControl('', Validators.compose([Validators.required]));
  address2Control = new FormControl('', Validators.compose([]));
  addressCityControl = new FormControl('', Validators.compose([Validators.required]));
  addressStateControl = new FormControl('', Validators.compose([Validators.required]));
  addressZipControl = new FormControl('', Validators.compose([Validators.required]));
  addressCountryControl = new FormControl('', Validators.compose([Validators.required]));

  emailControl = new FormControl('', Validators.compose([Validators.required, emailOrEmptyValidator]));

  addressText: string;
  address1Value: string;
  address2Value: string;
  addressCityValue: string;
  addressStateValue: string;
  addressZipValue: string;
  addressCountryValue: string;

  editingAddress = false;
  editingEmail = false;
  closeClicked = false;

  editing = false;
  showDownloadSuccess = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactInfoService: ContactInfoService,
    private loadingService: LoadingService,
    private contactUsState: ContactUsStateService,
    private apptSvc: AppointmentsService,
    private downloadService: FileDownloadService,
  ) {
    super();
  }


  ngOnInit(): void {


    const delivery = this.contactUsState.getState('delivery');
    if (delivery?.sendBy === 'mail') {
      this.addressChecked = true;
      this.address1Value = delivery.address1;
      this.address2Value = delivery.address2;
      this.addressCityValue = delivery.city;
      this.addressStateValue = delivery.state;
      this.addressZipValue = delivery.zip;
      this.addressCountryValue = delivery.country;
      this.addressText = delivery.value;
    } else if (delivery?.sendBy === 'email') {
      this.emailChecked = true;
      this.emailValue = delivery.value;
    }

    this.route.queryParams.subscribe(params => {
      this.editing = params.edit === 'true'
    });

    if (!delivery) {
      this.loadingService.loading = true;
      this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
        this.loadingService.loading = false;
        if (!this.address1Value) {
          this.address1Value = patientContactInfo.address1;
          this.address2Value = patientContactInfo.address2;
          this.addressCityValue = patientContactInfo.city;
          this.addressStateValue = patientContactInfo.state;
          this.addressZipValue = patientContactInfo.postalCode;
          this.addressCountryValue = patientContactInfo.country;
          this.updateAddressText();
        }
        if (!this.emailValue) {
          this.emailValue = patientContactInfo.emailAddress || '';
        }
      });
    }
  }

  editEmail = () => {
    this.editingAddress = false;
    this.editingEmail = true;
    this.emailControl.setValue(this.emailValue);
  }

  editAddress = () => {
    this.editingEmail = false;
    this.editingAddress = true;
    this.address1Control.setValue(this.address1Value);
    this.address2Control.setValue(this.address2Value);
    this.addressCityControl.setValue(this.addressCityValue);
    this.addressStateControl.setValue(this.addressStateValue);
    this.addressZipControl.setValue(this.addressZipValue);
    this.addressCountryControl.setValue(this.addressCountryValue);
  }

  closeEditing = () => {
    this.editingAddress = false;
    this.editingEmail = false;
    this.closeClicked = true;
    if (!this.address1Value && this.addressChecked || !this.emailValue && this.emailChecked) {
      this.addressChecked = false;
      this.emailChecked = false;
    }
    //return true;
  }

  onToggleEmailCheckbox = () => {
    if (this.editingEmail === false && this.closeClicked === false)
      this.emailChecked = !this.emailChecked;
    if (this.emailChecked) {
      this.addressChecked = false;
      if (!this.emailValue) {
        this.editEmail();
      }
    }
    if (this.closeClicked === true)
      this.closeClicked = false;
    // this.markAsDirtyForm();
  }

  onToggleAddressCheckbox = () => {
    if (this.editingAddress === false && this.closeClicked === false)
      this.addressChecked = !this.addressChecked;
    if (this.addressChecked) {
      this.emailChecked = false;
      if (!this.address1Value) {
        this.editAddress();
      }
    }
    if (this.closeClicked === true)
      this.closeClicked = false;
    // this.markAsDirtyForm();
  }

  saveEmail = () => {
    this.emailValue = this.emailControl.value;
    this.closeEditing();
  }

  saveAddress = () => {
    this.address1Value = this.address1Control.value;
    this.address2Value = this.address2Control.value;
    this.addressCityValue = this.addressCityControl.value;
    this.addressStateValue = this.addressStateControl.value;
    this.addressZipValue = this.addressZipControl.value;
    this.addressCountryValue = this.addressCountryControl.value;
    this.updateAddressText();
    this.closeEditing();
  }

  updateAddressText = () => {
    this.addressText = this.address1Value;
    if (this.address2Value) {
      this.addressText += ', ' + this.address2Value;
    }
    if (this.addressCityValue) {
      this.addressText += ', ' + this.addressCityValue;
    }
    if (this.addressStateValue) {
      this.addressText += ', ' + this.addressStateValue;
    }
    if (this.addressZipValue) {
      this.addressText += ', ' + this.addressZipValue;
    }
    if (this.addressCountryValue) {
      this.addressText += ', ' + this.addressCountryValue;
    }

  }

  onDownloadPdf = () => {
    const range = this.contactUsState.getState('range');
    const startDate = new Date(range.startDate);
    const endDate = new Date(range.endDate);

    this.loadingService.loading = true;
    this.apptSvc.downloadSchedule(startDate, endDate).subscribe((doc: Blob) => {
      this.loadingService.loading = false;
      this.downloadService.downloadPdf(doc, 'Appointment_Schedule');
      this.showDownloadSuccess = true;
    }, error => {
      this.loadingService.loading = false;
      throw (error);
    });
  }

  onNextClick = (e: any) => {
    const delivery: any = {

    };
    if (this.emailChecked) {
      delivery.sendBy = 'email';
      delivery.value = this.emailValue;
    } else if (this.addressChecked) {
      delivery.sendBy = 'mail';
      delivery.value = this.addressText;
      delivery.address1 = this.address1Value;
      delivery.address2 = this.address2Value;
      delivery.city = this.addressCityValue;
      delivery.state = this.addressStateValue;
      delivery.zip = this.addressZipValue;
      delivery.country = this.addressCountryValue;
    }

    this.contactUsState.setState({ delivery });
    this.router.navigate(['non-medical', 'review'], { relativeTo: this.route.parent });
  }

  onCloseSuccess = () => {
    this.router.navigateByUrl('/app/ask-question');
  }

  close = () => {
    window.history.back();
  }

}
