import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LabResultsListComponent } from './lab-results-list/lab-results-list.component';
import { LabResultsDetailComponent } from './lab-results-detail/lab-results-detail.component';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { ResolveLabResultsGuard, ResolveLabSetGuard } from '../core/guards';
import { ConsolidatedLabReportComponent } from './consolidated-lab-report/consolidated-lab-report.component';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: '',
				component: LabResultsListComponent,
				resolve: { labResults: ResolveLabResultsGuard },
				data: { permissionLevel: PermissionLevel.VIEW_LAB_RESULTS },
				children: [
					{
						path: ':datePerformed/:performedBy',
						component: LabResultsDetailComponent,
						resolve: { labResults: ResolveLabSetGuard },
						data: { permissionLevel: PermissionLevel.VIEW_LAB_RESULTS }
					}
				]
			},
			{
				path: 'consolidated',
				component: ConsolidatedLabReportComponent,
				resolve: { labResults: ResolveLabResultsGuard },
				data: { permissionLevel: PermissionLevel.VIEW_LAB_RESULTS }
			}			
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LabResultsRoutingModule {}
