import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { CCDADocument, CCDADocumentDetailView, CCDADocumentList } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';

@Injectable()
export class DocumentExchangeService extends BaseService {
	getCCDADocumentList(startDate:Date=null,endDate:Date=null): Observable<CCDADocumentList[]> {
		const startDateStr: string = startDate ? startDate.toDateString() : null;
		const endDateStr: string = endDate ? endDate.toDateString() : null;
		return this.get({
			endpoint: ServiceEndpoint.DOCUMENTEXCHANGE_GETCCDADOCUMENTLIST,			
			params: {
				startDate: startDateStr,
				endDate: endDateStr
			}
		});
	}

	getCCDADocumentDetail(document: CCDADocument): Observable<CCDADocumentDetailView> {
		return this.post({
			endpoint: ServiceEndpoint.DOCUMENTEXCHANGE_GETCCDADOCUMENTDETAIL,
			body: document
		});
	}

	getLatestCCDADocument(): Observable<CCDADocumentDetailView> {
		return this.get({
			endpoint: ServiceEndpoint.DOCUMENTEXCHANGE_GETLATESTCCDADOCUMENT
		});
	}

	downloadCCDADocument(document: CCDADocument): Observable<Blob> {
		return this.postBlob({
			endpoint: ServiceEndpoint.DOCUMENTEXCHANGE_DOWNLOADCCDADOCUMENT,
			body: document
		});
	}

	/*transmitCCDADocumentDirectEmail(document: CCDADocument): Observable<any> {
		return this.post({
			endpoint: ServiceEndpoint.DOCUMENTEXCHANGE_TRANSMITCCDADOCUMENTDIRECTEMAIL,
			body: document
		});
	}*/

	transmitCCDADocumentRegularEmail(document: CCDADocument, barracuda:boolean): Observable<any> {
		return this.post({
			endpoint: ServiceEndpoint.DOCUMENTEXCHANGE_TRANSMITCCDADOCUMENTREGULAREMAIL,
			body: document,
			params:{barracuda:barracuda}
		});
	}
}
