import { Injectable } from '@angular/core';
import { Observable, NEVER } from 'rxjs';

import { BaseService } from './base.service';
import { FinanceDocumentInfo, ROIForm, ROIFormResponse } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { TelehealthAnncFormModel } from './models';

@Injectable()
export class FormLibraryService extends BaseService {
	getRoiForm(): Observable<ROIForm> {
		return this.get({
			endpoint: ServiceEndpoint.DOWNLOADS_GETROIFORM,
			params: { listOptions: true }
		});
	}

	getRoiFormAsPDF(): Observable<Blob> {
		return this.getBlob({ endpoint: ServiceEndpoint.DOWNLOADS_GETROIPDF });
	}

	getFinanceDocumentInfo(): Observable<FinanceDocumentInfo> {
		return this.get({
			endpoint: ServiceEndpoint.DOWNLOADS_GETFINANCEDOCUMENTINFO,
			cacheInvalidator: NEVER
		});
	}

	downloadFinancialDocumentAsPDF(download): Observable<Blob> {
		return this.getBlob({ endpoint: download.url });
	}

	submitROIForm(roiFormResponse: ROIFormResponse) {
		return this.post({
			endpoint: ServiceEndpoint.DOWNLOADS_SUBMITROIFORM,
			body: roiFormResponse
		});
	}

	submitAnncForm(anncFormModel: TelehealthAnncFormModel){
		return this.post({
			endpoint: ServiceEndpoint.DOWNLOADS_SUBMITANNCFORM,
			body: anncFormModel
		});
	}

	getPatientMrn(){
		return this.get({
			endpoint: ServiceEndpoint.DOWNLOADS_GETMRN,
		});
	}

	getAnncFormExists(): Observable<boolean> {
		return this.get({
			endpoint: ServiceEndpoint.DOWNLOADS_ANNCFORMEXISTS
		});
	}

	getAnncFormAsPDF(): Observable<Blob> {
		return this.getBlob({ endpoint: ServiceEndpoint.DOWNLOADS_DOWNLOADANNCFORM });
	}
}
