import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {  DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { CCDADocumentList } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { FormControlName, FormGroup, FormControl, Validators } from '@angular/forms';
import { dateOrEmptyValidator } from '../../shared/validators/date-or-empty.validator';
import * as formHelper from '../../shared/helpers/form.helper';
import { LoadingService, DocumentExchangeService } from '../../core/services';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-clinical-summaries',
  templateUrl: './clinical-summaries.component.html',
  styleUrls: ['./clinical-summaries.component.scss']
})
export class ClinicalSummariesComponent extends BaseComponent implements  OnInit {
  startTime = 0;
  constructor(private router: Router, private route: ActivatedRoute, private loadingService:LoadingService,
    private documentExchangeService:DocumentExchangeService) { 
    super();
    this.startTime = parseInt(localStorage.getItem('startTimeOfClinicalSummaries'), 10) || Date.now();
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
  
    FormControlName.prototype.ngOnChanges = function () {
      let result;
      if (arguments) {
        result = originFormControlNameNgOnChanges.apply(this, arguments);
      }
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };
  }

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;

  documents: CCDADocumentList[];
  filterForm: FormGroup;

  
  ngOnInit() {
    this.documents = this.route.snapshot.data.documents;

    this.filterForm = new FormGroup({     
      startDate: new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator])),
      endDate: new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator])),       
    });

    formHelper.markControlAndChildControlsAsDirty(this.filterForm); 
    const endTime = Date.now();
    const elapsedTime = (endTime-this.startTime)/1000;
    if(window.location.pathname.split('/').length<=4 && window.location.pathname.includes('/medical-documents/clinical-summaries'))
    trackPageLoadingTime(window.location.pathname,elapsedTime);
    localStorage.removeItem('startTimeOfClinicalSummaries');
  }

  wrongOrder():boolean {
    
    const startDate = new Date(this.filterForm.get('startDate').value);
    const endDate = new Date(this.filterForm.get('endDate').value);
    
    return moment(startDate).isAfter(moment(endDate));

  }

  validateComponent(field:string) {
    this.filterForm.get(field).updateValueAndValidity();
  }

  filterCCDA() {
    if(this.filterForm.valid && !this.wrongOrder()) {
      this.loadingService.loading = true;
      const startDate: Date = new Date(this.filterForm.get('startDate').value);
      const endDate: Date = new Date(this.filterForm.get('endDate').value);
      this.documentExchangeService.getCCDADocumentList(startDate,endDate).subscribe((docs:Array<CCDADocumentList>)=>{
        this.documents = docs;
        this.loadingService.loading = false;
      },(error) => {
        this.loadingService.loading = false;
        throw(error);
      });
    }
  }

  
  navigate(action:string) {
    
    this.dataGrid.instance.getSelectedRowsData().then((rowData:Array<CCDADocumentList>) => {
     
      let ids = "";

      for (let i = 0; i < rowData.length; i++) {
          ids = ids + rowData[i].uniqueId;
          if(i + 1 < rowData.length) ids = ids + "-";
      }

      if(ids.length > 0 ) {
        this.router.navigate([ids,action], { relativeTo: this.route });
      }
      
    });
  }

  onDocumentSelected = (data) => {
    this.router.navigate([data.uniqueId], { relativeTo: this.route });
  };
}
