import { Component, OnInit } from '@angular/core';
import { AuthenticationService, LoadingService, UserProfileService } from '../../core/services';
import { Proxy, UserProfile } from '../../core/services/models';
import { StringHelper } from '../../shared/helpers/string.helper';
import { trackEvent } from '../../shared/helpers/tracking.helper';
import { alert } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-select-patient-card',
  templateUrl: './select-patient-card.component.html',
  styleUrls: ['./select-patient-card.component.scss']
})
export class SelectPatientCardComponent implements OnInit {

  userProfile: UserProfile;
  proxies: Array<Proxy>;
  activeProxy: Proxy;
  hasActiveProxy: boolean;
  proxyRows: Array<Array<Proxy>> = [];
  constructor(private profileSvc: UserProfileService, private loadingService: LoadingService, private authSvc: AuthenticationService) { }

  ngOnInit() {
    this.profileSvc.getUserProfileHardCache().subscribe((profile: UserProfile) => {
      this.proxyRows = [];
      this.userProfile = profile;
      this.proxies = profile.proxies.filter(proxy => proxy.relationshipType !== "Self");
      if (this.proxies && this.proxies.length > 0) {
        this.activeProxy =
          this.proxies.filter(
            proxy => proxy.isImpersonating && proxy.relationshipType !== 'Self'
          )[0] || null;
        this.hasActiveProxy = (this.activeProxy != null);
        for (let i = 0; i < this.proxies.length; i++) {
          if (i % 2 === 0) {
            this.proxyRows.push([])
          }
          this.proxyRows[this.proxyRows.length - 1].push(this.proxies[i]);
        }
      } else {
        this.hasActiveProxy = false;
      }
    });
  }

  hasProxies(): boolean {
    if (this.proxies && this.proxies.length > 0) {
      const proxies = this.proxies.filter(proxy => proxy.relationshipType !== "Self");
      return (proxies.length > 0 || this.userProfile.isSuperUser);
    } else {
      return false;
    }
    return false;
  }

  changeProxy = (proxyId: string) => {
    trackEvent('Select patient card - View Patient Portal clicked', 'Click');
    if (StringHelper.isInteger(proxyId)) {
      this.loadingService.loading = true;
      this.profileSvc.canPatientBeImpersonatedBy(proxyId).subscribe((impersonationAllowed: boolean) => {
        if (impersonationAllowed) {
          this.authSvc.authenticateProxy(proxyId);
        } else {
          this.loadingService.loading = false;
          alert("Impersonation is not allowed for requested user", "Information");
        }
      });
    }
  };
}
