import { NgModule } from '@angular/core';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxDateBoxModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxSchedulerModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxToolbarModule,
  DxValidatorModule,
} from 'devextreme-angular';

import { SharedModule } from '../shared/shared.module';
import { AppointmentDetailModalComponent } from './appointment-detail-modal/appointment-detail-modal.component';
import { AppointmentsListComponent } from './appointments-list/appointments-list.component';
import { AppointmentsRoutingModule } from './appointments-routing.module';
import { DownloadScheduleComponent } from './download-schedule/download-schedule.component';
import { AppointmentLateReschedulingComponent } from './appointment-late-rescheduling/appointment-late-rescheduling.component';


@NgModule({
  imports: [
    SharedModule,
    DxSchedulerModule,
    DxContextMenuModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxButtonModule,
    DxDateBoxModule,
    DxValidatorModule,
    AppointmentsRoutingModule,
    DxToolbarModule,
    DxLoadPanelModule
  ],
  declarations: [
    AppointmentsListComponent,
    AppointmentDetailModalComponent,
    DownloadScheduleComponent,
    AppointmentLateReschedulingComponent
  ],
  exports: [AppointmentDetailModalComponent]
})
export class AppointmentsModule { }
