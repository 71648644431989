export const environment = {
	version: '3.5-dev',
	cacheLogging: false,
	production: false,
	portalServiceBaseUrl: 'https://v3devservice.myctca.com/api/v1/',
	billPayUrl: 'https://www.patientportal.me/CTCA/Patient/login.html ',
	ctcaIdentityUrl: 'https://dev.accounts.myctca.com/user/detail',
	securityTab: 'userdetails-securityprivacy',
	profileTab: 'userdetails-details',
	medicalProfileTab: 'userdetails-medicalprofile',
	labResultsReviewWarning: "24",
	appointmentActionDisclaimer: "24",
	outageMessage: " ",
	gaId: "UA-23384102-2",
	smartlookKey: "4c3d051fbce8083f70ab6b83dad0e00e30c13f83",
	launchDarklyKey: "612f7f263b6ee125f8a37212",
	chatBotUrl: "https://ctca-hc-covidassessmentbot-dev.azurewebsites.net/",
	sitSurveyUrl: 'https://dev.portal.myctca.com/myProsk/SITInterface/BypassLogin.aspx',
	datadogClientToken: 'pub9b57b7280265fa6e9d1a918bb914cebe',
	environment: 'dev',
	oidc: {
		authority: 'https://dev.accounts.myctca.com',
		client_id: 'CTCA.Portal.UI.Web',
		redirect_uri: 'https://dev.portal.myctca.com/auth',
		post_logout_redirect_uri: 'https://dev.portal.myctca.com/',
		silent_redirect_uri: 'https://dev.portal.myctca.com/silent-renew',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
		automaticSilentRenew: false,
		monitorSession: true,
		loadUserInfo: false,
		revokeAccessTokenOnSignout:
			false,
		authenticationLoggingEnabled:
			true
	},
	auth0: {
		domain: 'ctca-dev.us.auth0.com',
		audience: 'http://localidentityservice.ctca.local/api',
		client_id: '2bvvjmHgUmnr4zRGlensoIVx1bp7FOFM',
		redirect_uri: 'https://dev.portal.myctca.com/callback',
		post_logout_redirect_uri: 'https://dev.portal.myctca.com/',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
	}
};
