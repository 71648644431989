<app-card label="Financial">
  <div class="financial">
    <p>
      Please download and complete any documents you may need prior to your upcoming visit to {{financeDocumentInfo?.facilityName}}.
      Due to sensitive materials, we prefer that you mail or fax your application and supporting documents, if applicable.
      If you prefer email, please encrypt your message and attachments. Please mail, fax, or email to:
    </p>
    <div class="facility-address">
      <div>{{ financeDocumentInfo?.organizationName }}</div>
      <div *ngIf="financeDocumentInfo?.attention">Attn: {{ financeDocumentInfo?.attention }}</div>
      <div>{{ financeDocumentInfo?.address}}</div>
      <div *ngIf="financeDocumentInfo?.address2">{{ financeDocumentInfo?.address2}}</div>
      <div>
        <span>{{ financeDocumentInfo?.city }}, </span>
        <span>{{ financeDocumentInfo?.state }} </span>
        <span>{{ financeDocumentInfo?.zipCode }}</span>
      </div>
      <!-- <div *ngIf="financeDocumentInfo?.address?.country">{{ financeDocumentInfo?.country }}</div> -->
    </div>
    <div *ngIf="financeDocumentInfo?.fax">FAX: {{ financeDocumentInfo?.fax }}</div>
    <div *ngIf="financeDocumentInfo?.email">EMAIL: {{ financeDocumentInfo?.email }}</div>
    <p>
      For further questions regarding these documents and/or Financial Assistance, please call the {{ financeDocumentInfo?.attention
      }} team at {{ financeDocumentInfo?.phone }}.
    </p>
    <div class="downloads">
      Documents available for download (PDF format):
    </div>
    <div class="downloads-list">
      <ul *ngFor="let item of financeDocumentInfo?.downloads;">
        <li>
          <a *ngIf="item?.isDownload" class="dead-link" (click)="onDownLoadDocumentLinkClick(item)">{{item.title}}</a>
          <span *ngIf="!(item?.isDownload)">{{item.title}}</span>
        </li>
      </ul>
    </div>
  </div>
</app-card>
