import { NgModule } from '@angular/core';
import { SharedModule } from '../../app/shared/shared.module';
import { ResolveUserNotificationsGuard } from '../core/guards';
import { UserNotificationsService } from '../core/services';

import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxSelectBoxModule,
  DxDateBoxModule  
} from 'devextreme-angular';

import { AccountRoutingModule } from './account-routing.module';
import { UserLogsComponent } from './user-logs/user-logs.component';
import { PreferencesComponent } from './preferences/preferences.component';
import { NotificationsComponent } from './notifications/notifications.component';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxChartModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    AccountRoutingModule
  ],
  declarations: [    
    UserLogsComponent, PreferencesComponent, NotificationsComponent
  ],
  providers:[
    ResolveUserNotificationsGuard,
    UserNotificationsService    
  ]
})
export class AccountModule {}
