import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { ContactInfoService, ContactUsStateService, LoadingService } from 'app/core/services';
import { ContactUsSubject, PatientContactInfo } from 'app/core/services/models';
import { getAutofillPhoneNumber, phoneNumberValidator } from 'app/home/shared/helpers/phone.helper';
import { emailOrEmptyValidator } from 'app/shared/validators/email-or-empty.validator';


@Component({
  selector: 'app-ask-question-non-medical-contact',
  templateUrl: './ask-question-non-medical-contact.component.html',
  styleUrls: ['../../request-appointmentv2/request-appointmentv2.component.scss', './ask-question-non-medical-contact.component.scss']
})
export class AskQuestionNonMedicalContactComponent extends BaseComponent implements OnInit {

  requestForm: FormGroup;
  editingPhone = false;
  editingEmail = false;
  phoneChecked = false;
  emailChecked = false;
  phoneValue: string;
  emailValue: string;
  closeClicked = false;
  phoneControl = new FormControl('', [Validators.required, phoneNumberValidator]);
  emailControl = new FormControl('', Validators.compose([Validators.required, emailOrEmptyValidator]));
  editing = false;
  title: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactUsState: ContactUsStateService,
    private contactInfoService: ContactInfoService,
    private loadingService: LoadingService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.editing = params.edit === 'true'
    });

    this.requestForm = new FormGroup({
      // preferredDateTime: new FormControl('', Validators.compose([Validators.required, datetimeOrEmptyValidator])),
      // phoneNumber: new FormControl('', [Validators.required, phoneNumberValidator]),
    });

    const state = this.contactUsState.getState();
    const contact = state.contact;
    if (contact) {
      this.emailChecked = contact.emailChecked;
      this.phoneChecked = contact.phoneChecked;
      this.emailValue = contact.emailValue;
      this.phoneValue = contact.phoneValue;
    }

    const subject: ContactUsSubject = state.subject;
    this.title = subject.value;

    if (!this.phoneValue || !this.emailValue) {
      this.loadingService.loading = true;
      this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
        this.loadingService.loading = false;
        if (!this.phoneValue) {
          this.phoneValue = getAutofillPhoneNumber(patientContactInfo) || '';
        }
        if (!this.emailValue) {
          this.emailValue = patientContactInfo.emailAddress || '';
        }
      });
    }
  }

  onTogglePhoneCheckbox = () => {
    if (this.editingPhone === false && this.closeClicked === false) {
      this.phoneChecked = !this.phoneChecked;
    }

    if (this.phoneChecked) {
      this.emailChecked = false;
      if (!this.phoneValue) {
        this.editPhone();
      }
    }
    if (this.closeClicked === true)
      this.closeClicked = false;
  }

  onToggleEmailCheckbox = () => {
    if (this.editingEmail === false && this.closeClicked === false)
      this.emailChecked = !this.emailChecked;
    if (this.emailChecked) {
      this.phoneChecked = false;
      if (!this.emailValue) {
        this.editEmail();
      }
    }
    if (this.closeClicked === true)
      this.closeClicked = false;
  }

  editPhone = () => {
    this.editingPhone = true;
    this.editingEmail = false;
    this.phoneControl.setValue(this.phoneValue);
  }

  editEmail = () => {
    this.editingPhone = false;
    this.editingEmail = true;
    this.emailControl.setValue(this.emailValue);
  }

  closeEditing = () => {
    this.editingPhone = false;
    this.editingEmail = false;
    this.closeClicked = true;
    if (!this.phoneValue && this.phoneChecked || !this.emailValue && this.emailChecked) {
      this.phoneChecked = false;
      this.emailChecked = false;
    }
    //return true;
  }

  savePhone = () => {
    this.phoneValue = this.phoneControl.value;
    this.closeEditing();
  }

  saveEmail = () => {
    this.emailValue = this.emailControl.value;
    this.closeEditing();
  }

  onNextClick = (e: any) => {
    this.contactUsState.setState({
      contact: {
        phoneChecked: this.phoneChecked,
        emailChecked: this.emailChecked,
        phoneValue: this.phoneValue,
        emailValue: this.emailValue
      }
    });
    this.router.navigate(['non-medical', this.editing ? 'review' : 'reason'], { relativeTo: this.route.parent });
  }

  close = () => {
    window.history.back();
  }

}
