import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DocumentWhitelistPayload, UserProfile } from '../../core/services/models';
import { AdminService, LoadingService, UserProfileService, ResizingService } from '../../core/services';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import { alert } from 'devextreme/ui/dialog';
import { DxTextBoxComponent, DxSelectBoxComponent, DxCheckBoxComponent, DxPopupComponent } from 'devextreme-angular';
import { PermissionLevel } from '../../core/permissions/permission-levels.enum';

@Component({
  selector: 'app-documents-whitelist-popup',
  templateUrl: './documents-whitelist-popup.component.html',
  styleUrls: ['./documents-whitelist-popup.component.scss']
})
export class DocumentsWhitelistPopupComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("documentName", { static: false }) view_document: DxTextBoxComponent;
  @ViewChild('documentType', { static: false }) view_type: DxSelectBoxComponent;
  @ViewChild('documentActive', { static: false }) view_active: DxCheckBoxComponent;
  @ViewChild('popup', { static: true }) popup: DxPopupComponent;
  
  public actionButtonText:string;

  public documentId:string;
  public document:DocumentWhitelistPayload;
  public title:string;
  public updating:boolean;

  private documentTypes:Array<{ name:string,id:string }> = new Array<{ name:string,id:string }>();
  private documentForm:FormGroup;


  constructor(private adminService:AdminService,
    router: Router,
    route: ActivatedRoute,
    public loadingService:LoadingService,
    public profileSvc:UserProfileService,
    resizingService: ResizingService) { 
    super(router, route, resizingService);    

  }

  ngOnInit() {
    
    this.documentId = this.route.snapshot.params['id'];
    this.document = this.route.snapshot.data.document;   
    this.updating = this.documentId && this.document ? true : false;

    this.documentForm = new FormGroup({
      name : new FormControl('',[Validators.required]),
      parentDocumentTypeName: new FormControl('',[Validators.required]),
      active: new FormControl(false)        
    });
    
    this.adminService.GetParentDocumentTypes().subscribe((docTypes:Array<string>) => {

      for (let i = 0; i < docTypes.length; i++) {
        const type = docTypes[i];
        const option = { id: type, name: type };
        this.documentTypes.push(option);
      }

      if (this.updating) {
        
        this.documentForm.get('name').setValue(this.document.name);
        this.documentForm.get('parentDocumentTypeName').setValue(this.document.parentDocumentTypeName);
        this.documentForm.get('active').setValue(this.document.active);
        this.title = this.actionButtonText = "Update Document Type";
  
      } else {
  
        this.documentForm.get('name').setValue("");
        this.documentForm.get('active').setValue(false);
        this.title = this.actionButtonText = "Add Document Type";
  
      }
    });
  }

  ngAfterViewInit() {
    this.profileSvc.getUserProfile().subscribe((profile:UserProfile) => {
      let permission = false;
      permission = profile.userPermissions!=null && profile.userPermissions.indexOf(PermissionLevel.MANAGE_DOCUMENTS_WHITELIST) >= 0;
      setTimeout(() => {
        this.view_document.disabled = !permission;
        this.view_type.disabled = !permission;
        this.view_active.disabled = !permission;        
      },0);
    });

    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }
  
  validateComponent(control:string) {
    this.documentForm.get(control).updateValueAndValidity();
  }
  
  action = () => {

    if( this.documentForm.valid ) {

      let typeId = "";      

      if(this.updating) {
        typeId = this.document.typeId;        
      }

      const name: string = this.documentForm.get('name').value;
      const parentDocumentTypeName: string = this.documentForm.get('parentDocumentTypeName').value;
      const active: boolean = this.documentForm.get('active').value;
            

      if(this.updating) {

        const doc: DocumentWhitelistPayload = new DocumentWhitelistPayload();
        doc.typeId = typeId;
        doc.name = name;
        doc.parentDocumentTypeName = parentDocumentTypeName;
        doc.active = active;
        this.loadingService.loading = true;
        this.adminService.saveDocumentWhitelist(doc).subscribe((result:string)=>{
          this.loadingService.loading = false;
          alert("Document type has been saved","Information");          
          this.navigateToParent();
        },error => {
          this.loadingService.loading = false;
          throw(error);
        });

      } else {

        const doc: any = {
          name:name,
          parentDocumentTypeName:parentDocumentTypeName,
          active:active
        };

        this.loadingService.loading = true;
        this.adminService.addDocumentWhitelist(doc).subscribe((result:string)=>{
          this.loadingService.loading = false;
          alert("Document type has been saved","Information");          
          this.navigateToParent();
        },error => {
          this.loadingService.loading = false;
          throw(error);
        });


      }

    }

  };

}
