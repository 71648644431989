import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prescriptionType'
})
export class PrescriptionTypePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value) {
      switch (value.toLowerCase()) {
        case 'ctca-prescribed':
          return 'CTCA';
        default:
        case 'self-reported':
          return 'Other Provider';
      }
    } else {
      console.error('Bad value passed to PrescriptionTypePipe', value);
    }
  }
}
