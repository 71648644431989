import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AdminService } from '../services';
import { DocumentWhitelistPayload } from '../services/models/';

@Injectable()
export class ResolveDocumentsWhitelistPayload implements Resolve<Array<DocumentWhitelistPayload>> {
  constructor(private adminService: AdminService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Array<DocumentWhitelistPayload> | Observable<Array<DocumentWhitelistPayload>> | Promise<Array<DocumentWhitelistPayload>> {
    return this.adminService.getDocumentsWhitelist();
  }
}