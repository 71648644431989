<ng-container *ngIf="route" smartlook-safe>
  <div smartlook-safe class="nav-item" (click)="toggleSubItems()" [routerLink]="route" (keyup.enter)="navigate()" routerLinkActive="active">
    <ng-container *ngTemplateOutlet="navItemContent" smartlook-safe></ng-container>
  </div>
</ng-container>
<ng-container *ngIf="!route" smartlook-safe>
  <div smartlook-safe class="nav-item" (click)="toggleSubItems()" (keyup.enter)="toggleSubItems()" [class.submenu-active]="menuState === '*'">
    <ng-container *ngTemplateOutlet="navItemContent" smartlook-safe></ng-container>
    <div class="flex"></div>
    <img class="chevron" src="assets/images/icons/SVG/nav-chevron.svg" alt="Toggle menu"/>
  </div>
</ng-container>
<ng-container smartlook-safe *ngIf="subItems">
  <ng-container smartlook-safe *ngTemplateOutlet="subItemsContent"></ng-container>
</ng-container>

<ng-template #navItemContent smartlook-safe>
  <div class="nav-item-icon">
    <span class="fa" [ngClass]="icon"></span>
  </div>
  <div class="nav-item-label" tabindex="0" smartlook-safe>{{ label }}</div>
  <div class="nav-item-badge" *ngIf="badgeText">
    <div class="nav-item-badge-value">{{ badgeText }}</div>
  </div>
  <div class="nav-item-selected-arrow"></div>
</ng-template>

<ng-template #subItemsContent smartlook-safe>
  <div class="nav-subitems" [@expandMenu]="menuState" smartlook-safe>
    <ng-container *ngFor="let subItem of subItems">
      <ng-container *appShowFor="subItem.permission">
        <div *ngIf="!subItem.target" class="nav-subitem" [routerLink]="subItem.route" (keyup.enter)="navigateTo(subItem.route)" routerLinkActive="active">
          <a tabindex="0">{{ subItem.label }}</a>
        </div>
        <div *ngIf="subItem.target && !subItem.reload" class="nav-subitem" (click)="navigateAway(subItem.route, subItem.target)" routerLinkActive="active">
          <a tabindex="0">{{ subItem.label }}</a>
        </div>
        <div *ngIf="subItem.target && subItem.reload" class="nav-subitem" (click)="navigateAwayAndReload(subItem.route, subItem.target)" routerLinkActive="active">
          <a tabindex="0">{{ subItem.label }}</a>
        </div>
        <div class="nav-item-selected-arrow"></div>
      </ng-container>
    </ng-container>
  </div>

</ng-template>
