import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTabsModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxLoadPanelModule,
  DxContextMenuModule,
  DxTagBoxModule
} from 'devextreme-angular';

import { SecureMailListComponent } from './secure-mail-list/secure-mail-list.component';
import { SecureMailRoutingModule } from './secure-mail-routing.module';
import { ViewMessageModalComponent } from './view-message-modal/view-message-modal.component';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxTabsModule,
    DxListModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxLoadPanelModule,
    DxContextMenuModule,
    DxTagBoxModule,
    SecureMailRoutingModule
  ],
  declarations: [SecureMailListComponent, ViewMessageModalComponent],
  exports: [ ViewMessageModalComponent]
})
export class SecureMailModule {}
