import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateSHMGuard } from '../../core/guards';
import { AskQuestionMedicalComponent } from './ask-question-medical.component';

const routes: Routes = [
  {
    path: '',
    component: AskQuestionMedicalComponent,
    children: [],
    canActivate: [CanActivateSHMGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AskQuestionMedicalRoutingModule { }
