import { Component, OnInit } from '@angular/core';
import { trackEvent } from '../../../app/shared/helpers/tracking.helper';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-become-patient-card',
  templateUrl: './become-patient-card.component.html',
  styleUrls: ['./become-patient-card.component.scss']
})
export class BecomePatientCardComponent implements OnInit {

  mrnPopupVisible = false;

  constructor() { }

  ngOnInit() {
  }

  navigateToPatientValidation() {
    trackEvent('Become patient card - Create a patient account clicked', 'Click');
    window.open(environment.ctcaIdentityUrl + "?injectedaction=patient-validation", '_blank');
  }

  showMRN(show: boolean) {
    trackEvent('Become patient card - Show MRN clicked', 'Click');
    this.mrnPopupVisible = show;
  }

}
