<ng-container *ngIf="router.url=='/app/ask-question'">
    <h1 class="mainTitle">
        Ask a Question
        <p class="subtitle">
            If this is a medical emergency, please dial 911.
        </p>
    </h1>
    <div class="card clickable" (click)="askMedicalQuestion()">
        <div class="card-content flexV">
            <div class="flexH">
                <img src="assets/images/icons/SVG/icon-clinicians-purple.svg" alt="Medical questions icon">
                <h1 class="center">Medical Questions</h1>
                <img src="assets/images/icons/SVG/right-arrow-gray.svg" alt="Arrow pointing right">
            </div>
            <h2>Treatment Plan, Medications, Lab Results, Clinical Question</h2>
            <p>Send a non-urgent secure message to your care team. Expect a reply within 24-36 hours.</p>
        </div>
    </div>
    <div class="card clickable" (click)="askNonMedicalQuestion()">
        <div class="card-content flexV">
            <div class="flexH">
                <img src="assets/images/icons/SVG/icon-operator-purple.svg" alt="Non-Medical Questions icon">
                <h1 class="center">Non-Medical Questions</h1>
                <img src="assets/images/icons/SVG/right-arrow-gray.svg" alt="Arrow pointing to the right">
            </div>
            <h2>Scheduling, Travel, Requesting Medical Records</h2>
            <p>Send an email to the appropiate team.</p>
        </div>
    </div>
    <div class="card">
        <div class="card-content flexV">
            <h1>Other Questions? Call</h1>
            <hr *ngIf="billingPhone" />
            <div class="flexHnoJustify" *ngIf="billingPhone">
                <div class="support-callout-icon">
                    <img src="assets/images/icons/SVG/phone-green.svg" alt="Phone icon">
                </div>
                <div class="flexV">
                    <div class="flexHnoJustify">
                        <div class="phone-title">Billing&nbsp;</div>
                        <div class="phone-subtitle">(English / Spanish)</div>
                    </div>
                    <div class="phone-number">{{billingPhone}}</div>
                </div>
            </div>
            <hr *ngIf="careManagementPhone" />
            <div class="flexHnoJustify" *ngIf="careManagementPhone">
                <div class="support-callout-icon">
                    <img src="assets/images/icons/SVG/phone-green.svg" alt="Phone icon">
                </div>
                <div class="flexV">
                    <div class="flexHnoJustify">
                        <div class="phone-title">Care Team</div>
                    </div>
                    <div class="phone-number">{{careManagementPhone}}</div>
                </div>
            </div>
            <hr />
            <div class="flexHnoJustify">
                <div class="support-callout-icon">
                    <img src="assets/images/icons/SVG/phone-green.svg" alt="Phone icon">
                </div>
                <div class="flexV">
                    <div class="flexHnoJustify">
                        <div class="phone-title">Technical Support</div>
                    </div>
                    <div class="phone-number">(800) 234-0482</div>
                </div>
            </div>
        </div>
    </div>
    <dx-load-panel    
        shadingColor="rgba(0,0,0,0.4)"
        [position]="{ of: Window }"
        [(visible)]="isLoading"
        [showIndicator]="true"
        [showPane]="true"
        [shading]="true"
        [hideOnOutsideClick]="false">
    </dx-load-panel>
</ng-container>

<router-outlet></router-outlet>