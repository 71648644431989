import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxSelectBoxModule,
  DxDateBoxModule  
} from 'devextreme-angular';


import { PatientReportedRoutingModule } from './patient-reported-routing.module';
import { SITSurveyComponent } from './sit-survey/sit-survey.component';
import { SymptomInventoryComponent } from './symptom-inventory/symptom-inventory.component';
import { SitDataGraphsComponent } from './sit-data-graphs/sit-data-graphs.component';
import { SITSurveyCommunicationService } from './sit-survey/sit-survey-communication.service';


@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxChartModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    PatientReportedRoutingModule
  ],
  providers: [SITSurveyCommunicationService],
  declarations: [    
    SITSurveyComponent, SymptomInventoryComponent, SitDataGraphsComponent
  ]
})
export class PatientReportedModule {}
