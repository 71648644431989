import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxTagBoxModule,
  DxValidatorModule
} from 'devextreme-angular';

import { AllergiesComponent } from './allergies/allergies.component';
import { HealthHistoryRoutingModule } from './health-history-routing.module';
import { HealthIssuesComponent } from './health-issues/health-issues.component';
import { ImmunizationsComponent } from './immunizations/immunizations.component';
import { PresciptionsDetailModalComponent } from './presciptions-detail-modal/presciptions-detail-modal.component';
import { PrescriptionTypePipe } from './prescription-type.pipe';
import { PrescriptionsComponent } from './prescriptions/prescriptions.component';
import { VitalsChartModalComponent } from './vitals-chart-modal/vitals-chart-modal.component';
import { VitalsComponent } from './vitals/vitals.component';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxChartModule,
    DxCheckBoxModule,
    DxContextMenuModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxTagBoxModule,
    DxValidatorModule,
    HealthHistoryRoutingModule
  ],
  declarations: [
    VitalsComponent,
    VitalsChartModalComponent,
    HealthIssuesComponent,
    ImmunizationsComponent,
    AllergiesComponent,
    PrescriptionsComponent,
    PresciptionsDetailModalComponent,
    PrescriptionTypePipe,
  ]
})
export class HealthHistoryModule {}
