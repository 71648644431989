import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginCallbackComponent } from '../app/login-callback/login-callback.component';
import { SilentRenewCallbackComponent } from '../app/silent-renew-callback/silent-renew-callback.component';
import { LoggedInGuard, ResolveAppointmentRedirectGuard, UnsavedChangesGuard } from './core/guards';
import { LayoutComponent } from './core/layout/layout.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { PermissionLevel } from './core/permissions/permission-levels.enum';
import { PermissionGuard } from './core/permissions/permission.guard';
import { ErrorComponent } from './error-handler/error/error.component';
import { UnstableConnectionErrorComponent } from './error-handler/unstable-connection-error/unstable-connection-error.component';
import { AppointmentConfirmedComponent } from './appointment-confirmed/appointment-confirmed.component';
import { Auth0LoginCallbackComponent } from './login-callback/auth0-login-callback.component';
import { EmailVerificationSuccessComponent } from './email-verification-success/email-verification-success.component';
import { PatientSafetyNotificationModule } from './patient-safety-notification/patient-safety-notification.module';
// import { ContactInfoModule } from '../app/contact-info/contact-info.module';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
import { AdvancedNoticeNonCoverageFormModule } from './advanced-notice-non-coverage-form/advanced-notice-non-coverage-form.module';
import { AppointmentsModule } from './appointments/appointments.module';
import { BillPayModule } from './bill-pay/bill-pay.module';
import { CancelAppointmentV2Module } from './cancel-appointmentv2/cancel-appointmentv2.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { FormLibraryModule } from './form-library/form-library.module';
import { HealthHistoryModule } from './health-history/health-history.module';
import { HelpModule } from './help/help.module';
import { HomeModule } from './home/home.module';
import { LabResultsModule } from './lab-results/lab-results.module';
import { MedicalDocumentsModule } from './medical-documents/medical-documents.module';
import { PatientReportedModule } from './patient-reported/patient-reported.module';
import { PrescriptionRenewalRequestModule } from './prescription-renewal-request/prescription-renewal-request.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';
import { ReportingModule } from './reporting/reporting.module';
import { RequestAppointmentV2Module } from './request-appointmentv2/request-appointmentv2.module';
import { SecureMailModule } from './secure-mail/secure-mail.module';
import { SubmitROIAuthorizationOnlineFormModule } from './submit-roi-authorization-online-form/submit-roi-authorization-online-form.module';
import { TermsOfUseModule } from './terms-of-use/terms-of-use.module';
import { OutageComponent } from './error-handler/outage/outage.component';
import { AskQuestionModule } from './ask-question/ask-question.module';
import { ClaimsModule } from './claims/claims.module';
import { AskQuestionMedicalModule } from './ask-question/ask-question-medical/ask-question-medical.module';

const routes: Routes = [
	{
		path: '',
		component: LandingPageComponent
	},
	{
		path: 'appointment-confirmation',
		component: AppointmentConfirmedComponent
	},
	{
		path: 'email-verification-success',
		component: EmailVerificationSuccessComponent
	},
	{
		path: 'verify-your-email',
		component: EmailVerificationSuccessComponent
	},
	{
		path: 'app',
		canActivate: [LoggedInGuard],
		canActivateChild: [PermissionGuard,LoggedInGuard],		
		component: LayoutComponent,
		children: [
			{
				path: '',
				redirectTo: '/app/home',
				pathMatch: 'full'
			},
			{
				path: 'home',
				loadChildren: () => HomeModule,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'appointments',
				loadChildren: () => AppointmentsModule,
				data: { permissionLevel: PermissionLevel.VIEW_APPOINTMENTS },
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'patient-reported',
				loadChildren: () => PatientReportedModule,
				data: { permissionLevel: PermissionLevel.VIEW_PATIENT_REPORTED_DOCUMENTS }
			},
			{
				path: 'lab-results',
				loadChildren: () => LabResultsModule,
				data: { permissionLevel: PermissionLevel.VIEW_LAB_RESULTS }
			},
			{
				path: 'send-message',
				loadChildren: () => AskQuestionMedicalModule,
				data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'prescriptions/request-renewal/:id',
				loadChildren: () => PrescriptionRenewalRequestModule,
				data: { permissionLevel: PermissionLevel.REQUEST_PRESCRIPTION_REFILL },
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'prescriptions/request-renewal',
				loadChildren: () => PrescriptionRenewalRequestModule,
				data: { permissionLevel: PermissionLevel.REQUEST_PRESCRIPTION_REFILL },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'request-appointmentv2',
				loadChildren: () => RequestAppointmentV2Module,
				data: { permissionLevel: PermissionLevel.REQUEST_APPOINTMENT },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'reschedule-appointmentv2/:id',
				loadChildren: () => RequestAppointmentV2Module,
				resolve: { appointment: ResolveAppointmentRedirectGuard },
				data: { permissionLevel: PermissionLevel.RESCHEDULE_APPOINTMENT },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'cancel-appointmentv2/:id',
				loadChildren: () => CancelAppointmentV2Module,
				resolve: { appointment: ResolveAppointmentRedirectGuard },
				data: { permissionLevel: PermissionLevel.CANCEL_APPOINTMENT },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'advanced-notice-of-non-coverage',
				loadChildren: () => AdvancedNoticeNonCoverageFormModule,
				data: { permissionLevel: PermissionLevel.SUBMIT_ANNC_FORM },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'release-of-information',
				loadChildren: () => SubmitROIAuthorizationOnlineFormModule,
				data: { permissionLevel: PermissionLevel.SUBMIT_ROI_FORM },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'reply-message/:id',
				loadChildren: () => AskQuestionMedicalModule,
				data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES },
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard],
			},
			{
				path: 'secure-mail',
				loadChildren: () => SecureMailModule,
				data: { permissionLevel: PermissionLevel.VIEW_SECURE_MESSAGES },
				runGuardsAndResolvers: 'always'
			},
			{
				path: 'medical-documents',
				loadChildren: () => MedicalDocumentsModule,
				data: { permissionLevel: PermissionLevel.VIEW_MEDICAL_DOCUMENTS }
			},
			{
				path: 'health-history',
				loadChildren: () => HealthHistoryModule,
				data: { permissionLevel: PermissionLevel.VIEW_HEALTH_HISTORY }
			},
			{
				path: 'forms-library',
				loadChildren: () => FormLibraryModule,
				data: { permissionLevel: PermissionLevel.VIEW_FORMS_LIBRARY }
			},
			{
				path: 'bill-pay',
				loadChildren: () => BillPayModule,
				data: { permissionLevel: PermissionLevel.VIEW_BILL_PAY }
			},
			{
				path: 'claims',
				loadChildren: () => ClaimsModule,
				data: { permissionLevel: PermissionLevel.VIEW_CLAIMS }
			},
			{
				path: 'account',
				loadChildren: () => AccountModule,
				data: { permissionLevel: PermissionLevel.VIEW_MY_ACCOUNT }
			},
			/*{
				path: 'contact-info',
				loadChildren: () => ContactInfoModule,
				data: { permissionLevel: PermissionLevel.VIEW_MEDICAL_CONTACTS }
			},*/
			{
				path: 'admin',
				loadChildren: () => AdminModule,
				data: { permissionLevel: PermissionLevel.VIEW_ADMINISTRATION }
			},
			{
				path: 'reporting',
				loadChildren: () => ReportingModule,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			},
			{
				path: 'help',
				loadChildren: () => HelpModule,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'contact-us',
				loadChildren: () => ContactUsModule,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'privacy-policy',
				loadChildren: () => PrivacyPolicyModule,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'terms-of-use',
				loadChildren: () => TermsOfUseModule,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'patient-safety-notification',
				loadChildren: () => PatientSafetyNotificationModule,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'ask-question',
				loadChildren: () => AskQuestionModule,
				data: { PermissionLevel: PermissionLevel.SEND_SECURE_MESSAGES},
				runGuardsAndResolvers: 'always',
				canDeactivate: [UnsavedChangesGuard]
			}
		]
	},
	{ path: 'callback', component: Auth0LoginCallbackComponent },
	{ path: 'auth', component: LoginCallbackComponent },
	{ path: 'silent-renew', component: SilentRenewCallbackComponent },
	{
		path: 'error',
		component: ErrorComponent
	},
	{
		path: 'connection-error',
		component: UnstableConnectionErrorComponent
	},
	{
		path: 'outage',
		component: OutageComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes,{
		onSameUrlNavigation: 'reload'
	  })],
	exports: [RouterModule]
})
export class AppRoutingModule {}
