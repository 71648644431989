import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxDateBoxModule,
  DxListModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTooltipModule,
} from 'devextreme-angular';

import { FrequentlyAskedQuestionsComponent } from './frequently-asked-questions/frequently-asked-questions.component';
import { HelpRoutingModule } from './help-routing.module';
import { HelpComponent } from './help.component';
import { UserGuideComponent } from './user-guide/user-guide.component';

@NgModule({
    imports: [
        SharedModule,
        DxPopupModule,
        DxScrollViewModule,
        DxTextBoxModule,
        DxButtonModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        DxTooltipModule,
        DxLoadPanelModule,
        DxListModule,
        HelpRoutingModule
    ],
    declarations: [
        HelpComponent,
        UserGuideComponent,
        FrequentlyAskedQuestionsComponent
    ]
})
export class HelpModule { }
