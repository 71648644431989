import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Popup } from '../../core/popup/popup';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentsService, FileDownloadService, LoadingService, ResizingService } from '../../core/services';
import { FormGroup, FormControlName, FormControl, Validators } from '@angular/forms';
import { dateOrEmptyValidator } from '../../shared/validators/date-or-empty.validator';
import * as formHelper from '../../shared/helpers/form.helper';
import * as moment from 'moment';
import { DxPopupComponent } from 'devextreme-angular';
import { AppointmentDetail } from '../../../app/core/services/models';

@Component({
  selector: 'app-download-schedule',
  templateUrl: './download-schedule.component.html',
  styleUrls: ['./download-schedule.component.scss']
})
export class DownloadScheduleComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
  downloadForm:FormGroup;
  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
  appointments: AppointmentDetail[];
  noAppointments = false;
  constructor(
    router: Router,
    route: ActivatedRoute,
    private apptSvc: AppointmentsService,
    private downloadService: FileDownloadService,
    private loadingService:LoadingService,
    resizingService: ResizingService
  ) {

    super(router, route, resizingService);

    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
  
    FormControlName.prototype.ngOnChanges = function () {
      let result;
      if (arguments) {
        result = originFormControlNameNgOnChanges.apply(this, arguments);
      }
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };

  }

  ngOnInit() {
    this.downloadForm = new FormGroup({     
      startDate: new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator])),
      endDate: new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator])),       
    });
    this.loadingService.loading = true;
    formHelper.markControlAndChildControlsAsDirty(this.downloadForm);
    this.apptSvc.getAppointments().subscribe((appointments: AppointmentDetail[]) => {
      this.appointments = appointments;
      this.loadingService.loading = false;
    }, () => {
      this.loadingService.loading = false;
    });
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }

  wrongOrder():boolean {
    
    const startDate = new Date(this.downloadForm.get('startDate').value);
    const endDate = new Date(this.downloadForm.get('endDate').value);
    
    return moment(startDate).isAfter(moment(endDate));

  }

  validateComponent(field:string) {
    this.downloadForm.get(field).updateValueAndValidity();
  }

  canDownload() {
    if (this.downloadForm.valid && !this.wrongOrder()) {
      const startDate = moment(new Date(this.downloadForm.get('startDate').value));
      const endDate = moment(new Date(this.downloadForm.get('endDate').value)).add(1, 'day');
      const appointment = this.appointments.find(a => {
        const appointmentDate = moment(a.startDateTime);
        return appointmentDate.isAfter(startDate) && appointmentDate.isBefore(endDate);
      });
      this.noAppointments = !appointment;
      return !!appointment;
    }
    this.noAppointments = false;
    return false;
  }

  onDownloadClick = () => {

    if(this.downloadForm.valid && !this.wrongOrder()) {
      this.loadingService.loading = true;
      const startDate: Date = new Date(this.downloadForm.get('startDate').value);
      const endDate: Date = new Date(this.downloadForm.get('endDate').value);
      this.apptSvc.downloadSchedule(startDate, endDate).subscribe((doc:Blob) => {
        this.loadingService.loading = false;
        this.downloadService.downloadPdf(doc, 'Appointment_Schedule')
      }, error => {
        this.loadingService.loading = false;
        throw(error);
      });
  
    }
  };
  
}
