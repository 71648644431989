import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { DxButtonModule, DxContextMenuModule, DxLoadPanelModule } from 'devextreme-angular';

import {
	LoggedInGuard,
	ProxiesGuard,
	ResolveAppointmentRedirectGuard,
	ResolveCareTeamsGuard,
	ResolveCcdaDocumentGuard,
	ResolveCcdaDocumentListGuard,
	ResolveImagingDocumentGuard,
	ResolveImagingDocumentListGuard,
	ResolveMedicalDocumentGuard,
	ResolveMedicalDocumentListGuard,
	ResolvePrescriptionGuard,
	ResolveVitalsChartDataGuard,
	ResolveAlertMessagePayload,
	ResolveAddAlertMessagePayload,
	ResolveAlertMessagesPayload,
	ResolveDocumentsWhitelistPayload,
	ResolveDocumentWhitelistPayload,
	UnsavedChangesGuard,
	CanActivatePrescriptionGuard,
	CanActivateSHMGuard,
} from './guards';
import { LayoutComponent } from './layout/layout.component';
import { MobileSideMenuComponent } from './mobile-side-menu/mobile-side-menu.component';
import { NavMenuItemComponent } from './nav-menu-item/nav-menu-item.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NavUserInfoComponent } from './nav-user-info/nav-user-info.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PermissionGuard } from './permissions/permission.guard';
import { PermissionsDirectivesModule } from '../permissions-directives/permissions-directives.module';
import { SITSurveyCommunicationService } from '../../app/patient-reported/sit-survey/sit-survey-communication.service';
import {
	AppointmentsService,
	AuthenticationService,
	ContactInfoService,
	DocumentExchangeService,
	FileDownloadService,
	FormLibraryService,
	HealthHistoryService,
	HtmlTransformerService,
	LabResultsService,
	MedicalDocumentsService,
	SecureMailService,
	SupportService,
	UserProfileService,
	UserProfileAvoidCacheService,
	UserPreferencesService,
	LoadingService,
	NavigationService,
	AdminService,
	ReportingService,
	ResizingService,
	PlatformService,
	LaunchDarklyService,
	ContactUsStateService,
	ClaimsService
} from './services';
import { ContactOverlayComponent } from '../../app/contact-us/contact-overlay/contact-overlay.component';
import { SharedModule } from '../../app/shared/shared.module';
import { ResolveFacilityInfoGuard } from './guards';
import { IdentityEventService } from './services';

@NgModule({
	imports: [
		CommonModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		RouterModule,
		DxButtonModule,
		DxLoadPanelModule,
		PermissionsDirectivesModule,
		SharedModule,
		DxContextMenuModule
	],
	exports: [MobileSideMenuComponent, NavMenuComponent, PageHeaderComponent, PageFooterComponent, DxLoadPanelModule],
	declarations: [
		MobileSideMenuComponent,
		NavMenuComponent,
		PageHeaderComponent,
		NavMenuItemComponent,
		NavUserInfoComponent,
		PageFooterComponent,
		LayoutComponent,
		ContactOverlayComponent
	],
	providers: [
		LaunchDarklyService,
		AuthenticationService,
		AppointmentsService,
		ContactInfoService,
		LabResultsService,
		UserProfileService,
		UserProfileAvoidCacheService,
		UserPreferencesService,
		LoadingService,
		NavigationService,
		AdminService,
		SecureMailService,
		MedicalDocumentsService,
		DocumentExchangeService,
		FileDownloadService,
		FormLibraryService,
		HtmlTransformerService,
		SupportService,
		HealthHistoryService,
		ReportingService,
		ResizingService,
		PlatformService,
		SITSurveyCommunicationService,
		IdentityEventService,
		LoggedInGuard,
		ProxiesGuard,
		PermissionGuard,
		ResolveCareTeamsGuard,
		ResolveFacilityInfoGuard,
		ResolveAppointmentRedirectGuard,
		ResolvePrescriptionGuard,
		ResolveVitalsChartDataGuard,
		ResolveCcdaDocumentGuard,
		ResolveCcdaDocumentListGuard,
		ResolveMedicalDocumentGuard,
		ResolveMedicalDocumentListGuard,
		ResolveImagingDocumentListGuard,
		ResolveImagingDocumentGuard,
		ResolveAlertMessagePayload,
		ResolveAddAlertMessagePayload,
		ResolveAlertMessagesPayload,
		ResolveDocumentsWhitelistPayload,
		ResolveDocumentWhitelistPayload,
		UnsavedChangesGuard,
		CanActivatePrescriptionGuard,
		CanActivateSHMGuard,
		ContactUsStateService,
		ClaimsService
	]
})
export class CoreModule { }
