<app-card label="Contact Your Treatment Facility">
  <div *ngIf="primaryFacilityInfo" class="contact-treatment-facility">
    <p class="facility-name">
      {{primaryFacilityInfo?.address?.name}}
    </p>
    <div class="facility-address">
      <div>{{ primaryFacilityInfo?.address?.line1 }}</div>
      <div *ngIf="primaryFacilityInfo?.address?.line2">{{ primaryFacilityInfo?.address?.line2 }}</div>
      <div>
        <span>{{ primaryFacilityInfo?.address?.city }},</span>&nbsp;
        <span>{{ primaryFacilityInfo?.address?.state }}</span>&nbsp;
        <span>{{ primaryFacilityInfo?.address?.postalCode }}</span>
      </div>
      <div *ngIf="primaryFacilityInfo?.address?.country">{{ primaryFacilityInfo?.address?.country }}</div>
    </div>
    <div class="facility-phone-numbers">
      <div *ngIf="primaryFacilityInfo?.mainPhone">Main Phone: {{ primaryFacilityInfo?.mainPhone }}</div>
      <div *ngIf="primaryFacilityInfo?.schedulingPhone">Scheduling: {{ primaryFacilityInfo?.schedulingPhone }}</div>
      <div *ngIf="primaryFacilityInfo?.accommodationsPhone">Accommodations: {{ primaryFacilityInfo?.accommodationsPhone }}</div>
      <div *ngIf="primaryFacilityInfo?.transportationPhone">Transportation: {{ primaryFacilityInfo?.transportationPhone }}</div>      
    </div>
  </div>
  <div *ngIf="primaryFacilityInfoArray">
    <div *ngFor="let primaryFacilityInfo of primaryFacilityInfoArray" class="contact-treatment-facility">
      <p class="facility-name">
        {{primaryFacilityInfo?.address?.name}}
      </p>
      <div class="facility-address">
        <div>{{ primaryFacilityInfo?.address?.line1 }}</div>
        <div *ngIf="primaryFacilityInfo?.address?.line2">{{ primaryFacilityInfo?.address?.line2 }}</div>
        <div>
          <span>{{ primaryFacilityInfo?.address?.city }},</span>&nbsp;
          <span>{{ primaryFacilityInfo?.address?.state }}</span>&nbsp;
          <span>{{ primaryFacilityInfo?.address?.postalCode }}</span>
        </div>
        <div *ngIf="primaryFacilityInfo?.address?.country">{{ primaryFacilityInfo?.address?.country }}</div>
      </div>
      <div class="facility-phone-numbers">
        <div *ngIf="primaryFacilityInfo?.mainPhone">Main Phone: {{ primaryFacilityInfo?.mainPhone }}</div>              
      </div>
    </div>
  </div>  
</app-card>