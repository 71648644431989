import { AbstractControl, ValidationErrors } from '@angular/forms';

export function integer(control: AbstractControl): ValidationErrors | null {
    
        const value:any = control.value;       

        if(value) {
            
            if (!(parseInt(value, 10) == value)) {

                return {
                    integer:{integer:false, actual:value}
                };

            } else {

                return null;

            }

        } else {

            return null;

        }

    

}