import { Component, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';

import { Popup } from '../../core/popup/popup';
import { Router, ActivatedRoute } from '@angular/router';
import { ResizingService } from '../../core/services';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-blue-button-popup',
  templateUrl: './blue-button-popup.component.html',
  styleUrls: ['./blue-button-popup.component.scss']
})
export class BlueButtonPopupComponent extends Popup implements AfterViewInit, OnDestroy {
  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

  constructor(router: Router, route: ActivatedRoute, resizingService: ResizingService) {
    super(router, route, resizingService);
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }
  
}
