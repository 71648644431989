import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CanActivateSHMGuard } from "app/core/guards";
import { PermissionLevel } from "app/core/permissions/permission-levels.enum";
import { AskQuestionNonMedicalContactComponent } from "./ask-question-non-medical-appointment-contact/ask-question-non-medical-contact.component";
import { AskQuestionNonMedicalReasonComponent } from "./ask-question-non-medical-appointment-reason/ask-question-non-medical-reason.component";
import { AskQuestionNonMedicalAppointmentTimeComponent } from "./ask-question-non-medical-appointment-time/ask-question-non-medical-appointment-time.component";
import { AskQuestionNonMedicalCompleteRoiComponent } from "./ask-question-non-medical-complete-roi/ask-question-non-medical-complete-roi.component";
import { AskQuestionNonMedicalEmailComponent } from "./ask-question-non-medical-email/ask-question-non-medical-email.component";
import { AskQuestionNonMedicalReviewComponent } from "./ask-question-non-medical-review/ask-question-non-medical-review.component";
import { AskQuestionNonMedicalRoiComponent } from "./ask-question-non-medical-roi/ask-question-non-medical-roi.component";
import { AskQuestionNonMedicalScheduleDeliveryComponent } from "./ask-question-non-medical-schedule-delivery/ask-question-non-medical-schedule-delivery.component";
import { AskQuestionNonMedicalScheduleRangeComponent } from "./ask-question-non-medical-schedule-range/ask-question-non-medical-schedule-range.component";
import { AskQuestionNonMedicalSelectAppointmentComponent } from "./ask-question-non-medical-select-appointment/ask-question-non-medical-select-appointment.component";
import { AskQuestionNonMedicalSubjectComponent } from "./ask-question-non-medical-subject/ask-question-non-medical-subject.component";
import { AskQuestionNonMedicalComponent } from "./ask-question-non-medical/ask-question-non-medical.component";
import { AskQuestionComponent } from "./ask-question.component";

const routes: Routes = [
    {
        path: '',
        component: AskQuestionComponent,
        children: [
            {
                path: 'non-medical/subject',
                component: AskQuestionNonMedicalSubjectComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/select-appointment',
                component: AskQuestionNonMedicalSelectAppointmentComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/appointment-time',
                component: AskQuestionNonMedicalAppointmentTimeComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/appointment-contact',
                component: AskQuestionNonMedicalContactComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/reason',
                component: AskQuestionNonMedicalReasonComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/review',
                component: AskQuestionNonMedicalReviewComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/roi',
                component: AskQuestionNonMedicalRoiComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/complete-roi',
                component: AskQuestionNonMedicalCompleteRoiComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/schedule-range',
                component: AskQuestionNonMedicalScheduleRangeComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/schedule-delivery',
                component: AskQuestionNonMedicalScheduleDeliveryComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical',
                component: AskQuestionNonMedicalComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
            {
                path: 'non-medical/email',
                component: AskQuestionNonMedicalEmailComponent,
                data: { permissionLevel: PermissionLevel.SEND_SECURE_MESSAGES }
            },
        ],
        canActivate: [CanActivateSHMGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AskQuestionRoutingModule {}
