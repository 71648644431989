import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, EMPTY, throwError as observableThrowError } from 'rxjs';
import { MedicalDocumentsService } from '../services';
import { MedicalDocument } from '../services/models';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ResolveMedicalDocumentGuard implements Resolve<MedicalDocument> {
  constructor(private docSvc: MedicalDocumentsService, private router: Router) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): MedicalDocument | Observable<MedicalDocument> | Promise<MedicalDocument> {
    const docType = route.parent.data.documentType;
    const id = route.params.id;
    return this.docSvc.getMedicalDocument(docType, id).pipe(catchError((error:HttpErrorResponse) => {
      if(error.status === 404) {
        this.router.navigate(['app','medical-documents',docType.toLowerCase()]);
        return EMPTY;
      } else {
        return observableThrowError(error);
      }
    }));
  }
}
