import { environment as local } from "./environment.local";
import { environment as dev } from "./environment.dev";
import { environment as qa } from "./environment.qa";
import { environment as stage } from "./environment.stage";
import { environment as prod } from "./environment.prod";

const ENVIRONMENT_PER_HOST: any = {
	'localhost': local,
	'dev.portal.myctca.com': dev,
	'v3dev.myctca.com': dev,
	'qa.portal.myctca.com': qa,
	'v3qa.myctca.com': qa,
	'stage.portal.myctca.com': stage,
	'v3test.portal.myctca.com': stage,
	'portal.myctca.com': prod
};
const host = window?.location?.host?.split(':')[0] || 'localhost';
let _environment: any;

if (host.endsWith('.cloudfront.net')) {
	_environment = prod;
	_environment.auth0.redirect_uri = "https://" + host + "/callback";
} else {
	_environment = ENVIRONMENT_PER_HOST[host];
}

export const environment = _environment;