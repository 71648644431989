<app-card label="Notifications"> 
    <p>Select those items below for which you would like to receive email notifications when new information 
      has been made available since your last Log In to the myCTCA Portal.</p> 
    <form *ngIf="notificationsForm" [formGroup]="notificationsForm" novalidate>      
      <div class="dx-fieldset">
        <div class="dx-field">
          <div formArrayName="notifications" class="notifications-values">
            <div class="dx-field-value">
              <span *ngFor="let notification of notifications.controls;  let i = index" [formGroupName]="i">
                <dx-check-box style="padding-top:10px;display: block;" [formControlName]="getKey(notification['controls'])"  
                text="{{checkBoxName(notification)}}"></dx-check-box>
              </span>
            </div>
          </div>
        </div>       
      </div>
    </form>
    <div class="outer">
      <dx-button class="inner" text="Update Notifications" (onClick)="updateNotifications()"></dx-button>
    </div>
</app-card>
