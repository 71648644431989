import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import ArrayStore from 'devextreme/data/array_store';
import { FacilityInfo, KeyValuePair, ContactUsMailMessage } from '../../core/services/models';
import { SupportService, UserProfileService, LoadingService, ResizingService } from '../../core/services';
import { alert } from 'devextreme/ui/dialog';
import { DxPopupComponent } from 'devextreme-angular';
import { isValidPhoneNumber, phoneNumberValidator } from '../../home/shared/helpers/phone.helper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-send-message-technical-support-modal',
	templateUrl: './send-message-to-technical-support-modal.component.html',
	styleUrls: ['./send-message-to-technical-support-modal.component.scss']
})
export class SendMessageTechnicalSupportModalComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
	
	facilitiesDataSource: ArrayStore;
	areasOfConcernDataSource: ArrayStore;
	sendMessageToTechSupport: FormGroup;

	contactUsMailMessage: ContactUsMailMessage;

	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

	constructor(
		private contactUsService: SupportService,
		private profileSvc: UserProfileService,
		route: ActivatedRoute,
		router: Router,
		private loadingService:LoadingService,
		resizingService: ResizingService,
		private fb: FormBuilder,
	) {
		super(router, route, resizingService);
		this.onCancel = this.onCancel.bind(this);
	}

	ngOnInit() {
		this.contactUsMailMessage = new ContactUsMailMessage();
		this.profileSvc.getUserProfile().subscribe(profile => {
			this.contactUsMailMessage.userName = `${profile.firstName} ${profile.lastName}`;
			this.initializeTreatementFacilitySelectionOptions();
			this.initializeFeedbackAreasofConcern();
		});

		this.sendMessageToTechSupport = this.fb.group({
			phoneNumber: ['', Validators.compose([Validators.required, phoneNumberValidator])],
		});
	}

	ngAfterViewInit(){
		super.init(this.popup);
	}

	ngOnDestroy(){
		super.destroy();
	}

	phoneValidationCallback(e) {
		return isValidPhoneNumber(this.contactUsMailMessage.phoneNumber);
	}

	saveSubject(){
		sessionStorage.setItem('dirtyForm','1');
	}
	
	savePhone(){
		sessionStorage.setItem('dirtyForm','1');
	}

	saveMessage(){
		sessionStorage.setItem('dirtyForm','1');
	}
	
	onCancel()
	{
		this.router.navigate(['app','contact-us']);
	}
	
	initializeTreatementFacilitySelectionOptions() {
		this.contactUsService
			.getAllFacilitiesInfoForTreamentFacilitySelect()
			.subscribe((facilityInfoList: Array<FacilityInfo>) => {
				this.facilitiesDataSource = new ArrayStore({
					data: facilityInfoList.map((f: FacilityInfo) =>
						this.getTreatementFacilityFormattedKeyValuePair(f)
					),
					key: 'key'
				});
			});
	}

	initializeFeedbackAreasofConcern() {
		this.contactUsService.getFeedbackAreasofConcern().subscribe(feedbackAreasofConcern => {
			this.areasOfConcernDataSource = new ArrayStore({
				data: feedbackAreasofConcern.map((f: KeyValuePair) =>
					this.getFeedbackAreasofConcernKeyValuePair(f)
				),
				key: 'key'
			});
		});
	}

	getTreatementFacilityFormattedKeyValuePair(facility: FacilityInfo) {
		const keyValuePair: KeyValuePair = {
			value: facility.address.city + ', ' + facility.address.state + ' - ' + facility.name,
			key: facility.name
		};
		return keyValuePair;
	}

	getFeedbackAreasofConcernKeyValuePair(areaOfConcern: KeyValuePair) {
		const keyValuePair: KeyValuePair = { value: areaOfConcern.value, key: areaOfConcern.value };
		return keyValuePair;
	}

	onSubmitClick = e => {
		if (e.validationGroup.validate().isValid) {
			this.loadingService.loading = true;
			this.profileSvc.getUserProfile().subscribe(profile => {
				this.contactUsService
					.sendContactUsEmail({
						facility: this.contactUsMailMessage.facility,
						areaOfConcern: this.contactUsMailMessage.areaOfConcern,
						userName: this.contactUsMailMessage.userName,
						emailAddress: profile.emailAddress,
						phoneNumber: this.contactUsMailMessage.phoneNumber,
						subject: this.contactUsMailMessage.subject,
						comments: this.contactUsMailMessage.comments
					})
					.subscribe(
						() => {
							sessionStorage.removeItem("dirtyForm");
							this.loadingService.loading = false;
							alert(
								'Your email submitted. We will respond to your email message within one (1) business day.',
								'Message'
							);
							this.navigateToParent();
						},
						error => {
							sessionStorage.removeItem("dirtyForm");
							this.loadingService.loading = false;
							throw(error);
						}
					);
			});
		}
	};
}
