import { Component, OnInit } from '@angular/core';
import { FileDownloadService, SupportService, LoadingService } from '../../core/services';
import { Link } from '../../core/services/models';

@Component({
	selector: 'app-external-links',
	templateUrl: './external-links.component.html',
	styleUrls: ['./external-links.component.scss']
})
export class ExternalLinksComponent implements OnInit {
	smartlook = (window as any).smartlook;
	externalLinks;
	showView = false;
	constructor(
		private downloadsService: FileDownloadService,
		private supportService: SupportService,
		private loadingService:LoadingService
	) {}

	ngOnInit() {
		setTimeout(() => {
			this.showView = true
		}, 200);
		this.supportService.getExternalLinks().subscribe(
			links =>
				(this.externalLinks = links.map(item => ({
					title: item.title,
					items: item.children
				})))
		);
	}

	downloadPdf(link: Link) {
		this.onOpenLink(link);
		this.loadingService.loading = true;
		this.downloadsService
			.getFileContent(link.url)
			.subscribe(data => {
				this.downloadsService.downloadPdf(data, link.title);
				this.loadingService.loading = false;
			});
	}

	onListReady = (event) => {
		const itemCount = event.component.option('items').length;
		// collapse all groups except the first one.
		for (let i = 0; i < itemCount; i++) {
			event.component.collapseGroup(i);
		}
	};

	mouseEnteredResources() {
		if (this.smartlook) {
			this.smartlook("track", "Home - Resources - Mouse entered");
		}
	}

	onOpenLink(item: Link) {
		if (this.smartlook) {
			this.smartlook("track", "Home - Resources - Resource click", {
				title: item.title,
				link: item.url,
				isDownload: item.isDownload
			});
		};
	}

	clickedInsideResources(event) {
		if (this.smartlook) {
			const target = event.target || event.srcElement;
			if (target.className === 'dx-template-wrapper ng-star-inserted') {
				const groupName = target.innerHTML.trim();
				this.smartlook("track", "Home - Resources - Group click", { groupName });
			}
		}
	}
}
