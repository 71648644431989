import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { ContactUsStateService } from 'app/core/services';


@Component({
  selector: 'app-ask-question-non-medical-reason',
  templateUrl: './ask-question-non-medical-reason.component.html',
  styleUrls: ['./ask-question-non-medical-reason.component.scss']
})
export class AskQuestionNonMedicalReasonComponent extends BaseComponent implements OnInit {

  reason = '';
  editing = false;
  title: string;

  constructor(private route: ActivatedRoute, private router: Router, private contactUsState: ContactUsStateService) {
    super();
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.editing = params.edit === 'true'
    });
    const state = this.contactUsState.getState();
    const reason = state.reason;
    console.log('reason:', reason);
    this.reason = reason || '';
    this.title = state.subject.value;
  }

  onNextClick = (e: any) => {
    this.contactUsState.setState({ reason: this.reason });
    this.router.navigate(['non-medical', 'review'], { relativeTo: this.route.parent });
  }

  close = () => {
    window.history.back();
  }

}
