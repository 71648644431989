<dx-popup #sendMessageToTechSupportModal [hideOnOutsideClick] = "false" [visible]="visible$ | async" title="Send a Message to Technical Support" height="660"
  width="750" (onHidden)="navigateToParent()" titleTemplate="title">
  <dxi-toolbar-item [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Cancel', onClick: onCancel }">
  </dxi-toolbar-item>
  <dxi-toolbar-item [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Submit', icon: 'fa fa-check-square-o', type: 'default', onClick: onSubmitClick }">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view>
      <div class="alert">
        <div class="icon">
          <i class="fa fa-exclamation-triangle fa-2x"></i>
        </div>
        <div class="content">
          If this is a medical emergency, call 911 or your local emergency number for assistance. If you need an immediate response,
          please call your Care Manager. We will respond to your email message within one (1) business day.
        </div>
      </div>
      <div class="dx-fieldset sendMessageToTechSupport">
        <form [formGroup]="sendMessageToTechSupport" novalidate>
          <div class="dx-field">
            <div class="dx-field-label">From</div>
            <div class="dx-field-value-static">{{contactUsMailMessage.userName}}</div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">To</div>
            <div class="dx-field-value-static">Technical Support</div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><label for="subject">Subject</label></div>
            <div class="dx-field-value">
              <dx-text-box [inputAttr]="{ id: 'subject' }" [(value)]="contactUsMailMessage.subject" (change) = "saveSubject()">
                <dx-validator [validationRules]="[{type: 'required', message: 'Subject is required'}]"></dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><label for="phoneNumber">My Phone #</label></div>
            <div class="dx-field-value">
              <app-phone-field [inputAttr]="{ id: 'phoneNumber' }" [(value)]="contactUsMailMessage.phoneNumber" formControlName="phoneNumber"
              [isValid]="sendMessageToTechSupport?.controls['phoneNumber'].valid || sendMessageToTechSupport?.controls['phoneNumber'].pristine"
              [validationError]="{ message: 'Phone is required and in correct format' }" (change) = "savePhone()">
              </app-phone-field>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><label for="concern">Area of Concern</label></div>
            <div class="dx-field-value">
                <dx-select-box [inputAttr]="{ id: 'concern' }" id="selectedAreaOfConcern" [(value)]="contactUsMailMessage.areaOfConcern" [dataSource]="areasOfConcernDataSource" displayExpr="value" valueExpr="key">
                    <dx-validator [validationRules]="[{type: 'required', message: 'Area of Concern is required'}]"></dx-validator>
                </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><label for="facility">Treatment Facility</label></div>
            <div class="dx-field-value">
              <dx-select-box [inputAttr]="{ id: 'facility' }" id="selectedTreatementFacility" [(value)]="contactUsMailMessage.facility" [dataSource]="facilitiesDataSource" displayExpr="value" valueExpr="key">
                  <dx-validator [validationRules]="[{type: 'required', message: 'Treatment Facility is required'}]"></dx-validator>
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"><label for="message">Message</label></div>
          </div>
          <div class="dx-field">
            <dx-text-area [inputAttr]="{ id: 'message' }" [(value)]="contactUsMailMessage.comments" height="275px" (change) = "saveMessage()">
              <dx-validator [validationRules]="[{type: 'required', message: 'Message is Required'}]"></dx-validator>
            </dx-text-area>
          </div>
        </form>
      </div>
    </dx-scroll-view>
  </div>
  <div *dxTemplate="let data of 'title'" style="height: 50px;">
    <p style="display: inline; float: left;margin-top: 10px;margin-bottom: auto;" class="dx-popup-titlebar">Send a Message to Technical Support</p>
    <p style="display: inline; float: right;"><img src="../../../assets/images/icons/SVG/close.svg" alt="" (click) = "onCancel()" style="cursor: pointer;"></p>
</div>
</dx-popup>