import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { DocumentExchangeService, HtmlTransformerService } from '../../core/services';

@Component({
	selector: 'app-latest-ccda',
	templateUrl: './latest-ccda.component.html',
	styleUrls: ['./latest-ccda.component.scss']
})
export class LatestCcdaComponent extends BaseComponent implements OnInit {
	@ViewChild('content', { static: true }) content: ElementRef;
	private docAvailable:boolean;
	

	constructor(
		private docXSvc: DocumentExchangeService,
		private transformer: HtmlTransformerService
	) {
		super();		
	}

	ngOnInit() {

		this.docAvailable = false;
		let pushContent = "";

		this.docXSvc.getLatestCCDADocument().subscribe(doc => {

			if(doc.content.trim().length === 0) {
				this.docAvailable = false;
				pushContent = doc.title;				
			}
			else {
				this.docAvailable = true;
				pushContent = atob(doc.content);
			}
			pushContent = pushContent.split('#777').join('#333');
			this.transformer
				.load(pushContent)
				.bindToHashLinksToSpans(hash => document.getElementById(hash).scrollIntoView())
				.appendTo(this.content.nativeElement);
		});
	}

	calcScrollViewHeight() {		
		return this.docAvailable ? '500px' : '57px';
	}

	calcCardHeight() {		
		return this.docAvailable ? '600px' : '150px';
	}

}
