import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { MedicalDocumentsService } from '../services';
import { MedicalDocument } from '../services/models';

@Injectable()
export class ResolveMedicalDocumentListGuard implements Resolve<MedicalDocument[]> {
  constructor(private docSvc: MedicalDocumentsService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): MedicalDocument[] | Observable<MedicalDocument[]> | Promise<MedicalDocument[]> {
    const docType = route.data.documentType;
    //return this.docSvc.getMedicalDocuments(docType)
    const docs =  this.docSvc.getMedicalDocuments(docType);
    return docs.pipe(():any=>{
      localStorage.setItem(`startTimeOf${docType}`,Date.now().toString());
      return docs;
    })
  }
}
