<div [class]="wrapperClass()" (click)="clicked()">
    <div [class]="containerClass()">
        <div *ngIf="hasCheckbox" class="checkbox" (click)="onToggleCheckbox.emit()">
            <img *ngIf="checked" src="assets/images/icons/SVG/checkmark-purple.svg" alt="Checkmark"/>
            <div *ngIf="!checked" class="notChecked"></div>
        </div>
        <div *ngIf="icon" class="icon">
            <img [src]="icon" alt="Icon"/>
        </div>
        <div class="content">
            <div class="title">{{title}}</div>
            <div class="subtitle">{{subtitle}}</div>
        </div>
        <div *ngIf="hasEdit" class="button">
            <img (click)="onEdit.emit()" src="assets/images/icons/SVG/edit-pencil.svg" alt="Edit button"/>
        </div>
        <div *ngIf="hasDelete" class="button">
            <img (click)="onDelete.emit()" src="assets/images/icons/SVG/delete-trash.svg" alt="Delete button"/>
        </div>
        <div *ngIf="hasClose" class="button">
            <img (click)="onClose.emit()" src="assets/images/icons/SVG/close.svg" alt="Close button"/>
        </div>
    </div>
    <div class="contentWrapper">
        <ng-content></ng-content>
    </div>
</div>