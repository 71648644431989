<app-card label="Activity Logs">
	<div card-buttons-desktop>
		<dx-button text="Clear Filters" icon="fa fa-times" (onClick)="clearFilters()"></dx-button>
	</div>

	<label for="timestamp" class="visuallyhidden">Timestamp</label>
	<label for="userName" class="visuallyhidden">Username</label>
	<label for="formattedMessage" class="visuallyhidden">Message</label>

	<dx-data-grid
		[dataSource]="logs"
		height="600"
		showBorders="true"
		[remoteOperations]="true"
		[hoverStateEnabled]="true"
		[wordWrapEnabled]="false"
		[columnAutoWidth]="false"
		[rowAlternationEnabled]="true"
		[showColumnLines]="false"
		[masterDetail]="{ enabled: true, template: 'detail' }"
	>
		<div *dxTemplate="let log of 'detail'">
			<div style="white-space: normal">
				<span class="bold">Event time: </span><span>{{ log.data.timestamp }}</span>
				&nbsp;&nbsp;&nbsp;
				<span class="bold" *ngIf="log.data.details != null">Sent to: </span
				><span>{{ log.data.details }}</span>
			</div>
		</div>

		<dxi-column
			[editorOptions]="{ inputAttr: { id: 'timestamp' } }"
			[width]="200"
			dataField="timestamp"
			dataType="datetime"
			[filterOperations]="['=', '<=', '>=']"
			caption="Date"
		></dxi-column>
		<dxi-column
			[editorOptions]="{ inputAttr: { id: 'userName' } }"
			[width]="200"
			dataField="userName"
			[filterOperations]="['contains', 'startswith', 'endswith', '=']"
			caption="Username"
		></dxi-column>
		<dxi-column
			[editorOptions]="{ inputAttr: { id: 'formattedMessage' } }"
			dataField="formattedMessage"
			[filterOperations]="['contains', 'startswith', 'endswith', '=']"
			caption="Message"
		></dxi-column>

		<dxo-filter-row [visible]="true"></dxo-filter-row>
		<dxo-scrolling mode="virtual"></dxo-scrolling>
		<dxo-sorting mode="none"></dxo-sorting>
	</dx-data-grid>
</app-card>
<router-outlet></router-outlet>
