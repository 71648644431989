<dx-popup
  class="home"
  #alertpopup
  [visible]="visible$ | async"
  title="Alert Message Detail"
  width="560"
  height="640" (onHidden)="navigateToParent(2)">
  <dx-scroll-view>
    <div id="alert-message-content">
      <ul>
        <li *ngFor="let message of Messages; index as i">
          <div [innerHTML]="message"></div>    
        </li>
      </ul>
    </div>
  </dx-scroll-view>
</dx-popup>
