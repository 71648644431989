import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../core/component/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from '../../app/core/services';

@Component({
  selector: 'app-email-verification-success',
  templateUrl: './email-verification-success.component.html',
  styleUrls: ['./email-verification-success.component.scss']
})
export class EmailVerificationSuccessComponent extends BaseComponent implements OnInit {

  email = '';
  success: boolean;

  currentUrl: string;

  constructor(
    private route: ActivatedRoute,
    public loadingService: LoadingService,
    private router: Router,

  ) {
    super();
  }

  goPortalHome() {
    this.loadingService.loading = true;
    this.router.navigate(['app', 'home']);
  }

  ngOnInit() {
    console.log(this.route.url.subscribe((url) => {
      this.currentUrl = (url.length > 0 ? url[0].path : '') || '';
    }));
    this.route.queryParams.subscribe(params => {
      this.email = params['email'];
      this.success = (params['success'] === 'true' || params['success'] === true || params['success'] === 1);
    });
  }

}
