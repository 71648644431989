import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormControlName, FormArray, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormLibraryService, UserProfileService, LoadingService, ResizingService, AppointmentsService } from '../core/services';
import { SupportService } from '../core/services';
import { FacilityInfo, UserProfile } from '../../app/core/services/models';
import { Appointment } from '../home/shared/models';
import ArrayStore from 'devextreme/data/array_store';
import { dateOrEmptyValidator } from '../shared/validators/date-or-empty.validator';
import * as formHelper from '../shared/helpers/form.helper';
import { alert } from 'devextreme/ui/dialog';
import { Md5 } from 'ts-md5';
// tslint:disable-next-line:import-barrels
import { TelehealthOption, TelehealthAnncFormModel } from '../../app/core/services/models/annc-form.model';
import * as moment from 'moment';
import { BaseComponent } from '../../app/core/component/base.component';
import { trackPageLoadingTime } from '../../app/shared/helpers/tracking.helper';


@Component({
  selector: 'app-advanced-notice-non-coverage-form',
  templateUrl: './advanced-notice-non-coverage-form.component.html',
  styleUrls: ['./advanced-notice-non-coverage-form.component.scss']
})
export class AdvancedNoticeNonCoverageFormComponent extends BaseComponent implements OnInit, OnDestroy {
  anncForm: FormGroup;
  facilitiesData: any;
  facilities: FacilityInfo[];
  userProfile: UserProfile;
  mrn: string;
  telehealthOptionIsValid: boolean;
  isTelehealthOptionSelected: boolean;
  telehealthOptions: Object;
  checkboxOptions: Array<TelehealthOption>;
  selectedPaymentOption: string;
  _validationOrder: Array<string>;
  _inThePast: boolean;
  startTime = 0;
  constructor(
    private router: Router,
    route: ActivatedRoute,
    private fb: FormBuilder,
    private formLibraryService: FormLibraryService,
    private userProfileService: UserProfileService,
    private loadingService: LoadingService,
    private supportService: SupportService,
    resizingService: ResizingService,
    private appointmentService: AppointmentsService,
  ) {
    super();
    this.startTime = Date.now();
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;

    FormControlName.prototype.ngOnChanges = function () {
      let result;
      if (arguments) {
        result = originFormControlNameNgOnChanges.apply(this, arguments);
      }
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };

    this.telehealthOptionIsValid = false;
  }

  ngOnInit() {
    this.loadingService.loading = true;
    this.userProfileService.getUserProfileHardCache().subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
      this.formLibraryService.getPatientMrn().subscribe((mrn: string) => {
        this.mrn = mrn
        const now = moment();
        const today = now.format('YYYY[-]MM[-]DD');
        const ahead = now.add(90, 'd').format('YYYY[-]MM[-]DD');
        this.appointmentService.getAppointmentsByDate(today, ahead).subscribe((appts: Array<Appointment>) => {
          const appointment = appts.find(appt => {
            return appt.isTeleHealth && moment(appt.startDateTime).format('YYYY[-]MM[-]DD') >= today;
          });
          if (appointment) {
            if (this.anncForm) {
              this.anncForm.get('dpDateOfService').setValue(moment(appointment.startDateTime).format('L'));
            }
          }
        },
          error => {
            this.loadingService.loading = false;
            throw (error);
          });
        this.checkboxOptions = this.getCheckboxOptions();
        this.createAnncForm();
      }, error => {
        this.loadingService.loading = false;
        throw (error);
      });
    }, error => {
      this.loadingService.loading = false;
      throw (error);
    });
  }

  getKey(obj): string {
    return Object.keys(obj)[0];
  }

  get paymentOptions(): FormArray {
    return this.anncForm.get('paymentOptions') as FormArray;
  }

  get isOneOptionSelected(): boolean {
    return this.telehealthOptionIsValid;
  }

  getCheckboxOptions() {
    return [
      {
        id: 'option1',
        selected: false
      },
      {
        id: 'option2',
        selected: false
      }
    ];
  }

  createAnncForm() {
    this.removeDirtyForm();
    this.supportService.getAllFacilitiesInfoForTreamentFacilitySelect().subscribe((facilities: FacilityInfo[]) => {
      this.loadingService.loading = false;
      const endTime = Date.now();
      const elspasedTime = (endTime-this.startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elspasedTime);
      this.facilities = facilities;
      this.facilitiesData = new ArrayStore({
        data: this.facilities,
        key: 'name'
      });

      //Form Group for a ANNC Form
      this.anncForm = this.fb.group({
        selectedFacility: [this.userProfile.primaryFacility, Validators.required],
        txtFirstName: [this.userProfile.firstName, Validators.required],
        txtLastName: [this.userProfile.lastName, Validators.required],
        txtInsuranceName: ['', Validators.required],
        txtMrn: [this.mrn, Validators.required],
        dpDateOfService: ['', Validators.compose([Validators.required, dateOrEmptyValidator])],
        paymentOptions: this.fb.array([]),
        txtSignature: ['', Validators.required],
        txtPatientRelation: [''],
        dpDateSigned: [moment().format('L'), Validators.compose([Validators.required, dateOrEmptyValidator])]
      });



      this.setTelehealthOptions(this.checkboxOptions);

      this._validationOrder = [
        "selectedFacility",
        "txtFirstName",
        "txtLastName",
        "txtMrn",
        "txtIdentificationNumber",
        "dpDateOfService",
        "paymentOptions",
        "txtPatientRelation",
        "dpDateSigned",
      ];
      setTimeout(() => {
        formHelper.markControlAndChildControlsAsDirty(this.anncForm);
      }, 1000)
    }, error => {
      this.loadingService.loading = false;
      throw (error);
    }
    );

  }

  private setTelehealthOptions(options: TelehealthOption[]) {
    this.telehealthOptions = new Object();

    const optionsFormGroups = options.map((option: TelehealthOption) =>
      this.getTelehealthOptionsFormGroup(option)
    );
    this.anncForm.setControl('paymentOptions', this.fb.array(optionsFormGroups));
  }


  getTelehealthOptionsFormGroup(telehealthOption: TelehealthOption) {
    const hash: string = Md5.hashStr(telehealthOption.id).toString();
    const paymentOption: TelehealthOption = {
      id: telehealthOption.id,
      selected: false
    };

    this.telehealthOptions[hash] = paymentOption;

    const option: any = new Object();
    option[hash] = false;

    return this.fb.group(option);
  }

  setPaymentOptionSelectionValidation() {
    const checkList = this.anncForm.get('paymentOptions') as FormArray;
    const noneSelected = checkList.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    if (noneSelected) {
      this.telehealthOptionIsValid = false;
    }
  }

  dateOfServiceValueChanged(callback: Function) {
    const serviceDateCtrl = this.anncForm.get('dpDateOfService') as FormControl;
    const today = moment();
    const serviceDate = new Date(this.anncForm.get('dpDateOfService').value);

    this._inThePast = moment(serviceDate).isBefore(moment(today));
    serviceDateCtrl.updateValueAndValidity();

    setTimeout(() => {
      if (callback) callback();
      this.markAsDirtyForm();
    }, 50);
  }

  signedDateDateBoxValueChanged() {
    this.anncForm.get('dpDateSigned').updateValueAndValidity();
    this.markAsDirtyForm();
  }

  optionValueChange(e) {
    const checkList = this.anncForm.get('paymentOptions') as FormArray;

    if (!this.selectedPaymentOption) {
      const noneSelected = checkList.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
      this.telehealthOptionIsValid = !noneSelected;
      this.selectedPaymentOption = e.element.id;
    } else {
      this.selectedPaymentOption = undefined;
      const chkBox = checkList.controls.find(opt => opt['controls'][this.getKey(opt['controls'])].nativeElement.id !== e.element.id) as FormGroup;
      const key = this.getKey(chkBox.controls);
      checkList.controls.forEach(opt => {
        if (this.getKey(opt['controls']) === key) {
          chkBox.controls[key].setValue(false);
        }
      });

      const noneSelected = checkList.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
      this.telehealthOptionIsValid = !noneSelected;
      this.selectedPaymentOption = e.element.id;
    }
    this.markAsDirtyForm();
  }

  scrollIntoViewInvalid(top: AbstractControl): boolean {

    if (top instanceof FormControl) {
      if (top.invalid) {
        (<any>top).nativeElement.scrollIntoView();
        return true;
      }
    } else if (top instanceof FormGroup) {
      for (const key of Object.keys(top.controls)) {
        const invalid = this.scrollIntoViewInvalid(top.controls[key]);
        if (invalid) return true;
      }
    } else if (top instanceof FormArray) {
      for (const control of top.controls) {
        const invalid = this.scrollIntoViewInvalid(control);
        if (invalid) return true;
      }
    }
    return false;
  }

  scrollIntoViewInOrder(): boolean {
    for (const key of this._validationOrder) {
      const invalid = this.scrollIntoViewInvalid(this.anncForm.controls[key]);
      if (invalid) return true;
    }
    return false;
  }

  onSubmitClick = () => {
    this.setPaymentOptionSelectionValidation();

    if (this.anncForm.invalid || !this.selectedPaymentOption) {
      formHelper.markControlAndChildControlsAsDirty(this.anncForm);

      this.scrollIntoViewInOrder();

      alert('Please provide the required information, then click Submit.', 'Message');

      return;
    }

    this.loadingService.loading = true;

    const formModel = this.anncForm.getRawValue();

    const anncFormModel: TelehealthAnncFormModel = {
      facilityName: formModel.selectedFacility,
      insuranceName: formModel.txtInsuranceName,
      patientName: `${formModel.txtFirstName} ${formModel.txtLastName}`,
      mrn: formModel.txtMrn,
      dateOfService: formModel.dpDateOfService,
      paymentOption: this.selectedPaymentOption,
      patientSignature: formModel.txtSignature,
      responsibleParty: formModel.txtPatientRelation,
      dateSigned: formModel.dpDateSigned
    };

    this.formLibraryService.submitAnncForm(anncFormModel).subscribe(
      response => {
        this.loadingService.loading = false;
        this.removeDirtyForm();
        alert('Your Advanced Notification of Non-Coverage form was submitted.', 'Information');
        this.close();
      },
      error => {
        this.loadingService.loading = false;
        throw (error);
      }
    );

  };

  markAsDirtyForm() {
    sessionStorage.setItem('dirtyForm', '1');
  }

  removeDirtyForm() {
    sessionStorage.removeItem('dirtyForm');
  }

  ngOnDestroy() {
    this.removeDirtyForm();
  }

  close = () => {
    this.router.navigate(['app', 'forms-library']);
  }

}