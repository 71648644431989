<div class="header">
  <div class="header-content">

    <div class="ctca-logo">
      <img src="assets/images/ctca-coh-logo.png?1" width="184" height="59" alt="CTCA Logo" />
    </div>

    <div class="myctca-logo">
      <img src="assets/images/logo.png?1" alt="MyCTCA" />
    </div>
    <div (click)="toggleMobileSideMenu()" [class]="sideMenuOpen?'toggle-mobile-side-menu-button side-menu-open':'toggle-mobile-side-menu-button'">
      <!--img [src]="sideMenuOpen?'assets/images/icons/SVG/icon-close-menu.svg':'assets/images/icons/SVG/icon-burger.svg'"/-->
      <div class="burger-menu-bar"></div>
      <div class="burger-menu-bar"></div>
      <div class="burger-menu-bar"></div>
    </div>
    <div *ngIf="badgeCount>0 && !sideMenuOpen" class="badge">{{badgeCount}}</div>
  </div>
</div>
<router-outlet name="headerOutlet"></router-outlet>
