import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertMessagePayload } from '../services/models';
import { AdminService } from '../services';

@Injectable()
export class ResolveAddAlertMessagePayload implements Resolve<AlertMessagePayload> {
  constructor(private adminService: AdminService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): AlertMessagePayload | Observable<AlertMessagePayload> | Promise<AlertMessagePayload> {
    const messageId = "";
     return this.adminService.getAlertMessage(messageId);
  }
}