import { NgModule } from '@angular/core';

import { AskQuestionMedicalRoutingModule } from './ask-question-medical.routing.module';
import { AskQuestionMedicalComponent } from './ask-question-medical.component';
import { DxButtonModule, DxTagBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidatorModule } from 'devextreme-angular';
import { SharedModule } from 'app/shared/shared.module';


@NgModule({
  imports: [
    AskQuestionMedicalRoutingModule,
    DxButtonModule,
    DxTextBoxModule,
    DxTagBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    SharedModule
  ],
  declarations: [AskQuestionMedicalComponent],
  exports: [AskQuestionMedicalComponent]
})
export class AskQuestionMedicalModule { }
