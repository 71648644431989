import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { Router } from '@angular/router';
import { SecureMailService } from '../../core/services';
import { custom } from 'devextreme/ui/dialog';
import { trackEvent } from '../../shared/helpers/tracking.helper';

@Component({
	selector: 'app-quick-links-card',
	templateUrl: './quick-links-card.component.html',
	styleUrls: ['./quick-links-card.component.scss']
})
export class QuickLinksCardComponent extends BaseComponent implements OnInit {
		
	primaryTreatmentFacility: string;
	hasCareTeam: boolean;

	constructor(private router: Router, private secureMailSvc: SecureMailService) {
		super();
	}

	ngOnInit() {
		return this.secureMailSvc.hasCareTeam().then((hasCareTeam) => {
			this.hasCareTeam = hasCareTeam;
		});
	}

	telehealthSetupGuide() {
		trackEvent('Quicklink - Telehealth setup guide', 'Quicklink selected', 'Telehealth setup guide');
		const ts = Date.now();
		const isMobile = window.innerWidth <= 640;
		const customDialog = custom({
			messageHtml: `
				<div class="dialogText noButtons">
					<img class="closeButton" onClick="document.getElementsByClassName('${ts}')[0].remove()"  src="assets/images/icons/SVG/close.svg" alt="Close dialog"/>
					<h1>About Telehealth</h1>
					Telehealth is a video visit with your provider and is offered for select patients and appointments in between hospital visits. 
					When you are scheduled for a telehealth visit, all you have to do is click on a link using your desktop computer, tablet, or smartphone and be instantly connected with your provider — all from the comfort of your home.<br/>
					<br/>
					<a href="https://www.cancercenter.com/telehealth-setup-guide" target="_blank">Learn more about our Telehealth Service ></a>
					<br/>
					<br/>
					<h1 style="margin-bottom:0px;">Telehealth Technical Support</h1><br/>
					<div>Call: <b>(800) 234-0482</b></div>
					<div style="margin-top:13px;">Email: <b>CTCATelehealthSupport@ctca-hope.com</b></div>
				</div>
				`,
			showTitle: false,
			popupOptions: {
				elementAttr: { class: (isMobile ? 'customAlertMobile' : 'customAlertDesktop') + ' ' + ts }
			},
			position: isMobile ? 'bottom' : 'center',
			buttons: [
				{
					elementAttr: { class: 'hiddenButton' }	
				}
			]
		} as any);
		customDialog.show();

	}

	requestAppointment() {
		trackEvent('Quicklink - Request appointment', 'Quicklink selected', 'Request appointment');
		this.router.navigate(['app', 'request-appointmentv2']);
	}

	sendMessage() {
		//trackEvent('Quicklink - Message my care team', 'Quicklink selected', 'Message my care team');
		trackEvent('Quicklink - Ask a Question', 'Quicklink selected', 'Ask a Question');
		//this.router.navigate(['app', 'send-message']);
		this.router.navigate(['app', 'ask-question']);
	}

	requestRenewal() {
		trackEvent('Quicklink - Renew prescription', 'Quicklink selected', 'Renew prescription');
		this.router.navigate(['app', 'prescriptions', 'request-renewal']);
	}
}
