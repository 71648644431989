<dx-popup [visible]="visible$ | async" title="Download Schedule" height="400" width="400" (onHidden)="navigateToParent()">

  <dxi-toolbar-item [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [disabled]="!canDownload()" [options]="{ text: 'Download PDF', type: 'default', onClick: onDownloadClick }">
  </dxi-toolbar-item>

  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view [useNative]="true">
      <form *ngIf="downloadForm" [formGroup]="downloadForm" novalidate>
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label"><label for="startDate">Start Date</label></div>
            <div class="dx-field-value">
              <dx-date-box  type="date" [inputAttr]="{ id: 'startDate' }" formControlName="startDate" placeholder="mm/dd/yyyy" [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" [isValid]="downloadForm.get('startDate').valid || downloadForm.get('startDate').pristine" (onFocusOut)="validateComponent('startDate')"></dx-date-box>
            </div>
          </div>
          <div class="dx-field">
              <div class="dx-field-label"><label for="endDate">End Date</label></div>
              <div class="dx-field-value">
                <dx-date-box  type="date" [inputAttr]="{ id: 'endDate' }" formControlName="endDate" placeholder="mm/dd/yyyy" [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" [isValid]="downloadForm.get('endDate').valid || downloadForm.get('endDate').pristine" (onFocusOut)="validateComponent('endDate')"></dx-date-box>
              </div>
          </div>
          <div *ngIf="wrongOrder()" class="dx-field">
              <div class="dx-field-label"></div>
              <div class="dx-field-value">
                  <span class="red">
                      Start Date can't be after End Date
                  </span>
              </div>
          </div>
          <div *ngIf="noAppointments" class="dx-field">
            <div class="dx-field-label"></div>
            <div class="dx-field-value">
                <span class="red">
                    There are no appointments for the selected range
                </span>
            </div>
          </div>
        </div>
      </form>
    </dx-scroll-view>
  </div>
</dx-popup>
