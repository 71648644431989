import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../core/component/base.component';
import { FormLibraryService } from '../core/services';
import { Router, NavigationEnd } from '@angular/router';
import { trackPageLoadingTime } from '../shared/helpers/tracking.helper';


@Component({
  selector: 'app-form-library',
  templateUrl: './form-library.component.html',
  styleUrls: ['./form-library.component.scss']
})
export class FormLibraryComponent extends BaseComponent implements OnInit {
  startTime = 0;
  constructor(private formLibraryService: FormLibraryService, private router: Router){
    super();
    //let's call the form check method
    //when this component loads to force the Annc check
    this.startTime = Date.now();
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd){
        if(event.url === '/app/forms-library'){
          this.anncFormExistsForDownload();
        }
      }
    });
  }

  anncExists: boolean;

  ngOnInit(){
    const endTime = Date.now();
    const elapsedTime = (endTime-this.startTime)/1000;
    trackPageLoadingTime(window.location.pathname,elapsedTime);
    if(!this.anncExists){
      this.anncFormExistsForDownload();
    }    
  }

  anncFormExistsForDownload(){
    this.formLibraryService.getAnncFormExists().subscribe((exists : boolean) => {
      this.anncExists = exists;
    });
  }
}


