<app-card label="Lab Results">
  <div card-buttons-desktop>
    <dx-button text="Consolidated Report" icon="fa fa-table" (click)="viewConsolidatedLabReport()"></dx-button>
  </div>
  <div card-buttons-mobile>
    <dx-button id="more-button" icon="fa fa-ellipsis-v" (onClick)="showMoreOptions($event)"></dx-button>
    <dx-context-menu #moreContextMenu [dataSource]="moreOptions" target="#more-button" [position]="{ at: 'bottom left' }" (onItemClick)="onMoreContextMenuItemClick($event)">
    </dx-context-menu>
  </div>

  <dx-data-grid id="lab-results-list-grid" [dataSource]="labResultsDataSource" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }"
    [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }" [wordWrapEnabled]="true"
    [rowAlternationEnabled]="true" [showColumnLines]="false" [searchPanel]="{ visible: true, placeholder: 'Search...' }"
    (onRowClick)="onLabResultSelected($event.data)">

    <dxi-column dataField="dateCollected" caption="Date" dataType="date" sortOrder="desc" width="100px" cellTemplate="dateCellTemplate"></dxi-column>
    <dxi-column dataField="labSets" caption="Lab Sets" [allowSorting]="false" [allowSearch]="true" cellTemplate="labSetsCellTemplate"
      [calculateFilterExpression]="labSetsFilterExpression"></dxi-column>
    <dxi-column dataField="collectedBy" caption="Collected By" hidingPriority="0" width="140px"></dxi-column>

    <div *dxTemplate="let labResults of 'dateCellTemplate'">
      <a (click)="onLabResultSelected(labResults.data)" class="dead-link">{{ labResults.data.dateCollected | date: 'shortDate' }}</a>
    </div>

    <div *dxTemplate="let labResults of 'labSetsCellTemplate'">
      <div *ngFor="let labSet of labResults.data.labSets" class="lab-set">
        {{ labSet.name }}
      </div>
    </div>
  </dx-data-grid>
</app-card>
<router-outlet></router-outlet>
