import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxChartModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxSelectBoxModule,
  DxDateBoxModule  
} from 'devextreme-angular';


import { AdminRoutingModule } from './admin-routing.module';
import { AlertMessagesComponent } from './alert-messages/alert-messages.component';
import { AlertMessagesPopupComponent } from './alert-messages-popup/alert-messages-popup.component';
import { DocumentsWhitelistComponent } from './documents-whitelist/documents-whitelist.component';
import { DocumentsWhitelistPopupComponent } from './documents-whitelist-popup/documents-whitelist-popup.component';
import { ActivityLogsComponent } from './activity-logs/activity-logs.component';
import { LoadNpiFilesComponent } from './load-npi-files/load-npi-files.component';


@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxChartModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxSelectBoxModule,
    DxDateBoxModule,
    AdminRoutingModule
  ],
  declarations: [
    AlertMessagesComponent,
    AlertMessagesPopupComponent,
    DocumentsWhitelistComponent,
    DocumentsWhitelistPopupComponent,
    ActivityLogsComponent,
    LoadNpiFilesComponent
  ]
})
export class AdminModule {}
