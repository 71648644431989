import { AbstractControl, FormControl, FormGroup, FormArray } from '@angular/forms';

export function markControlAndChildControlsAsDirty(control: AbstractControl) {
  if (control instanceof FormControl) {
    control.markAsDirty({ onlySelf: true });
  } else if (control instanceof FormGroup) {
    Object.keys(control.controls).forEach((field: string) => {
      const groupControl = control.get(field);
      markControlAndChildControlsAsDirty(groupControl);
    });
  } else if (control instanceof FormArray) {
    const controlAsFormArray = control as FormArray;
    controlAsFormArray.controls.forEach((arrayControl: AbstractControl) => markControlAndChildControlsAsDirty(arrayControl));
  }
}

export function getDataHash(control: AbstractControl) {
  if (control instanceof FormControl) {
    if(control.value != null && typeof control.value !== "undefined") return "\u00A9" + control.value.toString() + "\u00A9";
    else return "\u00A9" + "\u00A9";
  } else if (control instanceof FormGroup) {
    let hash = "";
    Object.keys(control.controls).forEach((field: string) => {
      const groupControl = control.get(field);
      hash = hash + getDataHash(groupControl);
    });
    return hash;
  } else if (control instanceof FormArray) {
    let hash = "";
    const controlAsFormArray = control as FormArray;
    controlAsFormArray.controls.forEach((arrayControl: AbstractControl) => {
      hash = hash + getDataHash(arrayControl)
    });
    return hash;
  }
}
