<div class="flexV">
    <h1 class="mainTitle">{{title}}</h1>
    <hr>
    <h2 class="subtitle">What is your question about?</h2>

    <app-tags [tags]="subjects" [selectedTag]="selectedSubject" (onSelectTag)="selectSubject($event)"></app-tags>

    <dx-text-area class="message" placeholder="Enter your question here..." *ngIf="selectedSubject?.hasQuestion"
    [height]="100" [inputAttr]="{ id: 'message' }" [(value)]="message" valueChangeEvent="keyup"></dx-text-area>

    <div class="buttons">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            [disabled]="!(selectedSubject && (!selectedSubject?.hasQuestion || message.length>0))"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>
</div>