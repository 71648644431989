import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileDownloadService } from '../../core/services';

import { Popup } from '../../core/popup/popup';
import { HtmlTransformerService, MedicalDocumentsService, LoadingService, ResizingService } from '../../core/services';
import { ImagingResult } from '../../core/services/models';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-imaging-detail-popup',
  templateUrl: './imaging-detail-popup.component.html',
  styleUrls: ['./imaging-detail-popup.component.scss']
})
export class ImagingDetailPopupComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('popup', { static: true }) popup : DxPopupComponent;

  constructor(
    router: Router,
    route: ActivatedRoute,
    private docSvc: MedicalDocumentsService,
    private downloadSvc: FileDownloadService,
    private transformer: HtmlTransformerService,
    private loadingService:LoadingService,
    resizingService: ResizingService
  ) {
    super(router, route, resizingService);
  }

  title: string;
  document: ImagingResult;

  ngOnInit() {
    this.document = this.route.snapshot.data.document;
    this.title = this.document.itemName;
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }

  loadContent(event) {
    const element = event.component.content().querySelector('#modalContent');
    element.innerHTML = '';
    this.transformer
      .load(this.document.notes)
      .bindToHashLinksToSpans(hash => {
        document.querySelector(`[name=${hash}]`).scrollIntoView();
      })
      .appendTo(element);
  }

  download = () => {
    this.loadingService.loading = true;
    this.docSvc
      .getImagingDocumentReport(this.document.id)
      .subscribe(doc => {
        this.downloadSvc.downloadPdf(doc, this.title);
        this.loadingService.loading = false;
      });
  };
}
