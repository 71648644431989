import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators'

import { UserProfileService } from '../services';
import { PermissionLevel } from './permission-levels.enum';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private profileSvc: UserProfileService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.doActivationCheck(route, state);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.doActivationCheck(route, state);
  }

  private doActivationCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const level = route.data.permissionLevel;
    if (!level) {
      // permission level not specified in data, log a warning and let the user through
      console.warn(
        `PermissionGuard executed for route '${route.url}' with path '${
          state.url
        }', but no permissionLevel was specified in the route data.`
      );
      return true;
    } else {
      if (level === PermissionLevel.NO_REQUIREMENTS) {
        // route has no permission requirements, let the user through
        return true;
      } else {
        // permission level was specified in data, check against user permissions
        return this.profileSvc.getUserProfileHardCache().pipe(
          map(profile => profile.userPermissions.indexOf(level) > -1),
          tap(authorized => {
            if (!authorized) this.router.navigateByUrl('/app/home');
          })
        );
      }
    }
  }
}
