import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../core/services';
import { BaseComponent } from '../core/component/base.component';
import {
	DxLoadIndicatorModule 
  } from 'devextreme-angular';
@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.scss']
})
export class LoginCallbackComponent extends BaseComponent implements  OnInit {
  constructor(private authService: AuthenticationService) {super();}
  
  @ViewChild('loadindicator', { static: true }) alertpopup: DxLoadIndicatorModule;

  ngOnInit(): void {
    localStorage.setItem('startTimeFromAuth',Date.now().toString());
    this.authService.authenticateCallback();
  }

}



