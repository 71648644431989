import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { ChangeProxyPopupComponent } from './change-proxy-popup/change-proxy-popup.component';
import { HomeComponent } from './home.component';
import { ProxiesGuard, ResolveCareTeamsGuard } from '../core/guards';
import { ChatBotModalComponent } from './chatbot-modal/chatbot-modal.component';
import { AlertModalComponent } from './alert-popup/alert-modal.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		resolve: { careteams: ResolveCareTeamsGuard },
		children: [
			{
				path: 'select-patient',
				canActivate: [ProxiesGuard],
				component: ChangeProxyPopupComponent,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'interactive-chatbot',
				component: ChatBotModalComponent,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			},
			{
				path: 'alerts',
				component: AlertModalComponent,
				data: { permissionLevel: PermissionLevel.NO_REQUIREMENTS }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class HomeRoutingModule {}
