<app-card label="Consolidated Report">
  
  <div card-buttons-desktop>
    <dx-button text="Lab Results Summary" icon="fa fa-th-list" (click)="goSummary()"></dx-button>
  </div>

  <div card-buttons-mobile>
    <dx-button id="more-button" icon="fa fa-ellipsis-v" (onClick)="showMoreOptions($event)"></dx-button>
    <dx-context-menu #moreContextMenu [dataSource]="moreOptions" target="#more-button" [position]="{ at: 'bottom left' }" (onItemClick)="onMoreContextMenuItemClick($event)">
    </dx-context-menu>
  </div>

  <dx-data-grid id="consolidatedLabReport" [dataSource]="labResults" [remoteOperations]="false" [allowColumnReordering]="true"
    [allowColumnResizing]="true" [showBorders]="true" [columnAutoWidth]="true" [masterDetail]="{enabled: true, template: 'notesTemplate'}">

    <dxo-export [enabled]="true" fileName="ConsolidatedLabReport"></dxo-export>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-scrolling [mode]="'standard'" [showScrollbar]="'always'" [useNative]="false"></dxo-scrolling>

    <dxi-column dataField="performedDateTime" [width]="100" dataType="date" caption="Date Performed"></dxi-column>
    <dxi-column dataField="name" caption="Lab Set"></dxi-column>
    <dxi-column dataField="itemName" caption="Specific Lab"></dxi-column>
    <dxi-column dataField="result" caption="Test Result" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="normalRange" caption="Normal Range" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="abnormalityCodeDescription" caption="Result Remarks" cellTemplate="abnormalityTemplate"></dxi-column>
    <dxi-column dataField="collectedBy" caption="Collected By"></dxi-column>

    <ng-container *dxTemplate="let row of 'abnormalityTemplate'">
      <div [class.abnormal-high]="row.data.abnormalityCodeCalculated === 'H'" [class.abnormal-normal]="row.data.abnormalityCodeCalculated === 'N'"
        [class.abnormal-low]="row.data.abnormalityCodeCalculated === 'L'" [class.critical-high]="row.data.abnormalityCode === 'HH'">
        {{row.data.abnormalityCodeDescription}}
      </div>
    </ng-container>

    <ng-container *dxTemplate="let row of 'notesTemplate'">
      <div *ngIf="row.data.notes; else noNotes" [innerHTML]="row.data.notes"></div>
    </ng-container>
  </dx-data-grid>
</app-card>

<ng-template #noNotes>
  No notes were entered for this result.
</ng-template>
