export const enum ServiceEndpoint {

	ADMIN_GETDOCUMENTSWHITELIST = 'admin/getdocumentswhitelist',
	ADMIN_DELETEDOCUMENTWHITELIST = 'admin/deletedocumentfromwhitelist',
	ADMIN_GETDOCUMENTWHITELIST = 'admin/getdocumentfromwhitelist',
	ADMIN_GETPARENTDOCUMENTTYPES = 'admin/getparentdocumenttypes',
	ADMIN_SAVEADDDOCUMENTWHITELIST = 'admin/savedocumentfromwhitelist',
	ADMIN_GETLOGS	= 'admin/getactivitylogs',
	ADMIN_LOADNEWNPIFILE = 'admin/loadnewnpifile',
	ADMIN_SPLITNEWNPIFILE = 'admin/splitnewnpifile',

	ADMIN_GETSYSTEMALERTMESSAGES = 'admin/getsystemalertmessages',
	ADMIN_GETSYSTEMALERTMESSAGE = 'admin/getsystemalertmessage',
	ADMIN_SAVEADDSYSTEMALERTMESSAGE = 'admin/savesystemalertmessage ',
	ADMIN_DELETESYSTEMALERTMESSAGE = 'admin/deletesystemalertmessage',

	APPOINTMENTS_GETAPPOINTMENTS = 'appointments/getappointments',
	APPOINTMENTS_GETAPPOINTMENT = 'appointments/getappointment',
	APPOINTMENTS_REQUESTAPPOINTMENT = 'appointments/requestnewappointment',
	APPOINTMENTS_CANCELAPPOINTMENT = 'appointments/cancelappointment',
	APPOINTMENTS_RESCHEDULEAPPOINTMENT = 'appointments/rescheduleappointment',
	APPOINTMENTS_GETAPPOINTMENTSREPORT = 'appointments/getappointmentsreport',
	APPOINTMENTS_CONFIRMAPPOINTMENT = 'appointments/confirmappointment',
	APPOINTMENTS_REQUESTAPPOINTMENTV2 = 'appointments/requestnewappointmentv2',
	APPOINTMENTS_CANCELAPPOINTMENTV2 = 'appointments/cancelappointmentv2',
	APPOINTMENTS_RESCHEDULEAPPOINTMENTV2 = 'appointments/rescheduleappointmentv2',
	APPOINTMENTS_SENDSCHEDULEBYEMAIL = 'appointments/sendschedulebyemail',
	APPOINTMENTS_SENDSCHEDULEBYMAIL = 'appointments/sendschedulebymail',

	CLAIMS_DOCUMENTS_LIST = 'claims/documents',
	//CLAIMS_DOCUMENT_DETAIL = 'claims/documents/multipledocuments',
	CLAIMS_SINGLE_DOCUMENT = 'claims/documents/getdocument',
	CLAIMS_MULTIPLE_DOCUMENTS = 'claims/documents/multipledocuments',

	CONTACTINFO_GETCONTACTINFO = 'contactinfo/getcontactinfo',
	CONTACTINFO_GETALTERNATECONTACTINFO = 'contactinfo/getalternatecontacts',
	CONTACTINFO_UPDATECONTACTINFO = 'contactinfo/updatecontactinfo',
	CONTACTINFO_ADDALTERNATECONTACTINFO = 'contactinfo/addalternatecontactinfo',
	CONTACTINFO_UPDATEALTERNATECONTACTINFO = 'contactinfo/updatealternatecontactinfo',
	CONTACTINFO_DELETEALTERNATECONTACTINFO = 'contactinfo/deletealternatecontactinfo',
	CONTACTINFO_GETPHONETYPES = 'contactinfo/getphonetypes',
	CONTACTINFO_GETRELATIONSHIPTYPES = 'contactinfo/getrelationshiptypes',
	CONTACTINFO_GETRELATIONSHIPS = 'contactinfo/getrelationships',

	DOCUMENTEXCHANGE_GETCCDADOCUMENTLIST = 'documentexchange/getccdadocumentlist',
	DOCUMENTEXCHANGE_GETCCDADOCUMENTDETAIL = 'documentexchange/getccdadocumentdetail',
	DOCUMENTEXCHANGE_GETLATESTCCDADOCUMENT = 'documentexchange/getlatestccdadocument',
	DOCUMENTEXCHANGE_DOWNLOADCCDADOCUMENT = 'documentexchange/downloadccdadocument',
	DOCUMENTEXCHANGE_TRANSMITCCDADOCUMENTDIRECTEMAIL = 'documentexchange/transmitccdadocumentdirectemail',
	DOCUMENTEXCHANGE_TRANSMITCCDADOCUMENTREGULAREMAIL = 'documentexchange/transmitccdadocumentregularemail',

	DOWNLOADS_GETROIFORM = 'downloads/getroiform',
	DOWNLOADS_GETROIPDF = 'downloads/getroipdf',
	DOWNLOADS_GETFINANCEDOCUMENTINFO = 'downloads/getfinancedocumentinfo',
	DOWNLOADS_SUBMITROIFORM = 'downloads/submitroiform',
	DOWNLOADS_SUBMITANNCFORM = 'downloads/submitanncform',
	DOWNLOADS_DOWNLOADANNCFORM = 'downloads/downloadanncform',
	DOWNLOADS_ANNCFORMEXISTS = 'downloads/anncformexists',	
	DOWNLOADS_GETMRN = 'downloads/getmrn',

	HEALTHHISTORY_GETALLERGIES = 'healthhistory/getallergies',
	HEALTHHISTORY_GETALLERGIESREPORT = 'healthhistory/getallergiesreport',
	HEALTHHISTORY_GETHEALTHISSUES = 'healthhistory/gethealthissues',
	HEALTHHISTORY_GETHEALTHISSUESREPORT = 'healthhistory/gethealthissuesreport',
	HEALTHHISTORY_GETIMMUNIZATIONS = 'healthhistory/getimmunizations',
	HEALTHHISTORY_GETIMMUNIZATIONSREPORT = 'healthhistory/getimmunizationsreport',
	HEALTHHISTORY_GETPRESCRIPTION = 'healthhistory/getprescription',
	HEALTHHISTORY_GETPRESCRIPTIONS = 'healthhistory/getprescriptions',
	HEALTHHISTORY_GETPRESCRIPTIONSREPORT = 'healthhistory/getprescriptionsreport',
	HEALTHHISTORY_GETVITALS = 'healthhistory/getvitals',
	HEALTHHISTORY_GETVITALSREPORT = 'healthhistory/getvitalsreport',
	HEALTHHISTORY_GETVITALSGRAPHITEMS = 'healthhistory/getvitalsgraphitems',
	HEALTHHISTORY_GETVITALSGRAPHDATA = 'healthhistory/getvitalsgraphdata',

	LABRESULTS_GETLABRESULTS = 'labresults/getlabresults',
	LABRESULTS_GETLABRESULTSFORDATE = 'labresults/getlabresultsfordate',
	LABRESULTS_GETLABRESULTSREPORT = 'labresults/getlabresultsreport',

	MEDICALDOCUMENTS_GETCAREPLAN = 'medicaldocuments/getcareplan',
	MEDICALDOCUMENTS_GETCAREPLANREPORT = 'medicaldocuments/getcareplanreport',
	MEDICALDOCUMENTS_GETMEDICALDOCUMENTS = 'medicaldocuments/getmedicaldocuments',
	MEDICALDOCUMENTS_GETMEDICALDOCUMENT = 'medicaldocuments/getmedicaldocument',
	MEDICALDOCUMENTS_GETMEDICALDOCUMENTREPORT = 'medicaldocuments/getmedicaldocumentreport',
	MEDICALDOCUMENTS_GETIMAGINGDOCUMENTS = 'medicaldocuments/getimagingdocuments',
	MEDICALDOCUMENTS_GETIMAGINGDOCUMENT = 'medicaldocuments/getimagingdocument',
	MEDICALDOCUMENTS_GETIMAGINGDOCUMENTREPORT = 'medicaldocuments/getimagingdocumentreport',
	MEDICALDOCUMENTS_GETPATIENTREPORTEDDOCUMENTS = 'medicaldocuments/getpatientreporteddocuments',
	MEDICALDOCUMENTS_GETSITPDF = 'medicaldocuments/getsitpdf',

	REPORTING_GETACCOUNTTOTALS = 'reporting/getaccounttotals',
	REPORTING_GETEVENTCOUNTS = 'reporting/geteventcounts',
	REPORTING_GETEVENTCOUNTSPIVOT = 'reporting/geteventcountspivot',
	REPORTING_GETREGISTRATIONBYSITE = 'reporting/getregistrationbysite',
	REPORTING_GETMULOCATIONVISITCOUNTS = 'reporting/getmulocationvisitcounts',
	REPORTING_GETMULOCATIONDISTINCTEVENTCOUNTS = 'reporting/getmulocationdistincteventcounts',
	REPORTING_GETMUEVENTCOUNTSBYPATIENT = 'reporting/getmueventcountsbypatient',

	SECUREMAIL_GETCARETEAMS = 'securemessages/getcareteams',
	SECUREMAIL_GETSECUREMESSAGES = 'securemessages/getsecuremessages',
	SECUREMAIL_GETSECUREMESSAGE = 'securemessages/getsecuremessage',
	SECUREMAIL_GETSECUREMESSAGEREPORT = 'securemessages/getsecuremessagereport',
	SECUREMAIL_SETSECUREMESSAGEREAD = 'securemessages/setsecuremessageread',
	SECUREMAIL_SENDSECUREMESSAGE = 'securemessages/sendsecuremessage',
	SECUREMAIL_ARCHIVESECUREMESSAGE = 'securemessages/archivesecuremessage',
	SECUREMAIL_UNARCHIVESECUREMESSAGE = 'securemessages/unarchivesecuremessage',
	SECUREMAIL_SENDPRESCRIPTIONRENEWALREQUEST = 'securemessages/sendprescriptionrenewalrequest',

	SUPPORT_GETEXTERNALLINKS = 'support/getexternallinks',
	SUPPORT_GETUSERGUIDE = 'support/getuserguide',
	SUPPORT_GETHIPAANOTICE = 'support/gethipaanotice',
	SUPPORT_GETCOMPLIANCECERTIFICATE = 'support/getcompliancecertificate',
	SUPPORT_GETFACILITYINFO = 'support/getfacilityinfo',
	SUPPORT_GETFACILITYINFOALL = 'support/getfacilityinfoall',
	SUPPORT_GETFEEDBACKAREASOFCONCERN = 'support/getfeedbackareasofconcern',
	SUPPORT_SENDFEEDBACK = 'support/sendfeedback',
	SUPPORT_LOGCLIENTERROR = 'support/logclienterror',
	SUPPORT_GETACTIVESYSTEMALERTMESSAGES ='support/getactivesystemalertmessages',
	SUPPORT_GETREGISTRATIONGUIDE = 'support/getregistrationguide',
	SUPPORT_GETBADGESCOUNT = 'support/getbadgescount',
	SUPPORT_GETCONTACTUSQUESTIONS = 'support/getcontactusquestions',
	SUPPORT_SEND_FEEDBACK_FORM_DATA = 'support/sendfeedbackformdata',
	SUPPORT_LOGPORTALEVENT = 'support/logportalevent',

	USERPROFILE_GETUSERPROFILE = 'userprofile/getuserprofile',
	USERPROFILE_CANPATIENTBEIMPERSONATEDBY = 'userprofile/canpatientbeimpersonatedby',
	USERPROFILE_GETACTIVITYLOGS = 'userprofile/getuseractivitylogs',
	USERPROFILE_GETUSERNOTIFICATIONS = 'userprofile/getusernotifications',
	USERPROFILE_SAVEUSERNOTIFICATIONS = 'userprofile/saveusernotifications',
	USERPROFILE_GETUSERPREFERENCES = 'userprofile/getuserpreferences',
	USERPROFILE_SAVEUSERPREFERENCES = 'userprofile/saveuserpreferences',
	USERPROFILE_GETSITSURVEY = 'userprofile/getusersitlookupinfo'
}

export const anonymousEndpoints: string[] = [
	ServiceEndpoint.APPOINTMENTS_CONFIRMAPPOINTMENT
];