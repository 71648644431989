import { Component, OnInit } from '@angular/core';

import { FileDownloadService, FormLibraryService } from '../../core/services';
import { FinanceDocumentInfo } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';

@Component({
  selector: 'app-form-library-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent extends BaseComponent implements OnInit {
  financeDocumentInfo: FinanceDocumentInfo;
  constructor(
    private formLibraryService: FormLibraryService,
    private downloadSvc: FileDownloadService
  ) {
    super();
  }

  ngOnInit() {
    this.formLibraryService
      .getFinanceDocumentInfo()
      .subscribe((financeDocumentInfo: FinanceDocumentInfo) => {
        this.financeDocumentInfo = financeDocumentInfo;
      });
  }

  onDownLoadDocumentLinkClick = download => {
    this.formLibraryService
      .downloadFinancialDocumentAsPDF(download)
      .subscribe(data => this.downloadSvc.downloadPdf(data, download.title));
  };
}
