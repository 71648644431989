import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'uppercase'
})
export class UppercasePipe implements PipeTransform {
    transform(input?: string): string {
        if (input) {
            return input
                .toLowerCase()
                .split(' ')
                .map((word: string) => word[0].toUpperCase() + word.substr(1))
                .join(' ');
        } else {
            return '';
        }
    }
}
