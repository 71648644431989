<div class="app-container">
  <div class="layout-container">
    <div class="header">
      <img class="ctca-logo" src="assets/images/logo.png?1" width="159" height="50" alt="CTCA Logo"/>
    </div>

    <div class="content">
      <img class="icon" src="assets/images/icons/SVG/outage-icon.svg" alt="Outage"/>
      <h1>{{outage.title}}</h1>
      <p>{{outage.text}}</p>
    </div>
    <div class="cta-header">{{outage.ctaHeader}}</div>
    <div *ngIf="outage.phoneNumber" class="cta-button" (click)="call()">
      <img src="/assets/images/icons/SVG/phone-white.svg" width="15" height="22" alt="Phone icon"/>
      {{outage.phoneNumberFormatted}}
    </div>
    <div class="cta-footer">
      {{outage.footerText}}<br/>
      <br/>
      <a *ngIf="outage.footerLinkUrl" [href]="outage.footerLinkUrl" target="_blank">{{outage.footerLinkText}}</a>
    </div>
  </div>
</div>