import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { SymptomInventoryComponent } from './symptom-inventory/symptom-inventory.component';
import { SitDataGraphsComponent } from './sit-data-graphs/sit-data-graphs.component';
import { SITSurveyComponent } from './sit-survey/sit-survey.component';


const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'sit-survey',
				component: SITSurveyComponent,
				data: { permissionLevel: PermissionLevel.VIEW_PATIENT_REPORTED_DOCUMENTS }
			},
			{
				path: 'symptom-inventory',
				component:SymptomInventoryComponent,
				data: { permissionLevel: PermissionLevel.VIEW_PATIENT_REPORTED_DOCUMENTS }
			},
			{
				path: 'sit-data-graphs',
				component:SitDataGraphsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_PATIENT_REPORTED_DOCUMENTS }
			},
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class PatientReportedRoutingModule {}
