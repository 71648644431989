import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../app/core/services';

@Component({
  selector: 'app-terms-of-use-rejected',
  templateUrl: './terms-of-use-rejected.component.html',
  styleUrls: ['./terms-of-use-rejected.component.scss']
})
export class TermsOfUseRejectedComponent implements OnInit {

  constructor(private auth:AuthenticationService) { }

  ngOnInit() {
  }

  backToMyAccount() {
		this.auth.revertAuthenticatedProxy();
	}

}
