import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { custom } from 'devextreme/ui/dialog';
import { Auth0Client, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { environment } from '../../../environments/environment';
import * as jwt_decode from 'jwt-decode';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { ActiveToken, UserToken, UserProfile, Proxy } from './models';
import { UserProfileAvoidCacheService } from './user-profile-avoid-cache.service';
import { IdentityEventService } from './identity-event.service';
import { IdentityLogEvent } from './models';
import { IdentityEvent } from './identity-event.enum';

export interface AuthenticationCallbackResult {
	success: boolean;
	error_description?: string;
}
@Injectable()
export class AuthenticationService {

	public get activeToken(): number {
		if (this.primary_token) return ActiveToken.Primary;
		else if (this.proxy_token) return ActiveToken.Proxy;
		else return ActiveToken.None;
	}

	public get active_token(): UserToken {
		let active_token = null;
		switch (this.activeToken) {
			case ActiveToken.Primary:
				active_token = this.primary_token;
				break;
			case ActiveToken.Proxy:
				active_token = this.proxy_token;
				break;
			default:
				break;
		}
		return active_token;
	}

	public getAuthSignature(): string {
		if (this.active_token) {
			return `${this.active_token.token_type} ${this.active_token.access_token}`;
		} else {
			return null;
		}
	}

	public get primary_token() {
		return JSON.parse(localStorage.getItem('primary_token'));
	}

	public set primary_token(token: UserToken) {
		localStorage.setItem('primary_token', JSON.stringify(token));
	}

	public get proxy_token() {
		return JSON.parse(localStorage.getItem('proxy_token'));
	}

	public set proxy_token(token: UserToken) {
		localStorage.setItem('proxy_token', JSON.stringify(token));
	}

	public isTokenValid(): boolean {
		if (this.active_token && this.active_token.id_token) {
			try {
				const decodedIdToken = jwt_decode<{ exp: number }>(this.active_token.id_token);
				const threshold = 110; // 10 seconds after the session renewal alert
				if (decodedIdToken.exp - threshold > Date.now() / 1000) {
					return true;
				}
			} catch (e) {
				return false;
			}
		}
		return false;
	}


	private timerID = null;
	private _activeTokenChanged = new Subject();
	private eventTask: IdentityLogEvent;
	public activeTokenChanged$ = this._activeTokenChanged.asObservable();
	public lastActivityDate: Date;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private profileService: UserProfileAvoidCacheService,
		private identityEventService: IdentityEventService
	) { }

	private auth0 = new Auth0Client({
		domain: environment.auth0.domain,
		client_id: environment.auth0.client_id,
		redirect_uri: environment.auth0.redirect_uri,
		audience: environment.auth0.audience,
		cacheLocation: 'localstorage',
		useRefreshTokens: true,
	});

	async authenticatePrimary() {
		await this.auth0.loginWithRedirect({
			scope: environment.auth0.scope
		});
	}

	async authenticateProxy(id: string) {
        const auth0Token = await this.auth0.getTokenSilently({
            detailedResponse: true,
            impersonate: id,
			ignoreCache: true,
        });
        localStorage.removeItem('primary_token');
        localStorage.removeItem('proxy_token');
        localStorage.setItem('impersonate', id);
		const token = this.transformToken(auth0Token);
        this.proxy_token = token;

		const decodedAccessToken = jwt_decode<{ exp: number }>(this.proxy_token.access_token);

		this.eventTask = new IdentityLogEvent();
		this.eventTask.eventTask = IdentityEvent.START_IMPERSONATION;
		this.eventTask.userName = decodedAccessToken['sub'];

		this.identityEventService.logEvent(this.eventTask);
			
		await this.router.navigateByUrl('app/home/alerts');
		window.location.reload();
		

    }

	private transformToken(token: GetTokenSilentlyVerboseResponse, appState: string = '') {
        return {
            ...token,
            token_type: 'Bearer',
            state: appState,
            scope: token.scope || environment.auth0.scope,
            session_state: appState,
        }
    }

	async renewProxy() {
		this.authenticatePrimary();
	}

	async revertAuthenticatedProxy() {
        const auth0Token = await this.auth0.getTokenSilently({
            detailedResponse: true,
			ignoreCache: true,
        });

		const impersonatedId = localStorage.getItem('impersonate');

        localStorage.removeItem('primary_token');
        localStorage.removeItem('proxy_token');
        localStorage.removeItem('impersonate');
		const token = this.transformToken(auth0Token);
        this.primary_token = token;

		const decodedAccessToken = jwt_decode<{ exp: number }>(this.primary_token.access_token);
		
		this.eventTask = new IdentityLogEvent();
		this.eventTask.eventTask = IdentityEvent.END_IMPERSONATION;
		this.eventTask.userName = decodedAccessToken['sub'];
		this.eventTask.originalValue = impersonatedId;

		this.identityEventService.logEvent(this.eventTask);

		await this.router.navigateByUrl('app/home/alerts');
		window.location.reload();
	}

	createAuthenticateUrl() {
		const settings = environment.oidc;
		let authUrl = `${settings.authority}/connect/authorize`;
		authUrl += `?client_id=${encodeURIComponent(settings.client_id)}`;
		authUrl += `&redirect_uri=${encodeURIComponent(settings.redirect_uri)}`;
		authUrl += `&response_type=${encodeURIComponent(settings.response_type)}`;
		authUrl += `&scope=${encodeURIComponent(settings.scope)}`;
		authUrl += `&state=primary`;
		authUrl += `&nonce=${this.nonce(32)}`;

		return authUrl;
	}

	redirectIfInLocalStore() {
		if(localStorage.getItem('redirectAfterAuth')) {
			this.redirectAfterLogin();
		}
	}

	redirectAfterLogin() {
		const redirect = localStorage.getItem('redirectAfterAuth');
		localStorage.removeItem('redirectAfterAuth');
		this.router.navigateByUrl(redirect || 'app/home/alerts');	
	}

	/**
	 * it will result in a AuthenticationCallbackResult
	 */
	async newAuthenticateCallback(): Promise<AuthenticationCallbackResult> {
		try {
			const redirectResult = await this.auth0.handleRedirectCallback();
			//logged in. you can get the user profile like this:
			const user = await this.auth0.getUser();
			console.log(user);
			const auth0Token = await this.auth0.getTokenSilently({
				detailedResponse: true
			});
			this.primary_token = this.transformToken(auth0Token, redirectResult.appState);
			const decodedAccessToken = jwt_decode<{ exp: number }>(this.primary_token.access_token);

			this.eventTask = new IdentityLogEvent();
			this.eventTask.eventTask = IdentityEvent.LOGIN;
			this.eventTask.userName = decodedAccessToken['sub'];			

			this.identityEventService.logEvent(this.eventTask);
			this._activeTokenChanged.next();
			this.redirectAfterLogin();
			return {
				success: true
			}
		} catch(error) {
			return {
				success: false,
				error_description: error.message || 'some error when try to login'
			}
		}
	}

	authenticateCallback() {
		if (this.route.fragment["value"]) {

			this.route.fragment
				.pipe(
					map(fragment => fragment.split('&')),
					map(parts => parts.map(part => part.split('='))),
					map(pairs =>
						pairs.reduce((obj, pair) => {
							obj[pair[0]] = pair[1];
							return obj;
						}, {})
					),
					first() // complete the observable immediately
				)
				.subscribe((token: UserToken) => {


					localStorage.removeItem('primary_token');
					localStorage.removeItem('proxy_token');

					if (token && token.state) {

						switch (token.state) {
							case 'primary':
								this.primary_token = token;
								this._activeTokenChanged.next();
								break;
							case 'proxy':
								this.proxy_token = token;
								this._activeTokenChanged.next();
								break;
						}

						if (this.primary_token || this.proxy_token) {
							this.profileService.getUserProfile().subscribe((profile: UserProfile) => {


								let hasActiveProxy = false;
								let activeProxy: Proxy = null;
								const allProxies: Array<Proxy> = profile.proxies;

								if (allProxies && allProxies.length > 0) {
									activeProxy =
										allProxies.filter(
											proxy => proxy.isImpersonating && proxy.relationshipType !== 'Self'
										)[0] || null;
									hasActiveProxy = (activeProxy != null);
								} else {
									hasActiveProxy = false;
								}

								if (hasActiveProxy && token.state === 'primary') {
									this.renewProxy();
								} else if (!hasActiveProxy && token.state === 'proxy') {
									this.authenticatePrimary();
								} else {
									this.handleTimeout();

									const redirect = localStorage.getItem('redirectAfterAuth');
									this.router.navigateByUrl(redirect || 'app/home/alerts');

								}
							});
						}
					}

				});
		}
	}

	logout() {
		const token = (this.primary_token?.access_token) ?? this.proxy_token?.access_token;
		const decodedAccessToken = jwt_decode<{ exp: number }>(token);

		const logoutEvent = new IdentityLogEvent();
		logoutEvent.eventTask = IdentityEvent.LOGOFF;
		logoutEvent.userName = decodedAccessToken['sub'];
		this.identityEventService.logEvent(logoutEvent);

		localStorage.removeItem('primary_token');
		localStorage.removeItem('proxy_token');
		this.auth0.logout({
			federated: true,
			returnTo: environment.auth0.post_logout_redirect_uri
		});
	}

	private handleTimeout() {
		if (this.timerID) clearTimeout(this.timerID);
		if (this.active_token && this.active_token.expires_in && this.activeToken !== ActiveToken.None) {
			this.timerID = setTimeout(() => {
				const allowSessionTimeoutPopupOnUrls = [
					'/app/send-message',
					'/app/advanced-notice-of-non-coverage',
					'/app/release-of-information',
					'/app/cancel-appointment',
					'/app/cancel-appointmentv2',
					'/app/reschedule-appointment',
					'/app/reschedule-appointmentv2',
					'/app/prescriptions/request-renewal',
					'/app/request-appointment',
					'/app/request-appointmentv2',
					'/app/contact-us/contact-technical-support',
					'/app/ask-question'
				];
				let allowSessionTimeoutPopup = false;
				for (const url of allowSessionTimeoutPopupOnUrls) {
					if (this.router.url.startsWith(url)) {
						allowSessionTimeoutPopup = true;
						break;
					}
				}
				if (allowSessionTimeoutPopup) {
					const isMobile = window.innerWidth <= 640;
					const sessionRenewalDialog = custom({
						messageHtml: '<div class="dialogText dialogTextBold">Your session is about to expire.<br/>Do you wish to continue?</div>',
						showTitle: false,
						popupOptions: {
							elementAttr: { class: isMobile ? 'customAlertMobile' : 'customAlertDesktop' }
						},
						position: isMobile ? 'bottom' : 'center',
						buttons: [
							{
								text: "Log Out",
								elementAttr: { class: 'greenDialogButton' },
								onClick: () => {
									this.logout();
								}
							},
							{
								text: "Continue Session",
								elementAttr: { class: 'greenDialogButton' },
								onClick: () => {
									localStorage.setItem('redirectAfterAuth', this.router.routerState.snapshot.url);
									if (this.activeToken === ActiveToken.Primary || this.activeToken === ActiveToken.None) {
										this.authenticatePrimary();
									} else if (this.activeToken === ActiveToken.Proxy) {
										this.renewProxy();
									}
								}
							}
						]
					} as any);
					sessionRenewalDialog.show();
				}
			}, (this.active_token.expires_in - 120) * 1000);
		}
	}

	private nonce(length: number) {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < length; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}
}
