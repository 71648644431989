<app-card label="Alert Messages">
  
    <div card-buttons-desktop>
      <dx-button text="Add Message" *appShowFor="PermissionLevel.MANAGE_SYSTEM_ALERT_MESSAGES" icon="fa fa-plus" (onClick)="addMessagePopUp()"></dx-button>
    </div>
    <div card-buttons-mobile>
      <dx-button text="Add Message" *appShowFor="PermissionLevel.MANAGE_SYSTEM_ALERT_MESSAGES" icon="fa fa-plus" (onClick)="addMessagePopUp()"></dx-button>
    </div>
  
    <dx-data-grid [dataSource]="messages" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }"
      [wordWrapEnabled]="false" [columnAutoWidth]="false" [rowAlternationEnabled]="true" [showColumnLines]="false" [searchPanel]="{ visible: true, placeholder: 'Search...' }"
      (onRowClick)="onMessageSelected($event.data)">
  
      <dxi-column [width]="180" dataField="controllerName" caption="Controller"></dxi-column>
      <dxi-column [width]="120" dataField="id" *appShowFor="PermissionLevel.MANAGE_SYSTEM_ALERT_MESSAGES" caption="Action" cellTemplate="actionTemplate"></dxi-column>
      <div *dxTemplate="let action of 'actionTemplate'">
        <dx-button text="Remove" class="small" (onClick)="deleteMessageConfirmation($event,action.data)"></dx-button>
      </div>
      <dxi-column [width]="80" dataField="displayOrder" caption="Order"></dxi-column>
      <dxi-column [width]="300" dataField="messageText" caption="Message" cellTemplate="messageTemplate"></dxi-column>
      <div *dxTemplate="let message of 'messageTemplate'" class="dotdotdot">
          <a (click)="onMessageSelected(message.data)" class="dead-link">{{ message.data.messageText }}</a>
      </div>
      <dxi-column dataField="startDate" dataType="date" caption="Start Date"></dxi-column>
      <dxi-column dataField="endDate" dataType="date" caption="End Date"></dxi-column>
  
    </dx-data-grid>
  </app-card>
  <router-outlet></router-outlet>