<app-card label="Vitals">
  <div card-buttons-desktop>
    <dx-button text="Download my Vitals" icon="fa fa-download" [disabled]="!canDownload()" (onClick)="download()"></dx-button>
  </div>
  <div card-buttons-mobile>
    <dx-button text="Download my Vitals" icon="fa fa-download" [disabled]="!canDownload()" (onClick)="download()"></dx-button>
  </div>
  <dx-data-grid [dataSource]="vitals" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }"
    [wordWrapEnabled]="true" [rowAlternationEnabled]="true" [showColumnLines]="false" [searchPanel]="{ visible: true, placeholder: 'Search...' }">

    <dxi-column dataField="enteredDate" caption="Date Entered " dataType="date" sortOrder="desc" format="MMMM d yyyy" [allowSorting]="false" [allowSearch]="false"
      [groupIndex]="0"></dxi-column>
    <dxi-column dataField="observationItem" caption="Observation Item" [allowSorting]="false" [allowSearch]="true"></dxi-column>
    <dxi-column dataField="value" caption="Value" [allowSorting]="false" [allowSearch]="false" hidingPriority="0" width="140px"></dxi-column>
    <dxi-column dataField="observationItem" caption="" [allowSorting]="false" [allowSearch]="false" cellTemplate="graph" width="60px"></dxi-column>

    <div *dxTemplate="let item of 'graph'">
      <i class="fa fa-line-chart" [routerLink]="[item.value]"></i>
    </div>
  </dx-data-grid>
</app-card>
<router-outlet></router-outlet>
