import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { ActiveAccountsComponent } from './active-accounts/active-accounts.component';
import { EventCountsComponent } from './event-counts/event-counts.component';
import { RegistrationsBySiteComponent } from './registrations-by-site/registrations-by-site.component';
import { LocationVisitsComponent } from './location-visits/location-visits.component';
import { LocationEventsComponent } from './location-events/location-events.component';
import { EventCountsPivotComponent } from './event-counts-pivot/event-counts-pivot.component';
import { EventsByPatientComponent } from './events-by-patient/events-by-patient.component';

const routes: Routes = [
	{
		path: '',
		children: [			
			{
				path: 'active-accounts',
				component:ActiveAccountsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			},
			{
				path: 'event-counts',
				component:EventCountsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			},
			{
				path: 'event-counts-pivot',
				component:EventCountsPivotComponent,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			},
			{
				path: 'registrations-by-site',
				component:RegistrationsBySiteComponent,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			},
			{
				path: 'location-visits',
				component:LocationVisitsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			},
			{
				path: 'location-events',
				component:LocationEventsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			},
			{
				path: 'events-by-patient',
				component:EventsByPatientComponent,
				data: { permissionLevel: PermissionLevel.VIEW_REPORTING }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReportingRoutingModule {}
