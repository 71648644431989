<ng-container *ngIf="userProfile">
  <div class="app-container" smartlook-safe>
    <div class="layout-container">
      <div class="header">
        <app-page-header data-recording-sensitive [sideMenuOpen]="isMobileSideMenuVisible" (onToggleMobileSideMenuClick)="onMobileSideMenuToggleVisibility()"></app-page-header>
      </div>
      <div class="body">
        <div [class]="'sidebar'+(showContactOverlay?' contact-overlay-open':'')" smartlook-safe>
          <app-nav-menu [showContactOverlay]="showContactOverlay" (showContactOverlayChange)="showContactOverlayChanged($event)" smartlook-safe></app-nav-menu>
        </div>
        <div class="content-area" id="content-area">
          <div *ngIf="isImpersonating" class="proxy-switcher">
            <img class="proxy-icon" src="/assets/images/icons/SVG/icon-user-pic.svg" alt="User icon"/>
            <div class="proxy-text">You are viewing <b>{{proxyName}}</b> Account</div>
            <div class="proxy-switch-button" (click)="switchAccount()">
              <img class="proxy-switch-icon" src="/assets/images/icons/SVG/icon-switch.svg" alt="Switch account"/>
              <span class="proxy-switch-button-text">Switch Account</span>
            </div>  
          </div>
          <h1 class="greeting" aria-hidden="true">{{greeting}}</h1>
          <div class="content">
            <router-outlet></router-outlet>
            <app-page-footer></app-page-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-mobile-side-menu class="mobile-side-menu sidebar" [isVisible]="isMobileSideMenuVisible" (onClose)="onMobileSideMenuClose()" [showContactOverlay]="showContactOverlay" (showContactOverlayChange)="showContactOverlayChanged($event)" smartlook-safe></app-mobile-side-menu>
</ng-container>
<dx-load-panel    
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: Window }"
    [(visible)]="loadingService.loading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>
