import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ServiceEndpoint } from './service-endpoint.enum';

@Injectable()
export class ErrorNotificationService extends BaseService {

	notify(message:string):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.SUPPORT_LOGCLIENTERROR,
			body: {payload:message}
		});
	}
	
}