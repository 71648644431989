import { Component } from '@angular/core';
import { trackEvent } from '../shared/helpers/tracking.helper';
import { environment } from '../../environments/environment';
import { BaseComponent } from '../core/component/base.component';

@Component({
  selector: 'app-help',
  templateUrl: './bill-pay.component.html',
  styleUrls: ['./bill-pay.component.scss']
})
export class BillPayComponent extends BaseComponent {
  openBillPay() {
    trackEvent("Bill Pay - Pay Your Bill Online");
    window.open(environment.billPayUrl, '_blank');
  }
}
