import { Component, OnDestroy, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DxPopupComponent } from 'devextreme-angular';
import { LoadingService, ResizingService } from '../../core/services';
import { Popup } from '../../core/popup/popup';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chatbot-modal',
  templateUrl: './chatbot-modal.component.html',
  styleUrls: ['./chatbot-modal.component.scss']
})
export class ChatBotModalComponent extends Popup implements OnInit, AfterViewInit, OnDestroy { 
  
  @ViewChild('chatbotiframe', { static: true }) chatbotiframe: ElementRef;
  @ViewChild('chatbotpopup', { static: true }) chatbotpopup: DxPopupComponent;


  constructor(
    private loadingService:LoadingService,
    router: Router,
		route: ActivatedRoute,
    resizingService: ResizingService
  ) {
    super(router, route, resizingService);
    
  }

  ngOnInit() {    
    this.loadingService.loading = true;
    this.loadChatBot();
  }    

  loadChatBot(){
    this.chatbotiframe.nativeElement.setAttribute('src', environment.chatBotUrl);
  }

  ngAfterViewInit(){    
    super.init(this.chatbotpopup);
    this.loadingService.loading = false;
  }

  ngOnDestroy() {    
    super.destroy();   
  }

}
