import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { FileDownloadService, SecureMailService, LoadingService, UserProfileService, ResizingService, NavigationService, ContactInfoService } from '../../core/services';
import { FacilityInfo, PatientContactInfo, SecureMailMessage, UserProfile } from '../../core/services/models';
import { ActivatedRoute, Router } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DxContextMenuComponent, DxPopupComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

export enum MoreOption {
  Archive,
  Restore,
  Download
}

@Component({
  selector: 'app-view-message-modal',
  templateUrl: './view-message-modal.component.html',
  styleUrls: ['./view-message-modal.component.scss']
})
export class ViewMessageModalComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
  threadClasses: any = { 0: true };
  completeData = true;
  comments: SafeHtml;
  message: SecureMailMessage;
  messages: {
    message: SafeHtml;
    date: string;
    from: string;
  }[];
  canSendMessages = false;
  isMobile = false;
  mobileSubscription:Subscription;
  navigationSubscription:Subscription;
  content: HTMLElement = null;
  selectedTo: string[] = [];
  patientName = '';
  profile: UserProfile;
  hasCareTeam: boolean;
  facilityInfo: FacilityInfo;
  prettyPhone: string;
  cleanPhone: string;
  patientContactInfo: PatientContactInfo;

  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

  @ViewChildren('moreContextMenu') moreContextMenus: QueryList<DxContextMenuComponent>;
  moreContextMenu: DxContextMenuComponent;

  moreOptions = [

  ];

  constructor(router: Router, 
              route: ActivatedRoute, 
              private secureMailSvc: SecureMailService,
              private downloadSvc: FileDownloadService,
              private loadingService:LoadingService,
              private userProfileService:UserProfileService,
              private sanitizer: DomSanitizer,
              private contactInfoService: ContactInfoService,
              resizingService:ResizingService,
              private navigationService: NavigationService) {
    super(router, route, resizingService);
    
    this.message = null;

    this.secureMailSvc.hasCareTeam().then((hasCareTeam) => {
      this.hasCareTeam = hasCareTeam;
    });

    this.navigationSubscription = this.navigationService.RouteChanged.subscribe(() => {
      if(!this.navigationSubscription) {
        const id = this.route.snapshot.params['id'];

        this.userProfileService.getUserProfileHardCache().subscribe((profile:UserProfile) => {
          if (!this.patientName) {
            this.patientName = profile.lastName + ', ' + profile.firstName;
          }
          this.profile = profile;
          this.loadingService.loading = true;
          const startTime = Date.now();
          if (profile && profile.userPermissions && profile.userPermissions.length > 0 && profile.userPermissions.indexOf(this.PermissionLevel.SEND_SECURE_MESSAGES) > -1) {
            this.canSendMessages = true;
          } else {
            this.canSendMessages = false;
          }

          if (profile && profile.userPermissions && profile.userPermissions.length > 0 && profile.userPermissions.indexOf(this.PermissionLevel.VIEW_MEDICAL_CONTACTS) > -1) {
            this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
              this.patientName = patientContactInfo.lastName + ', ' + patientContactInfo.firstName;
              this.patientContactInfo = patientContactInfo;
            });
          }

          this.secureMailSvc.getSecureMessage(id).subscribe((m: SecureMailMessage) => {
            this.comments = this.sanitizer.bypassSecurityTrustHtml(m.comments);
            this.message = m;
            this.selectedTo = m.selectedTo.map((to => to.name));

            if (this.message.threadMessages && this.message.threadMessages.length > 0) {
              this.messages = this.message.threadMessages.map(tm => {
                if (!tm.from) {
                  this.completeData = false;
                }
                const date = moment(tm.date);
                return {
                  date: moment().isSame(date, 'day') ? date.format("h:mm a") : date.format("MMM D"),
                  from: tm.from,
                  message: this.sanitizer.bypassSecurityTrustHtml(tm.body)
                }
              });
            } else {
              this.completeData = false;
            }

            this.mobileSubscription = this.resizingService.IsMobile().subscribe((result: boolean) => {
              this.isMobile = result;
              this.loadingService.loading = false;
              const endTime = Date.now();
              const elapsedTime = (endTime-startTime)/1000;
              trackPageLoadingTime('/app/secure-mail/inbox/:id',elapsedTime);
            });

            this.setMoreOptions();
          });
        });
      }
    });     
    
  }  

  ngOnInit() {      
    this.loadingService.loading = true;

    this.facilityInfo = this.route.snapshot.data.facilityInfo;
    this.prettyPhone = this.facilityInfo.careManagementPhone;
    this.cleanPhone = this.prettyPhone ? '+1' + this.prettyPhone.replace(/[^0-9]/g, '') : '';
  }

  ngAfterViewInit(){
    super.init(this.popup);
    this.content = document.getElementById('message-content');
    if(this.content){
      this.content.parentElement.classList.add('secure-message-popup-content');
    }

    this.moreContextMenus.changes.subscribe((comps: QueryList<DxContextMenuComponent>) => {
      this.moreContextMenu = comps.first;
    });
  }

  ngOnDestroy() {
    if (this.mobileSubscription) {
      this.mobileSubscription.unsubscribe();
    }
    if(this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }    
  }

  shouldArchive():boolean {
    return this.message && this.message.folderName !== "Deleted Items" && this.canSendMessages && !this.message.isReadOnly;
  }

  shouldRestore():boolean {
    return this.message && this.message.folderName === "Deleted Items" && this.canSendMessages && !this.message.isReadOnly;
  }

  onArchiveClick = () => {
    this.loadingService.loading = true;
    this.secureMailSvc.archiveSecureMessage(this.message.mailMessageId).subscribe(() => {
      this.loadingService.loading = false;
      this.close();
    });
  };

  onUnArchiveClick = () => {
    this.loadingService.loading = true;
    this.secureMailSvc.unArchiveSecureMessage(this.message.mailMessageId).subscribe(() => {
      this.loadingService.loading = false;
      this.close();
    });
  };

  onDownloadAsPDFClicked = () => {
    this.loadingService.loading = true;
    this.secureMailSvc.getSecureMessageReport(this.message.mailMessageId).subscribe((data:Blob) => {
      this.loadingService.loading = false;
        this.downloadSvc.downloadPdf(data,`Secure Message - ${this.message.sent}`);
    });
  };

  onReplyClick = () => {
    if(this.canSendMessages) {
      localStorage.setItem('sendReplyStartTime',Date.now().toString());
      this.router.navigate(['app','reply-message',this.message.mailMessageId]);
    }
  };

  threadClass = (i) => {
    let classes = 'thread ';
    if (this.threadClasses[i]) {
      classes += 'expanded ';
    } else {
      classes += 'collapsed ';
    }
    const message = this.messages[i];
    if (message && !message.from) {
      classes += 'headerless ';
    }
    return classes;
  }

  toggleThreadClass = (i) => {
    if (this.threadClasses[i]) {
      this.threadClasses[i] = false;
    } else {
      this.threadClasses[i] = true;
    }
  }

  isSelf = (sender: string) => {
    const senderNames = sender.toLowerCase().split(', ');
    if (senderNames.length > 1) {
      const first = senderNames[1].split(' ')[0];
      const last = senderNames[0].split(' ')[0];
      let patientFirst = this.profile.firstName;
      let patientLast = this.profile.lastName;

      if (this.patientContactInfo) {
        patientFirst = this.patientContactInfo.firstName;
        patientLast = this.patientContactInfo.lastName;
      } else if (this.profile.proxies && this.profile.proxies.length > 0) {
        const activeProxy = this.profile.proxies.filter(p => p.isImpersonating && p.relationshipType !== 'Self')[0];
        if (activeProxy) {
          patientFirst = activeProxy.firstName;
          patientLast = activeProxy.lastName;
        }
      }
      patientFirst = (patientFirst || '').toLowerCase().split(' ')[0];
      patientLast = (patientLast || '').toLowerCase().split(' ')[0];
      return first === patientFirst && last === patientLast;
    }
    return false;
  }

  initialsFromName = (name: string) => {
    const nameParts = name.trim().split(' ');
    let initials = '';
    if (nameParts.length > 0 && nameParts[0].length > 0) {
      initials = nameParts[0][0];
    }

    if (nameParts.length > 1 && nameParts[nameParts.length - 1].length > 0) {
      initials += nameParts[nameParts.length - 1][0];
    }
    if (initials.length === 0) {
      return '-';
    } else {
      return initials.toUpperCase();
    }
  }

  setMoreOptions = () => {
    this.moreOptions = [
      {
        id: MoreOption.Download,
        text: 'Download',
        icon: 'ctca-download'
      }
    ];

    if (this.shouldArchive()) {
      this.moreOptions.push({
        id: MoreOption.Archive,
        text: 'Archive',
        icon: 'ctca-archive-black',
      });
    }

    if (this.shouldRestore()) {
      this.moreOptions.push({
        id: MoreOption.Restore,
        text: 'Restore',
        icon: 'fa fa-undo'
      });
    }
  }

  showMoreOptions = () => {
    this.moreContextMenu.instance.show();
  }

  onMoreContextMenuItemClick(event: any) {
    if (event && event.itemData) {
      if (event.itemData.id === MoreOption.Download) {
        this.onDownloadAsPDFClicked();
      } else if (event.itemData.id === MoreOption.Archive) {
        this.onArchiveClick();
      } else if (event.itemData.id === MoreOption.Restore) {
        this.onUnArchiveClick();
      }
    }
  }
}
