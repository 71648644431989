import { Component, OnInit, ViewChild } from '@angular/core';
import { HealthHistoryService, FileDownloadService, LoadingService } from '../../core/services';
import { Allergy } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { HttpParams } from '@angular/common/http';
import { DxDataGridComponent } from 'devextreme-angular';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-allergies',
  templateUrl: './allergies.component.html',
  styleUrls: ['./allergies.component.scss']
})
export class AllergiesComponent extends BaseComponent implements  OnInit {
  constructor(private hhSvc: HealthHistoryService, 
              private downloadSvc: FileDownloadService,
              private loadingService:LoadingService) {super();}

  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  data: Allergy[];
  params: HttpParams;
  columnIndex: number;
  sortOrder = '';
  sortColumn = '';
  ngOnInit() {
    const startTime = Date.now();
    this.loadingService.loading = true;
    this.hhSvc.getAllergies().subscribe((data:Array<Allergy>) => {
      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elapsedTime);
      this.data = data;
    });
  }

  sortEvent(event: any){
    if(event.element){
      const pos = event.fullName.indexOf('[');
      this.columnIndex = parseInt(event.fullName.slice(pos + 1, pos + 2), 10);      
      if(this.dataGrid.instance.columnOption(this.columnIndex)){
        this.sortOrder = event.value;
        this.sortColumn = this.dataGrid.instance.columnOption(this.columnIndex).dataField;
      }
    }    
  }


  download = () => {
    this.loadingService.loading = true;
    const parameters = new HttpParams()
      .set('sortOrder', this.sortOrder)
      .set('sortColumn', this.sortColumn)
      .set('searchValue', this.dataGrid.searchPanel.text);
    this.hhSvc
      .getAllergiesReport(parameters)
      .subscribe((report:Blob) => {
        this.loadingService.loading = false;
        this.downloadSvc.downloadPdf(report, 'allergies');
    });
  };

  canDownload = () => {
    return this.data && this.data.length > 0;
  }
}
