import { AbstractControl, ValidationErrors } from '@angular/forms';
import * as moment from 'moment';

export function dateOrEmptyValidator(control: AbstractControl): ValidationErrors | null {
    
    if((<any>control).nativeElement) {

        let value = "";
        let type = "";

        try {

            value = (<any>control).nativeElement.children.item(0).children.item(1).children.item(0).value;
            type = (<any>control).nativeElement.children.item(0).children.item(1).children.item(0).type;

        } catch(error) {

            console.error('dxDateBox' + error.message);

            return {
                dateOrEmptyValidator:false
            }
            
        }

        if(value && type) {

            if(type === 'date' && (!moment(value, "YYYY[-]MM[-]DD", true).isValid() || moment(value, "YYYY[-]MM[-]DD", true).year() <= 0))
            {
                return {
                    dateOrEmptyValidator:false
                }
            }
            else if(type === 'text' && (!moment(value, "MM[/]DD[/]YYYY", true).isValid() || moment(value, "MM[/]DD[/]YYYY", true).year() <= 0)) {

                return {
                    dateOrEmptyValidator:false
                }

            } else {

                return null;

            }
            
        } else {

            return null;

        }

    } else {

        return null;
    }

}