import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { BaseComponent } from '../../core/component/base.component';

@Component({
    selector: 'app-frequently-asked-questions',
    templateUrl: './frequently-asked-questions.component.html',
    styleUrls: ['./frequently-asked-questions.component.scss']
})
export class FrequentlyAskedQuestionsComponent extends BaseComponent implements  OnInit {
  constructor(private router: Router) { super();}
    ngOnInit(): void {
        
    }

    onContactUsLinkClick() {
        this.router.navigate(['app','contact-us','contact-technical-support']);
    }
}