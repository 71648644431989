import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { custom } from "devextreme/ui/dialog";
import { Observable } from "rxjs";
import { BaseComponent } from "../component/base.component";

@Injectable()
export class UnsavedChangesGuard implements CanDeactivate<BaseComponent> {
    constructor(private router: Router) { }

    canDeactivate(
        component: BaseComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let showAlert = false;
        if (sessionStorage.getItem('dirtyForm')) {
            showAlert = true;
        }
        if (showAlert) {
            const isMobile = window.innerWidth <= 640;
            const customDialog = custom({
                messageHtml: '<div class="dialogText dialogTextBold">You have unsubmitted changes that will be lost.<br/>Do you wish to leave this page?</div>',
                showTitle: false,
                popupOptions: {
                    elementAttr: { class: isMobile ? 'customAlertMobile' : 'customAlertDesktop' }
                },
                position: isMobile ? 'bottom' : 'center',
                buttons: [
                    {
                        text: "Stay on this Page",
                        elementAttr: { class: 'greenDialogButton' },
                        onClick: () => {
                            // Do nothing
                        }
                    },
                    {
                        text: "Leave this Page",
                        elementAttr: { class: 'greenDialogButton' },
                        onClick: () => {
                            sessionStorage.removeItem('dirtyForm');
                            this.router.navigateByUrl(nextState.url);
                        }
                    }
                ]
            } as any);
            customDialog.show();
        }
        return !showAlert;
    }
}