import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { LoadingService, UserPreferencesService, AuthenticationService, ResizingService } from '../../core/services';
import { UserPreference } from 'app/core/services/models';
import { FormGroup, FormControl } from '@angular/forms';
import { alert } from 'devextreme/ui/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { trackPageLoadingTime } from '../../../app/shared/helpers/tracking.helper';

declare var window:any;

@Component({
  selector: 'app-terms-of-use-agreement',
  templateUrl: './terms-of-use-agreement.component.html',
  styleUrls: ['./terms-of-use-agreement.component.scss']
})
export class TermsOfUseAgreementComponent extends BaseComponent implements OnInit, OnDestroy {
  
  allPreferences:Array<UserPreference>;
  static ACCEPTEDTERMSOFUSE = "AcceptedTermsOfUse";
  termsOfUseForm:FormGroup;
  termsOfUseOption = "True";
  termsOfUseAgreed = false;
  initialPos = 0;
  header:HTMLElement = null;
  top = 0;
  mobileSubscription:Subscription;
  tabletSubscription:Subscription;
  desktopSubscription:Subscription;

  radioGroupItems = [
    { text: "I accept the Terms of Use", option: "True" },
    { text: "I decline the Terms of Use", option: "False" }    
  ];

  constructor(private loadingService:LoadingService, private userPreferencesService:UserPreferencesService, 
    private router:Router, private authService:AuthenticationService, private resizingService:ResizingService
    ) { 
    super();
  }

  

  ngOnInit() {    

    this.termsOfUseForm = new FormGroup({
      termsOfUse : new FormControl('False')          
    });
    const startTime = Date.now();
    this.loadingService.loading = true;

    this.userPreferencesService.getUserPreferences().subscribe((preferences:Array<UserPreference>) => {

      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elapsedTime);
      this.allPreferences = preferences;
      const termsOfUse: UserPreference = this.allPreferences.find(p => p.userPreferenceType === TermsOfUseAgreementComponent.ACCEPTEDTERMSOFUSE);
      this.termsOfUseOption = termsOfUse ? termsOfUse.userPreferenceValue : "False";
      this.termsOfUseAgreed = this.termsOfUseOption === "True";

      setTimeout(() => {

        this.header = document.getElementById('floating-header');   
        
        if(this.header)
        {  
          this.initialPos = this.header.offsetTop;
          
          window.addEventListener('scroll',this.onWindowScroll, true);
  
          this.mobileSubscription = this.resizingService.IsMobile().subscribe((isMobile:boolean) => {
            
            if (isMobile) {
              this.top = 87;
              this.initialPos = 217;
  
              const e: any = { name: 'scroll' };
              e.target = { scrollTop: this.header.parentElement.parentElement.parentElement.parentElement.parentElement.scrollTop };
              this.onWindowScroll(e);
            } 
            
          });
  
          this.tabletSubscription = this.resizingService.IsTablet().subscribe((isTablet:boolean) => {
            
            if (isTablet) {
              this.top = 87;
              this.initialPos = 217;
  
              const e: any = { name: 'scroll' };
              e.target = { scrollTop: this.header.parentElement.parentElement.parentElement.parentElement.parentElement.scrollTop };
              this.onWindowScroll(e);           
            }
            
          });
  
          this.desktopSubscription = this.resizingService.IsDesktop().subscribe((isDesktop:boolean) => {
            
            if (isDesktop) {
  
              this.top = 0;
  
              const e: any = { name: 'scroll' };
              e.target = { scrollTop: this.header.parentElement.parentElement.parentElement.parentElement.parentElement.scrollTop };
              this.onWindowScroll(e);             
            }
            
          });      
          
        }
  
      },500);

    });    
   
  }
  
  
  ngOnDestroy() {  
      if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
      if (this.tabletSubscription)  this.tabletSubscription.unsubscribe();
      if (this.desktopSubscription) this.desktopSubscription.unsubscribe();  
      window.removeEventListener('scroll',this.onWindowScroll, true);      
  }

  

  update() {

    if (this.header) {

      const margin: number = parseInt(window.getComputedStyle(this.header.parentNode, null).getPropertyValue("padding-left"), 10);

      if(this.header.classList.contains("sticky")) {

        const rect: ClientRect = this.header.parentElement.getBoundingClientRect();
        this.header.style.width = rect.width + "px";
        this.header.style.marginTop = "0px";
        this.header.style.top = this.top + "px";
        this.header.childNodes[0]["style"]["paddingLeft"] = "15px";
        this.header.childNodes[0]["style"]["paddingRight"] = "15px";

      } else {

        this.header.style.width = "";
        this.header.style.marginTop = -1 * margin + "px";        
        this.header.style.top = "";       
        this.header.childNodes[0]["style"]["paddingLeft"] = "";
        this.header.childNodes[0]["style"]["paddingRight"] = ""; 

      }

      
      this.header.style.marginLeft = -1 * margin + "px";
      this.header.style.marginRight = -1 * margin + "px"; 

    }
  }


  onWindowScroll = (e:any) => {
    
    if(this.header) {

      const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || e.target["scrollTop"] || 0;
    
      if(number > this.initialPos - this.top) {
        this.header.classList.add("sticky"); 
        this.update();   
      } else {
        this.header.classList.remove("sticky");
        this.update();
      }

    }   
  }
    
  submitTermsOfUse () {      

    const index = this.allPreferences.findIndex(p => p.userPreferenceType === TermsOfUseAgreementComponent.ACCEPTEDTERMSOFUSE);

      if(index >= 0) {

        this.loadingService.loading = true;
        const value: string = this.termsOfUseForm.get('termsOfUse').value.trim();
        this.allPreferences[index].userPreferenceValue = value;
        
        this.userPreferencesService.saveUserPreferences(this.allPreferences).subscribe((result:string) => {
          
          this.loadingService.loading = false;

          if(result === 'Success') {

            if(value === "True") {

              this.router.navigateByUrl('/app/home/alerts');

            } else {

              this.authService.logout();

            }
            
          } else {

            alert("An unexpected error occurred while saving your preferences, If the error persist please contact technical support for assistance","Information");

          }

        }, error => {

          this.loadingService.loading = false;
          throw(error);

        });

      }

    
  }
  

}
