<app-card label="Active Accounts">    
  
    <dx-data-grid [dataSource]="accounts" [hoverStateEnabled]="true" [wordWrapEnabled]="false" [columnAutoWidth]="false" [rowAlternationEnabled]="true" [showColumnLines]="false">
  
      <dxi-column [width]="300" dataField="accountType" caption="Account Type"></dxi-column>
      <dxi-column dataField="count" caption="Count"></dxi-column>      
  
    </dx-data-grid>

</app-card>
<router-outlet></router-outlet>
