import 'nodelist-foreach-polyfill';

// -------------------------------------------------------------
// - Html Transformer Fluent API
// - Add functions to this class to extend the Html Transformer.
// - All functions should modify the private "element" variable.
// - All Functions MUST return "this" to support chaining.
// -------------------------------------------------------------
export class HtmlTransformerImpl {
  private element: HTMLElement;
  constructor(html: string) {
    this.element = document.createElement('div');
    this.element.innerHTML = html;
  }

  bindToHashLinks(callback: (hash: string) => any): HtmlTransformerImpl {
    const links = this.element.querySelectorAll('a');
    links.forEach(link => {
      const hash = link.hash;
      if (hash) {        
        link.setAttribute('class', 'dead-link');
        link.addEventListener('click', callback.bind(this, decodeURI(hash.slice(1))));
      }
    });

    return this;
  }

  bindToHashLinksToSpans(callback: (hash: string) => any): HtmlTransformerImpl {
    const links = this.element.querySelectorAll('a');
    links.forEach(link => {
      const hash = link.hash;
      if (hash) {        
        const span = document.createElement('span');
        span.innerText = link.innerText;
        span.setAttribute('class', 'dead-link');
        span.addEventListener('click', callback.bind(this, decodeURI(hash.slice(1))));

        link.replaceWith(span);
      }
    });

    return this;
  }

  appendTo(domElement: Element): void {
    domElement.appendChild(this.element);
  }
}
