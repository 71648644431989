<div class="flexV">
    <h1 class="mainTitle">Medical Question</h1>
    <hr>
    <div class="flexH">
        <div class="subtext">If this is a medical emergency, please dial 911.</div>
    </div>


    <div *ngIf="careteamsExist">
        <div class="dx-fieldset">
            <div class="dx-fieldset-header">Send to</div>
            <div class="dx-field-value-static">
                <dx-tag-box
                [dataSource]="careteams"
                [showSelectionControls]="true"
                displayExpr="name"
                valueExpr="systemId"
                [value]="replyCareTeamsSelected"
                (onValueChanged)="itemSelectionChanged($event)"
                class="recipients-dropdown"
                placeholder="Select Recipient"
                [inputAttr]="{title:'Recipients'}"
                [elementAttr]="{title:'Recipients','aria-label':'Recipients'}"
                >
                </dx-tag-box>
              </div>
        </div>

        <div class="dx-fieldset">
            <div class="dx-fieldset-header">Subject</div>            
            <div class="dx-field">
                <dx-text-box class="dx-field-value" 
                    [inputAttr]="{ id: 'subject', title:'Subject' }" [(value)]="subject" (change)="saveSubject()">
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="Subject is required."
                        >
                        </dxi-validation-rule>
                      </dx-validator>
                </dx-text-box>
            </div>
        </div>

        <div class="dx-fieldset">
            <div class="dx-fieldset-header">Message</div>
            <div class="dx-field">
                <dx-text-area class="dx-field-value" 
                    [height]="200" [inputAttr]="{ id: 'message', title: 'Message' }" [(value)]="text" (change)="saveMessage()">
                    <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            message="Message is required."
                        >
                        </dxi-validation-rule>
                      </dx-validator>
                </dx-text-area>
            </div>
        </div>

    </div>

    <div class="buttons">
        <dx-button 
            text="Cancel" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            icon="fa fa-envelope"
            text="Send"
            type="success"
            *ngIf="careteamsExist"
            (onClick)="onSendClick($event)"
        ></dx-button>
    </div>
</div>