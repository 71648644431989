import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import { DxPopupComponent } from 'devextreme-angular';
import { ResizingService } from '../../../app/core/services';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends Popup implements OnInit, AfterViewInit, OnDestroy  {
  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
  constructor(router:Router, route:ActivatedRoute, resizingService: ResizingService) {
    super(router, route, resizingService);
   }

  ngOnInit() {    
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }

  navigateToParent(levels = 1) {
    this.close(); 
  }

  goHome = () => {
    this.close();   
    this.router.navigate(['app','home'],{relativeTo:this.route.parent});
  };

}
