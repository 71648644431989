<div class="container" (click)="onClose.emit()">
    <div class="popup" (click)="onPopupClick($event)">
        <div class="popupContent">
            <img *ngIf="showCloseButton" class="closeButton" (click)="onClose.emit()"  src="assets/images/icons/SVG/close.svg" alt="Close alert"/>
            <img class="checkmark" src="assets/images/icons/SVG/checkmark-green.svg" alt="Checkmark"/>
            <h1>{{title}}</h1>
            <p *ngIf="description">{{description}}</p>
            <app-button [text]="buttonText" (onClick)="onClose.emit()"></app-button>
        </div>
    </div>
</div>