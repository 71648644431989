<app-card label="Documents Whitelist">
  
  <div card-buttons-desktop>
    <dx-button text="Add Document" *appShowFor="PermissionLevel.MANAGE_DOCUMENTS_WHITELIST" icon="fa fa-plus" (onClick)="addDocumentPopUp()"></dx-button>
  </div>
  <div card-buttons-mobile>
    <dx-button text="Add Document" *appShowFor="PermissionLevel.MANAGE_DOCUMENTS_WHITELIST" icon="fa fa-plus" (onClick)="addDocumentPopUp()"></dx-button>
  </div>

  <dx-data-grid [dataSource]="documents" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }"
    [wordWrapEnabled]="false" [columnAutoWidth]="false" [rowAlternationEnabled]="true" [showColumnLines]="false" [searchPanel]="{ visible: true, placeholder: 'Search...' }"
    (onRowClick)="onDocumentSelected($event.data)">

    <dxi-column [width]="300" dataField="name" caption="Document Name" cellTemplate="documentTemplate"></dxi-column>
    <div *dxTemplate="let message of 'documentTemplate'" class="dotdotdot">
      <a (click)="onDocumentSelected(message.data)" class="dead-link">{{ message.data.name }}</a>
    </div>
    <dxi-column [width]="120" dataField="typeId" *appShowFor="PermissionLevel.MANAGE_DOCUMENTS_WHITELIST" caption="Action" cellTemplate="actionTemplate"></dxi-column>
    <div *dxTemplate="let action of 'actionTemplate'">
      <dx-button text="Remove" class="small" (onClick)="deleteDocumentConfirmation($event,action.data)"></dx-button>
    </div>
    <dxi-column [width]="300" dataField="parentDocumentTypeName" caption="Document Type"></dxi-column>    
    <dxi-column dataField="active" caption="Active"></dxi-column>

  </dx-data-grid>
</app-card>
<router-outlet></router-outlet>
