<dx-popup #popup [visible]="visible$ | async" (onHidden)="navigateToParent()" [title]="title"  height="350" width="510">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
  </dxi-toolbar-item>
  <dxi-toolbar-item *appShowFor="PermissionLevel.MANAGE_DOCUMENTS_WHITELIST" toolbar="bottom" location="after" widget="dxButton"
    [options]="{ text: actionButtonText, type: 'default', onClick: action }">
  </dxi-toolbar-item>  
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view>
      <div id="modalContent">
          <form *ngIf="documentForm" [formGroup]="documentForm" novalidate>
            <div class="dx-fieldset">
                <div class="dx-field">
                    <div class="dx-field-label">Document Name</div>
                    <div class="dx-field-value">
                        <dx-text-box #documentName [ngClass]="{'opacity':documentName.disabled}" formControlName="name" [isValid]="documentForm.get('name').valid"></dx-text-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Document Type</div>
                    <div class="dx-field-value">
                        <dx-select-box #documentType [ngClass]="{'opacity':documentType.disabled}" formControlName="parentDocumentTypeName" [dataSource]="documentTypes"
                        [isValid]="documentForm.get('parentDocumentTypeName').valid" valueExpr="id" displayExpr="name"></dx-select-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div class="dx-field-label">Active</div>
                    <div class="dx-field-value">
                        <dx-check-box #documentActive [ngClass]="{'opacity':documentActive.disabled}" style="float:left;" formControlName="active"></dx-check-box>
                    </div>
                </div>                       
            </div>
          </form>
      </div>
    </dx-scroll-view>
  </div>
</dx-popup>
<router-outlet></router-outlet>
