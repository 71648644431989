import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HealthHistoryService } from '../services';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class ResolveVitalsChartDataGuard implements Resolve<any> {
  constructor(private healthHistorySvc: HealthHistoryService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    const seriesName = route.params['seriesName'];
    return this.healthHistorySvc.getVitalsGraphData(seriesName).pipe(
      map(data =>
        data
          .sort((a, b) => {
            const aDate = new Date(a.enteredDate);
            const bDate = new Date(b.enteredDate);
            if (aDate < bDate) return -1;
            if (aDate === bDate) return 0;
            if (aDate > bDate) return 1;
          })
          .map(d => ({
            ...d,
            enteredDate: moment(d.enteredDate).format('MMM D YYYY')
          }))
      )
    );
  }
}
