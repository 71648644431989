import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { Prescription } from '../services/models';
import { HealthHistoryService } from '../services';

@Injectable()
export class ResolvePrescriptionGuard implements Resolve<Prescription> {
  constructor(private hhSvc: HealthHistoryService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Prescription | Observable<Prescription> | Promise<Prescription> {
    const id = route.params['id'];
    return this.hhSvc.getPrescription(id);
  }
}
