// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//local portalServiceBaseUrl: 'http://localportalservice.ctca.local/api/v1/'
//dev portalServiceBaseUrl: 'https://v3devservice.myctca.com/api/v1/'
//this is a comment so we can test the environment.outageMessage issue in prod
//deployment

export const environment = {
	version: '<local>',
	cacheLogging: true,
	production: false,
	portalServiceBaseUrl: 'https://v3devservice.myctca.com/api/v1/',
	billPayUrl: 'https://www.patientportal.me/CTCA/Patient/login.html',
	ctcaIdentityUrl: 'https://local.accounts.myctca.com/user/detail',
	securityTab: 'userdetails-securityprivacy',
	profileTab: 'userdetails-details',
	medicalProfileTab: 'userdetails-medicalprofile',	
	labResultsReviewWarning:"24",
	appointmentActionDisclaimer:"24",
	outageMessage:"",
	gaId: "UA-23384102-2",//2 is dev
	smartlookKey: "",
	launchDarklyKey: "612f7f263b6ee125f8a37212",
	chatBotUrl: 'http://ctca-hc-covidassessmentbot-dev.azurewebsites.net/',
	sitSurveyUrl: 'https://dev.portal.myctca.com/myProsk/SITInterface/BypassLogin.aspx',
	datadogClientToken: 'pub9b57b7280265fa6e9d1a918bb914cebe',
	environment: 'local',
	oidc: {
		authority: 'https://local.accounts.myctca.com',
		client_id: 'CTCA.Portal.UI.Web',
		redirect_uri: 'http://localhost:4200/auth',
		post_logout_redirect_uri: 'http://localhost:4200/',
		silent_redirect_uri: 'http://localhost:4200/silent-renew',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
		automaticSilentRenew: false,
		accessTokenExpiringNotificationTime: 2,
		monitorSession: true,
		loadUserInfo: true,
		revokeAccessTokenOnSignout: false,
		authenticationLoggingEnabled: true
	},
	auth0: {
		domain: 'ctca-dev.us.auth0.com',
		audience: 'http://localidentityservice.ctca.local/api',
		client_id: '2bvvjmHgUmnr4zRGlensoIVx1bp7FOFM',
		redirect_uri: 'http://localhost:4200/callback',
		post_logout_redirect_uri: 'http://localhost:4200/',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
	}
};
