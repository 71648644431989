import { ErrorHandler,  NgModule } from '@angular/core';
import { ErrorComponent } from './error/error.component';
import { UnstableConnectionErrorComponent } from './unstable-connection-error/unstable-connection-error.component';
import { ErrorsHandler } from './errors-handler';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ErrorNotificationService } from '../core/services';

import {
    DxButtonModule,   
    DxPopupModule,
    DxScrollViewModule,
    DxTextAreaModule    
  } from 'devextreme-angular';
import { OutageComponent } from './outage/outage.component';

@NgModule({
    imports: 
    [CommonModule,
    RouterModule,
    BrowserModule,
    SharedModule,
    DxButtonModule,   
    DxPopupModule,
    DxScrollViewModule,
    DxTextAreaModule],
  declarations: [ErrorComponent, UnstableConnectionErrorComponent, OutageComponent],
    providers: [
      {
        provide: ErrorHandler,
        useClass: ErrorsHandler,
      },
      ErrorNotificationService
    ]
})
export class ErrorHandlerModule {}