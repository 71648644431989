<div class="flexV">
    <h1 class="mainTitle">{{title}}</h1>
    <hr>
    <h2 class="subtitle">What is your preferred date and time?</h2>

    <i>You can select up to 3 options.</i>
    <div *ngFor="let dateTime of preferredDatesTimes;let i = index">
        <app-selectable-item 
        [checked]="true"
        [title]="dateTime.date | date:'MMMM d, y'"
        [subtitle]="(dateTime.date | date:'EEEE')+', '+(dateTime.time | schedulingHours)"
        [hasEdit]="true"
        [hasDelete]="true"
        (onEdit)="editDateTime(i)"
        (onDelete)="deleteDateTime(i)"
        ></app-selectable-item>
    </div>
    
    <div *ngIf="preferredDatesTimes?.length<3" class="addDatesTimesButton formElement" (click)="addDatesTimes()"><img src="assets/images/icons/SVG/plus-purple.svg"  alt="Add Dates and times icon"/> Add Dates/Times</div>
   
    <div class="buttons" *ngIf="!editing">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            (onClick)="onNextClick($event)"
            [disabled]="preferredDatesTimes?.length==0"
        ></dx-button>
    </div>
    <div class="buttons" *ngIf="editing">
        <dx-button
            text="Save"
            type="success"
            [disabled]="preferredDatesTimes?.length==0"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>

    <div class="sidePopup calendar" *ngIf="showCalendar">
        <img class="closeButton" (click)="closeCalendar()" src="assets/images/icons/SVG/close.svg" alt="Popup's close button"/>
        <h2>Preferred Date</h2>
        <i>Appointment requests must be at least 24 hours in advance. </i>
        <div class="calendarWrapper"
        >
            <dx-calendar #calendar [(value)]="dateSelected" [disabledDates]="disabledDates" (onValueChanged)="onDateChanged()"></dx-calendar>
        </div>
        <h2>Preferred Time</h2>
        <i>Select all that apply.</i>
        <div class="timesContainer">
            <app-selectable-item [hasCheckbox]="true" [checked]="timeAMChecked" (onClick)="onToggleAMCheckbox()" [clickable]="true" title="Morning" subtitle="8 am - 12 pm"></app-selectable-item>
            <app-selectable-item [hasCheckbox]="true" [checked]="timePMChecked" (onClick)="onTogglePMCheckbox()" [clickable]="true" title="Afternoon" subtitle="12 pm - 4 pm"></app-selectable-item>
        </div>
        <div class="spacer"></div>
        <app-button [disabled]="(!timeAMChecked && !timePMChecked) || !dateSelected" [text]="editingDateIndex>=0?'Save Changes':'Add Option'" (onClick)="addDateTimeOption()"></app-button>
    </div>
    
</div>