<ng-container class="navMenu" *ngIf="userProfile" smartlook-safe>
  <img class="ctcaLogo" src="assets/images/logo.png?1" alt="CTCA Logo"/>
  <div [class]="userMenuClasses()">
    <div class="user-menu-header" (click)="toggleUserMenu()">
      <img class="avatar" src="assets/images/icons/SVG/user-pic.svg" alt="User icon"/>
      <div class="user-menu-right">
        <div class="first-line">
          <div class="name">{{ fullName }}</div>
          <img class="chevron" src="assets/images/icons/SVG/nav-chevron.svg" alt="Toggle user menu"/>
        </div>
        <div class="role">{{ role }}</div>
      </div>
    </div>
    <div class="user-menu-links">
      <div class="user-menu-link" (click)="openMyAccount()">My Account</div>
      <ng-container *ngIf="hasProxies">
        <div *ngIf="hasActiveProxy" class="user-menu-link" (click)="backToMyAccount()">Back to My Account</div>
        <div *ngIf="!hasActiveProxy" class="user-menu-link" (click)="selectPatient()">Select Patient</div>
      </ng-container>
      <!--div *appShowFor="PermissionLevel.VIEW_MEDICAL_CONTACTS" [class]="router.url=='/app/contact-info'?'user-menu-link active':'user-menu-link'" [routerLink]="['/app/contact-info']" routerLinkActive="active">Contact Info</div-->
      <div *appShowFor="PermissionLevel.VIEW_QUICK_LINKS" class="user-menu-link" (click)="openShareRecords()">Share My Records</div>
      <div *ngIf="hasPermission(PermissionLevel.VIEW_MY_ACCOUNT) && (hasPermission(PermissionLevel.MANAGE_PREFERENCES) || hasPermission(PermissionLevel.MANAGE_NOTIFICATIONS))" [class]="router.url=='/app/account/preferences'?'user-menu-link active':'user-menu-link'" [routerLink]="['/app/account/preferences']" routerLinkActive="active">Preferences</div>
      <div class="user-menu-link" (click)="logout()">Sign Out</div>
    </div>
  </div>

  <app-nav-menu-item *appShowFor="PermissionLevel.NO_REQUIREMENTS" label="Home" icon="ctca-home" route="/app/home" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_APPOINTMENTS" label="Appointments" icon="ctca-appointments" route="/app/appointments" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_LAB_RESULTS" label="Lab Results" icon="ctca-labs" route="/app/lab-results" [badgeText]="unreadLabResultsCount" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.SEND_SECURE_MESSAGES" label="Ask a Question" icon="ctca-ask-question" route="/app/ask-question" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_SECURE_MESSAGES" label="Messages" icon="ctca-messages" route="/app/secure-mail" [badgeText]="unreadMessageCount" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_MEDICAL_DOCUMENTS" label="Medical Documents" icon="ctca-docs" [subItems]="medicalDocumentsPages" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_HEALTH_HISTORY" label="Health History" icon="ctca-health" [subItems]="healthHistoryPages" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_INTERACTIVE" label="Interactive" icon="fa-comments" [subItems]="interactivePages" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_PATIENT_REPORTED_DOCUMENTS" label="Patient Reported" icon="ctca-reported" [subItems]="SITPages" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_FORMS_LIBRARY  " label="Forms Library" icon="ctca-forms" route="/app/forms-library" smartlook-safe></app-nav-menu-item>
  <!--<app-nav-menu-item *appShowFor="PermissionLevel.VIEW_MY_ACCOUNT" label="My Account" icon="fa-user"  [subItems]="myAccountPages" smartlook-safe></app-nav-menu-item>-->
  <!--<app-nav-menu-item *appShowFor="PermissionLevel.VIEW_MEDICAL_CONTACTS" label="Contact Info" icon="fa-address-card"  route="/app/contact-info" smartlook-safe></app-nav-menu-item>-->
  <app-nav-menu-item *ngIf="(hasPermission(PermissionLevel.VIEW_BILL_PAY) || hasPermission(PermissionLevel.VIEW_CLAIMS)) && claimsEnabled" label="Billing" icon="ctca-pay" [subItems]="billingPages" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *ngIf="hasPermission(PermissionLevel.VIEW_BILL_PAY) && !claimsEnabled" label="Bill Pay" icon="ctca-pay" route="/app/bill-pay" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_ADMINISTRATION" label="Administration" icon="fa-wrench" [subItems]="adminPages" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.VIEW_REPORTING" label="Reporting" icon="fa-file-text" [subItems]="reportingPages" smartlook-safe></app-nav-menu-item>
  <app-nav-menu-item *appShowFor="PermissionLevel.NO_REQUIREMENTS" label="Help" icon="ctca-help" route="/app/help" smartlook-safe></app-nav-menu-item>

  <div class="support-callout" (click)="toggleContactOverlay()" smartlook-safe>
    <div class="support-callout-icon"><img src="assets/images/icons/SVG/phone-green.svg" alt="Phone icon"/></div>
    <div class="support-callout-title">Important CTCA<br/>Phone Numbers</div>
  </div>

  <div *appShowFor="PermissionLevel.VIEW_USER_LOGS" [class]="router.url=='/app/account/user-logs'?'footer-link active':'footer-link'"  [routerLink]="['/app/account/user-logs']" routerLinkActive="active">Activity Logs</div>
  <div [class]="router.url=='/app/patient-safety-notification'?'footer-link active':'footer-link'" [routerLink]="['/app/patient-safety-notification']" routerLinkActive="active">Patient Safety Notification</div>
  <div [class]="router.url=='/app/privacy-policy'?'footer-link active':'footer-link'" [routerLink]="['/app/privacy-policy']" routerLinkActive="active">Privacy Practices</div>
  <div [class]="(router.url=='/app/contact-us' || router.url=='/app/contact-us/contact-technical-support')?'footer-link active':'footer-link'" [routerLink]="['contact-us']" routerLinkActive="active">Technical Support</div>

  <app-contact-overlay *ngIf="showContactOverlay" (closeOverlay)="closeOverlay()"></app-contact-overlay>
</ng-container>
