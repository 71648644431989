import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import dxPopup from 'devextreme/ui/popup';
import { BaseComponent } from './core/component/base.component';
import { environment } from '../environments/environment';
import { StringHelper } from './shared/helpers/string.helper';
import { FEATURE_FLAG_PORTAL_OUTAGE, LaunchDarklyService } from './core/services';
import { Outage } from './core/services/models';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { initDatadog } from './shared/helpers/logging.helper';
import { trackPageTime, trackPageView } from './shared/helpers/tracking.helper';
declare let gtag: Function;
declare const checkInitializer: any;
const smartlookNonce = StringHelper.nonce(32);
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
	outageEnabled = false;
	constructor(private ld: LaunchDarklyService, private router: Router) {
		super();
		
		this.addDatadog();
		this.addGoogleAnalytics();
		this.addSmartlook();
		this.addContentSecurityPolicy();

		const navEndEvent$ = router.events.pipe(
			filter(e => e instanceof NavigationEnd)
		);
		navEndEvent$.subscribe((e: NavigationEnd) => {
			trackPageView(e.urlAfterRedirects);
		});

		window.onbeforeunload = () => {
			trackPageTime();
		}
	}

	handleOutage(outage: Outage) {
		this.outageEnabled = this.router.routerState.snapshot.url === '/outage';
		if (outage.enabled && !this.outageEnabled) {
			this.router.navigateByUrl('/outage');
		} else if (!outage.enabled && this.outageEnabled) {
			this.router.navigateByUrl('/app/home/alerts');
		}
		this.outageEnabled = outage.enabled;
	}

	ngOnInit() {		
		this.dxPopupDefaults();		
		this.ld.flagChange.subscribe((flags: LDFlagSet) => {
			this.handleOutage(flags[FEATURE_FLAG_PORTAL_OUTAGE]);
		});
		this.ld.flagValue(FEATURE_FLAG_PORTAL_OUTAGE, {}).then(outage => this.handleOutage(outage));
	}
	ngOnDestroy(): void {
		this.ld.flagChange.unsubscribe();
	}

	dxPopupDefaults() {
		dxPopup.defaultOptions({
			options: {
				hideOnOutsideClick: true,
				dragEnabled: false,
				showCloseButton: true,
				height: 500,
				width: 500,
				maxHeight: '100vh',
				maxWidth: '100vw'
			}
		});
	}
		
	addGoogleAnalytics(){
		const script = document.createElement('script');
		script.async = true;
		script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaId;
		document.head.prepend(script);
	}

	addSmartlook() {
		if (environment.smartlookKey && environment.smartlookKey !== 'false') {
			const w = window as any;
			if (!w.smartlook) {
				(function (d) {
					const o: any = w.smartlook = function () { o.api.push(arguments) }, h = d.getElementsByTagName('head')[0];
					const c = d.createElement('script'); o.api = new Array(); c.async = true; c.type = 'text/javascript';
					c.nonce = smartlookNonce;
					c.crossOrigin = 'anonymous';
					c.charset = 'utf-8'; c.src = 'https://rec.smartlook.com/recorder.js'; h.appendChild(c);
				})(document);
			}
			w.smartlook('init', environment.smartlookKey);
		}
	}

	addDatadog() {
		initDatadog();
	}

	loadInitializerScripts(){
		checkInitializer();
	}

	addContentSecurityPolicy(){
		const content = document.createElement('meta');
			content.setAttribute('http-equiv', 'Content-Security-Policy');
		content.setAttribute('content', "default-src 'self' 'unsafe-inline' " +
			`https://${environment.auth0.domain} ` +
			environment.chatBotUrl + " https://local.accounts.myctca.com/ blob:; style-src 'self' 'unsafe-inline'; " +
			"script-src 'self' 'unsafe-eval' 'unsafe-inline' https: ws: " +
			"https://www.googletagmanager.com blob: " +
			"https://*.smartlook.cloud https://*.smartlook.com " +
			"https://*.google-analytics.com https://*.browser-intake-datadoghq.com; " +
			"worker-src blob:; " +
			"img-src 'self' data: https://*.myctca.com https://www.googletagmanager.com https://*.google-analytics.com; " +
			"connect-src 'self' https://*.myctca.com http://*.ctca.local https://*.google-analytics.com " +
			`https://*.launchdarkly.com https://${environment.auth0.domain}  https://*.browser-intake-datadoghq.com ` +
			"https://*.google-analytics.com https://*.smartlook.cloud https://*.smartlook.com;");
		document.head.prepend(content);
	}
}
