export class AlertMessage {
    constructor(
        public id?:string,
        public controllerName?: string,
        public methodName?: string,
        public messageTitle?: string,
        public messageText?: string,
        public startDate?: string,
        public endDate?: string,
        public disableFunction?: boolean,
        public facility?: string,
        public displayOrder?: number,
        public priority?: boolean,
    ) { }
}