<app-card label="Release of Information Form">
  <div class="popup-content">
    <div class="header">
      <div class="title">
        Release of Information Form
      </div>
      <div class="button" (click)="close()"><i class="dx-icon-close"></i></div>
    </div>
      <form *ngIf="roiAuthorizationForm" [formGroup]="roiAuthorizationForm" novalidate>
        <div class="dx-fieldset">
          
          <div class="dx-field shorter">
            <label for="selectedFacility" class="label">Treatment Facility</label>
            <dx-select-box [inputAttr]="{ id: 'selectedFacility' }" [dataSource]="facilitiesData" displayExpr="value" valueExpr="key" formControlName="selectedFacility"
                [isValid]="roiAuthorizationForm?.controls['selectedFacility'].valid || roiAuthorizationForm?.controls['selectedFacility'].pristine"
                [validationError]="{ message: 'Treatment Facility is required' }">
            </dx-select-box>            
          </div>

          <div class="dx-field shorter">
            <div class="label">Patient's Name</div>            
            <label for="firstName" class="visuallyhidden">First Name:</label>              
            <dx-text-box [inputAttr]="{ id: 'firstName' }" formControlName="firstName" placeholder="First Name"  [isValid]="roiAuthorizationForm?.controls['firstName'].valid || roiAuthorizationForm?.controls['firstName'].pristine"
              [validationError]="{ message: 'First Name is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>              
            <label for="lastName" class="visuallyhidden">Last Name:</label>
            <dx-text-box [inputAttr]="{ id: 'lastName' }" style="margin-top: 10px;" formControlName="lastName" placeholder="Last Name"  [isValid]="roiAuthorizationForm?.controls['lastName'].valid || roiAuthorizationForm?.controls['lastName'].pristine"
              [validationError]="{ message: 'Last Name is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>         
          </div>

          <div class="dx-field shorter">
            <label for="birthDate" class="label">Date of Birth</label>
            <dx-date-box [inputAttr]="{ id: 'birthDate' }" formControlName="birthDate" type="date" [max]="dateNow" placeholder="mm/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format"
              dateOutOfRangeMessage="Date of Birth is not valid" (onFocusOut)="birthDateDateBoxValueChanged()" [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" [isValid]="roiAuthorizationForm?.controls['birthDate'].valid || roiAuthorizationForm?.controls['birthDate'].pristine"
              [validationError]="{ message: 'Date of Birth is required'}" style="width: 100%" pickerType="rollers">
            </dx-date-box>           
          </div>
                       
          <div class="dx-field shorter">
            <div class="label">Delivery Method</div>            
            <div formArrayName="deliveryMethods">              
                <div *ngFor="let deliveryMethod of deliveryMethods.controls;  let i = index" [formGroupName]="i">
                  <div style="padding-top:5px;display: block;" *ngIf="!_deliveryMethodOptions[getKey(deliveryMethod['controls'])].pickUpDateField">
                    <dx-check-box [formControlName]="getKey(deliveryMethod['controls'])" text="{{_deliveryMethodOptions[getKey(deliveryMethod['controls'])].description}}  "
                      (onValueChanged)="onDeliveryMethodSelectionChange($event,deliveryMethod)" [isValid]="isAtleastOneDeliveryMethodSelected" [ValidFormControl]="isAtleastOneDeliveryMethodSelected"
                      [validationError]="{ message: 'At least one delivery method selection is required' }">
                    </dx-check-box>
                  </div>
                  <div style="padding-top:5px;display: block;" *ngIf="_deliveryMethodOptions[getKey(deliveryMethod['controls'])].pickUpDateField">
                    <label for="pickUpDate" class="visuallyhidden">Pick-up date:</label>
                    <dx-date-box [inputAttr]="{ id: 'pickUpDate' }" type="date" [formControlName]="getKey(deliveryMethod['controls'])" (onFocusOut)="pickupDateDateBoxValueChanged(null)" [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" placeholder="mm/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format"
                     [isValid]="deliveryMethod['controls'][getKey(deliveryMethod['controls'])].valid || deliveryMethod['controls'][getKey(deliveryMethod['controls'])].pristine"
                      [validationError]="{ message: 'Pick-up date is required' }" style="width: 100%" pickerType="rollers">
                    </dx-date-box>
                  </div>
                </div>              
            </div>
          </div>

          <div *ngIf="_wrongPickUpdate" class="dx-field shorter">              
            <span class="red">
              Pick-up date should be in the future
            </span>              
          </div>

          <br />
                    
          <div class="dx-field shorter">
            <div class="label">1. I authorize Cancer Treatment Centers of America® (CTCA) facilities to:</div>                                       
            <div formArrayName="authorizationActions">
              <div *ngFor="let authorizationAction of authorizationActions.controls;  let i = index" [formGroupName]="i">
                <dx-check-box style="margin-top: 10px;" [formControlName]="getKey(authorizationAction['controls'])"  text="{{_authorizationActions[getKey(authorizationAction['controls'])].description}}"
                  (onValueChanged)="onAuthorizationActionSelectionChange($event)" [isValid]="isAtleastOneAuthorizationActionSelected" [ValidFormControl]="isAtleastOneAuthorizationActionSelected"
                  [validationError]="{ message: 'Authorization action is required' }">
                </dx-check-box>
              </div>
            </div>            
          </div>

          <div class="dx-field shorter">
            <div class="character-count-container">
              <label for="facilityOrIndividual" class="label">Facility/Individual</label>
              <span>{{ remainingCharacters }} characters remaining</span>
            </div>

            <dx-text-box [inputAttr]="{ id: 'facilityOrIndividual' }" formControlName="facilityOrIndividual" [isValid]="roiAuthorizationForm?.controls['facilityOrIndividual'].valid || roiAuthorizationForm?.controls['facilityOrIndividual'].pristine"
                [validationError]="{ message: 'Facility/Individual is required' }" [maxLength]="100" (change)="markAsDirtyForm()" (onOptionChanged)="onFacilityChanged($event)">
            </dx-text-box>            
          </div>

          <div class="dx-field shorter">
            <label for="address" class="label">Address</label>
            <dx-text-box [inputAttr]="{ id: 'address' }" formControlName="address" placeholder="Address" [isValid]="roiAuthorizationForm?.controls['address'].valid || roiAuthorizationForm?.controls['address'].pristine"
                [validationError]="{ message: 'Address is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>            
          </div>

          <div class="dx-field shorter">
              <label for="city" class="visuallyhidden">City:</label>
              <dx-text-box [inputAttr]="{ id: 'city' }" formControlName="city" placeholder="City" [isValid]="roiAuthorizationForm?.controls['city'].valid || roiAuthorizationForm?.controls['city'].pristine"
                [validationError]="{ message: 'City is required' }" (change)="markAsDirtyForm()">
              </dx-text-box>
              <label for="state" class="visuallyhidden">State:</label>
              <dx-text-box [inputAttr]="{ id: 'state' }" style="margin-top: 10px;" formControlName="state" placeholder="State" [isValid]="roiAuthorizationForm?.controls['state'].valid || roiAuthorizationForm?.controls['state'].pristine"
                [validationError]="{ message: 'State is required' }" (change)="markAsDirtyForm()">
              </dx-text-box>          
          </div>

          <div class="dx-field shorter">            
            <label for="zip" class="visuallyhidden">Zip:</label>
            <dx-text-box [inputAttr]="{ id: 'zip' }" formControlName="zip" placeholder="Zip" [isValid]="roiAuthorizationForm?.controls['zip'].valid || roiAuthorizationForm?.controls['zip'].pristine"
              [validationError]="{ message: 'Zip is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>            
          </div>  

          <div class="dx-field shorter">
            <label for="phoneNumber" class="label">Phone</label>            
            <app-phone-field [inputAttr]="{ id: 'phoneNumber' }" formControlName="phoneNumber" 
              [isValid]="roiAuthorizationForm?.controls['phoneNumber'].valid || roiAuthorizationForm?.controls['phoneNumber'].pristine"
              [validationError]="{ message: 'Phone is required and in correct format' }" (change)="markAsDirtyForm()">
            </app-phone-field>   
          </div>

          <div class="dx-field shorter">
            <label for="fax" class="label">Fax</label>            
            <app-phone-field [inputAttr]="{ id: 'fax' }" formControlName="fax" [isValid]="roiAuthorizationForm?.controls['fax'].valid || roiAuthorizationForm?.controls['fax'].pristine"
              [validationError]="{ message: 'Fax is required and in correct format' }" (change)="markAsDirtyForm()">
            </app-phone-field>            
          </div>

          <div class="dx-field shorter">
            <label for="email" class="label">E-mail</label>            
            <dx-text-box [inputAttr]="{ id: 'email' }" formControlName="email" [isValid]="roiAuthorizationForm?.controls['email'].valid || roiAuthorizationForm?.controls['email'].pristine"
              [validationError]="{ message: 'Email is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>                 
          </div>

          <br />

          <div class="dx-field shorter">
            <div class="label">Purpose</div>
            <div formArrayName="purposes">
              <div *ngFor="let purpose of purposes.controls;  let i = index" [formGroupName]="i">
                <dx-check-box style="margin-top: 5px;"  [formControlName]="getKey(purpose['controls'])" text="{{_purposeOptions[getKey(purpose['controls'])].description}}"
                  (onValueChanged)="onPurposeSelectionChange($event)" [isValid]="isAtleastOnePurposeSelected" [ValidFormControl]="isAtleastOnePurposeSelected" [validationError]="{ message: 'Purpose is required' }">
                </dx-check-box>
              </div>
            </div>
          </div>

          <br />

          <div class="dx-field shorter">
            <div class="label">2. I request and authorize Provider to release the health information specified below, from the following treatment dates to Recipients.</div>
          </div>

          <div class="dx-field shorter">
            <div class="label">DATES OF SERVICE</div>
          </div>

          <div class="dx-field shorter">
            <label for="beginDate" class="label">From</label>           
            <dx-date-box [inputAttr]="{ id: 'beginDate' }" type="date" formControlName="beginDate" placeholder="mm/dd/yyyy" (onFocusOut)="beginDateDateBoxValueChanged(null)" [useMaskBehavior]="true"  displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format"
              dateOutOfRangeMessage="Date provided must be in the past" [isValid]="(roiAuthorizationForm?.controls['beginDate'].valid || roiAuthorizationForm?.controls['beginDate'].pristine)" [ValidFormControl]="!_wrongBeginDate"
              [validationError]="{ message: 'Begin date is required' }"  style="width: 100%" pickerType="rollers">
            </dx-date-box>
          </div>
          
          <div *ngIf="_wrongBeginDate" class="dx-field shorter">              
            <span class="red">
              Start Date should be current or in the past
            </span>              
          </div>

          <div class="dx-field shorter">
            <label for="endDate" class="label">To</label>            
            <dx-date-box [inputAttr]="{ id: 'endDate' }" type="date" formControlName="endDate" placeholder="mm/dd/yyyy" (onFocusOut)="endDateDateBoxValueChanged(null)" [useMaskBehavior]="true" displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format" 
              dateOutOfRangeMessage="End Date must be after Begin Date" [isValid]="(roiAuthorizationForm?.controls['endDate'].valid || roiAuthorizationForm?.controls['endDate'].pristine)" [ValidFormControl]="!_wrongOrder"
              [validationError]="{ message: 'End date is required' }"  style="width: 100%" pickerType="rollers">
            </dx-date-box>            
          </div>

          <div *ngIf="_wrongOrder" class="dx-field shorter">              
            <span class="red">
              Start Date can't be after End Date
            </span>              
          </div>

          <br />

          <div class="dx-field shorter">
            <div class="label">INFORMATION TO BE DISCLOSED</div>
          </div>

          <div class="dx-field shorter">
            (Check all categories or specific categories, as desired.)
          </div>

          <div class="dx-field shorter">            
            <div style="margin:auto;" formArrayName="disclosureInformationList">              
              <dx-check-box style="margin-top: 5px;" [formControl]="selectAllDisclosureInformation" text="Select All" (click)="onSelectAllDisclosureInformationClicked($event)"></dx-check-box>                              
              <div *ngFor="let disclosureInformation of disclosureInformationList.controls;  let i = index" [formGroupName]="i">
                <div *ngIf="isRegularDisclosureInfoOption(disclosureInformation)">
                  <dx-check-box style="margin-top: 5px;" [formControlName]="getKey(disclosureInformation['controls'])" text="{{_disclosureInformationOption[getKey(disclosureInformation['controls'])].description}}"
                    (onValueChanged)="onDisclosureInformationSelectionChange($event)" [isValid]="isAtleastOneDisclosureInformationSelected" [ValidFormControl]="isAtleastOneDisclosureInformationSelected"
                    [validationError]="{ message: 'At least one disclosure information selection is required' }">
                  </dx-check-box>
                </div>
                <div *ngIf="isOtherSpecifyDisclosureInfoOption(disclosureInformation)">
                    <dx-check-box style="margin-top: 5px;" [formControlName]="getKey(disclosureInformation['controls'])" text="{{_disclosureInformationOption[getKey(disclosureInformation['controls'])].description}}"
                      (onValueChanged)="onDisclosureInformationOtherSelectionChange($event,disclosureInformation)" [isValid]="isAtleastOneDisclosureInformationSelected" [ValidFormControl]="isAtleastOneDisclosureInformationSelected"
                      [validationError]="{ message: 'At least one disclosure information selection is required' }">
                    </dx-check-box>
                </div>
                <div *ngIf="_disclosureInformationOption[getKey(disclosureInformation['controls'])].description == 'text box'">                  
                  <label for="otherInfo" class="visuallyhidden">Other:</label>
                  <dx-text-box [inputAttr]="{ id: 'otherInfo' }" style="margin-top: 5px;" [formControlName]="getKey(disclosureInformation['controls'])" [isValid]="disclosureInformation['controls'][getKey(disclosureInformation['controls'])].valid || disclosureInformation['controls'][getKey(disclosureInformation['controls'])].pristine"
                    [validationError]="{ message: 'Other disclosure information is required' }" (change)="markAsDirtyForm()"></dx-text-box>
                </div>
              </div>
            </div>
          </div>

          <br />

          <div class="dx-field shorter">
              <div class="label">Highly Confidential Information</div>
          </div>

          <div class="dx-field shorter">
            <div class="label">3. I understand that my health information may include sensitive categories of information listed below. I request and authorize Provider to release all of the information described below if such information exists unless I specify otherwise (uncheck selections below or enter your restrictions).</div>
          </div>

          <div class="dx-field shorter">            
            <div formArrayName="highlyConfidentialInformationList">
              <div *ngFor="let item of highlyConfidentialInformationList.controls;  let i = index" [formGroupName]="i">
                <dx-check-box style="margin-top: 5px;" [formControl]="item['controls'].selected" text="{{item['controls'].description.value}}" (onValueChanged)="onHighlyConfidentialSelectionChange($event)">
                </dx-check-box>
              </div>              
            </div>
          </div>

          <br />

          <div class="dx-field shorter">
            <div class="label">I request that Provider withhold the following categories of information from the Recipients named in Section 1.</div>
          </div>

          <br />

          <div class="dx-field shorter">            
            <label for="restrictions" class="label">Please indicate any restrictions:</label>
            <dx-text-area [inputAttr]="{ id: 'restrictions' }" style="margin-top: 10px;" formControlName="restrictions" [height]="90" (change)="markAsDirtyForm()">
            </dx-text-area>          
          </div>

          <br />          

          <div class="dx-field shorter">
            <div class="roi-authorization">
              <div class="label">This authorization is valid for release of information for the dates listed on the request.</div>
              <div class="content-list">
                <ul>
                  <li>
                    I understand CTCA may not condition treatment, payment, enrollment or eligibility for benefits on whether or not
                    I sign this authorization.
                  </li>
                  <li>
                    I understand that the use or disclosure of my health information is voluntary except in accordance with federal or state
                    law and any mandatory reporting requirements.
                  </li>
                  <li>
                    I understand that once my health information is disclosed it may be re-disclosed by the recipient and the information may
                    not be protected by federal or state privacy laws or regulations.
                  </li>
                  <li>
                    A photocopy or facsimile of this authorization will be treated in the same manner as the original.
                  </li>
                  <li>
                    I understand that this authorization will expire five years from the date signed on this form. This authorization may be 
                    revoked at any time by submitting a request in writing to the Health Information Management department; the revocation will
                    not apply to any information already released.
                  </li>                  
                  <li>
                    I understand that I may request a copy of this authorization form.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="dx-field shorter">            
            <label for="parentResponsible" class="label">Patient/Legal Responsible Party</label>
            <dx-text-box [inputAttr]="{ id: 'parentResponsible' }" formControlName="signature" [isValid]="roiAuthorizationForm?.controls['signature'].valid || roiAuthorizationForm?.controls['signature'].pristine"
              [validationError]="{ message: 'Patient/Legal Responsible Party is required' }" (change)="markAsDirtyForm()">
            </dx-text-box>
          </div>

          <div class="dx-field shorter">
            <label for="patientRelation" class="label">Relation to Patient, if signed by anyone other than the patient</label>
            <dx-text-box [inputAttr]="{ id: 'patientRelation' }" formControlName="patientRelation" (change)="markAsDirtyForm()">
            </dx-text-box>
          </div>
          
        </div>
      </form>
      <div class="buttons">
        <dx-button text="Cancel" (onClick)="close()"></dx-button>
        <dx-button icon="fa fa-check-square-o" class="dx-button-default" text="Send" (onClick)="onSubmitClick()"></dx-button>
      </div>
    </div>
</app-card>
<router-outlet></router-outlet>