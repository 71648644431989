import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertMessage } from '../services/models';
import { AdminService } from '../services';

@Injectable()
export class ResolveAlertMessagesPayload implements Resolve<Array<AlertMessage>> {
  constructor(private adminService: AdminService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Array<AlertMessage> | Observable<Array<AlertMessage>> | Promise<Array<AlertMessage>> {
    return this.adminService.getAlertMessages();
  }
}