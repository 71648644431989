<app-card label="Meaningful Use Patient Event Counts By Location">

  <form *ngIf="eventsForm" [formGroup]="eventsForm" novalidate>
    <div class="dx-fieldset">
      <div class="dx-field">
        <div class="dx-field-label"><label for="startDate">Start Date:</label></div>
        <div class="dx-field-value">
          <div style="display:table-cell">
            <dx-date-box #startDate [inputAttr]="{ id: 'startDate' }" formControlName="startDate" [max]="max" [min]="min" (onFocusOut)="startDateValueChanged()" placeholder="mm/dd/yyyy" displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format" [isValid]="eventsForm.get('startDate').valid || eventsForm.get('startDate').pristine"></dx-date-box>
          </div>  
        </div>                      
      </div>
      <div class="dx-field">
        <div class="dx-field-label"><label for="endDate">End Date:</label></div>
        <div class="dx-field-value">
          <div style="display:table-cell">
            <dx-date-box #endDate [inputAttr]="{ id: 'endDate' }" formControlName="endDate" [max]="max" [min]="min" (onFocusOut)="endDateValueChanged()" placeholder="mm/dd/yyyy" displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format" [isValid]="eventsForm.get('endDate').valid || eventsForm.get('endDate').pristine"></dx-date-box>
          </div>
        </div>                      
      </div>
      <div *ngIf="wrongOrder()" class="dx-field">
          <div class="dx-field-label"></div>
          <div class="dx-field-value">
              <span class="red">
                  Start Date can't be after End Date
              </span>
          </div>
      </div>  
      <div *ngIf="!inRange()" class="dx-field">
          <div class="dx-field-label"></div>
          <div class="dx-field-value">
              <span class="red">
                  Date value outside range {{minDate()}} - {{maxDate()}}
              </span>
          </div>
      </div>      
      <div class="dx-field">
        <div class="dx-field-label"></div>
        <div class="dx-field-value">
            <dx-button class="left" text="Lookup Event Counts" (onClick)="lookupEvents()"></dx-button>
        </div> 
      </div>
    </div>
  </form>

  <div *ngIf="events.length > 0">
    <dx-data-grid [dataSource]="events" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [searchPanel]="{ visible: true, placeholder: 'Search...' }" [wordWrapEnabled]="false" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="false">
  
      <dxi-column dataField="epi"  caption="EPI"></dxi-column>
      <dxi-column dataField="mrn" caption="MRN"></dxi-column>
      <dxi-column dataField="eventId" caption="EventId"></dxi-column>
      <dxi-column dataField="muCount" caption="MU Count"></dxi-column>
      <dxi-column dataField="eventTotal" caption="Event Total"></dxi-column>
      <dxi-column dataField="eventDescription" caption="Description"></dxi-column>
      <dxi-column dataField="location" caption="Location"></dxi-column>       
  
    </dx-data-grid>
  </div>

</app-card>
<router-outlet></router-outlet>
