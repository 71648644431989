import { Injectable } from '@angular/core';


@Injectable()
export class PlatformService {

	constructor() {}
	
	isIE() {
		const ua = navigator.userAgent;
		/* MSIE used to detect old browsers and Trident used to newer ones*/
		const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;    
		return is_ie; 
	}

	isFirefox() {
		const ua = navigator.userAgent;
		const is_fx = ua.toLowerCase().indexOf("firefox") > -1;    
		return is_fx; 
	}
	
}
