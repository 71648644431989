<app-card label="Activity Logs">
    <div card-buttons-desktop>
        <dx-button text="Clear Filters" icon="fa fa-times" (onClick)="clearFilters()"></dx-button>
    </div>
  
  <dx-data-grid [dataSource]="logs" height="600"  showBorders="true" [remoteOperations]="true" [hoverStateEnabled]="true" 
    [wordWrapEnabled]="false" [columnAutoWidth]="false" [rowAlternationEnabled]="true" [showColumnLines]="false"
    [masterDetail]="{ enabled: true, template: 'detail' }">

    <div *dxTemplate="let log of 'detail'">
        <div style="white-space: normal;">
          <table style="width:100%">
            <tr>
              <td class="bold">Keyword:</td>
              <td>{{log.data.keywordDecoded}}</td>
              <td class="bold">OpCode:</td>
              <td>{{log.data.opcodeDecoded}}</td>
              <td class="bold">Task:</td>
              <td>{{log.data.taskDecoded}}</td>
            </tr>
          </table>
          <hr>
          <span class="bold">Payload:</span><span>{{log.data.payload}}</span>
        </div>
    </div>

    <dxi-column [width]="170" dataField="timestamp" dataType="datetime" [filterOperations]="[ '=', '<=', '>=']" caption="Date"></dxi-column>
    <dxi-column [width]="200" dataField="userName" [filterOperations]="[ 'contains', 'startswith', 'endswith', '=' ]"  caption="Username"></dxi-column>
    <dxi-column [width]="80" dataField="epiid" [filterOperations]="[ 'contains', 'startswith', 'endswith', '=' ]" caption="Epiid"></dxi-column>    
    <dxi-column dataField="formattedMessage" [filterOperations]="[ 'contains', 'startswith', 'endswith', '=' ]"  caption="Message"></dxi-column>
    <dxi-column [width]="80" dataField="eventId" [filterOperations]="['=']" caption="EventId"></dxi-column> 
    
    
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-scrolling mode="virtual"></dxo-scrolling>
    <dxo-sorting mode="none"></dxo-sorting>       

  </dx-data-grid>
</app-card>
<router-outlet></router-outlet>
