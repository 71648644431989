export const environment = {
	version: '3.5-test',
	cacheLogging: false,
	production: true,
	portalServiceBaseUrl: 'https://v3testservice.myctca.com/api/v1/',
	billPayUrl: 'https://www.patientportal.me/CTCA/Patient/login.html ',
	ctcaIdentityUrl: 'https://testaccounts.myctca.com/user/detail',
	securityTab: 'userdetails-securityprivacy',
	profileTab: 'userdetails-details',
	medicalProfileTab: 'userdetails-medicalprofile',
	labResultsReviewWarning: "24",
	appointmentActionDisclaimer: "24",
	outageMessage: " ",
	gaId: "UA-23384102-3",
	smartlookKey: "a41f43cd827f87c9c2c917bdd3a9b4d57110800b",
	launchDarklyKey: "6127df81d159f3262c229310",
	chatBotUrl: "https://ctca-hc-covidassessmentbot-dev.azurewebsites.net/",
	sitSurveyUrl: 'https://test.portal.myctca.com/myProsk/SITInterface/BypassLogin.aspx',
	datadogClientToken: 'pub9b57b7280265fa6e9d1a918bb914cebe',
	environment: 'test',
	oidc: {
		authority: 'https://testaccounts.myctca.com',
		client_id: 'CTCA.Portal.UI.Web',
		redirect_uri: 'https://stage.portal.myctca.com/auth',
		post_logout_redirect_uri: 'https://stage.portal.myctca.com/',
		silent_redirect_uri: 'https://stage.portal.myctca.com/silent-renew',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
		automaticSilentRenew: false,
		monitorSession: true,
		loadUserInfo: false,
		revokeAccessTokenOnSignout:
			false,
		authenticationLoggingEnabled:
			true
	},
	auth0: {
		domain: 'ctca-test.us.auth0.com',
		audience: 'http://identityservice.ctca.test/api',
		client_id: 'GJ8g5QOv2ZLpTxSdg9Jb59Y5VWhgIEeH',
		redirect_uri: 'https://stage.portal.myctca.com/callback',
		post_logout_redirect_uri: 'https://stage.portal.myctca.com/',
		response_type: 'id_token token',
		scope: 'openid external.identity.readwrite api email profile impersonation',
	}
};
