import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Appointment } from '../../home/shared/models';
import { BaseComponent } from '../../core/component/base.component';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment-timezone';
import { FacilityInfo } from '../../core/services/models';

@Component({
	selector: 'app-upcoming-appointments-card',
	templateUrl: './upcoming-appointments-card.component.html',
	styleUrls: ['./upcoming-appointments-card.component.scss']
})
export class UpcomingAppointmentsCardComponent extends BaseComponent implements OnInit, OnChanges {
	
	@Input() appointments: Array<Appointment>;
	@Input() facilityInfo: FacilityInfo;
	@Input() daysAhead = 0;
	visibleAppointments: Array<Appointment> = [];
	ghosts = new Array(2);
	page = 0;
	pageSize = 3;
	appointmentsGroups: { title: string, appointments: Appointment[] }[] = [];
	MAX_DAYS = 2;

	constructor(private router:Router,
		private route:ActivatedRoute) {
		super();	
	}

	ngOnInit() {				
	}

	ngOnChanges(){
		this.getAppointments();		
	}

	getAppointments() {
		this.appointmentsGroups = [];
		if(this.appointments){
			let lastDate = '';
			let totalDays = 0;
			for (const appointment of this.appointments) {
				const now = moment().valueOf();
				let startMoment = moment(appointment.startDateTime);
				if (this.facilityInfo?.timeZoneOffset) {
					startMoment = startMoment.tz(this.facilityInfo.timeZoneOffset);
				}
				const isWithin24Hours = startMoment.isBefore(now + 864000000);
				if (!isWithin24Hours) {
					appointment.teleHealthUrl = '';
				}
				const isToday = moment(appointment.startDateTime).isSame(moment.now(), 'day');
				const date = (isToday ? 'TODAY, ' : '') + moment(appointment.startDateTime).format("dddd, MMMM Do");
				if (date !== lastDate) {
					totalDays++;
					if (totalDays > this.MAX_DAYS) {
						break;
					}
					lastDate = date;
					this.appointmentsGroups.push({ title: date, appointments: [appointment] });
				} else {
					this.appointmentsGroups[this.appointmentsGroups.length - 1].appointments.push(appointment);
				}
			}
		}
	}
	
	navigateToPreferences() {
        this.router.navigate(['account','preferences'],{relativeTo: this.route.parent.parent});
    }

	showDetails(event) {}

	openAppointmentDetail(appointmentId: string) {
		this.router.navigate(['app', 'appointments', appointmentId]);
	}

	prevClick() {
		if (this.page < 0) {
			this.page = 0;
		} else {
			this.page--;
		}
		this.changePage();
	}

	nextClick() {
		if ((this.page + 1) * this.pageSize < this.appointments.length) {
			this.page++;
		}
		this.changePage();
	}

	private changePage() {
		const start = this.page * this.pageSize;
		let end = start + this.pageSize;
		if (end > this.appointments.length) {
			end = this.appointments.length;
		}
		this.visibleAppointments = this.appointments.slice(start, end);
	}

	viewAllAppointments() {
		this.router.navigate(['app', 'appointments']);
	}

	requestAppointment() {
		this.router.navigate(['app', 'request-appointmentv2']);
	}

	openUrl(url: string, e?: any) {
		e.event.stopPropagation();
		window.open(url);
	}
}
