import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { MedicalDocumentsService, LoadingService } from '../../core/services';
import { PatientReportedItem, Graph, GraphPoint } from '../../core/services/models';
import { StringHelper } from '../../shared/helpers/string.helper';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-sit-data-graphs',
  templateUrl: './sit-data-graphs.component.html',
  styleUrls: ['./sit-data-graphs.component.scss']
})
export class SitDataGraphsComponent extends BaseComponent implements OnInit {

  patientReportedItems:Array<PatientReportedItem> = new Array<PatientReportedItem>();  
  graphs:Array<Graph> = new Array<Graph>();
  selectedGraph:Graph;

  symptomForm: FormGroup = new FormGroup({    
    selectedSymptom : new FormControl('',Validators.compose([Validators.required]))
  });

  constructor(private medicalDocumentsService:MedicalDocumentsService, loadingService:LoadingService) { 
    super();
    this.selectedGraph = null;
  }

  ngOnInit() {

    this.medicalDocumentsService.getPatientReportedDocuments().subscribe((data:Array<PatientReportedItem>) => {
      
      this.patientReportedItems = data; 
      
      for (let i = 0; i < this.patientReportedItems.length; i++) {
        
        const p: PatientReportedItem = this.patientReportedItems[i];

        if (StringHelper.isNumeric(p.observationValue)) {                   
          const g: Graph = this.graphs.find(_g => _g.Name === p.itemName);
          if (g) {
            const gP: GraphPoint = new GraphPoint();
            gP.dateEntered = p.performedDate;
            gP.value = parseInt(p.observationValue, 10);
            g.Add(gP);
          } else {
            const graph: Graph = new Graph(p.itemName);
            const gP: GraphPoint = new GraphPoint();
            gP.dateEntered = p.performedDate;
            gP.value = parseInt(p.observationValue, 10);
            graph.Add(gP);
            this.graphs.push(graph);
          }
        }

      }

      this.graphs.sort((x:Graph,y:Graph) => {
        return (x.Name.localeCompare(y.Name));
      });


    });
  }

  isGraphDataAvailable():boolean {
    return (this.selectedGraph &&  this.selectedGraph.data.length > 0);
  }

  updateGraph(event:Object) {
    this.selectedGraph = this.graphs.find(g => g.Name === this.symptomForm.get('selectedSymptom').value);
  }

  customizeTooltip(arg: any) {
    const argument: string = moment(new Date(arg.argumentText)).format('ll');
    return {
        text: "<b>Date Recorded:</b>" + " " + argument + " - " + "<b>Value Recorded:</b>" + " " + arg.valueText
    };
  }

}
