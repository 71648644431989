<dx-popup #popup [visible]="visible$ | async" title="Blue Button Initiative" (onHidden)="navigateToParent()">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view>
      <div class="shorter">
          <p>myCTCA supports the
            <a href="https://www.healthit.gov/patients-families/blue-button/about-blue-button">Blue Button</a>®
            <sub>1</sub> initiative by providing you an easy way to download your personal health information to a file. The Blue
            Button® initiative was created by the U.S. government to provide patients with access to their complete medical records,
            in a standardized format that can be imported into other systems. You can download the file of your personal data
            and save the file on your own personal computer. After you have saved it, you can import that same file into other
            computer-based personal health management tools.</p>
          <p>
            <strong>How does the Blue Button® capabilities differ from the rest of the myCTCA Portal</strong>
          </p>
          <ul>
            <li>The data displayed in the rest of the myCTCA portal is available as soon as possible, for example a lab result is viewable
              as soon as it is completed.</li>
            <li>The Blue Button data is not available until after a care event has been completed, for example after a hospital discharge.
              The Blue Button data could take up to four days before it is available. The delay in the Blue button data ensures
              that a complete picture of the care event is included in the record.</li>
          </ul>
          <p>
            <strong>Safeguarding your data</strong>
          </p>
          <p>Since you control access to your downloaded health information, it's your responsibility to protect it. You should
            treat your personal and health information just like you would your banking or other confidential information. Here
            are some important things to remember:</p>
          <ul>
            <li>You may want to download your information to a CD, flash drive or a mobile app. Encrypted and password protected
              flash drives, CDs and mobile apps are recommended.</li>
            <li>If you want to send your information via email, you should encrypt the message.</li>
            <li>Keep paper copies in a safe and secure place that you can control.</li>
            <li>Don't download your data on public computers.</li>
          </ul>
          <p>
            <sub>1</sub> The name "Blue Button," the Blue Button logo, the Blue Button Combined Logo, and the slogan, "Download My
            Data" are registered service marks of the U.S. Department of Health and Human Services.</p>
      </div>        
    </dx-scroll-view>
  </div>
</dx-popup>
