import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Md5 } from 'ts-md5/dist/md5';
import {
  HealthHistoryService, SecureMailService, UserProfileService, LoadingService,
  SupportService,
  ContactInfoService
} from '../core/services';
import * as formHelper from '../shared/helpers/form.helper';
import { alert } from 'devextreme/ui/dialog';
import {
  FacilityInfo,
  PatientContactInfo,
  Prescription,
  PrescriptionRenewalRequest,
  ShmRecipient,
  UserProfile
} from '../core/services/models';
import { BaseComponent } from '../core/component/base.component';
import { getAutofillPhoneNumber, phoneNumberValidator } from '../home/shared/helpers/phone.helper';
import { trackPageLoadingTime } from '../shared/helpers/tracking.helper';



@Component({
  selector: 'app-prescription-renewal-request',
  templateUrl: './prescription-renewal-request.component.html',
  styleUrls: ['./prescription-renewal-request.component.scss']
})
export class PrescriptionRenewalRequestComponent extends BaseComponent implements OnInit, OnDestroy {
  prescriptionId: string;
  patientPrescriptions: Array<Prescription>;
  prescriptionDictionary: Object;
  profile: UserProfile;
  careteams: ShmRecipient[];
  careteamsSelected: ShmRecipient[] = [];
  careteamsExist: boolean;
  prescriptionSelectionIsValid: boolean;
  primaryFacility: FacilityInfo;
  prescriptionForm: FormGroup;
  startTime = 0;
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private hhSvc: HealthHistoryService,
    private profileSvc: UserProfileService,
    private mailSvc: SecureMailService,
    private loadingService: LoadingService,
    private supportService: SupportService,
    private contactInfoService: ContactInfoService,
  ) {
    super();
    this.careteamsExist = true;
  }

  getKey(obj): string {
    return Object.keys(obj)[0];
  }

  ngOnInit(): void {
    this.startTime = Date.now();
    this.loadingService.loading = true;
    this.prescriptionId = this.route.snapshot.params['id'];
    this.patientPrescriptions = this.patientPrescriptions || [];
    this.careteams = this.route.snapshot.data.careteams;
    this.careteamsExist = this.careteams && this.careteams.length > 0;
    setTimeout(() => {
      const select = document.getElementsByTagName("select");
      for (let i = 0; i < select.length; i++) {
        const s = select.item(i);
        if (s.parentElement.parentElement.title) {
          s.setAttribute('title', s.parentElement.parentElement.title);
        }
      }
    }, 10);
    this.profileSvc.getUserProfile().subscribe(profile => {
      this.profile = profile;
      this.supportService.getAllFacilitiesInfoForTreamentFacilitySelect().subscribe((facilities: FacilityInfo[]) => {

        this.primaryFacility = facilities.find((facility) => {
          return facility.name === this.profile.primaryFacility;
        });
      });

    });



    this.hhSvc
      .getPrescriptions()
      .pipe(
        mergeMap((p: Prescription[]) => p),
        filter((p: Prescription) => p.allowRenewal),
        map((d: Prescription) => {
          if (this.prescriptionId && d.prescriptionId === this.prescriptionId)
            return { ...d, clientSelected: true };
          else return { ...d, clientSelected: false };
        })
      )
      .subscribe(data => {
        this.patientPrescriptions.push(data);
      },
        (error) => {
          this.loadingService.loading = false;
          throw (error);
        },
        () => {

          this.prescriptionForm = this.fb.group({
            prescriptions: this.fb.array([]),
            patientPhone: ['', [Validators.required, phoneNumberValidator]],
            pharmacyName: ['', Validators.required],
            pharmacyPhone: ['', [Validators.required, phoneNumberValidator]],
            comments: ['']
          });

          this.initializePrescriptions();

          formHelper.markControlAndChildControlsAsDirty(this.prescriptionForm);

          this.loadingService.loading = true;
          this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
            this.prescriptionForm.get('patientPhone').setValue(getAutofillPhoneNumber(patientContactInfo) || '');
            this.loadingService.loading = false;
          });
          this.onPrescriptionSelectionChange(null);
          this.removeDirtyForm();
        });
  }

  ngOnDestroy(): void {
    this.removeDirtyForm();
  }

  markAsDirtyForm() {
    sessionStorage.setItem('dirtyForm', '1');
  }

  removeDirtyForm() {
    sessionStorage.removeItem('dirtyForm');
  }

  itemSelectionChanged(e) {
    this.careteamsSelected = e.component._selectedItems;
    this.markAsDirtyForm();
  }

  get prescriptions(): FormArray {
    return this.prescriptionForm.get('prescriptions') as FormArray;
  }

  checkBoxName(prescription: FormGroup): string {
    return this.prescriptionDictionary[this.getKey(prescription['controls'])].drugName.trim() + ": " + this.prescriptionDictionary[this.getKey(prescription['controls'])].instructions.trim();
  }

  private initializePrescriptions(): void {
    this.loadingService.loading = false;
    this.prescriptionDictionary = new Object();
    const prescriptionFormControls = this.patientPrescriptions.map((prescription: Prescription) =>
      this.getPrescriptionFormControl(prescription)
    );
    this.prescriptionForm.setControl('prescriptions', this.fb.array(prescriptionFormControls));
        const endTime = Date.now();
        const elapsedTime = (endTime-this.startTime)/1000;
        
        if(this.route.snapshot.params['id']) 
        trackPageLoadingTime('/app/prescriptions/request-renewal/:id',elapsedTime);
        else
        trackPageLoadingTime(window.location.pathname,elapsedTime);
  }

  private getPrescriptionFormControl(prescription: Prescription): FormGroup {
    const hash: string = Md5.hashStr(prescription.prescriptionId + '_' + prescription.drugName).toString();
    prescription.hash = hash;
    this.prescriptionDictionary[hash] = prescription;
    const option: any = new Object();
    option[hash] = prescription.clientSelected;
    return this.fb.group(option);

  }

  onPrescriptionSelectionChange(selectedPrescriptionId: string) {
    if (selectedPrescriptionId !== null) {
      // find the prescription object
      const selectedPrescription = this.prescriptions.controls.filter(p => this.prescriptionDictionary[this.getKey(p['controls'])].prescriptionId === selectedPrescriptionId);
      // check if the prescription selected has been checked or unchecked
      const selectedPrescriptionState = selectedPrescription[0]['controls'][this.getKey(selectedPrescription[0]['controls'])].value;
      // loop through the list of prescriptions, make sure only the one selected is checked.
      this.prescriptions.controls.forEach( (control) => {
        const prescriptionId = this.prescriptionDictionary[this.getKey(control['controls'])].prescriptionId;
        if (prescriptionId !== selectedPrescriptionId && selectedPrescriptionState === true) {
          // uncheck this prescription
          control['controls'][this.getKey(control['controls'])].setValue(false);
        }
      });
    }

    const noneSelected = this.prescriptions.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    this.prescriptionSelectionIsValid = !noneSelected;
    this.markAsDirtyForm();
  }

  getSelectedPrescriptions(): Array<string> {
    const selectedPrescriptions: Array<string> = this.prescriptions.controls
      .filter(opt => opt['controls'][this.getKey(opt['controls'])].value === true)
      .map(opt => {
        return this.prescriptionDictionary[this.getKey(opt['controls'])].prescriptionId;
      });
    return selectedPrescriptions;
  }

  send = () => {

    if (this.prescriptionForm && this.prescriptionForm.valid) {

      this.loadingService.loading = true;

      const request: PrescriptionRenewalRequest = {
        selectedPrescriptions: this.getSelectedPrescriptions(),
        patientPhone: this.prescriptionForm.get('patientPhone').value,
        pharmacyName: this.prescriptionForm.get('pharmacyName').value,
        pharmacyPhone: this.prescriptionForm.get('pharmacyPhone').value,
        comments: this.prescriptionForm.get('comments').value,
        to: this.careteamsSelected
      };

      this.mailSvc.sendPrescriptionRenewalRequest(request).subscribe((result) => {
        this.loadingService.loading = false;
        this.removeDirtyForm();
        this.close();
        setTimeout(() => {
          alert("Prescription requested succesfully", "Information");
        }, 300);
      },
        (error) => {
          this.loadingService.loading = false;
          throw (error);
        });

    }

  };

  close = () => {
    window.history.back();
  }
}
