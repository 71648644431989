import { animate, state, style, transition, trigger } from '@angular/animations';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { BaseComponent } from '../component/base.component';

@Component({
	selector: 'app-mobile-side-menu',
	templateUrl: './mobile-side-menu.component.html',
	styleUrls: ['./mobile-side-menu.component.scss'],
	animations: [
		trigger('slideInOut', [
			state(
				'in',
				style({
					transform: 'translateX(0)'
				})
			),
			state(
				'out',
				style({
					transform: 'translateX(100%)'
				})
			),
			transition('in <=> out', animate('300ms ease-in-out'))
		]),
		trigger('fadeInOut', [
			state(
				'in',
				style({
					opacity: 0.2,
					display: 'block'
				})
			),
			state(
				'out',
				style({
					opacity: 0,
					display: 'none'
				})
			),
			transition('in <=> out', animate('300ms ease-in-out'))
		])
	]
})
export class MobileSideMenuComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy {
	@Input() isVisible: boolean;
	@Output() onClose = new EventEmitter();
	mobileSideMenuState = 'in';
	mobileSideMenuMaskState = 'out';
	routerSubscription: Subscription;
	@Input() showContactOverlay = false;
	@Output() showContactOverlayChange = new EventEmitter<boolean>();

	constructor(private router: Router) {
		super();
	}

	ngOnChanges() {
		if (this.isVisible === undefined) {
			return;
		}

		this.mobileSideMenuState = !this.isVisible ? 'in' : 'out';
		this.mobileSideMenuMaskState = this.isVisible ? 'in' : 'out';
	}

	ngOnInit() {
		this.routerSubscription = this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(() => {
				this.close();
			});
	}

	close() {
		this.onClose.emit();
	}

	ngOnDestroy() {
		this.routerSubscription.unsubscribe();
	}

	showContactOverlayChanged(e) {
		this.showContactOverlayChange.emit(e);
		this.showContactOverlay = e;
	}

}
