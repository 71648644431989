import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentDetail, PatientContactInfo } from '../core/services/models';
import { Popup } from '../core/popup/popup';
import { UserProfileService, ResizingService, ContactInfoService, AppointmentsService, LoadingService, NavigationService } from '../core/services';
import { UserProfile } from '../core/services/models';
import { FormGroup, FormControlName, FormControl, Validators } from '@angular/forms';
import { getAutofillPhoneNumber, phoneNumberValidator } from '../home/shared/helpers/phone.helper';
import { emailOrEmptyValidator } from '../shared/validators/email-or-empty.validator';
import { Subscription } from 'rxjs';
import { trackEvent, trackPageLoadingTime } from '../shared/helpers/tracking.helper';
import { requiredTrimmedValidator } from '../shared/validators/required-trimmed.validator';

@Component({
  selector: 'app-cancel-appointmentv2',
  templateUrl: './cancel-appointmentv2.component.html',
  styleUrls: ['../request-appointmentv2/request-appointmentv2.component.scss']
})
export class CancelAppointmentV2Component extends Popup implements OnInit, OnDestroy {

  id: string;

  requestForm: FormGroup;

  phoneChecked = false;
  emailChecked = false;
  phoneValue = '';
  emailValue= '';

  profile: UserProfile;

  currentStep = 1;

  phoneControl = new FormControl('', [Validators.required, phoneNumberValidator]);
  emailControl = new FormControl('', Validators.compose([Validators.required, emailOrEmptyValidator]));

  editingPhone = false;
  editingEmail = false;
  closeClicked = false;

  editingStep: number;

  phoneCheckedModal = false;
  emailCheckedModal = false;
  phoneValueModal: string;
  emailValueModal: string;
  navigationSubscription: Subscription;
  appointment: AppointmentDetail;

  showSuccessAlert = false;
  startTime = 0;
  constructor(
    router: Router,
    route: ActivatedRoute,
    private profileSvc: UserProfileService,
    private appointmentService: AppointmentsService,
    private loadingService: LoadingService,
    resizingService: ResizingService,
    private contactInfoService: ContactInfoService,
    private navigationService: NavigationService,
  ) {
    super(router, route, resizingService);
    this.startTime = parseInt(localStorage.getItem('startTimeOfCancelApptV2'), 10) || Date.now();
    localStorage.removeItem('startTimeOfCancelApptV2');
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;

    FormControlName.prototype.ngOnChanges = function () {
      const result = originFormControlNameNgOnChanges.apply(this, arguments);
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };

  }

  scrollToTop() {
    document.getElementById('content-area').scroll(0, 0);
  }

  ngOnInit() {
    this.scrollToTop();
    this.navigationSubscription = this.navigationService.RouteChanged.subscribe(() => {
      this.id = this.route.snapshot.params['id'];
      this.profileSvc.getUserProfileHardCache().subscribe(profile => (this.profile = profile));
      if (this.id) {
        this.loadingService.loading = true;
        this.appointmentService.getAppointment(this.id).subscribe((appDetail: AppointmentDetail) => {
          this.appointment = appDetail;

          this.loadingService.loading = false;
          let endTime = Date.now();
          const elapsedTime = (endTime-this.startTime)/1000;
          // this value is used to check for the startTime, due to if user navigates using browser arrow button
          const tempValueOfStart = this.startTime;
          this.startTime = 0;
          endTime = 0;
          if(window.location.pathname.includes('cancel-appointmentv2') && tempValueOfStart!==0)
          trackPageLoadingTime('/app/cancel-appointmentv2/:id',elapsedTime);
          trackEvent('ACTION_APPOINTMENTS_CANCEL_TAP');
        });
      }
      this.currentStep = 1;
      this.trackCurrentStep();
    });

    this.profileSvc.getUserProfileHardCache().subscribe(profile => (this.profile = profile));

    this.requestForm = new FormGroup({
      reason: new FormControl('', [requiredTrimmedValidator]),
      additionalNotes: new FormControl(''),

      reasonPopup: new FormControl('', [requiredTrimmedValidator]),
      additionalNotesPopup: new FormControl(''),
      // preferredDateTime: new FormControl('', Validators.compose([Validators.required, datetimeOrEmptyValidator])),
      // phoneNumber: new FormControl('', [Validators.required, phoneNumberValidator]),
    });

    this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
      if (!this.phoneValue) {
        this.phoneValue = getAutofillPhoneNumber(patientContactInfo) || '';
      }
      if (!this.emailValue) {
        this.emailValue = patientContactInfo.emailAddress || '';
      }
    });
  
    this.removeDirtyForm();
  }

  ngOnDestroy() {
    if (!this.showSuccessAlert) {
      trackEvent('ACTION_APPT_CANCEL_REQ_LEAVE_TAP');
    }
    super.destroy();
    this.removeDirtyForm();
  }

  trackCurrentStep = () => {
    switch (this.currentStep) {
      case 1:
        trackEvent('PAGE_APPT_CANCEL_REQ_REASON_VIEW');
        break;
      case 2:
        trackEvent('PAGE_APPT_CANCEL_REQ_COMM_PREF_VIEW');
        break;
      case 3:
        trackEvent('PAGE_APPT_CANCEL_REQ_ADDI_COMMENTS_VIEW');
        break;
      case 4:
        trackEvent('PAGE_APPT_CANCEL_REQ_SUMMARY_VIEW');
        break;
    }
  }

  updateStep = (newStep: number) => {
    this.scrollToTop();
    this.currentStep = newStep;
    this.markAsDirtyForm();
    this.trackCurrentStep();
  }

  nextStep = () => {
    this.updateStep(this.currentStep + 1);
  }
  previousStep = () => {
    this.updateStep(this.currentStep - 1);
  }

  onTogglePhoneCheckbox = () => {
    if (this.editingPhone === false && this.closeClicked === false)
    {
      this.phoneChecked = !this.phoneChecked;
    }
    
    if (this.phoneChecked) {
      this.emailChecked = false;
      if (!this.phoneValue) {
        this.editPhone();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
    this.markAsDirtyForm();
  }

  onToggleEmailCheckbox = () => {
    if (this.editingEmail === false && this.closeClicked === false)
    this.emailChecked = !this.emailChecked;
    
    if (this.emailChecked) {
      this.phoneChecked = false;
      if (!this.emailValue) {
        this.editEmail();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
    this.markAsDirtyForm();
  }

  editPhone = () => {
    this.editingPhone = true;
    this.editingEmail = false;
    this.phoneControl.setValue(this.phoneValue);
  }

  editEmail = () => {
    this.editingPhone = false;
    this.editingEmail = true;
    this.emailControl.setValue(this.emailValue);
  }

  closeEditing = () => {
    this.scrollToTop();
    this.editingPhone = false;
    this.editingEmail = false;
    this.closeClicked = true;
    if (!this.phoneValue && this.phoneChecked || !this.emailValue && this.emailChecked) {
      this.phoneChecked = false;
      this.emailChecked = false;
    }

    if (!this.phoneValueModal && this.phoneCheckedModal || !this.emailValueModal && this.emailCheckedModal) {
      this.phoneCheckedModal = false;
      this.emailCheckedModal = false;
    }
  }

  savePhone = () => {
    this.phoneValue = this.phoneControl.value;
    this.closeEditing();
  }

  saveEmail = () => {
    this.emailValue = this.emailControl.value;
    this.closeEditing();
  }

  onTogglePhoneCheckboxModal = () => {
    if (this.editingPhone === false && this.closeClicked === false)
    {
      this.phoneCheckedModal = !this.phoneCheckedModal;
    }
    
    if (this.phoneCheckedModal) {
      this.emailCheckedModal = false;
      if (!this.phoneValueModal) {
        this.editPhoneModal();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
  }

  onToggleEmailCheckboxModal = () => {
    if (this.editingEmail === false && this.closeClicked === false)
    {
      this.emailCheckedModal = !this.emailCheckedModal;
    }
    
    if (this.emailCheckedModal) {
      this.phoneCheckedModal = false;
      if (!this.emailValueModal) {
        this.editEmailModal();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
  }

  editPhoneModal = () => {
    this.editingPhone = true;
    this.editingEmail = false;
    this.phoneControl.setValue(this.phoneValueModal);
  }

  editEmailModal = () => {
    this.editingEmail = true;
    this.editingPhone = false;
    this.emailControl.setValue(this.emailValueModal);
  }

  savePhoneModal = () => {
    this.phoneValueModal = this.phoneControl.value;
    this.closeEditing();
  }

  saveEmailModal = () => {
    this.emailValueModal = this.emailControl.value;
    this.closeEditing();
  }

  closeEditingStep = () => {
    this.editingStep = undefined;
    this.markAsDirtyForm();
  }

  editStep = (step: number) => {
    this.scrollToTop();
    this.editingStep = step;

    if (step === 1) {
      this.requestForm.get('reasonPopup').setValue(this.requestForm.get('reason').value);
    } else if (step === 2) {
      this.phoneCheckedModal = this.phoneChecked;
      this.emailCheckedModal = this.emailChecked;
      this.phoneValueModal = this.phoneValue;
      this.emailValueModal = this.emailValue;
    } else if (step === 3) {
      this.requestForm.get('additionalNotesPopup').setValue(this.requestForm.get('additionalNotes').value);
    }
  }


  saveStep1 = () => {
    this.requestForm.get('reason').setValue(this.requestForm.get('reasonPopup').value);
    this.closeEditingStep();
  }

  saveStep2 = () => {
    this.phoneChecked = this.phoneCheckedModal;
    this.emailChecked = this.emailCheckedModal;
    this.phoneValue = this.phoneValueModal;
    this.emailValue = this.emailValueModal;
    this.closeEditingStep();
  }

  saveStep3 = () => {
    this.requestForm.get('additionalNotes').setValue(this.requestForm.get('additionalNotesPopup').value);
    this.closeEditingStep();
  }

  markAsDirtyForm() {
    sessionStorage.setItem('dirtyForm', '1');
  }

  removeDirtyForm() {
    sessionStorage.removeItem('dirtyForm');
  }

  validateComponent(control: string) {
    this.requestForm.get(control).updateValueAndValidity();
    this.markAsDirtyForm();
  }

  close = () => {
    this.router.navigateByUrl('/app/appointments/' + this.id);
  }

  onSuccessCloseClick = () => {
    this.close();
  };

  onSendClick = () => {
    trackEvent('ACTION_APPT_CANCEL_REQ_SUBMIT_TAP');
    if (this.requestForm.get('reason').valid) {
      this.loadingService.loading = true;
      if (this.id) {
        this.appointmentService
          .cancelAppointmentv2({
            appointmentId: this.id,
            subject: 'Appointment Cancellation',
            reason: this.requestForm.get('reason').value,
            communicationPreference: this.emailChecked ? 'EMAIL' : 'CALL',
            phoneNumber: this.phoneChecked ? this.phoneValue : undefined,
            email: this.emailChecked ? this.emailValue : undefined,
            additionalNotes: this.requestForm.get('additionalNotes').value
          })
          .subscribe(null, null, () => {
            this.loadingService.loading = false;
            this.removeDirtyForm();
            this.showSuccessAlert = true;
            trackEvent('APPT_CANCEL_REQ_SUBMIT_SUCCESSFUL');
            trackEvent(this.emailChecked ? 'APPT_CANCEL_REQ_EMAIL_ME_SELECTION' : 'APPT_CANCEL_REQ_CALL_ME_SELECTION');
          });
      }
    } else {
      trackEvent('APPT_CANCEL_REQ_SUBMIT_UNSUCCESSFUL');
    }
  };
}
