import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';

@Injectable()
export class FileDownloadService extends BaseService {

	downloadPdf(blob: Blob, name: string) {
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
			(window.navigator as any).msSaveOrOpenBlob(blob, `${name}.pdf`);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = data;
		link.download = `${name}.pdf`;
		document.body.appendChild(link);
		link.click();
		setTimeout(() => {
			document.body.removeChild(link);
			window.URL.revokeObjectURL(data)
		}, 100);
	}

	downloadZip(blob: Blob, name: string) {
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
			(window.navigator as any).msSaveOrOpenBlob(blob, `${name}.zip`);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = data;
		link.download = `${name}.zip`;
		document.body.appendChild(link);
		link.click();
		setTimeout(() => {
			document.body.removeChild(link);
			window.URL.revokeObjectURL(data)
		}, 100);
	}	

	getFileContent(url: string): Observable<Blob> {
		return this.getBlob({ endpoint: url });
	}

	/*
	getFileContentById(documentId: string | number): Observable<Blob> {
		// call the service

		// find out the content-type

		// act based in the content-type
		
	}
	*/

	/**
	 * Downloads to the browser a blob in a for of file. No matter the file type
	 * @param blob 
	 * @param fileName 
	 * @returns 
	 */
	downloadAnyFileType(blob: Blob, fileName: string) {

		// remove any double quotes from the file if found
		fileName = fileName.replace('"', ''); // replacing one "" character
		fileName = fileName.replace('"', ''); // replacing second "" character


		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
			(window.navigator as any).msSaveOrOpenBlob(blob, `${fileName}`);
			return;
		}

		// For other browsers:
		// Create a link pointing to the ObjectURL containing the blob.
		const data = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = data;
		link.download = `${fileName}`;
		document.body.appendChild(link);
		link.click();
		setTimeout(() => {
			document.body.removeChild(link);
			window.URL.revokeObjectURL(data)
		}, 100);
	}
}
