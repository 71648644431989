import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { ServiceEndpoint } from './service-endpoint.enum';
import { Router } from '@angular/router';
import { AlertMessagePayload, AlertMessage, DocumentWhitelistPayload } from './models';

@Injectable()
export class AdminService extends BaseService {
  constructor(router: Router, httpClient: HttpClient) {
		super(router, httpClient);
	}
	
	getDocumentsWhitelist():Observable<Array<DocumentWhitelistPayload>> {
		return this.get({
			endpoint:ServiceEndpoint.ADMIN_GETDOCUMENTSWHITELIST
		});
	}

	getDocumentWhitelist(documentId:string):Observable<DocumentWhitelistPayload> {
		return this.post({
			endpoint:ServiceEndpoint.ADMIN_GETDOCUMENTWHITELIST,
			body:{id:documentId}
		});
	}

	GetParentDocumentTypes():Observable<Array<string>> {
		return this.get({
			endpoint:ServiceEndpoint.ADMIN_GETPARENTDOCUMENTTYPES
		});
	}

	deleteDocumentWhitelist(documentId:string):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_DELETEDOCUMENTWHITELIST,
			body:{id:documentId}
		});
	}

	saveDocumentWhitelist(document:DocumentWhitelistPayload):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_SAVEADDDOCUMENTWHITELIST,
			body:document
		});
	}

	addDocumentWhitelist(document:any):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_SAVEADDDOCUMENTWHITELIST,
			body:document
		});
	}

	getAlertMessages(): Observable<Array<AlertMessage>> {
		return this.get({
			endpoint: ServiceEndpoint.ADMIN_GETSYSTEMALERTMESSAGES
		});
	}

	getAlertMessage(messageId:string): Observable<AlertMessagePayload> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_GETSYSTEMALERTMESSAGE,
			body:{
				id: messageId
			}
		});
	}

	saveMessage(message:AlertMessage):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_SAVEADDSYSTEMALERTMESSAGE,
			body:message
		});
	}

	addMessage(message:any):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_SAVEADDSYSTEMALERTMESSAGE,
			body:message
		});
	}

	deleteMessage(messageId:string):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_DELETESYSTEMALERTMESSAGE,
			body:{id:messageId}
		});
	}

	loadNpiFile(url:string):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_LOADNEWNPIFILE,
			body:{url:url}
		});
	}

	splitNpiFile(directoryName:string,fileName:string):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.ADMIN_SPLITNEWNPIFILE,
			body:{directoryName:directoryName,fileName:fileName}
		});
	}
}
