import { Component, OnInit, ViewChild } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import * as AspNetData from "devextreme-aspnet-data-nojquery";
import { ServiceEndpoint } from '../../core/services';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../core/services';
import { DxDataGridComponent } from "devextreme-angular";


@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss']
})
export class ActivityLogsComponent implements OnInit {

  @ViewChild(DxDataGridComponent, { static: true }) dataGrid: DxDataGridComponent;
  logs:CustomStore;

  constructor(private authService:AuthenticationService) {
     
    const serviceUrl = `${environment.portalServiceBaseUrl}${ServiceEndpoint.ADMIN_GETLOGS}`;

    this.logs = AspNetData.createStore({
        key:"systemId",
        loadUrl:serviceUrl,
        onBeforeSend: (operation, ajaxSettings) => {
          const authSignature: string = this.authService.getAuthSignature();
          if(authSignature) {
            ajaxSettings.headers = {};
            ajaxSettings.headers["Authorization"] = authSignature;
          }
        }
    });

  }

  clearFilters() {
    this.dataGrid.instance.clearFilter();
  }

  ngOnInit() {
  }

}
