import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Popup } from '../../core/popup/popup';
import {
  FileDownloadService,
  HtmlTransformerService,
  MedicalDocumentsService,
  LoadingService,
  ResizingService
} from '../../core/services';
import { MedicalDocument, MedicalDocumentType } from '../../core/services/models';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
  selector: 'app-medical-document-details-popup',
  templateUrl: './medical-document-details-popup.component.html',
  styleUrls: ['./medical-document-details-popup.component.scss']
})
export class MedicalDocumentDetailsPopupComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
  
  constructor(
    router: Router,
    route: ActivatedRoute,
    private docSvc: MedicalDocumentsService,
    private downloadSvc: FileDownloadService,
    private transformer: HtmlTransformerService,
    private loadingService:LoadingService,
    resizingService: ResizingService
  ) {
    super(router, route, resizingService);
  }

  document: MedicalDocument;
  docType: MedicalDocumentType;
  title: string;

  ngOnInit() {
    this.document = this.route.snapshot.data.document;
    this.docType = this.route.snapshot.parent.data.documentType;
    this.title = this.document.documentName;
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }

  loadContent(event) {
    const element = event.component.content().querySelector('#modalContent');
    element.innerHTML = '';
    this.transformer
      .load(this.document.documentText)
      .bindToHashLinksToSpans(hash => {
        document.querySelector(`[name=${hash}]`).scrollIntoView();
      })
      .appendTo(element);
  }

  download = () => {
    this.loadingService.loading = true;
    this.docSvc
      .getMedicalDocumentReport(this.docType, this.document.documentId)
      .subscribe(doc => {        
        this.downloadSvc.downloadPdf(doc, this.title);
        this.loadingService.loading = false;
      });
  };
}
