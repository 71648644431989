import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { SecureMailService, LoadingService, UserProfileService, SupportService } from '../../core/services';
import { SecureMailMessage, MailFolder, UserProfile } from '../../core/services/models';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
	selector: 'app-new-messages-card',
	templateUrl: './new-messages-card.component.html',
	styleUrls: ['./new-messages-card.component.scss']
})
export class NewMessagesCardComponent extends BaseComponent implements OnInit {

	canMarkAsRead = false;
	
	constructor(private mailSvc: SecureMailService,
		private loadingService:LoadingService,
		private router:Router,
		private userProfileService: UserProfileService,
		private supportService: SupportService) {
		super();

		this.userProfileService.getUserProfileHardCache().subscribe((profile:UserProfile) => {
			if(profile && profile.userPermissions && profile.userPermissions.length > 0 && profile.userPermissions.indexOf(this.PermissionLevel.SEND_SECURE_MESSAGES) > -1) {
				this.canMarkAsRead = true;	
			} else {
				this.canMarkAsRead = false;
			}
		});

	}

	newMessages: SecureMailMessage[];
	ngOnInit() {
		this.mailSvc
			.getSecureMessages(MailFolder.Inbox)
			.pipe(
				map(msgs => {
					return msgs.filter(message => {
						if (!message.isRead) {
							message.sent = new Date(message.sent);
							return message;
						}
					});
				})
			)
			.subscribe(msgs => (this.newMessages = msgs));
	}

	viewMessageDetails(msg: SecureMailMessage) {
		if(this.canMarkAsRead) {
			this.loadingService.loading = true;
			this.mailSvc.setSecureMessageRead(msg.mailMessageId).subscribe(() => {
				msg.isRead = true;
				this.supportService.updateBadgesCount();
				this.router.navigate(['app','secure-mail', 'inbox', msg.mailMessageId]);
			}, error => {
				this.loadingService.loading = false;
				throw(error);
			});
		} else {
			this.router.navigate(['app','secure-mail', 'inbox', msg.mailMessageId]);	
		}
	}

	viewAllMessages() {
		this.router.navigate(['app', 'secure-mail', 'inbox']);
	}

	composeMessage() {
		this.router.navigate(['app', 'ask-question']);
	}
}
