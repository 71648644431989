import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Popup } from '../../core/popup/popup';
import { FileDownloadService, LabResultsService, LoadingService, ResizingService } from '../../core/services';
import { Lab, LabResultsCollection, LabSet } from '../shared/models';
import { DxPopupComponent } from 'devextreme-angular';

@Component({
	selector: 'app-lab-results-detail',
	templateUrl: './lab-results-detail.component.html',
	styleUrls: ['./lab-results-detail.component.scss']
})
export class LabResultsDetailComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
	
	labResultsCollection: LabResultsCollection;
	labsFlattened: Array<Lab>;
	labResultsReviewWarning:string = environment.labResultsReviewWarning;
	@ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;
	constructor(
		router: Router,
		route: ActivatedRoute,
		private labResultsService: LabResultsService,
		private downloadSvc: FileDownloadService,
		private loadingService:LoadingService,
		resizingService: ResizingService
	) {
		super(router, route, resizingService);
	}

	ngOnInit() {

		const data = this.route.snapshot.data.labResults;

		if(data && data.length > 0) {
			this.labResultsCollection = this.labResultsService.mapLabResults(data)[0];
			// Flatten the labs so that the DevExpress control can take care of grouping by lab set
			const labs = new Array<Lab>();
			if (this.labResultsCollection && this.labResultsCollection.labSets) {
				this.labResultsCollection.labSets.forEach((labSet: LabSet) => {
					if (labSet.labs) {
						labSet.labs.forEach((lab: Lab) => {
							labs.push(lab);
						});
					}
				});
			}
			this.labsFlattened = labs;
		} else {
			this.labResultsService.removeLabResultsFromCache();
			this.close();			
		}
		
	}

	ngAfterViewInit(){
		super.init(this.popup);
	}

	ngOnDestroy(){
		super.destroy();
	}

	onCellPrepared(event: any) {
		// If there are no notes to display, hide the detail expand button
		if (
			event.rowType === 'data' &&
			event.column.command === 'expand' &&
			(event.data.notes === '' || event.data.notes === null)
		) {
			event.cellElement.classList.remove('dx-datagrid-expand');
			event.cellElement.innerHTML = '';
		}
	}

	onDownloadAsPDFClicked = () => {
		this.loadingService.loading = true;
		this.labResultsService
			.getLabResultsReport(
				this.labResultsCollection.dateCollected,
				this.labResultsCollection.collectedBy
			)
			.subscribe(data => {
				this.loadingService.loading = false;
				this.downloadSvc.downloadPdf(
					data,
					`Lab Results - ${this.labResultsCollection.dateCollected}`
				);
			});
	};
}
