<div class="flexV">
    <h1 class="mainTitle">{{title}}</h1>
    <hr>
    <h2 class="subtitle">{{subtitle}}</h2>

    <div class="appointments">
        <ng-container *ngFor="let appointment of appointments">
            <div [class]="appointment?.appointmentId==selectedAppointment?.appointmentId?'appointment selected':'appointment'"  (click)="selectAppointment(appointment)">
                <div class="flexH">
                    <div class="date stretch">{{appointment?.startDateTime | date: 'fullDate'}}</div>
                    <div class="checkbox"></div>
                </div>
                <div class="title">{{appointment.description}}</div>
                <div class="provider">{{appointment.resources?('Dr. '+appointment.resources):''}}</div>
                <div class="time">{{appointment.startDateTime | date: 'shortTime'}} {{appointment.facilityTimeZone}}, {{appointment.location}}</div>
            </div>
        </ng-container>
    </div>
   
    <div class="buttons">
        <dx-button 
            [elementAttr]="{class:'backButton'}"
            icon="fa fa-angle-left"
            text="Back" 
            type="success"
            (onClick)="close()"
        ></dx-button>
        <dx-button
            [elementAttr]="{class:'nextButton'}"
            icon="fa fa-angle-right"
            text="Next"
            type="success"
            [disabled]="!selectedAppointment"
            (onClick)="onNextClick($event)"
        ></dx-button>
    </div>
</div>