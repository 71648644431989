<app-card label="Patient Reported Documents">
          
    <p>Please download and review the following document for information about your Symptom Inventory Tool data:</p>    
    
    <div class="outer">
      <dx-button class="inner" text="SIT Patient Education Document" (onClick)="downloadSIT()"></dx-button>  
    </div>   
  
</app-card>

<app-card label="Patient Reported Symptoms">
          
  <dx-data-grid [dataSource]="patientReportedItems" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [wordWrapEnabled]="false" 
  [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="false" [masterDetail]="{enabled: true, template: 'notesTemplate'}">
    
    <dxi-column dataField="performedDate" caption="Date" dataType="date" format="MMMM d yyyy" [allowSorting]="false" [groupIndex]="0" sortOrder="desc"></dxi-column>
    <dxi-column dataField="itemName" caption="Symptom" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="observationValue" caption="Score" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="rangeValue" caption="Score Range" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="abnormalityCodeDescription" caption="Status" cellTemplate="abnormalityTemplate" [allowSorting]="false"></dxi-column>
    
    <ng-container *dxTemplate="let row of 'abnormalityTemplate'">
      <div [class.abnormal-high]="row.data.abnormalityCode === 'H'" [class.abnormal-normal]="row.data.abnormalityCode === 'N'"
        [class.abnormal-low]="row.data.abnormalityCode === 'L'" [class.critical-high]="row.data.abnormalityCode === 'HH'">
        {{row.data.abnormalityCodeDescription}}
      </div>
    </ng-container>

    <ng-container *dxTemplate="let row of 'notesTemplate'">
      <div *ngIf="row.data.formatedTextEncoded; else noNotes" [innerHTML]="row.data.formatedTextEncoded"></div>
    </ng-container>

    <dxo-grouping expandMode="rowClick" [autoExpandAll]="false">
    </dxo-grouping>

  </dx-data-grid> 

</app-card>

<ng-template #noNotes>
  No notes were entered for this result.
</ng-template>


<router-outlet></router-outlet>
