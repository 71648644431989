import { HttpClient, HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import * as cloneDeep from 'lodash/cloneDeep';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, throwError as observableThrowError, timer } from 'rxjs';
import { catchError, shareReplay, take, takeUntil, tap, mergeMap, retryWhen } from 'rxjs/operators';
import { ServiceConfig } from './service-config.interface';
import { Router } from '@angular/router';
import { trackEvent } from '../../../app/shared/helpers/tracking.helper';
@Injectable()
export abstract class BaseService {
	constructor(protected router: Router, protected httpClient: HttpClient) {
		window.cacheInstances = window.cacheInstances || {};
	}

	genericRetryStrategy = ({
		maxRetryAttempts = 3,
		scalingDuration = 1000
	  }: {
		maxRetryAttempts?: number,
		scalingDuration?: number		
	  } = {}) => (attempts: Observable<any>) => {
		return attempts.pipe(
		  mergeMap((error, i) => {
			const retryAttempt = i + 1;
			// if maximum number of retries have been met
			// or response is a status code we don't wish to retry, throw error			
			if (  retryAttempt > maxRetryAttempts || error.status !== 0) {
			  return observableThrowError(error);
			}
			console.log(
			  `Attempt ${retryAttempt}: retrying in ${retryAttempt *
				scalingDuration}ms`
			);
			// retry after 1s, 2s, etc...
			return timer(retryAttempt * scalingDuration);
		  })
		);
	  };

	/**
	 * Sends an HTTP POST request to the portal service.
	 * @param config The service configuration.
	 */
	protected post<TType>(config: ServiceConfig): Observable<TType> {
		console.log('%cSERVICE POST', 'background-color: #20B2AA; color: #fff', config);

		const query = this.buildHttpParams(config.params);
		const url = `${environment.portalServiceBaseUrl}${config.endpoint}`;
		/*just remove the shareReplay function from below "pipe" function when removing the code for 
		subscribing the rawObservable from "cacheObservable" function*/
		const request = this.httpClient
			.post<TType>(url, config.body, { params: query, headers: config.headers })
			.pipe(retryWhen(this.genericRetryStrategy()),catchError(this.handleError),shareReplay());

		return this.cacheObservable(url, request, config.cacheInvalidator, config.cacheKey);
	}

	/**
	 * Sends an HTTP GET request to the portal service.
	 * @param config The service configuration.
	 */
	protected postBlob(config: ServiceConfig): Observable<Blob> {
		console.log('%cSERVICE POST BLOB', 'background-color: #20B2AA; color: #fff', config);

		const query = this.buildHttpParams(config.params);
		const url = `${environment.portalServiceBaseUrl}${config.endpoint}`;
		/*just remove the shareReplay function from below "pipe" function when removing the code for 
		subscribing the rawObservable from "cacheObservable" function*/
		const request = this.httpClient
			.post(url, config.body, { params: query, responseType: 'blob' })
			.pipe(retryWhen(this.genericRetryStrategy()),catchError(this.handleError),shareReplay());

		return this.cacheObservable(url, request, config.cacheInvalidator, config.cacheKey);
	}

	/**
	 * Post to the portal service endpoint to obtain a ZIP file with Hyland documents
	 * @param config URL to the REST endpoint
	 * @returns An observable with Response headers
	 */
	protected postBlobWithFilename(config: ServiceConfig): Observable<HttpResponse<Blob>>{
		console.log('%cSERVICE POST BLOB WITH HEADERS', 'background-color: #20B2AA; color: #fff', config);

		const query = this.buildHttpParams(config.params);
		const url = `${environment.portalServiceBaseUrl}${config.endpoint}`;
		/*just remove the shareReplay function from below "pipe" function when removing the code for 
		subscribing the rawObservable from "cacheObservable" function*/
		const request = this.httpClient
			.post(url, 
				config.body, 
				{ 
					params: query, 
					observe: 'response',
					responseType: 'blob' 
				})
			.pipe(retryWhen(this.genericRetryStrategy()),catchError(this.handleError),shareReplay());

		return this.cacheObservable(url, request, config.cacheInvalidator, config.cacheKey);
	}

	/**
	 * Sends an HTTP GET request to the portal service.
	 * @param config The service configuration.
	 */
	protected get<TType>(config: ServiceConfig): Observable<TType> {
		console.log('%cSERVICE GET', 'background-color: #20B2AA; color: #fff', config);
		const query = this.buildHttpParams(config.params);
		const url = `${environment.portalServiceBaseUrl}${config.endpoint}`;
		/*just remove the shareReplay function from below "pipe" function when removing the code for 
		subscribing the rawObservable from "cacheObservable" function*/
		const request = this.httpClient
			.get<TType>(url, { params: query })
			.pipe(retryWhen(this.genericRetryStrategy()),catchError(this.handleError),shareReplay());

		return this.cacheObservable(url, request, config.cacheInvalidator, config.cacheKey);
	}

	/**
	 * Sends an HTTP GET request to the portal service.
	 * @param config The service configuration.
	 */
	protected getBlob(config: ServiceConfig): Observable<Blob> {
		let url = `${environment.portalServiceBaseUrl}${config.endpoint}`;

		console.log('%cSERVICE GET BLOB', 'background-color: #20B2AA; color: #fff', config);
		const query = this.buildHttpParams(config.params);
		/*just remove the shareReplay function from below "pipe" function when removing the code for 
		subscribing the rawObservable from "cacheObservable" function*/
		const request = this.httpClient
			.get(url, { params: query, responseType: 'blob' })
			.pipe(retryWhen(this.genericRetryStrategy()),catchError(this.handleError),shareReplay());

		return this.cacheObservable(url, request, config.cacheInvalidator, config.cacheKey);
	}

	/**
	 * Sends an HTTP Get request to the portal service which returns a blob and 
	 * a content-disposition header
	 * @param config The Service configuration
	 */
	protected getBlobWithFilename(config: ServiceConfig): Observable<HttpResponse<Blob>> {
		let url = `${environment.portalServiceBaseUrl}${config.endpoint}`;

		console.log('%cSERVICE GET BLOB WITH HEADERS', 'background-color: #20B2AA; color: #fff', config);
		const query = this.buildHttpParams(config.params);

		/*just remove the shareReplay function from below "pipe" function when removing the code for 
		subscribing the rawObservable from "cacheObservable" function*/
		const request = this.httpClient
			.get(url, { 
				params: query,
				observe: 'response',
				responseType: 'blob'
				})
			.pipe(retryWhen(this.genericRetryStrategy()),catchError(this.handleError),shareReplay());

		return this.cacheObservable(url, request, config.cacheInvalidator, config.cacheKey);
	}

	/**
	 * Sends an HTTP POST request to the portal service.
	 * @param config The service configuration.
	 */
	protected getBlobPOST(config: ServiceConfig): Observable<Blob> {
		console.log('%cSERVICE GET BLOB', 'background-color: #20B2AA; color: #fff', config);
		const query = this.buildHttpParams(config.params);
		const url = `${environment.portalServiceBaseUrl}${config.endpoint}`;
		/*just remove the shareReplay function from below "pipe" function when removing the code for 
		subscribing the rawObservable from "cacheObservable" function*/
		const request = this.httpClient
			.post(url, config.body ,{ params: query, responseType: 'blob' })
			.pipe(retryWhen(this.genericRetryStrategy()),catchError(this.handleError),shareReplay());

		return this.cacheObservable(url, request, config.cacheInvalidator, config.cacheKey);
	}

	/**
	 * Builds an HttpParamsObject
	 * @param params The ServiceParams array to convert into HttpParams
	 */
	private buildHttpParams(params: {}) {
		let query = new HttpParams();

		if (params instanceof Array) {
			// params is an array of ServiceParam objects
			params.forEach(element => {
				query = query.set(element.key, element.value);
			});
		} else {
			// params is a simple object with key-value pairs
			for (const key in params) {
				if (params.hasOwnProperty(key)) {
					query = query.set(key, params[key]);
				}
			}
		}

		return query;
	}

	/**
	 * Caches an observable
	 * @param key The key to cache the observable under.
	 * @param rawObservable$ The observable to cache
	 * @param invalidate$ The invalidate observable
	 */
	protected cacheObservable(
		key: string,
		rawObservable$: Observable<any>,
		invalidate$?: Observable<any>,
		cacheKey?: string
	): Observable<any> {
		const cacheName = `${key}_${cacheKey || ''}`;
		if (!invalidate$) {
			const startTimeOfApiLoading = Date.now();
			// if no invalidate observable was provided, skip caching
			if (environment.cacheLogging) {
				console.log('%cCACHE SKIP ', 'background-color: #FF8C00; color: #fff', cacheName);
			}
			/* subscribed the rawObservale for tracking the api loading time
			and response size */
			const val = rawObservable$;
			val.subscribe((resp)=>{
				try {
					const parsedResp = JSON.stringify(resp);
					const sizeInKb = parsedResp.length/1024;
					const endTimeOfApiLoading = Date.now();
					const elapsedTimeOfApiLoading = (endTimeOfApiLoading-startTimeOfApiLoading)/1000;
					trackEvent(key,'Api endpoint loading time',undefined,elapsedTimeOfApiLoading);
					trackEvent(key,'Api response size',undefined,sizeInKb);
				} catch (error) {
					console.log("Error thrown from parsing data",error);
				}
			});
			return rawObservable$;
		} else {
			
			let value = this.getFromCache(cacheName);
			
			if (!value) {
				// if the cache doesn't exist, add caching operators and save the observable to the cache
				value = rawObservable$;
				const startTimeOfApiLoading_2 = Date.now();
				this.saveToCache(
					cacheName,
					rawObservable$.pipe(
						tap(() => {
							if (environment.cacheLogging) {
								console.log('%cCACHE MISS', 'background-color: #9400D3; color: #fff', cacheName);
							}
						}), // log a cache miss (occurs when the value is loaded from the service)
						takeUntil(invalidate$), // complete the observable when the invalidate observable emits a value
						shareReplay(1), // only return the last value (i.e. the cached value)
						tap(() => {
							if (environment.cacheLogging) {
								console.log('%cCACHE HIT ', 'background-color: #228B22; color: #fff', cacheName);
							}
						}) // log a cache hit (occurs when the value is loaded from the cache)
					)
				);
				/* subscribed the rawObservale for tracking the api loading time
				and response size */
				value.subscribe((resp)=>{
					try {
						const parsedResp = JSON.stringify(resp);
						const sizeInKb = parsedResp.length/1024;
						const endTimeOfApiLoading_2 = Date.now();
						const elapsedTimeOfApiLoading_2 = (endTimeOfApiLoading_2-startTimeOfApiLoading_2)/1000;
						trackEvent(key,'Api endpoint loading time',undefined,elapsedTimeOfApiLoading_2);
						trackEvent(key,'Api response size',undefined,sizeInKb);

					} catch (error) {
						console.log("Error thrown from parsing data",error);
					}
				});
				// subscribe to the invalidate function to clear the cache
				invalidate$.pipe(take(1)).subscribe(() => {
					if (environment.cacheLogging) {
						console.log('%cCACHE INVALIDATE', 'background-color: #f00; color: #fff', cacheName);
					}
					this.removeFromCache(cacheName);
				});
			}

			return value;
		}
	}

	private saveToCache(key: string, value: any) {
		window.cacheInstances[key] = value;
	}

	protected removeFromCache(key: string) {
		window.cacheInstances[key] = null;
		delete window.cacheInstances[key];
	}

	private getFromCache(key: string) {
		if(!window.cacheInstances[key]) return null; 
		const clonedObject: any = cloneDeep(window.cacheInstances[key]);		
		return clonedObject;
	}	

	/**
	 * Logs and error and rethrows it.
	 * @param error The error response to log
	 */
	protected handleError(error: HttpErrorResponse | any) {
		let errorMessage: string;

		if (error instanceof HttpErrorResponse) {
			errorMessage = error.status + ' - ' + (error.statusText || '') + ' ' + error.message;
		} else {
			errorMessage = error.message ? error.message : error.toString();
		}

		console.error('SERVICE ERROR', errorMessage);

		return observableThrowError(error);
	}

	
}