import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable ,  fromEvent as observableFromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable()
export class ResizingService {

    private mobileBreakpoint = 640;
    private tabletBreakpoint = 1024;

    private isMobile:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isTablet:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isTabletOrSmaller: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private isDesktop:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    
    constructor() {

        if(window.innerWidth <= this.mobileBreakpoint) {
            this.isMobile.next(true);
        } else {
            this.isMobile.next(false);
        }

        if(window.innerWidth <= this.tabletBreakpoint && window.innerWidth > this.mobileBreakpoint) {
            this.isTablet.next(true);
        } else {
            this.isTablet.next(false);
        }

        if (window.innerWidth <= this.tabletBreakpoint) {
            this.isTabletOrSmaller.next(true);
        } else {
            this.isTabletOrSmaller.next(false);
        }

        if(window.innerWidth > this.tabletBreakpoint) {
            this.isDesktop.next(true);
        } else {
            this.isDesktop.next(false);
        }

        observableFromEvent(window, 'resize')
        .pipe(debounceTime(200))
        .subscribe((resizeEvent: any) => {

            const isMobile  = resizeEvent.target.innerWidth <= this.mobileBreakpoint ? true : false;
            const isTablet  = (resizeEvent.target.innerWidth <= this.tabletBreakpoint && resizeEvent.target.innerWidth > this.mobileBreakpoint) ? true : false;
            const isTabletOrSmaller = (resizeEvent.target.innerWidth <= this.tabletBreakpoint) ? true : false;
            const isDesktop = resizeEvent.target.innerWidth > this.tabletBreakpoint ? true : false;

            if (isMobile !== this.isMobile.getValue()) {
                this.isMobile.next(isMobile);                					
            }

            if (isTablet !== this.isTablet.getValue()) {
                this.isTablet.next(isTablet);		
            }

            if (isTabletOrSmaller !== this.isTabletOrSmaller.getValue()) {
                this.isTabletOrSmaller.next(isTabletOrSmaller);		
            }

            if (isDesktop !== this.isDesktop.getValue()) {
               
                this.isDesktop.next(isDesktop);				
            }

        });

    }

    public IsMobile():Observable<boolean> {
        return this.isMobile.asObservable();
    }

    public IsTablet():Observable<boolean> {
        return this.isTablet.asObservable();
    }

    public IsTabletOrSmaller(): Observable<boolean> {
        return this.isTabletOrSmaller.asObservable();
    }

    public IsDesktop():Observable<boolean> {
        return this.isDesktop.asObservable();
    }
}
