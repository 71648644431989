import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { UserPreference } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserPreferencesService extends BaseService {

	private _refreshPreferences = new Subject();
	private invalidateCache$ = this._refreshPreferences.asObservable();
	
	getUserPreferences(): Observable<Array<UserPreference>> {
		return this.get({
			endpoint: ServiceEndpoint.USERPROFILE_GETUSERPREFERENCES,
			cacheInvalidator: this.invalidateCache$
		});
	}

	saveUserPreferences(userPreferences:Array<UserPreference>):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.USERPROFILE_SAVEUSERPREFERENCES,
			body:userPreferences
		}).pipe(tap((result:string) => {
			if(result==='Success') this._refreshPreferences.next();
		}));
	}
	
}