import { Component, OnDestroy, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular';
import { LoadingService, ResizingService, SupportService } from '../../core/services';
import { Popup } from '../../core/popup/popup';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent extends Popup implements OnInit, AfterViewInit, OnDestroy { 
  
 @ViewChild('alertpopup', { static: true }) alertpopup: DxPopupComponent;

  Messages:Array<string>;

  constructor(
    private loadingService:LoadingService,
    router: Router,
		route: ActivatedRoute,
    resizingService: ResizingService,
    private supportService:SupportService
  ) {
    super(router, route, resizingService);
    
  }

  createLinks(text){
    const urlRegex = /((https?:\/\/|[^\s:=]+@www\.).*?[a-z_\/0-9\-\#=&])(?=(\.|,|;|\?|\!)?("|'|«|»|\[|\s|\r|\n|$))/gi;
    return text.replace(urlRegex,(url)=>{
      return '<a target="_blank" href="'+url+'">'+url+'</a>';
    });
  }

  ngOnInit() {    
    this.loadingService.loading = true;
    this.supportService.getAlertMessages().pipe(
			map((messages:Array<any>) => messages.map<any>(x => this.createLinks(x.messageText)))
		).subscribe((messages:Array<string>) => {
			if(messages && messages.length > 0) {
				this.Messages = messages;
			} else {
				this.Messages = [];
			}
		});
  }    


  ngAfterViewInit(){    
    super.init(this.alertpopup);
    this.loadingService.loading = false;
  }

  ngOnDestroy() {    
    super.destroy();   
  }

}
