import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { environment } from '../../../environments/environment';
import { ServiceEndpoint } from '../../core/services';
import { trackEvent } from '../../shared/helpers/tracking.helper';

@Component({
    selector: 'app-user-guide',
    templateUrl: './user-guide.component.html',
    styleUrls: ['./user-guide.component.scss']
})
export class UserGuideComponent extends BaseComponent implements  OnInit {

    constructor() { super();}


    ngOnInit() {}    

    openUserGuideWindow() {
        trackEvent("Help - Open User Guide Window");
        window.open(environment.portalServiceBaseUrl+ServiceEndpoint.SUPPORT_GETUSERGUIDE, "_blank","width=600,height=800");
    }
}
