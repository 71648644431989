import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ImagingComponent } from '../medical-documents/imaging/imaging.component';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxContextMenuModule,
  DxDataGridModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextBoxModule,
  DxTabsModule,
  DxCheckBoxModule,
  DxDateBoxModule,
  DxValidatorModule
} from 'devextreme-angular';

import { BlueButtonPopupComponent } from './blue-button-popup/blue-button-popup.component';
import { CarePlanComponent } from './care-plan/care-plan.component';
import { ClinicalSummariesDetailPopupComponent } from './clinical-summaries-detail-popup/clinical-summaries-detail-popup.component';
import { ClinicalSummariesTransmitPopupComponent } from './clinical-summaries-transmit-popup/clinical-summaries-transmit-popup.component';
import { ClinicalSummariesComponent } from './clinical-summaries/clinical-summaries.component';
import { ImagingDetailPopupComponent } from './imaging-detail-popup/imaging-detail-popup.component';
import { MedicalDocumentDetailsPopupComponent } from './medical-document-details-popup/medical-document-details-popup.component';
import { MedicalDocumentListComponent } from './medical-document-list/medical-document-list.component';
import { MedicalDocumentsRoutingModule } from './medical-documents-routing.module';
import { ClinicalSummariesDetailEncryptionDownloadPopupComponent } from './clinical-summaries-detail-encryption-download-popup/clinical-summaries-detail-encryption-download-popup.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    SharedModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxContextMenuModule,
    DxDataGridModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTabsModule,
    DxDateBoxModule,
    DxValidatorModule,
    MedicalDocumentsRoutingModule
  ],
  declarations: [
    CarePlanComponent,
    ClinicalSummariesComponent,
    ImagingComponent,
    BlueButtonPopupComponent,
    ClinicalSummariesDetailPopupComponent,
    ClinicalSummariesTransmitPopupComponent,
    ImagingDetailPopupComponent,
    MedicalDocumentDetailsPopupComponent,
    MedicalDocumentListComponent,
    ClinicalSummariesDetailEncryptionDownloadPopupComponent
  ]
})
export class MedicalDocumentsModule {}
