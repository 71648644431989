import { GraphPoint } from './graph-point.model';

export class Graph {

    private name:string;
    public data:Array<GraphPoint>;

    constructor(name:string) {
        this.name = name;
        this.data = new Array<GraphPoint>();
    }

    public get Name():string {
        return this.name;
    }

    public set Name(value:string) {
        this.name = value;
    }

    public Add(point:GraphPoint) {
        this.data.push(point);
        this.data.sort((x:GraphPoint,y:GraphPoint) => {
            return (x.dateEntered.localeCompare(y.dateEntered));
        });        
    }
    
}