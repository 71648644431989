import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SMARTLOOK_SAFE_ROUTES } from '../smartlook-safe-routes';
import { CurrentLocation } from './models';

@Injectable()
export class NavigationService {

    private routeChanged:BehaviorSubject<CurrentLocation>;
    private counter:number;
    
    constructor() {
        this.counter = 0;
        this.routeChanged = new BehaviorSubject<CurrentLocation>(null);
    }

    public get RouteChanged():Observable<CurrentLocation> {
        return this.routeChanged.asObservable();
    }

    public InformRouteChanged(url:string) {
        const smartlook = (window as any).smartlook;
        if (smartlook) {
            if (SMARTLOOK_SAFE_ROUTES.indexOf(url) >= 0) {
                smartlook('resume');
            } else {
                smartlook('pause');
            }
        }
        this.counter++;
        const currentLocation: CurrentLocation = new CurrentLocation();
        currentLocation.count = this.counter;
        currentLocation.url = url;
        this.routeChanged.next(currentLocation);
    }
}
