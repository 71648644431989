import { Component, OnInit, OnDestroy } from '@angular/core';
import { AlertMessage } from '../../core/services/models';
import { AdminService, LoadingService } from '../../core/services';
import { BaseComponent } from '../../core/component/base.component';
import { ActivatedRoute,Router, NavigationEnd } from '@angular/router';
import { StringHelper } from '../../shared/helpers/string.helper';
import { Subscription } from 'rxjs';
import { confirm, alert } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-alert-messages',
  templateUrl: './alert-messages.component.html',
  styleUrls: ['./alert-messages.component.scss']
})
export class AlertMessagesComponent extends BaseComponent implements OnInit, OnDestroy {

  messages:Array<AlertMessage> = [];
  navigationSubscription:Subscription;
  
  constructor(public loadingService:LoadingService,public adminService:AdminService,private router: Router, private route: ActivatedRoute) { 
    super(); 
    
    this.navigationSubscription = this.router.events.subscribe((e:any) => {
      if(e instanceof NavigationEnd) {       
        if(this.route.snapshot.data.messages) {
          this.messages = this.route.snapshot.data.messages;          
          if(this.messages.length > 1) {
            this.initializeMessages();
          }
        }
      }
    });
    
  }

  private initializeMessages() {
    const controllers: Array<string> = this.messages.map(x => x.controllerName);
    const start: string = StringHelper.sharedStart(controllers);
    for(let i=0; i < this.messages.length; i++) {
      const message: AlertMessage = this.messages[i];
      message.controllerName = message.controllerName.replace(start,"");
    }
  }

  ngOnInit() {
  }

  addMessagePopUp() {
    this.router.navigate(["add-message"], { relativeTo: this.route });
  }

  deleteMessageConfirmation($event,data) {

    if($event) {
      $event.event.stopPropagation();
    }

    const result: Promise<boolean> = confirm("Are you sure?", "Confirmation");
    result.then((dialogResult) => {
        if(dialogResult) {
          this.loadingService.loading = true;
          this.adminService.deleteMessage(data.id).subscribe(() => {
            this.loadingService.loading = false;
            alert("Alert Message has been removed","Information");
            this.router.navigate(['alert-messages'], { relativeTo: this.route.parent });
          });
        }
    });

  }

  onMessageSelected(data) {
    this.router.navigate([data.id], { relativeTo: this.route });
  }

  ngOnDestroy() {    
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }

}
