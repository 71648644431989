<app-card label="Secure Mail" class="ctcamailcard">

  
  <div card-buttons-desktop *ngIf="canSendMessages && hasCareTeam">
    <dx-button class="ctca-button" text="Compose New Message" icon="fa fa-pencil" (keyup)="handleKey($event)" (click)="sendMessage()"></dx-button>
  </div>
  
  <div card-buttons-mobile class="noCareTeamContainer" *ngIf="canSendMessages && !hasCareTeam && prettyPhone">
      <div class="noCareTeam">
        <div class="top">QUESTIONS FOR YOUR CARE TEAM?</div>
        <div class="bottom">Call <a href="tel:{{cleanPhone}}">{{ prettyPhone }}</a></div>
      </div>
  </div>
  <div card-buttons-desktop class="noCareTeamContainer" *ngIf="canSendMessages && !hasCareTeam && prettyPhone">
    <div class="noCareTeam">
      <div class="top">QUESTIONS FOR YOUR CARE TEAM?</div>
      <div class="bottom">Call <a href="tel:{{cleanPhone}}">{{ prettyPhone }}</a></div>
    </div>
  </div>

  <dx-tabs class="ctcatabs" [items]="tabs" selectedIndex="{{currentTabIndex}}" (onItemClick)="tabClick($event)"></dx-tabs>
  <div class="msg">
    <label for="search-box" class="visuallyhidden">Search</label>
    <dx-list 
    [dataSource]="messages" searchMode="contains" class="ctcasearch" 
    [searchExpr]="['from', 'subject','sentAsString','comments']" [focusStateEnabled]="false" 
    [activeStateEnabled]="false" [searchEnabled]="true" [noDataText]="noDataText()"
    >
      <dxo-search-editor-options
          placeholder="Search"
          [inputAttr]="{ id: 'search-box', class:'ctcasearchbox' }"
          [elementAttr]="{class:'ctcasearchelement'}">
      </dxo-search-editor-options>
      <div *dxTemplate="let msg of 'item'" class="ctcalistitem">
        <div [class.unread]="!msg.isRead" class="ctcalistitemcontent" (click)="viewMessageDetails(msg)">
          <div class="msg-from">{{msg.from}}</div>
          <div class="msg-subject desktop-only">{{msg.subject}}</div>
          <div class="msg-date">{{msg.sentAsString}}</div>
          <div class="clearfix"></div>
          <div class="msg-subject mobile-only">{{msg.subject}}</div>
        </div>
      </div>
    </dx-list>
    <dx-button *ngIf="canSendMessages && hasCareTeam" class="floating-button mobile-only" text="Compose" icon="fa fa-pencil" (keyup)="handleKey($event)" (click)="sendMessage()"></dx-button>
  </div>
</app-card>
<router-outlet></router-outlet>
