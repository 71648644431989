import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseService } from './base.service';
import { AppointmentDetail, AppointmentRequest, AppointmentRequestV2 } from './models';
import { SendAppointmentsSchedule } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';

@Injectable()
export class AppointmentsService extends BaseService {
	
	getAppointments():Observable<Array<AppointmentDetail>> {
		return this.get<Array<AppointmentDetail>>({
			endpoint: ServiceEndpoint.APPOINTMENTS_GETAPPOINTMENTS
		});
	}

	getAppointmentsByDate(startDate:string,endDate:string):Observable<Array<AppointmentDetail>> {
		return this.get<Array<AppointmentDetail>>({
			endpoint: ServiceEndpoint.APPOINTMENTS_GETAPPOINTMENTS,
			params:{
				startDate:startDate,
				endDate:endDate
			}
		});
	}

	getAppointment(appointmentId: string) {
		return this.post<AppointmentDetail>({
			endpoint: ServiceEndpoint.APPOINTMENTS_GETAPPOINTMENT,
			body: { appointmentId: appointmentId }		
		});
	}

	requestAppointment(request: AppointmentRequest) {
		return this.post({
			endpoint: ServiceEndpoint.APPOINTMENTS_REQUESTAPPOINTMENT,
			body: request
		});
	}

	cancelAppointment(request: AppointmentRequest) {
		return this.post({
			endpoint: ServiceEndpoint.APPOINTMENTS_CANCELAPPOINTMENT,
			body: request
		});
	}

	rescheduleAppointment(request: AppointmentRequest) {
		return this.post({
			endpoint: ServiceEndpoint.APPOINTMENTS_RESCHEDULEAPPOINTMENT,
			body: request
		});
	}

	downloadSchedule(startDate: Date, endDate: Date) {
		return this.getBlob({
			endpoint: ServiceEndpoint.APPOINTMENTS_GETAPPOINTMENTSREPORT,
			params: {
				startDate: startDate.toDateString(),
				endDate: endDate.toDateString()
			}
		});
	}

	confirmAppointment(apptTime: string, apptId: string): Observable<Array<any>> {
		return this.post<Array<any>>({
			endpoint: ServiceEndpoint.APPOINTMENTS_CONFIRMAPPOINTMENT,
			params: {
				apptTime,
				apptId
			}
		});
	}

	requestAppointmentv2(request: AppointmentRequestV2) {
		return this.post({
			endpoint: ServiceEndpoint.APPOINTMENTS_REQUESTAPPOINTMENTV2,
			body: request
		});
	}

	cancelAppointmentv2(request: AppointmentRequestV2) {
		return this.post({
			endpoint: ServiceEndpoint.APPOINTMENTS_CANCELAPPOINTMENTV2,
			body: request
		});
	}

	rescheduleAppointmentv2(request: AppointmentRequestV2) {
		return this.post({
			endpoint: ServiceEndpoint.APPOINTMENTS_RESCHEDULEAPPOINTMENTV2,
			body: request
		});
	}

	sendAppointmentsSchedule(request: SendAppointmentsSchedule) {
		if (request.sendBy === 'email') {
			return this.post({
				endpoint: ServiceEndpoint.APPOINTMENTS_SENDSCHEDULEBYEMAIL,
				body: request
			});
		} else if (request.sendBy === 'mail') {
			return this.post({
				endpoint: ServiceEndpoint.APPOINTMENTS_SENDSCHEDULEBYMAIL,
				body: request
			});
		}
	}
}
