import { NgModule } from '@angular/core';
import { PhoneFieldModule } from '../core/phone-field/phone-field.module';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxListModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTabsModule,
  DxTextAreaModule,
  DxTextBoxModule,
  DxValidatorModule,
  DxLoadPanelModule,
  DxContextMenuModule,
  DxTagBoxModule,
  DxCheckBoxModule,
  DxToolbarModule
} from 'devextreme-angular';
import { DxiToolbarItemModule } from 'devextreme-angular/ui/nested';
import { PrescriptionRenewalRequestComponent } from './prescription-renewal-request.component';
import { PrescriptionRenewalRequestRoutingModule } from './prescription-renewal-request.routing.module';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxTabsModule,
    DxListModule,
    DxPopupModule,
    DxScrollViewModule,
    DxTextBoxModule,
    DxTextAreaModule,
    DxValidatorModule,
    DxLoadPanelModule,
    DxContextMenuModule,
    DxTagBoxModule,
    PrescriptionRenewalRequestRoutingModule,
    DxCheckBoxModule,
    DxToolbarModule,
    DxiToolbarItemModule,
    PhoneFieldModule
  ],
  declarations: [PrescriptionRenewalRequestComponent],
  exports: [PrescriptionRenewalRequestComponent]
})
export class PrescriptionRenewalRequestModule { }
