import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../../app/core/services';
import { UserProfile } from '../../app/core/services/models';

@Component({
  selector: 'app-patient-safety-notification',
  templateUrl: './patient-safety-notification.component.html',
  styleUrls: ['./patient-safety-notification.component.scss']
})
export class PatientSafetyNotificationComponent implements  OnInit {
  constructor(private profileSvc: UserProfileService) {
    this.userProfile = null;
   }

  userProfile: UserProfile;

  ngOnInit() {
    this.profileSvc.getUserProfileHardCache().subscribe((profile:UserProfile) => {
			this.userProfile = profile;
    });
  }
}
