import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TermsOfUseAgreementComponent } from './terms-of-use-agreement/terms-of-use-agreement.component';
import { TermsOfUseRejectedComponent } from './terms-of-use-rejected/terms-of-use-rejected.component';

import {
  DxButtonModule,
  DxCheckBoxModule,
  DxPopupModule,
  DxScrollViewModule,
  DxTextBoxModule,
  DxRadioGroupModule, 
  DxDateBoxModule  
} from 'devextreme-angular';


import { TermsOfUseRoutingModule } from './terms-of-use-routing.module';


@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxPopupModule,
    DxScrollViewModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxRadioGroupModule,
    DxDateBoxModule,
    TermsOfUseRoutingModule
  ],
  declarations: [    
    TermsOfUseAgreementComponent,TermsOfUseRejectedComponent
  ],
  providers:[
    
  ]
})
export class TermsOfUseModule {}