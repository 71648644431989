import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { DocumentExchangeService } from '../services';
import { CCDADocumentList } from '../services/models';

@Injectable()
export class ResolveCcdaDocumentListGuard implements Resolve<CCDADocumentList[]> {
  constructor(private docXSvc: DocumentExchangeService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): CCDADocumentList[] | Observable<CCDADocumentList[]> | Promise<CCDADocumentList[]> {
    const docs =  this.docXSvc.getCCDADocumentList();
    return docs.pipe(()=>{
      localStorage.setItem('startTimeOfClinicalSummaries',Date.now().toString());
      return docs;
    })
  }
}
