import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { AskQuestionComponent } from "./ask-question.component";
import { AskQuestionRoutingModule } from "./ask-question.routing.module";
import { ButtonModule } from "app/core/button/button.module";
import { DxButtonModule, DxCalendarModule, DxCheckBoxModule, DxDateBoxModule, DxLoadPanelModule, DxSelectBoxModule, DxTagBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";
import { AskQuestionNonMedicalComponent } from "./ask-question-non-medical/ask-question-non-medical.component";
import { AskQuestionNonMedicalSubjectComponent } from "./ask-question-non-medical-subject/ask-question-non-medical-subject.component";
import { AskQuestionNonMedicalReasonComponent } from "./ask-question-non-medical-appointment-reason/ask-question-non-medical-reason.component";
import { AskQuestionNonMedicalAppointmentTimeComponent } from "./ask-question-non-medical-appointment-time/ask-question-non-medical-appointment-time.component";
import { AskQuestionNonMedicalScheduleDeliveryComponent } from "./ask-question-non-medical-schedule-delivery/ask-question-non-medical-schedule-delivery.component";
import { AskQuestionNonMedicalScheduleRangeComponent } from "./ask-question-non-medical-schedule-range/ask-question-non-medical-schedule-range.component";
import { AskQuestionNonMedicalSelectAppointmentComponent } from "./ask-question-non-medical-select-appointment/ask-question-non-medical-select-appointment.component";
import { AskQuestionNonMedicalReviewComponent } from "./ask-question-non-medical-review/ask-question-non-medical-review.component";
import { TagsModule } from "app/core/tags/tags.module";
import { ContactUsStateService, FormLibraryService } from "app/core/services";
import { SelectableItemModule } from "app/core/selectable-item/selectable-item.module";
import { AskQuestionNonMedicalContactComponent } from "./ask-question-non-medical-appointment-contact/ask-question-non-medical-contact.component";
import { PhoneFieldModule } from "app/core/phone-field/phone-field.module";
import { AskQuestionNonMedicalRoiComponent } from "./ask-question-non-medical-roi/ask-question-non-medical-roi.component";
import { AskQuestionNonMedicalCompleteRoiComponent } from "./ask-question-non-medical-complete-roi/ask-question-non-medical-complete-roi.component";
import { AlertV2Module } from "app/core/alert/alertv2.module";
import { AskQuestionNonMedicalEmailComponent } from "./ask-question-non-medical-email/ask-question-non-medical-email.component";

@NgModule({
    imports: [
        SharedModule,
        AskQuestionRoutingModule,
        ButtonModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxTagBoxModule,
        DxValidatorModule,
        DxButtonModule,
        TagsModule,
        DxCalendarModule,
        SelectableItemModule,
        PhoneFieldModule,
        DxDateBoxModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        AlertV2Module,
        DxLoadPanelModule,
    ],
    declarations: [
        AskQuestionComponent,
        AskQuestionNonMedicalComponent,
        AskQuestionNonMedicalSubjectComponent,
        AskQuestionNonMedicalReasonComponent,
        AskQuestionNonMedicalAppointmentTimeComponent,
        AskQuestionNonMedicalScheduleDeliveryComponent,
        AskQuestionNonMedicalScheduleRangeComponent,
        AskQuestionNonMedicalSelectAppointmentComponent,
        AskQuestionNonMedicalReviewComponent,
        AskQuestionNonMedicalContactComponent,
        AskQuestionNonMedicalRoiComponent,
        AskQuestionNonMedicalCompleteRoiComponent,
        AskQuestionNonMedicalScheduleRangeComponent,
        AskQuestionNonMedicalScheduleDeliveryComponent,
        AskQuestionNonMedicalEmailComponent,
    ],
    providers: [
        ContactUsStateService,
        FormLibraryService
    ]
})
export class AskQuestionModule {}


