import { HtmlTransformerImpl } from './HtmlTransformerImpl';
import { Injectable } from '@angular/core';

@Injectable()
export class HtmlTransformerService {
	constructor() {}

	load(html: string): HtmlTransformerImpl {
		return new HtmlTransformerImpl(html);
	}
}
