import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationError } from '@angular/router';
import * as moment from 'moment';
import { BaseComponent } from '../../core/component/base.component';
import { MailFolder, SecureMailMessage, UserProfile, CurrentLocation, FacilityInfo } from '../../core/services/models';
import { Subscription } from 'rxjs';
import { SecureMailService, LoadingService, UserProfileService, NavigationService, SupportService } from '../../core/services';
import { DxContextMenuComponent } from 'devextreme-angular';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

export enum MoreOption {
	SendSecureMessage
}

@Component({
	selector: 'app-secure-mail-list',
	templateUrl: './secure-mail-list.component.html',
	styleUrls: ['./secure-mail-list.component.scss']
})
export class SecureMailListComponent extends BaseComponent implements OnInit, OnDestroy {

	@ViewChild('moreContextMenu', { static: false }) moreContextMenu: DxContextMenuComponent;
	
	tabs = [
		{ text: 'Inbox', url: 'inbox', folder: MailFolder.Inbox },
		{ text: 'Sent', url: 'sent', folder: MailFolder.SentItems },
		{ text: 'Archived', url: 'archived', folder: MailFolder.DeletedItems }
	];

	moreOptions = [
		{ id: MoreOption.SendSecureMessage, 
			text: 'Send New Message', 
			icon: 'fa fa-envelope-o' 
		}
 	];

	currentTab: { text: string; url: string; folder: MailFolder };
	currentTabIndex = -1;
	messages: Array<SecureMailMessage>;
	selectedMessage: SecureMailMessage;
	navigationSubscription:Subscription;
	canMarkAsRead = false;
	canSendMessages = false;
	hasCareTeam: boolean;
	facilityInfo: FacilityInfo;
	prettyPhone: string;
	cleanPhone: string;

	constructor(
		private route: ActivatedRoute,
		private router: Router,		
		private secureMailSvc:SecureMailService,
		private loadingService:LoadingService,
		private userProfileService: UserProfileService,
		private navigationService: NavigationService,
		private supportService: SupportService
	) {
		super();
		
		this.secureMailSvc.hasCareTeam().then((hasCareTeam) => {
			this.hasCareTeam = hasCareTeam;
		});

		this.navigationSubscription = this.navigationService.RouteChanged.subscribe((location:CurrentLocation) => {
			
			
			if(location.url === '/app/secure-mail/inbox' || location.url === '/app/secure-mail/sent' || location.url === '/app/secure-mail/archived') {
				const startTime = Date.now();
				this.loadingService.loading = true;		
				const view = this.route.snapshot.params['view'];
				const id = this.route.snapshot.params['id'];
				const folder = this.getFolder(view);
				if(folder !== MailFolder.Error && !id) {
					this.secureMailSvc.getSecureMessages(folder).subscribe((allMessages: Array<SecureMailMessage>) => {
						this.messages = allMessages;
						this.messages = this.messages.map<SecureMailMessage>( (message:SecureMailMessage):SecureMailMessage => { 
							message.sentAsString = moment(message.sent).format("MMM D");
							return message;
						});				
						this.currentTabIndex = this.tabs.findIndex(tab => tab.url === view);
						this.currentTab = this.tabs[this.currentTabIndex];
						this.loadingService.loading = false;	
						const endTime = Date.now();
						const elapsedTime = (endTime-startTime)/1000;
						if(window.location.pathname.split('/').length<=4 && window.location.pathname.includes('secure-mail'))
						trackPageLoadingTime(window.location.pathname,elapsedTime);
					});
				} else {
					this.loadingService.loading = false;
					throw(new NavigationError(0,this.router.url,'inbox, sent or archived were not part of the url'));
				} 
			}
				
		});	
	}


	getFolder(view: string): MailFolder {
		switch (view) {
		  
		  case 'inbox':
			return MailFolder.Inbox;
		  case 'sent':
			return MailFolder.SentItems;
		  case 'archived':
			return MailFolder.DeletedItems;
		  default:
			return MailFolder.Error;  
		}
	}

	ngOnInit() {

		this.facilityInfo = this.route.snapshot.data.facilityInfo;
		this.prettyPhone = this.facilityInfo.careManagementPhone;
		this.cleanPhone = this.prettyPhone ? '+1' + this.prettyPhone.replace(/[^0-9]/g, '') : '';

		this.userProfileService.getUserProfileHardCache().subscribe((profile:UserProfile) => {
			if(profile && profile.userPermissions && profile.userPermissions.length > 0 && profile.userPermissions.indexOf(this.PermissionLevel.SEND_SECURE_MESSAGES) > -1) {
				this.canSendMessages = this.canMarkAsRead = true;	
			} else {
				this.canSendMessages =this.canMarkAsRead = false;
			}
		});
		
	}

	tabClick(event) {
		this.currentTab = event.itemData;	
		this.router.navigate([`../${this.currentTab.url}`], { relativeTo: this.route });		
	}	

	handleKey(event: KeyboardEvent){
		if(event.keyCode === 13){
			this.sendMessage();
		}
	}

	viewMessageDetails(msg: SecureMailMessage) {
		if(this.canMarkAsRead && !msg.isRead) {
			this.loadingService.loading = true;
			this.secureMailSvc.setSecureMessageRead(msg.mailMessageId).subscribe(() => {
				msg.isRead = true;
				this.loadingService.loading = false;		
				this.supportService.updateBadgesCount();
				this.router.navigate([this.currentTab.url,msg.mailMessageId],{relativeTo:this.route.parent});
			}, error => {
				this.loadingService.loading = false;
				throw(error);
			});
		} else {
			this.router.navigate([this.currentTab.url,msg.mailMessageId],{relativeTo:this.route.parent});
		}
	}

	showMoreOptions(event: any) {
		this.moreContextMenu.instance.show();
	}

	onMoreContextMenuItemClick(event: any) {
		
		if(event && event.itemData.id === MoreOption.SendSecureMessage ) {
			this.sendMessage();
		}
		
	}

	sendMessage() {
		this.router.navigate(['app', 'ask-question']);
	}

	ngOnDestroy() {		
		if(this.navigationSubscription) {
			this.navigationSubscription.unsubscribe();
		}
	}

	noDataText() {
		if (this.loadingService.loading) {
			return '';
		}
		const view = this.route.snapshot.params['view'];
		switch (view) {
			case 'archived':
				return `You have no archived messages.`;
			case 'sent':
				return `You have not sent any messages.`;
			case 'inbox':
				return `You have no received messages.`;
		}
		return 'There are no messages do display here.'
	}
}
