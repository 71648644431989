<app-card label="Contact Technical Support">
  <div class="contact-tech-support">
    <div class="call-us">
      <p class="title">Call Us</p>
      <p>
        If this is a medical emergency, call 911 or your local emergency number for assistance.
      </p>
      <p>
        If you need an immediate response related to your care, please call your Care Manager.
      </p>
      <p>
        For immediate assistance with your myCTCA.com Portal account, please call Technical Support: 1-800-234-0482
      </p>
    </div>
    <!--div class="write-us">
      <p class="title">Write Us</p>
      <p *appShowFor="PermissionLevel.SEND_SECURE_MESSAGES">Patients: if you have clinical concerns or questions relating to your care, please contact your Care Team using the
        <a class="dead-link" (click)="onSecureMailLinkClick()">Secure Mail</a> feature of myCTCA.com.</p>
      <p>Our goal is to ensure that your myCTCA.com Portal experience is the best that it can be, every time!</p>
      <p>We value your feedback and would like to hear from you. Please take a moment to fill in the form below. Please speak
        freely so that we fully understand your concerns.</p>
      <p>This mailbox is monitored by our IT staff during normal business hours.</p>
    </div>
    <div>
      <dx-button text="Send a Message to Technical Support" icon="fa fa-envelope-o" [routerLink]="['contact-technical-support']"></dx-button>
    </div-->
  </div>
</app-card>
<router-outlet></router-outlet>
