<div class="quick-links-container" smartlook-safe>
  <div class="quick-link-buttons" smartlook-safe>

    <div class="quick-link-button" (click)="requestAppointment()" (keyup.enter)="requestAppointment()">
      <div class="quick-link-button-icon">
        <img src="assets/images/icons/SVG/icon-schedule.svg" alt="Request Appointment">
      </div>
      <div class="quick-link-button-label">Request
        <br />Appointment</div>
    </div>

    
    <div class="quick-link-button" (click)="sendMessage()" (keyup.enter)="sendMessage()">
      <div class="quick-link-button-icon">
        <img [src]="hasCareTeam?'assets/images/icons/SVG/icon-ask-question.svg':'assets/images/icons/SVG/icon-ask-question.svg'" alt="Ask a Question">
      </div>
      <div class="quick-link-button-label">Ask a<br />Question</div>
    </div>
    
    <div class="quick-link-button" (click)="requestRenewal()" (keyup.enter)="requestRenewal()">
      <div class="quick-link-button-icon">
        <img src="assets/images/icons/SVG/icon-prescription.svg"  alt="Request Prescription Renewal">
      </div>
      <div class="quick-link-button-label">Renew
        <br />Prescription</div>
    </div>

    <div class="quick-link-button" (click)="telehealthSetupGuide()" tabindex="0" (keyup.enter)="telehealthSetupGuide()">
      <div class="quick-link-button-icon">
        <img src="assets/images/icons/SVG/icon-telehealth.svg"  alt="Share Records">
      </div>
      <div class="quick-link-button-label">Telehealth<br/>
        Setup Guide
        </div>
    </div>
  </div>
</div>
