<div class="backButton" (click)="close()">
  <img src="assets/images/icons/SVG/chevron-left-green.svg" alt="Back"/>
  Back
</div>
<form *ngIf="requestForm" [formGroup]="requestForm" novalidate>
<div class="card">
  <div class="card-content flexV">
    <img src="assets/images/icons/SVG/close.svg" class="closeCardMobile" (click)="close()" alt="Close"/>
    <h1 class="center">{{id?'Reschedule Request':'Request Appointment'}}</h1>
    <app-progress-bar [totalSteps]="id?4:5" [currentStep]="id?currentStep-1:currentStep"></app-progress-bar>
      <ng-container *ngIf="currentStep==1">
        <h1>What is the reason for your appointment? <i>(required)</i></h1>
        <dx-text-area [inputAttr]="{title:'Reason'}" #reason class="formElement" formControlName="reason" valueChangeEvent="keyup" (change)="markAsDirtyForm()" [height]="100" placeholder="(e.g Nutrition, Lab Follow-Up, Infusion etc.)" [isValid]="requestForm.get('reason').valid || requestForm.get('reason').pristine"></dx-text-area>
        <div class="spacer"></div>
        <div class="buttons">
          <app-button [disabled]="!requestForm.get('reason').valid" text="Next" rightIcon="assets/images/icons/SVG/chevron-right-white.svg" (onClick)="nextStep()"></app-button>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep==2">
        <ng-container *ngIf="id && appointment">
          <h1>Your Appointment Details</h1>
          <app-selectable-item>
            <div class="appointment-date">{{appointment?.startDateTime | date: 'fullDate'}}</div>
            <div class="appointment-description"><b>{{appointment?.description}}</b></div>
            <div class="appointment-details">
              Dr. {{appointment?.resources}}<br/>
              {{appointment?.startDateTime | date: 'shortTime'}} {{appointment?.facilityTimeZone}}, {{appointment?.location}}
            </div>
          </app-selectable-item>
        </ng-container>
        <h1>What is your preferred date and time? <i>(required)</i></h1>
        <i>You can select up to 3 options.</i>
        <div *ngFor="let dateTime of preferredDatesTimes;let i = index">
          <app-selectable-item 
          [checked]="true"
          [title]="dateTime.date | date:'MMMM d, y'"
          [subtitle]="(dateTime.date | date:'EEEE')+', '+(dateTime.time | schedulingHours)"
          [hasEdit]="true"
          [hasDelete]="true"
          (onEdit)="editDateTime(i)"
          (onDelete)="deleteDateTime(i)"
          ></app-selectable-item>
        </div>
        <div *ngIf="preferredDatesTimes.length<3" class="addDatesTimesButton formElement" (click)="addDatesTimes()"><img src="assets/images/icons/SVG/plus-purple.svg" alt="Add"/> Add Dates/Times</div>
        <div class="spacer"></div>
        <div class="buttons">
          <app-button *ngIf="!id" text="Back" buttonStyle="secondary" leftIcon="assets/images/icons/SVG/chevron-left-gray.svg" (onClick)="previousStep()"></app-button>
          <app-button [disabled]="preferredDatesTimes.length==0" text="Next" rightIcon="assets/images/icons/SVG/chevron-right-white.svg" (onClick)="nextStep()"></app-button>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep==3">
        <h1>How would you like us to contact you to confirm your appointment?<i>(required)</i></h1>
        <i>Select your communication preference.</i>
        <app-selectable-item [hasCheckbox]="true" (onClick)="onTogglePhoneCheckbox()" [clickable]="true" [checked]="phoneChecked" title="Call me" [subtitle]="editingPhone?undefined:phoneValue" (onEdit)="editPhone()" [hasEdit]="!editingPhone" [hasClose]="editingPhone" (onClose)="closeEditing()">
          <div *ngIf="editingPhone" class="inlineEdit">
            <app-phone-field [formControl]="phoneControl" [isValid]="true"></app-phone-field>
            <app-button [disabled]="!phoneControl.valid" text="Save" (onClick)="savePhone()"></app-button>
          </div>
        </app-selectable-item>
        <app-selectable-item [hasCheckbox]="true" (onClick)="onToggleEmailCheckbox()" [clickable]="true" [checked]="emailChecked" title="Email me" [subtitle]="editingEmail?undefined:emailValue" (onEdit)="editEmail()" [hasEdit]="!editingEmail" [hasClose]="editingEmail" (onClose)="closeEditing()">
          <div *ngIf="editingEmail" class="inlineEdit">
            <input type="email"  [formControl]="emailControl"/>
            <app-button [disabled]="!emailControl.valid" text="Save" (onClick)="saveEmail()"></app-button>
          </div>
        </app-selectable-item>
        <div class="spacer"></div>
        <div class="buttons">
          <app-button text="Back" buttonStyle="secondary" leftIcon="assets/images/icons/SVG/chevron-left-gray.svg" (onClick)="previousStep()"></app-button>
          <app-button [disabled]="(!phoneChecked && !emailChecked) || editingPhone || editingEmail || (phoneChecked && !phoneValue) || (emailChecked && !emailValue)" text="Next" rightIcon="assets/images/icons/SVG/chevron-right-white.svg" (onClick)="nextStep()"></app-button>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep==4">
        <h1>Please provide any additional comments.<i>(optional)</i></h1>
        <dx-text-area [inputAttr]="{title:'Additional notes'}" #additionalNotes formControlName="additionalNotes" valueChangeEvent="keyup" (change)="markAsDirtyForm()" [height]="100" placeholder="Preferred provider, etc."></dx-text-area>
        <div class="spacer"></div>
        <div class="buttons">
          <app-button text="Back" buttonStyle="secondary" leftIcon="assets/images/icons/SVG/chevron-left-gray.svg" (onClick)="previousStep()"></app-button>
          <app-button text="Next" rightIcon="assets/images/icons/SVG/chevron-right-white.svg" (onClick)="nextStep()"></app-button>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStep==5">
        <h1>Please review your appointment request details.</h1>
        
        <app-selectable-item *ngIf="id">
          <div class="appointment-date">{{appointment?.startDateTime | date: 'fullDate'}}</div>
          <div class="appointment-description"><b>{{appointment?.description}}</b></div>
          <div class="appointment-details">
            Dr. {{appointment?.resources}}<br/>
            {{appointment?.startDateTime | date: 'shortTime'}} {{appointment?.facilityTimeZone}}, {{appointment?.location}}
          </div>
        </app-selectable-item>

        <app-selectable-item *ngIf="!id" title="REASON FOR APPOINTMENT" [checked]="true" [hasEdit]="true" (onEdit)="editStep(1)">
          <div>{{requestForm.get('reason').value}}</div>
        </app-selectable-item>

        <app-selectable-item title="PREFERRED DATES AND TIMES" [checked]="true" [hasEdit]="true" (onEdit)="editStep(2)">
          <ng-container *ngFor="let dateTime of preferredDatesTimes; index as i">
            <hr *ngIf="i>0"/>
            <div><b>{{dateTime.date | date:'MMMM d, y'}}</b></div>
            <div>{{dateTime.date | date:'EEEE'}}, {{dateTime.time | schedulingHours}}</div>
          </ng-container>
        </app-selectable-item>

        <app-selectable-item title="CONTACT PREFERENCE" [checked]="true" [hasEdit]="true" (onEdit)="editStep(3)">
          <div *ngIf="phoneChecked">
            <b>Phone call</b><br/>
            {{phoneValue}}
          </div>
          <div *ngIf="emailChecked">
            <b>Email</b><br/>
            {{emailValue}}
          </div>
        </app-selectable-item>

        <app-selectable-item title="ADDITIONAL COMMENTS" [checked]="true" [hasEdit]="true" (onEdit)="editStep(4)">
          <div *ngIf="requestForm.get('additionalNotes').value else noAdditionalNotes">
            {{requestForm.get('additionalNotes').value}}
          </div>
          <ng-template #noAdditionalNotes>None</ng-template>
        </app-selectable-item>
        <div class="spacer"></div>
        <div class="buttons">
          <app-button text="Submit Request" (onClick)="onSendClick()"></app-button>
        </div>
      </ng-container>
    </div>



    <div class="sidePopup calendar" *ngIf="showCalendar">
      <img class="closeButton" (click)="closeCalendar()" src="assets/images/icons/SVG/close.svg" alt="Close"/>
      <h2>Preferred Date</h2>
      <i>Appointment requests must be at least 24 hours in advance. </i>
      <div class="calendarWrapper">
        <dx-calendar #calendar [(value)]="dateSelected" [disabledDates]="disabledDates" (onValueChanged)="onDateChanged()"></dx-calendar>
      </div>
      <h2>Preferred Time</h2>
      <i>Select all that apply.</i>
      <div class="timesContainer">
        <app-selectable-item [hasCheckbox]="true" [checked]="timeAMChecked" (onClick)="onToggleAMCheckbox()" [clickable]="true" title="Morning" subtitle="8 am - 12 pm"></app-selectable-item>
        <app-selectable-item [hasCheckbox]="true" [checked]="timePMChecked" (onClick)="onTogglePMCheckbox()" [clickable]="true" title="Afternoon" subtitle="12 pm - 4 pm"></app-selectable-item>
      </div>
      <div class="spacer"></div>
      <app-button [disabled]="(!timeAMChecked && !timePMChecked) || !dateSelected" [text]="editingDateIndex>=0?'Save Changes':'Add Option'" (onClick)="addDateTimeOption()"></app-button>
    </div>
    <div class="sidePopup" *ngIf="editingStep==1">
      <img class="closeButton" (click)="closeEditingStep()" src="assets/images/icons/SVG/close.svg" alt="Close"/>
      <h2 class="center">Reason for Appointment</h2>
      <h2>What is the reason for your appointment? <i>(required)</i></h2>
      <dx-text-area [inputAttr]="{title:'Reason'}" #reason formControlName="reasonPopup" valueChangeEvent="keyup" [height]="100" placeholder="(e.g Nutrition, Lab Follow-Up, Infusion etc.)" [isValid]="requestForm.get('reason').valid || requestForm.get('reason').pristine"></dx-text-area>
      <div class="spacer"></div>
      <app-button [disabled]="!requestForm.get('reasonPopup').valid" text="Save Changes" (onClick)="saveStep1()"></app-button>
    </div>

    <div class="sidePopup" *ngIf="editingStep==2">
      <img class="closeButton" (click)="closeEditingStep()" src="assets/images/icons/SVG/close.svg" alt="Close"/>
      <h2 class="center">Preferred Dates and Times</h2>
      <h2>What is your preferred date and time? <i>(required)</i></h2>

      <div *ngFor="let dateTime of preferredDatesTimesModal; let i = index">
        <app-selectable-item 
          [checked]="true"
          [title]="dateTime.date | date:'MMMM d, y'"
          [subtitle]="(dateTime.date | date:'EEEE')+', '+(dateTime.time | schedulingHours)"
          [hasEdit]="true"
          [hasDelete]="true"
          (onEdit)="editDateTime(i)"
          (onDelete)="deleteDateTime(i)"
        ></app-selectable-item>
      </div>
      <div *ngIf="preferredDatesTimesModal.length<3" class="addDatesTimesButton" (click)="addDatesTimes()">Add Dates/Times</div>
      <div class="spacer"></div>
      <app-button [disabled]="preferredDatesTimesModal.length==0" text="Save Changes" (onClick)="saveStep2()"></app-button>
    </div>

    <div class="sidePopup" *ngIf="editingStep==3">
      <img class="closeButton" (click)="closeEditingStep()" src="assets/images/icons/SVG/close.svg" alt="Close"/>
      <h2 class="center">Contact Preference</h2>
      <h2>How would you like us to contact you to confirm your appointment? <i>(required)</i></h2>
      <app-selectable-item [hasCheckbox]="true" (onClick)="onTogglePhoneCheckboxModal()" [clickable]="true" [checked]="phoneCheckedModal" title="Call me" [subtitle]="editingPhone?undefined:phoneValueModal" (onEdit)="editPhoneModal()" [hasEdit]="!editingPhone" [hasClose]="editingPhone" (onClose)="closeEditing()">
        <div *ngIf="editingPhone" class="inlineEdit">
          <app-phone-field [formControl]="phoneControl" [isValid]="true"></app-phone-field>
          <app-button [disabled]="!phoneControl.valid" text="Save" (onClick)="savePhoneModal()"></app-button>
        </div>
      </app-selectable-item>
      <app-selectable-item [hasCheckbox]="true" (onClick)="onToggleEmailCheckboxModal()" [clickable]="true" [checked]="emailCheckedModal" title="Email me" [subtitle]="editingEmail?undefined:emailValueModal" (onEdit)="editEmailModal()" [hasEdit]="!editingEmail" [hasClose]="editingEmail" (onClose)="closeEditing()">
        <div *ngIf="editingEmail" class="inlineEdit">
          <input type="email" [formControl]="emailControl"/>
          <app-button [disabled]="!emailControl.valid" text="Save" (onClick)="saveEmailModal()"></app-button>
        </div>
      </app-selectable-item>
      <div class="spacer"></div>
      <app-button [disabled]="(!phoneCheckedModal && !emailCheckedModal) || editingPhone || editingEmail || (phoneCheckedModal && !phoneValueModal) || (emailCheckedModal && !emailValueModal)" text="Save Changes" (onClick)="saveStep3()"></app-button>
    </div>

    <div class="sidePopup" *ngIf="editingStep==4">
      <img class="closeButton" (click)="closeEditingStep()" src="assets/images/icons/SVG/close.svg" alt="Close"/>
      <h2 class="center">Additional Comments</h2>
      <h2>Do you have any additional comments? <i>(optional)</i></h2>
      <dx-text-area [inputAttr]="{title:'Additional notes'}" #additionalNotes formControlName="additionalNotesPopup" valueChangeEvent="keyup" [height]="100" placeholder="Preferred provider, etc."></dx-text-area>
      <div class="spacer"></div>
      <app-button text="Save Changes" (onClick)="saveStep4()"></app-button>
    </div>
    <app-alertv2 
      *ngIf="showSuccessAlert"
      title="Request Sent" description="We will contact you within 1 business day to confirm your request."
      buttonText="Ok" (onClose)="onSuccessCloseClick()"
    ></app-alertv2>
  </div>
</form>
<router-outlet></router-outlet>