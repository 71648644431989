import { NgModule } from '@angular/core';
import { SharedModule } from '../../app/shared/shared.module';
import { PatientSafetyNotificationRoutingModule } from './patient-safety-notification-routing.module';
import { PatientSafetyNotificationComponent } from './patient-safety-notification.component';
import {
  DxButtonModule,
  DxTemplateModule,
  DxPopupModule,
  DxScrollViewModule
} from 'devextreme-angular';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    DxTemplateModule,
    DxPopupModule,
    DxScrollViewModule,
    PatientSafetyNotificationRoutingModule
  ],
  declarations: [PatientSafetyNotificationComponent]
})
export class PatientSafetyNotificationModule { }