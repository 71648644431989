<dx-popup class="view-message-popup" #viewMessageModal [visible]="visible$ | async" [title]="message?.subject" width="520" height="720" (onHidden)="navigateToParent()">
  <ng-container *ngIf="message" smartlook-safe>
    <dxi-toolbar-item *ngIf="shouldArchive()" [visible]="!isMobile" [toolbar]="'bottom'" [location]="'before'" [widget]="'dxButton'" [options]="{ text: 'Archive', icon: 'ctca-archive', type: 'default', onClick: onArchiveClick, elementAttr: { class: 'ctcabutton simple' } }">
    </dxi-toolbar-item>
    <dxi-toolbar-item *ngIf="shouldRestore()" [visible]="!isMobile" [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Restore', icon: 'fa fa-undo', type: 'default', onClick: onUnArchiveClick, elementAttr: { class: 'ctcabutton simple' } }">
    </dxi-toolbar-item>
    <dxi-toolbar-item [toolbar]="'bottom'" [visible]="!isMobile" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Download', icon: 'ctca-download', type: 'default', onClick: onDownloadAsPDFClicked, elementAttr: { class: 'ctcabutton white' } }">
    </dxi-toolbar-item>  
    <dxi-toolbar-item *ngIf="canSendMessages && hasCareTeam" [visible]="!isMobile" [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Reply', icon: 'ctca-reply', type: 'default', onClick: onReplyClick, elementAttr: { class: 'ctcabutton regular' } }">
    </dxi-toolbar-item>
    
    <dxi-toolbar-item *ngIf="canSendMessages && hasCareTeam" [visible]="isMobile" [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Reply', icon: 'ctca-reply', type: 'default', onClick: onReplyClick, elementAttr: { class: 'ctcabutton wide' } }">
    </dxi-toolbar-item>

    <dxi-toolbar-item [visible]="isMobile" [toolbar]="'top'" [location]="'after'" [widget]="'dxButton'" [options]="{ icon: 'fa fa-ellipsis-v', onClick: showMoreOptions, elementAttr: { id: 'more-button',class: 'ctcabutton simple' } }">
    </dxi-toolbar-item>
    
  </ng-container>  
  <div *dxTemplate="let data of 'content'" class="popup-content" id="message-content">
    <dx-context-menu #moreContextMenu [dataSource]="moreOptions" target="#more-button" [position]="{ at: 'bottom left' }" (onItemClick)="onMoreContextMenuItemClick($event)">
    </dx-context-menu>
    <div *ngIf="!hasCareTeam && prettyPhone" class="noCareTeamLine onlyDesktop topMargin">Have a Question? Call your Care Team <a href="tel:{{cleanPhone}}">{{ prettyPhone }}</a><hr/></div>
    <dx-scroll-view>
      <ng-container *ngIf="message">
        <ng-container *ngIf="!completeData">
          <p>
            <strong>Date:</strong> {{message.sent | date: 'dd-MMM-yyyy hh:mm a'}}
          </p>
          <p>
            <strong>From:</strong> {{message.from}}
          </p>
          <p>
            <strong>To:</strong> {{selectedTo}}
          </p>
          <p class="hardBreaks" [innerHtml]="comments"></p>  
        </ng-container>
        <ng-container *ngIf="completeData">
          <div *ngFor="let m of messages;let i = index" [class]="threadClass(i)" (click)="toggleThreadClass(i)">
              <div class="header" *ngIf="m.from">
                <div class="initials">{{initialsFromName(m.from)}}</div>
                <div class="middle">
                  <div class="name">{{m.from}}</div>
                  <div *ngIf="selectedTo?.length>0 && isSelf(m.from)" class="recipients">to {{selectedTo}}</div>
                </div>
                <div class="date">{{m.date}}</div>
              </div>
              <p class="message" [innerHtml]="m.message"></p>
          </div>
        </ng-container>
      </ng-container>
    </dx-scroll-view>
    <div class="noCareTeamFooter onlyMobile sideMargins" *ngIf="!hasCareTeam && prettyPhone">
      Have a Question?<br/>
      Call your Care Team <a href="tel:{{cleanPhone}}">{{ prettyPhone }}</a>
    </div>
  </div>
</dx-popup>
<router-outlet></router-outlet>
