<app-card label="Preferences" *appShowFor="PermissionLevel.MANAGE_PREFERENCES">
     
      <form *ngIf="preferencesForm" [formGroup]="preferencesForm" class="outer" style="margin-bottom: 20px;" novalidate>      
        
                <div class="inner">
                    <label for="lookAhead" class="label">Future Appointments lookup days:</label>
                    <div class="outer">
                        <dx-text-box [elementAttr]="{ style: 'margin-top:10px;', class:'inner' }" [width]="70" [inputAttr]="{ id: 'lookAhead' }" formControlName="lookAhead" [isValid]="preferencesForm.get('lookAhead').valid"></dx-text-box>                        
                    </div>
                </div>

                <div *ngIf="!preferencesForm.get('lookAhead').valid" class="outer" style="margin-top: 10px;">
                    <div class="inner red" *ngFor="let error of errors">{{error}}</div>
                </div>

                <div class="inner">
                    <div class="outer">
                        <dx-check-box [elementAttr]="{ id: 'termsOfUse', style: 'margin-top:10px;', class:'inner' }" text="Agreed to the Terms Of Use" formControlName="termsOfUse"></dx-check-box>                        
                    </div>                    
                </div>

                <div class="inner">
                    <div class="outer">
                        <a class="dead-link" (click)="goToTermsOfUse()">Click to review</a>
                    </div>
                </div>
        
      </form>

      <div class="outer">
        <dx-button class="inner" text="Update Preferences" (onClick)="updatePreferences()"></dx-button>   
      </div>
    
    
</app-card>

<app-notifications *appShowFor="PermissionLevel.MANAGE_NOTIFICATIONS"></app-notifications>

<router-outlet></router-outlet>
