import { Pipe, PipeTransform } from '@angular/core';

export const schedulingHoursPipeTransform = (input: string): string => {
    const noon = '12 pm';
    let startTime = '8 am';
    let endTime = '4pm';
    if (input === 'MORNING') {
        endTime = noon;
    } else if (input === 'AFTERNOON') {
        startTime = noon;
    }
    return startTime + ' - ' + endTime;
}

@Pipe({
    name: 'schedulingHours'
})
export class SchedulingHoursPipe implements PipeTransform {
    transform(input: string): string {
        return schedulingHoursPipeTransform(input);
    }
}
