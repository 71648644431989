export enum PermissionLevel {
	NO_REQUIREMENTS = 'No Requirements', // permission to uses when there are no permission requirements

	ADD_ALTERNATE_CONTACT = 'Add Alternate Contact',
	CANCEL_APPOINTMENT = 'Cancel Appointment',
	DOWNLOAD_ANNC_FORM = "Download ANNC Form",
	DOWNLOAD_CCDA_DOCUMENTS = 'Download CCDA Documents',
	EDIT_ALTERNATE_CONTACT = 'Edit Alternate Contact',
	EDIT_CONTACT = 'Edit Contact',
	EDIT_MEDICAL_DOCUMENT_TYPES = 'Edit Medical Document Types',
	LOAD_NPI_FILES = 'Load NPI Files',
	MANAGE_ASSIGNABLE_CLAIMS = 'Manage assignable claims',
	MANAGE_NOTIFICATIONS = 'Manage Notifications',
	MANAGE_PREFERENCES = 'Manage Preferences',
	VIEW_PREFERENCES = 'View Preferences',
	MANAGE_SYSTEM_ALERT_MESSAGES = 'Manage System Alert Messages',
	MANAGE_DOCUMENTS_WHITELIST = 'Manage Documents White List',
	REQUEST_APPOINTMENT = 'Request Appointment',
	REQUEST_PRESCRIPTION_REFILL = 'Request Prescription Refill',
	RESCHEDULE_APPOINTMENT = 'Reschedule Appointment',
	SEND_SECURE_MESSAGES = 'Send Secure Messages',
	SUBMIT_ROI_FORM = 'Submit ROI Form',
	SUBMIT_ANNC_FORM = 'Submit ANNC Form',
	TRANSMIT_CCDA_DOCUMENTS = 'Transmit CCDA Documents',
	VIEW_ACTIVITY_LOGS = 'View Activity Logs',
	VIEW_ALLERGIES = 'View Allergies',
	VIEW_APPLICATION_LOGS = 'View Application Logs',
	VIEW_APPOINTMENTS = 'View Appointments',
	VIEW_BILL_PAY = 'View Bill Pay',
	VIEW_CARE_PLAN = 'View Care Plan',
	VIEW_CCDA_DOCUMENTS = 'View CCDA Documents',
	VIEW_CLINICAL_DOCUMENTS = 'View Clinical Documents',
	VIEW_DOCUMENTS_WHITELIST = 'View Documents White List',
	VIEW_EXTERNAL_LINKS = 'View External Links',
	VIEW_FORMS_LIBRARY = 'View Forms Library',
	VIEW_HEALTH_HISTORY = 'View Health History',
	VIEW_ADMINISTRATION = 'View Administration',
	VIEW_SYSTEM_ALERT_MESSAGES = 'View System Alert Messages',	
	VIEW_HEALTH_ISSUES = 'View Health Issues',
	VIEW_IMAGING_DOCUMENTS = 'View Imaging Documents',
	VIEW_IMMUNIZATIONS = 'View Immunizations',
	VIEW_INTEGRATIVE_DOCUMENTS = 'View Integrative Documents',
	VIEW_LAB_RESULTS = 'View Lab Results',
	VIEW_MEDICAL_CONTACTS = 'View Medical Contacts',
	VIEW_MEDICAL_DOCUMENT_TYPES = 'View Medical Document Types',
	VIEW_MEDICAL_DOCUMENTS = 'View Medical Documents',
	VIEW_MY_ACCOUNT = 'View My Account',
	VIEW_PATIENT_REPORTED_DOCUMENTS = 'View Patient Reported Documents',
	VIEW_PRESCRIPTIONS = 'View Prescriptions',
	VIEW_QUICK_LINKS = 'View Quick Links',
	VIEW_RADIATION_DOCUMENTS = 'View Radiation Documents',
	VIEW_REPORTING = 'View Reporting',
	VIEW_SECURE_MESSAGES = 'View Secure Messages',
	VIEW_USER_LOGS = 'View User Logs',
	VIEW_VITAL_SIGNS = 'View Vital Signs',
	VIEW_CONVERT_ACCOUNT_SECTION = 'View Convert Account Section',
	VIEW_INTERACTIVE = 'View Interactive',
	SUBMIT_PATIENT_REPORTED_DOCUMENTS = 'Submit Patient Reported Documents',
	JOIN_TELEHEALTH_MEETING = 'Join Telehealth Meeting',
	VIEW_CLAIMS = 'View Claims',
}
