import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../core/component/base.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentsService, LoadingService } from '../core/services';

@Component({
  selector: 'app-appointment-confirmed',
  templateUrl: './appointment-confirmed.component.html',
  styleUrls: ['./appointment-confirmed.component.scss']
})
export class AppointmentConfirmedComponent extends BaseComponent implements OnInit {

  confirmed = false;
  failed = false;
  constructor(
    private router: Router,
    public loadingService: LoadingService,
    private appointmentsService: AppointmentsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  goPortalHome() {
    this.loadingService.loading = true;
    this.router.navigate(['app', 'home']);
  }

  ngOnInit() {
    this.loadingService.loading = true;
    this.route.queryParams.subscribe(params => {
      this.appointmentsService
        .confirmAppointment(params['appttime'], params['apptid'])
        .subscribe((response) => {
          this.loadingService.loading = false;
          this.confirmed = true;
        }, (error) => {
          this.loadingService.loading = false;
          this.failed = true;
        });
    });

  }

  get Window() {
    return window;
  }
}
