import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ShmRecipient, UserProfile } from '../services/models';
import { SecureMailService, UserProfileService } from '../services';
import { PermissionLevel } from '../permissions/permission-levels.enum';

@Injectable()
export class ResolveCareTeamsGuard implements Resolve<ShmRecipient[]> {
  constructor(private secureMailSvc: SecureMailService, public profileSvc: UserProfileService) { }
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): ShmRecipient[] | Observable<ShmRecipient[]> | Promise<ShmRecipient[]> {
    return new Promise<ShmRecipient[]>((resolve, reject) => {
      this.profileSvc.getUserProfile().subscribe((profile: UserProfile) => {
        let permission = false;
        permission = profile.userPermissions != null && profile.userPermissions.indexOf(PermissionLevel.VIEW_SECURE_MESSAGES) >= 0;
        // check if logged user has view secure message permission before making the call to getCareTeams
        if (permission) {
          this.secureMailSvc
            .getCareTeams()
            .subscribe((response: ShmRecipient[]) => {
              resolve(response);
            });
        }
        else {
          resolve([]);
        }
      });
    });
  }
}
