import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { initialize, LDClient, LDFlagSet } from 'launchdarkly-js-client-sdk';
import { environment } from '../../../environments/environment';

export const FEATURE_FLAG_PORTAL_OUTAGE = "portal.outage";
export const FEATURE_FLAG_PORTAL_CLAIMS_ENABLED = "portal.claimsEnabled";
@Injectable()
export class LaunchDarklyService {
    ldClient: LDClient;
    flags: LDFlagSet;
    flagChange: Subject<Object> = new Subject<Object>();
    constructor() {
        this.flags = {
            [FEATURE_FLAG_PORTAL_OUTAGE]: {},
            [FEATURE_FLAG_PORTAL_CLAIMS_ENABLED]: false,
        };
        if (environment.launchDarklyKey && environment.launchDarklyKey !== 'false') {
            this.ldClient = initialize(environment.launchDarklyKey,
                { anonymous: true });

            this.ldClient.on('change', (flags) => {
                Object.keys(this.flags).forEach(k => {
                    if (flags[k] !== undefined) {
                        this.flags[k] = flags[k].current;
                    }
                });
                this.flagChange.next(this.flags);
            });

            this.ldClient.on('ready', () => {
                Object.keys(this.flags).map(async (k) => {
                    this.flags[k] = await this.flagValue(k, this.flags[k]);
                });
            })
        }
    }

    public async flagValue(key: string, defaultValue: any) {
        await this.ldClient.waitUntilReady();
        return this.ldClient.variation(key, defaultValue);
    } 
}