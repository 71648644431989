import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentDetail, PatientContactInfo } from '../core/services/models';
import { Popup } from '../core/popup/popup';
import { UserProfileService, ResizingService, ContactInfoService, AppointmentsService, LoadingService, NavigationService } from '../core/services';
import { UserProfile } from '../core/services/models';
import { FormGroup, FormControlName, FormControl, Validators } from '@angular/forms';
import { getAutofillPhoneNumber, phoneNumberValidator } from '../home/shared/helpers/phone.helper';
import { emailOrEmptyValidator } from '../shared/validators/email-or-empty.validator';
import { Subscription } from 'rxjs';
import { trackEvent, trackPageLoadingTime } from '../shared/helpers/tracking.helper';
import { requiredTrimmedValidator } from '../shared/validators/required-trimmed.validator';

enum TimeOfDay {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  ALL_DAY = 'ALL_DAY'
}
interface DateTime {
  date: Date;
  time: TimeOfDay;
}
@Component({
  selector: 'app-request-appointmentv2',
  templateUrl: './request-appointmentv2.component.html',
  styleUrls: ['./request-appointmentv2.component.scss']
})
export class RequestAppointmentV2Component extends Popup implements OnInit, OnDestroy {

  id: string;

  requestForm: FormGroup;
  preferredDatesTimes: DateTime[] = [
    // {
    //   date: new Date(),
    //   time: TimeOfDay.MORNING
    // },
    // {
    //   date: new Date(),
    //   time: TimeOfDay.AFTERNOON
    // }
  ];
  phoneChecked = false;
  emailChecked = false;
  phoneValue = '';
  emailValue= '';
  profile: UserProfile;
  // isDate24HoursAhead = true;
  currentStep = 1;
  showCalendar = false;
  dateSelected?: Date;
  timeAMChecked = false;
  timePMChecked = false;
  editingDateIndex: number;
  closeClicked = false;
  phoneControl = new FormControl('', [Validators.required, phoneNumberValidator]);
  emailControl = new FormControl('', Validators.compose([Validators.required, emailOrEmptyValidator]));

  editingPhone = false;
  editingEmail = false;

  editingStep: number;

  preferredDatesTimesModal: DateTime[] = [];
  // timeAMControl = new FormControl();
  // timePMControl = new FormControl();
  phoneCheckedModal = false;
  emailCheckedModal = false;
  phoneValueModal: string;
  emailValueModal: string;
  navigationSubscription: Subscription;
  appointment: AppointmentDetail;
  showSuccessAlert = false;
  startTimeOfRequestAppt = 0;
  constructor(
    router: Router,
    route: ActivatedRoute,
    private profileSvc: UserProfileService,
    private appointmentService: AppointmentsService,
    private loadingService: LoadingService,
    resizingService: ResizingService,
    private contactInfoService: ContactInfoService,
    private navigationService: NavigationService,
  ) {
    super(router, route, resizingService);
    this.startTimeOfRequestAppt = Date.now();
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;

    FormControlName.prototype.ngOnChanges = function () {
      const result = originFormControlNameNgOnChanges.apply(this, arguments);
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };

  }

  scrollToTop() {
    document.getElementById('content-area').scroll(0, 0);
  }

  ngOnInit() {
    this.scrollToTop();
    this.navigationSubscription = this.navigationService.RouteChanged.subscribe(() => {
      this.id = this.route.snapshot.params['id'];
      this.profileSvc.getUserProfileHardCache().subscribe(profile => (this.profile = profile));
      if (this.id) {
        let startTimeOfReschedule = parseInt(localStorage.getItem('startTimeOfRescheduleApptV2'), 10) || Date.now();
        localStorage.removeItem('startTimeOfRescheduleApptV2');
        this.loadingService.loading = true;
        this.appointmentService.getAppointment(this.id).subscribe((appDetail: AppointmentDetail) => {
          this.appointment = appDetail;

          this.loadingService.loading = false;
          let endTimeOfReschedule = Date.now();
          const elapsedTimeOfReschedule = (endTimeOfReschedule-startTimeOfReschedule)/1000;
          // this value is used to check for the startReschedule, due to if user navigates using browser arrow button
          const tempValueOfStartReschedule = startTimeOfReschedule;
          startTimeOfReschedule = 0;
          endTimeOfReschedule = 0;
          if (window.location.pathname.includes('reschedule-appointmentv2') && tempValueOfStartReschedule !== 0)
          trackPageLoadingTime('/app/reschedule-appointmentv2/:id',elapsedTimeOfReschedule);
        });
        this.currentStep = 2;
        trackEvent('ACTION_APPOINTMENTS_RESCHEDULE_TAP');
      } else {
        this.currentStep = 1;
        trackEvent('ACTION_APPOINTMENTS_REQUEST_TAP');
      }
      this.trackCurrentStep();
    });

    this.profileSvc.getUserProfileHardCache().subscribe(profile => (this.profile = profile));
    let endTimeOfRequestAppt = Date.now();
      const elapsedTimeOfRequestAppt = (endTimeOfRequestAppt-this.startTimeOfRequestAppt)/1000;
      // this value is used to check for the startRequest, due to if user navigates using browser arrow button
      const tempValueOfStartRequest = this.startTimeOfRequestAppt;
      this.startTimeOfRequestAppt = 0;
      endTimeOfRequestAppt = 0;
    if (window.location.pathname.includes('request-appointmentv2') && tempValueOfStartRequest !== 0)
      trackPageLoadingTime(window.location.pathname,elapsedTimeOfRequestAppt);
    this.requestForm = new FormGroup({
      reason: new FormControl('', [requiredTrimmedValidator]),
      additionalNotes: new FormControl(''),

      reasonPopup: new FormControl('', [requiredTrimmedValidator]),
      additionalNotesPopup: new FormControl(''),
      // preferredDateTime: new FormControl('', Validators.compose([Validators.required, datetimeOrEmptyValidator])),
      // phoneNumber: new FormControl('', [Validators.required, phoneNumberValidator]),
    });

    this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
      if (!this.phoneValue) {
        this.phoneValue = getAutofillPhoneNumber(patientContactInfo) || '';
      }
      if (!this.emailValue) {
        this.emailValue = patientContactInfo.emailAddress || '';
      }
    });
  
    this.removeDirtyForm();
  }

  ngOnDestroy() {
    if (!this.showSuccessAlert) {
      trackEvent(this.id ? 'ACTION_APPT_RESCHED_REQ_LEAVE_TAP' : 'ACTION_APPT_NEW_REQ_LEAVE_TAP');
    }
    super.destroy();
    this.removeDirtyForm();
  }

  trackCurrentStep = () => {
    switch (this.currentStep) {
      case 1:
        trackEvent('PAGE_APPT_NEW_REQ_REASON_VIEW');
        break;
      case 2:
        trackEvent(this.id ? 'PAGE_APPT_RESCHED_REQ_PREF_DATETIME_VIEW' : 'PAGE_APPT_NEW_REQ_PREF_DATETIME_VIEW');
        break;
      case 3:
        trackEvent(this.id ? 'PAGE_APPT_RESCHED_REQ_COMM_PREF_VIEW' : 'PAGE_APPT_NEW_REQ_COMM_PREF_VIEW');
        break;
      case 4:
        trackEvent(this.id ? 'PAGE_APPT_RESCHED_REQ_ADDI_COMMENTS_VIEW' : 'PAGE_APPT_NEW_REQ_ADDI_COMMENTS_VIEW');
        break;
      case 5:
        trackEvent(this.id ? 'PAGE_APPT_RESCHED_REQ_SUMMARY_VIEW' : 'PAGE_APPT_NEW_REQ_SUMMARY_VIEW');
        break;
    }
  }

  updateStep = (newStep: number) => {
    this.scrollToTop();
    this.currentStep = newStep;
    this.markAsDirtyForm();
    this.trackCurrentStep();
  }

  nextStep = () => {
    this.updateStep(this.currentStep + 1);
  }
  previousStep = () => {
    this.updateStep(this.currentStep - 1);
  }


  addDatesTimes = () => {
    this.scrollToTop();
    this.editingDateIndex = undefined;
    this.dateSelected = undefined;
    this.showCalendar = true;
  }

  closeCalendar = () => {
    this.scrollToTop();
    this.showCalendar = false;
    this.markAsDirtyForm();
  }

  editDateTime = (i: number) => {
    this.scrollToTop();
    this.editingDateIndex = i;
    const dateTime = (this.editingStep === 2 ? this.preferredDatesTimesModal : this.preferredDatesTimes)[i];
    let isAM = true;
    let isPM = true;
    if (dateTime.time === TimeOfDay.AFTERNOON) {
      isAM = false;
    } else if (dateTime.time === TimeOfDay.MORNING) {
      isPM = false;
    }
    this.dateSelected = dateTime.date;
    this.timeAMChecked = isAM;
    this.timePMChecked = isPM;
    this.showCalendar = true;
  }

  deleteDateTime = (i: number) => {
    (this.editingStep === 2 ? this.preferredDatesTimesModal : this.preferredDatesTimes).splice(i, 1);
  }

  onDateChanged = () => {
    console.log('onDateChanged', this.dateSelected);
    const contentArea = document.getElementById('content-area');
    contentArea.scroll({
      top: contentArea.scrollHeight,
      behavior: 'smooth'
    });
  }

  addDateTimeOption = () => {
    const isAM = this.timeAMChecked;
    const isPM = this.timePMChecked;
    if (this.dateSelected && (isAM || isPM)) {
      const timeOfDay: TimeOfDay = (isAM && isPM) ? TimeOfDay.ALL_DAY : (isAM ? TimeOfDay.MORNING : TimeOfDay.AFTERNOON);
      const dateTime: DateTime = {
        date: this.dateSelected,
        time: timeOfDay
      };
      if (this.editingDateIndex >= 0) {
        (this.editingStep === 2 ? this.preferredDatesTimesModal : this.preferredDatesTimes).splice(this.editingDateIndex, 1, dateTime);
      } else {
        (this.editingStep === 2 ? this.preferredDatesTimesModal : this.preferredDatesTimes).push(dateTime);
      }
      this.timeAMChecked = false;
      this.timePMChecked = false;
      this.closeCalendar();
    }
  }

  isWeekend = (d: Date) => {
    const day = d.getDay();
    return day === 0 || day === 6;
  }

  isPastCutoff = (d: Date) => {
    const todayDate = new Date();
    const todayMs = todayDate.getTime();
    if (todayDate.getDay() === 5 && ((d.getTime() - todayMs) < 86400000 * 3)) {
      return true;
    } else if (todayDate.getDay() === 6 && ((d.getTime() - todayMs) < 86400000 * 2)) {
      return true;
    } else {
      return (d.getTime() - todayMs) < 86400000;
    }
  }

  disabledDates = (o: any) => {
    const d: Date = o.date;
    if (o.view === 'month') {
      return this.isWeekend(d) || this.isPastCutoff(d);
    } else {
      return false
    }
  }

  onToggleAMCheckbox = () => {
    this.timeAMChecked = !this.timeAMChecked;
  }
  onTogglePMCheckbox = () => {
    this.timePMChecked = !this.timePMChecked;
  }

  onTogglePhoneCheckbox = () => {
    if (this.editingPhone === false && this.closeClicked === false)
    {
      this.phoneChecked = !this.phoneChecked;
    }
    
    if (this.phoneChecked) {
      this.emailChecked = false;
      if (!this.phoneValue) {
        this.editPhone();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
    this.markAsDirtyForm();
  }

  onToggleEmailCheckbox = () => {
    if (this.editingEmail === false && this.closeClicked === false)
    this.emailChecked = !this.emailChecked;
    if (this.emailChecked) {
      this.phoneChecked = false;
      if (!this.emailValue) {
        this.editEmail();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
    this.markAsDirtyForm();
  }

  editPhone = () => {
    this.editingPhone = true;
    this.editingEmail = false;
    this.phoneControl.setValue(this.phoneValue);
  }

  editEmail = () => {
    this.editingPhone = false;
    this.editingEmail = true;
    this.emailControl.setValue(this.emailValue);
  }

  closeEditing = () => {
    this.editingPhone = false;
    this.editingEmail = false;
    this.closeClicked = true;
    if (!this.phoneValue && this.phoneChecked || !this.emailValue && this.emailChecked) {
      this.phoneChecked = false;
      this.emailChecked = false;
    }

    if (!this.phoneValueModal && this.phoneCheckedModal || !this.emailValueModal && this.emailCheckedModal) {
      this.phoneCheckedModal = false;
      this.emailCheckedModal = false;
    }
    //return true;
  }

  savePhone = () => {
    this.phoneValue = this.phoneControl.value;
    this.closeEditing();
  }

  saveEmail = () => {
    this.emailValue = this.emailControl.value;
    this.closeEditing();
  }

  onTogglePhoneCheckboxModal = () => {
    if (this.editingPhone === false && this.closeClicked === false)
    {
      this.phoneCheckedModal = !this.phoneCheckedModal;
    }
    
    if (this.phoneCheckedModal) {
      this.emailCheckedModal = false;
      if (!this.phoneValueModal) {
        this.editPhoneModal();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
  }

  onToggleEmailCheckboxModal = () => {
    if (this.editingEmail === false && this.closeClicked === false)
    {
      this.emailCheckedModal = !this.emailCheckedModal;
    }
    
    if (this.emailCheckedModal) {
      this.phoneCheckedModal = false;
      if (!this.emailValueModal) {
        this.editEmailModal();
      }
    }
    if (this.closeClicked === true)
    this.closeClicked = false;
  }

  editPhoneModal = () => {
    this.editingPhone = true;
    this.editingEmail = false;
    this.phoneControl.setValue(this.phoneValueModal);
  }

  editEmailModal = () => {
    this.editingEmail = true;
    this.editingPhone = false;
    this.emailControl.setValue(this.emailValueModal);
  }

  savePhoneModal = () => {
    this.phoneValueModal = this.phoneControl.value;
    this.closeEditing();
  }

  saveEmailModal = () => {
    this.emailValueModal = this.emailControl.value;
    this.closeEditing();
  }

  closeEditingStep = () => {
    this.scrollToTop();
    this.editingStep = undefined;
    this.markAsDirtyForm();
  }

  editStep = (step: number) => {
    this.scrollToTop();
    this.editingStep = step;

    if (step === 1) {
      this.requestForm.get('reasonPopup').setValue(this.requestForm.get('reason').value);
    } else if (step === 2) {
      this.preferredDatesTimesModal = [];
      this.preferredDatesTimes.forEach(dateTime => this.preferredDatesTimesModal.push({ ...dateTime }));
    } else if (step === 3) {
      this.phoneCheckedModal = this.phoneChecked;
      this.emailCheckedModal = this.emailChecked;
      this.phoneValueModal = this.phoneValue;
      this.emailValueModal = this.emailValue;
    } else if (step === 4) {
      this.requestForm.get('additionalNotesPopup').setValue(this.requestForm.get('additionalNotes').value);
    }
  }


  saveStep1 = () => {
    this.requestForm.get('reason').setValue(this.requestForm.get('reasonPopup').value);
    this.closeEditingStep();
  }

  saveStep2 = () => {
    this.preferredDatesTimes = [];
    this.preferredDatesTimesModal.forEach(dateTime => this.preferredDatesTimes.push({ ...dateTime }));
    this.closeEditingStep();
  }

  saveStep3 = () => {
    this.phoneChecked = this.phoneCheckedModal;
    this.emailChecked = this.emailCheckedModal;
    this.phoneValue = this.phoneValueModal;
    this.emailValue = this.emailValueModal;
    this.closeEditingStep();
  }

  saveStep4 = () => {
    this.requestForm.get('additionalNotes').setValue(this.requestForm.get('additionalNotesPopup').value);
    this.closeEditingStep();
  }

  markAsDirtyForm() {
    sessionStorage.setItem('dirtyForm', '1');
  }

  removeDirtyForm() {
    sessionStorage.removeItem('dirtyForm');
  }

  validateComponent(control: string) {
    this.requestForm.get(control).updateValueAndValidity();
    // this.isDate24HoursAhead = this.IsDate24HoursAhead();
    this.markAsDirtyForm();
  }

  // IsDate24HoursAhead(): boolean {
  //   const startDate = moment(this.requestForm.get('preferredDateTime').value);
  //   const hoursAhead = moment().add(parseInt(environment.appointmentActionDisclaimer, 10), "h");
  //   if (hoursAhead.isAfter(moment(startDate))) {
  //     return false;
  //   }
  //   return true;
  // }


  close = () => {
    window.history.back();
  }

  onSuccessCloseClick = () => {
    this.close();
  };

  onSendClick = () => {
    trackEvent(this.id ? 'ACTION_APPT_RECHED_REQ_SUBMIT_TAP' : 'ACTION_APPT_NEW_REQ_SUBMIT_TAP');
    // this.isDate24HoursAhead = this.IsDate24HoursAhead();

    if (this.id && this.preferredDatesTimes.length > 0) {
      this.loadingService.loading = true;
      this.appointmentService.rescheduleAppointmentv2({
        appointmentId: this.id,
        appointmentDateTimes: this.preferredDatesTimes.map((dateTime) => {
          // JSON.stringify converts dates into UTC, need to subtract the timezoneoffset to correct for this
          const tempDate = new Date(dateTime.date);
          tempDate.setHours(tempDate.getHours() - tempDate.getTimezoneOffset() / 60);
          return {
            date: dateTime.date,
            timePreference: dateTime.time
          };
        }),
        subject: 'Appointment Reschedule',
        communicationPreference: this.emailChecked ? 'EMAIL' : 'CALL',
        phoneNumber: this.phoneChecked ? this.phoneValue : undefined,
        email: this.emailChecked ? this.emailValue : undefined,
        additionalNotes: this.requestForm.get('additionalNotes').value
      })
        .subscribe(null, null, () => {
          this.loadingService.loading = false;
          this.removeDirtyForm();
          this.showSuccessAlert = true;
          trackEvent('APPT_RESCHED_REQ_SUBMIT_SUCCESSFUL');
          trackEvent(this.emailChecked ? 'APPT_RESCHED_REQ_EMAIL_ME_SELECTION' : 'APPT_RESCHED_REQ_CALL_ME_SELECTION');
          this.preferredDatesTimes.forEach(dateTime => {
            switch (dateTime.time) {
              case TimeOfDay.MORNING:
                trackEvent('APPT_RESCHED_REQ_MORNING_SLOT_SELECTION');
                break;
              case TimeOfDay.AFTERNOON:
                trackEvent('APPT_RESCHED_REQ_NOON_SLOT_SELECTION');
                break;
              case TimeOfDay.ALL_DAY:
                trackEvent('APPT_RESCHED_REQ_ALL_DAY_SLOT_SELECTION');
                break;
            }
          });
        });
    } else if (!this.id && this.requestForm.get('reason').valid && this.preferredDatesTimes.length > 0) {
      this.loadingService.loading = true;
      this.appointmentService
        .requestAppointmentv2({
          appointmentId: '',
          appointmentDateTimes: this.preferredDatesTimes.map((dateTime) => {
            // JSON.stringify converts dates into UTC, need to subtract the timezoneoffset to correct for this
            const tempDate = new Date(dateTime.date);
            tempDate.setHours(tempDate.getHours() - tempDate.getTimezoneOffset() / 60);
            return {
              date: dateTime.date,
              timePreference: dateTime.time
            };
          }),
            subject: 'Appointment Request',
            reason: this.requestForm.get('reason').value,
            communicationPreference: this.emailChecked ? 'EMAIL' : 'CALL',
            phoneNumber: this.phoneChecked ? this.phoneValue : undefined,
            email: this.emailChecked ? this.emailValue : undefined,
            additionalNotes: this.requestForm.get('additionalNotes').value
          })
          .subscribe(null, null, () => {
            this.loadingService.loading = false;
            this.removeDirtyForm();
            this.showSuccessAlert = true;
            trackEvent('APPT_NEW_REQ_SUBMIT_SUCCESSFUL');
            trackEvent(this.emailChecked ? 'APPT_NEW_REQ_EMAIL_ME_SELECTION' : 'APPT_NEW_REQ_CALL_ME_SELECTION');
            this.preferredDatesTimes.forEach(dateTime => {
              switch (dateTime.time) {
                case TimeOfDay.MORNING:
                  trackEvent('APPT_NEW_REQ_MORNING_SLOT_SELECTION');
                  break;
                case TimeOfDay.AFTERNOON:
                  trackEvent('APPT_NEW_REQ_NOON_SLOT_SELECTION');
                  break;
                case TimeOfDay.ALL_DAY:
                  trackEvent('APPT_NEW_REQ_ALL_DAY_SLOT_SELECTION');
                  break;
              }
            });
          });
    } else {
      trackEvent(this.id ? 'APPT_RESCHED_REQ_SUBMIT_UNSUCCESSFUL' : 'APPT_NEW_REQ_SUBMIT_UNSUCCESSFUL');
    }
  };
}
