<app-card label="Upcoming Appointments" actionLabel="View all appointments" actionLabelMobile="View all" (onActionClick)="viewAllAppointments()">
  <ng-container *ngIf="appointments?.length>0">
    <div class="appointments-info">
      You have {{appointments?.length || '0'}} upcoming {{appointments?.length===1 ? 'appointment' : 'appointments'}} in the next {{daysAhead}} days.
    </div>
    <div class="appointments" *ngFor="let appointmentsGroup of appointmentsGroups">
      <h1>{{appointmentsGroup.title}}</h1>
      <div class="appointment" *ngFor="let appointment of appointmentsGroup.appointments">
        <div class="appointment-dots">
          <img class="appointment-circle" src="/assets/images/icons/SVG/appointment-circle.svg" alt="Appointment bullet point"/>
          <div class="appointment-line"></div>
        </div>
        <div class="appointment-card" (click)="openAppointmentDetail(appointment.appointmentId)">
          <div class="appointment-left">
          <div class="appointment-description">{{appointment.description}}</div>
          <div class="appointment-location">
            <img *ngIf="appointment.isTelehealthAppointment && !appointment.teleHealthUrl" class="appointment-icon" src="/assets/images/icons/SVG/appointment-telehealth.svg" alt="Telehealth icon"/>
            {{appointment.location}}
          </div>
          <div class="appointment-telehealth-button" *ngIf="appointment.teleHealthUrl">
            <dx-button class="green" icon="ctca-camera" text="Join Telehealth Appointment" (onClick)="openUrl(appointment.teleHealthUrl,$event)"></dx-button>
          </div>
          </div>
          <div class="appointment-right">
            <div class="appointment-time">{{appointment.startDateTime | date:"h:mm a" | lowercase}}</div>
          </div>
        </div>
      </div>
    </div>
    <hr class="appointment-separator" *appShowFor="PermissionLevel.REQUEST_APPOINTMENT"/>
  </ng-container>
  <ng-container >
    <div class="appointments-info-empty" *ngIf="appointments?.length==0">
      No upcoming appointments.
    </div>
  </ng-container>
  <dx-button *appShowFor="PermissionLevel.REQUEST_APPOINTMENT" class="white margin0auto action-button" icon="ctca-calendar" text="Request Appointment" (onClick)="requestAppointment()"></dx-button>
</app-card>
