import { Component, OnInit, Input } from '@angular/core';
import { LoadingService, FormLibraryService, FileDownloadService } from '../../core/services';
import { BaseComponent } from '../../core/component/base.component';


@Component({
  selector: 'app-advanced-notice-non-coverage',
  templateUrl: './advanced-notice-non-coverage.component.html'
})
export class AdvancedNoticeNonCoverageComponent extends BaseComponent implements  OnInit {
  
  constructor(private loadingService: LoadingService, 
    private formLibraryService: FormLibraryService, 
    private downloadService: FileDownloadService) { super(); }

  @Input() anncFormExists: boolean;

  ngOnInit() {
    this.loadingService.loading = false;    
  }

  downloadAnncFormAsPDF(){
    this.loadingService.loading = true;
    this.formLibraryService
      .getAnncFormAsPDF()
      .subscribe(data => {
        this.loadingService.loading = false;
        this.downloadService.downloadPdf(data, `ANNC Form`)
    });
  }

}