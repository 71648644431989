import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LoginCallbackComponent } from '../app/login-callback/login-callback.component';
import { Auth0LoginCallbackComponent } from '../app/login-callback/auth0-login-callback.component';
import { LoadingService } from './core/services';
import { SilentRenewCallbackComponent } from '../app/silent-renew-callback/silent-renew-callback.component';
import { DxButtonModule, DxLoadIndicatorModule, DxPopupModule, DxSelectBoxModule } from 'devextreme-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule} from './shared/shared.module';
import { AddAuthenticationHeaderInterceptor } from './core/interceptors/add-authentication-header.interceptor';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ErrorHandlerModule } from './error-handler/error-handler.module';
import { AppointmentConfirmedComponent } from './appointment-confirmed/appointment-confirmed.component';
import { LaunchDarklyService } from './core/services';
import { EmailVerificationSuccessComponent } from './email-verification-success/email-verification-success.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginCallbackComponent,
    Auth0LoginCallbackComponent,
    SilentRenewCallbackComponent,
    LandingPageComponent,
    AppointmentConfirmedComponent,
    EmailVerificationSuccessComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    SharedModule,
    ErrorHandlerModule,
    DxPopupModule,
    DxButtonModule,
    DxSelectBoxModule,
    AppRoutingModule,
    DxLoadIndicatorModule,
  ],
  providers: [
    DatePipe,
    LoadingService,
    LaunchDarklyService,
    { provide: HTTP_INTERCEPTORS, useClass: AddAuthenticationHeaderInterceptor, multi: true },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
