import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { IdentityLogEvent } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';

@Injectable()
export class IdentityEventService extends BaseService {

	logEvent(eventTask:IdentityLogEvent):Observable<string> {
		return this.post({
			endpoint: ServiceEndpoint.SUPPORT_LOGPORTALEVENT,
			body: eventTask
		});
	}	
}