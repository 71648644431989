import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NEVER } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import { AlternateContactInfo, PatientContactInfo } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { Router } from '@angular/router';

@Injectable()
export class ContactInfoService extends BaseService {
	constructor(router: Router, httpClient: HttpClient, private authSvc: AuthenticationService) {
		super(router, httpClient);
	}

	getContactInfo() {
		return this.get({
			endpoint: ServiceEndpoint.CONTACTINFO_GETCONTACTINFO,
			cacheInvalidator: this.authSvc.activeTokenChanged$
		});
	}

	getAlternateContactInfo(){
		return this.get({
			endpoint: ServiceEndpoint.CONTACTINFO_GETALTERNATECONTACTINFO,
			cacheInvalidator: this.authSvc.activeTokenChanged$
		});
	}

	updateContactInfo(patientContactInfo: PatientContactInfo) {
		return this.post({
			endpoint: ServiceEndpoint.CONTACTINFO_UPDATECONTACTINFO,
			body: patientContactInfo
		});
	}

	addAlternateContactInfo(alternateContactInfo: AlternateContactInfo) {
		return this.post({
			endpoint: ServiceEndpoint.CONTACTINFO_ADDALTERNATECONTACTINFO,
			body: alternateContactInfo
		});
	}

	updateAlternateContactInfo(alternateContactInfo: AlternateContactInfo) {
		return this.post({
			endpoint: ServiceEndpoint.CONTACTINFO_UPDATEALTERNATECONTACTINFO,
			body: alternateContactInfo
		});
	}

	deleteAlternateContactInfo(alternateContactInfo: AlternateContactInfo) {
		return this.post({
			endpoint: ServiceEndpoint.CONTACTINFO_DELETEALTERNATECONTACTINFO,
			body: alternateContactInfo
		});
	}

	getContactInfoPhoneTypes() {
		return this.get({
			endpoint: ServiceEndpoint.CONTACTINFO_GETPHONETYPES,
			cacheInvalidator: NEVER
		});
	}

	getContactInfoRelationshipTypes() {
		return this.get({
			endpoint: ServiceEndpoint.CONTACTINFO_GETRELATIONSHIPTYPES,
			cacheInvalidator: NEVER
		});
	}

	getContactInfoRelationships() {
		return this.get({
			endpoint: ServiceEndpoint.CONTACTINFO_GETRELATIONSHIPS,
			cacheInvalidator: NEVER
		});
	}
}
