import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BaseComponent } from '../../core/component/base.component';

@Component({
  selector: 'app-contact-care-team',
  templateUrl: './contact-care-team.component.html',
  styleUrls: ['./contact-care-team.component.scss']
})
export class ContactCareTeamComponent extends BaseComponent implements OnInit {
  constructor(private router: Router) {
    super();
  }
  ngOnInit(): void {}

  onSecureMailLinkClick() {
    this.router.navigateByUrl(`/app/ask-question`);
  }
}
