import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { alert } from 'devextreme/ui/dialog';

import { Popup } from '../../core/popup/popup';
import { DocumentExchangeService, LoadingService, ResizingService } from '../../core/services';
import { emailOrEmptyValidator } from '../../shared/validators/email-or-empty.validator';
import { DxPopupComponent } from 'devextreme-angular';



const PasswordProtectHeight = 780;
const NormalHeight = 620;

class Tab {
  id: number;
  text: string;
  icon: string;
  content: string;
}

@Component({
  selector: 'app-clinical-summaries-transmit-popup',
  templateUrl: './clinical-summaries-transmit-popup.component.html',
  styleUrls: ['./clinical-summaries-transmit-popup.component.scss']
})
export class ClinicalSummariesTransmitPopupComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
  
  documentId: Array<string>;
  action:string;
  
  filePassRequired = false;
  sendSecureRequired = false;
  sendUnsecure = false;
  securityOptionSelected = true;

  tabs: Tab[] = [    
    { 
        id: 0,
        text: "Regular Email", 
        icon: "email", 
        content: "Regular Email" 
    }
  ];  
  
  transmitRegularEmailForm = new FormGroup({
    passwordProtect: new FormControl(false),
    useBarracuda: new FormControl(false),
    sendUnsecure: new FormControl(false),
    password : new FormControl('',Validators.required),
    email : new FormControl('',Validators.compose([Validators.required,emailOrEmptyValidator]))    
  });

  @ViewChild(DxPopupComponent, { static: true }) popup: DxPopupComponent;

  constructor(router: Router, route: ActivatedRoute, private docXSvc: DocumentExchangeService, 
    private loadingService:LoadingService, resizingService: ResizingService) {
    super(router, route, resizingService);
  }

  ngOnInit() {
    
      this.documentId = this.route.snapshot.parent.params.id.split("-");
      this.action = this.route.snapshot.url[0].path;
      this.passwordProtectChanged();

  }
  
  ngAfterViewInit() {
    super.init(this.popup);
  }

  ngOnDestroy() {
    super.destroy();
  }

  
  passwordProtectChanged() {
    this.filePassRequired = this.transmitRegularEmailForm.get('passwordProtect').value;
    this.sendSecureRequired = this.transmitRegularEmailForm.get('useBarracuda').value;

    if(this.sendSecureRequired && !this.filePassRequired) {
      this.sendSecureChanged();
      return;
    }
    if(this.sendUnsecure && this.filePassRequired){
      this.transmitRegularEmailForm.get('sendUnsecure').setValue(false);
    }
    if(!this.filePassRequired) {
      this.transmitRegularEmailForm.get('password').setValue("");
      this.popup.height = NormalHeight;
    } else {
      this.popup.height = PasswordProtectHeight;
      this.securityOptionSelected = true;
    }
  }

  sendSecureChanged() {
    this.sendSecureRequired = this.transmitRegularEmailForm.get('useBarracuda').value;
    if(this.sendSecureRequired) {
      this.transmitRegularEmailForm.get('passwordProtect').setValue(true);
      this.transmitRegularEmailForm.get('sendUnsecure').setValue(false);
      this.passwordProtectChanged();
      this.securityOptionSelected = true;
    }    
  }

  sendUnsecureChanged() {    
    this.sendUnsecure = this.securityOptionSelected = 
      this.transmitRegularEmailForm.get('sendUnsecure').value;
      if(this.sendUnsecure){        
        this.popup.height = NormalHeight;
        this.transmitRegularEmailForm.get('useBarracuda').setValue(false);
        this.transmitRegularEmailForm.get('passwordProtect').setValue(false);        
      }
  }

  close = () => {    
    if(this.action==='transmit-group') this.navigateToParent(2);
    else this.navigateToParent(1);
	};

  transmit = () => {
    
      if (this.transmitRegularEmailForm.get('email').valid) {
        if(this.filePassRequired && this.transmitRegularEmailForm.get('password').invalid) return;

        const password: string = this.filePassRequired ? this.transmitRegularEmailForm.get('password').value : "";
        const useBarracuda: boolean = this.transmitRegularEmailForm.get('useBarracuda').value;
        
        if(this.securityOptionSelected && !this.sendUnsecure 
          && !this.sendSecureRequired && !this.filePassRequired){
            this.securityOptionSelected = false;
            return;
          } 

        this.loadingService.loading = true;
        this.docXSvc.transmitCCDADocumentRegularEmail({
          documentId: this.documentId,
          directAddress: this.transmitRegularEmailForm.get('email').value,
          filePass: password          
        },useBarracuda).subscribe((data:any) => {
          this.loadingService.loading = false;
          alert("Your email has been sent to the requested recipient.","Transmission Status");
          this.close();
        },
        error => {
          this.loadingService.loading = false;
          throw(error);
        });
      }   

  };
  
}
