import { Injectable } from '@angular/core';

@Injectable()
export class LoadingService {

    private _loading:Boolean;
    
    constructor() {
        this._loading = false;
    }

    public get loading():Boolean {
        return this._loading;
    }

    public set loading(value:Boolean) {
        Promise.resolve(null).then(() => this._loading = value);
    }

    public setLoading(value:Boolean,callback:Function) {
        Promise.resolve(null).then(() => {
            this._loading = value;
            callback();
        });
    }
}
