import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { ContactUsStateService } from 'app/core/services';
import { ContactUsFormType, ContactUsQuestion, ContactUsSubject } from 'app/core/services/models';


@Component({
  selector: 'app-ask-question-non-medical-subject',
  templateUrl: './ask-question-non-medical-subject.component.html',
  styleUrls: ['./ask-question-non-medical-subject.component.scss']
})
export class AskQuestionNonMedicalSubjectComponent extends BaseComponent implements OnInit {

  subjects: ContactUsSubject[] = [];
  message = '';
  selectedSubject: ContactUsSubject;
  title: string;

  constructor(private route: ActivatedRoute, private router: Router, private contactUsState: ContactUsStateService) {
    super();

    const state = this.contactUsState.getState();
    const question: ContactUsQuestion = state.question;
    const subject: ContactUsSubject = state.subject;
    const message: string = state.message;

    this.subjects = question.subjects;
    this.title = question.value;

    if (subject) {
      this.selectedSubject = subject;
    }

    if (message) {
      this.message = message;
    }
  }


  ngOnInit(): void {

  }

  selectSubject = (subject: ContactUsSubject) => {
    this.selectedSubject = subject;
  }

  onNextClick = (e: any) => {
    this.contactUsState.setState({ subject: this.selectedSubject, message: this.message });
    const formType = this.selectedSubject.formType;
    if (formType === ContactUsFormType.RescheduleAppointment || formType === ContactUsFormType.CancelAppointment) {
      this.router.navigate(['non-medical', 'select-appointment'], { relativeTo: this.route.parent });
    } else if (formType === ContactUsFormType.RequestAppointment) {
      this.router.navigate(['non-medical', 'appointment-time'], { relativeTo: this.route.parent });
    } else if (formType === ContactUsFormType.RequestAppointmentSchedule) {
      this.router.navigate(['non-medical', 'schedule-range'], { relativeTo: this.route.parent });
    } else if (formType === ContactUsFormType.Basic) {
      this.router.navigate(['non-medical', 'review'], { relativeTo: this.route.parent });
    }
  }

  close = () => {
    window.history.back();
  }

}
