import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { LoadingService, SecureMailService, SupportService, UserProfileService } from 'app/core/services';
import { FacilityInfo, SecureMailMessage, ShmMessageType, ShmRecipient, UserProfile } from 'app/core/services/models';
import { trackPageLoadingTime } from 'app/shared/helpers/tracking.helper';
import { DxTagBoxComponent } from 'devextreme-angular';
import { alert } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-ask-question-medical',
  templateUrl: './ask-question-medical.component.html',
  styleUrls: ['./ask-question-medical.component.scss']
})
export class AskQuestionMedicalComponent extends BaseComponent implements OnInit, OnDestroy {

  firstName: string;
  lastName: string;
  subject: string;
  text: string;
  careteams: ShmRecipient[] = [];
  careteamsSelected: ShmRecipient[] = [];
  careteamsExist: boolean;
  replyMessage: SecureMailMessage;
  replyCareTeamsSelected: string[] = [];
  replyTos: any[] = [];
  profile: UserProfile;
  primaryFacility: FacilityInfo;
  @ViewChild(DxTagBoxComponent, { static: false }) replyToTagBox: DxTagBoxComponent;

  constructor(private loadingService: LoadingService,
    private profileSvc: UserProfileService,
    private supportService: SupportService,
    private secureMailSvc: SecureMailService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
  ) {
    super();
    this.careteamsExist = true;
  }


  ngOnInit(): void {
    const startTimeOfSendMessage = Date.now();
    const startTimeOfReplyMessage = parseInt(localStorage.getItem('sendReplyStartTime'), 10) || Date.now();
    this.loadingService.loading = true;
    this.profileSvc.getUserProfileHardCache().subscribe((userProfile: UserProfile) => {
      this.firstName = userProfile.firstName;
      this.lastName = userProfile.lastName;

      this.supportService.getAllFacilitiesInfoForTreamentFacilitySelect().subscribe((facilities: FacilityInfo[]) => {
        this.primaryFacility = facilities.find((facility) => {
          return facility.name === userProfile.primaryFacility;
        });
      });

      this.careteams.length = 0;
      this.secureMailSvc.getCareTeams().subscribe((teams: Array<ShmRecipient>) => {
        this.careteams = teams;
        this.loadingService.loading = false;
        this.careteamsExist = this.careteams && this.careteams[0] != null;
        setTimeout(() => {
          const select = document.getElementsByTagName("select");
          for (let i = 0; i < select.length; i++) {
            const s = select.item(i);
            if (s.parentElement.parentElement.title) {
              s.setAttribute('title', s.parentElement.parentElement.title);
            }
          }
        }, 10);

        if (this.route.parent.snapshot.params['id']) {
          this.loadingService.loading = true;
          // reply mode
          const id = this.route.parent.snapshot.params['id'];
          this.secureMailSvc.getSecureMessage(id).subscribe((message: SecureMailMessage) => {
            //check for dupes in the list
            message.to.forEach((team) => {
              if (!this.careteams.find((to) => {
                return to.systemId === team.systemId
              })) {
                const replyTos = JSON.parse(JSON.stringify(this.careteams));
                replyTos.push(team);
                if (this.replyToTagBox) {
                  this.replyToTagBox.dataSource = replyTos;
                }
              }
            });

            message.to.filter((team) => {
              return team.name === message.from;
            }).forEach((team) => {
              this.replyCareTeamsSelected.push(team.systemId);
              if (!this.replyTos.find((to) => {
                return to.systemId === team.systemId
              })) {
                this.replyTos.push(team);
              }
            });

            if (message.selectedTo) {
              message.selectedTo.forEach((team) => {
                this.replyCareTeamsSelected.push(team.systemId);
                if (!this.replyTos.find((to) => {
                  return to.systemId === team.systemId
                })) {
                  this.replyTos.push(team);
                }
              });
            }

            if (this.replyToTagBox) {
              this.replyToTagBox.dataSource = this.replyTos;
            }

            this.replyTo(message);
            this.loadingService.loading = false;
            const endTimeOfReplyMessage = Date.now();
            const elapsedTimeOfReplyMessage = (endTimeOfReplyMessage - startTimeOfReplyMessage) / 1000;
            trackPageLoadingTime('/app/reply-message/:id', elapsedTimeOfReplyMessage);
            localStorage.removeItem('sendReplyStartTime');
          });
        }
        if (!this.route.parent.snapshot.params['id']) {
          const endTimeOfSendMessage = Date.now();
          const elapsedTimeOfSendMessage = (endTimeOfSendMessage - startTimeOfSendMessage) / 1000;
          trackPageLoadingTime(window.location.pathname, elapsedTimeOfSendMessage);
        }
      });
    });
  }



  ngOnDestroy(): void {
    this.removeDirtyForm();
  }


  replyTo(msg: SecureMailMessage): void {
    this.replyMessage = msg;
    if (!msg.subject.startsWith('RE:')) {
      this.subject = `RE: ${msg.subject}`;
    } else {
      this.subject = msg.subject;
    }

    const to = msg.selectedTo.map((rec) => {
      return rec.name;
    });

    this.text =
      `\r\n\r\n-----Original Message-----\r\n\r\n` +
      `Date: ${this.datePipe.transform(msg.sent, 'dd-MMM-yyyy hh:mm a')}\r\n\r\n` +
      `From: ${msg.from}\r\n\r\n` +
      `To: ${to.join(', ')}\r\n\r\n` +
      `Subject: ${msg.subject}\r\n\r\n` +
      `${msg.comments}`;
  }

  saveSubject(): void{
    sessionStorage.setItem('dirtyForm', '1');
  }

  saveMessage(): void{
    sessionStorage.setItem('dirtyForm', '1');
  }

  removeDirtyForm() {
    sessionStorage.removeItem('dirtyForm');
  }

  itemSelectionChanged(e: any) {
    this.careteamsSelected = (e.component._selectedItems.length === 0) ?
      e.component._valuesToUpdate : e.component._selectedItems;
  }

  onSendClick = (e:any) => {
    if (e.validationGroup.validate().isValid) {
      this.loadingService.loading = true;      
      this.secureMailSvc
        .sendSecureMessage({
          from: `${this.firstName} ${this.lastName}`,
          to: this.careteamsSelected,
          selectedTo: this.careteamsSelected,
          subject: this.subject,
          comments: this.text,
          sent: new Date(),
          parentMessageId: this.replyMessage ? this.replyMessage.mailMessageId : null,
          messageType: ShmMessageType.general,
          folderName: this.replyMessage  ? this.replyMessage.folderName : null
        })
        .subscribe(
          () => {
            this.loadingService.loading = false;
            this.removeDirtyForm();
            this.close();
            setTimeout(() => {
              alert('Your email has been sent.', 'Information');
            }, 300);
          },
          error => {
            this.loadingService.loading = false;
            throw(error);
          }
        );  

    }
  }

  close = () => {
    window.history.back();
  }

}
