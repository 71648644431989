import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PrivacyPolicyRoutingModule } from './privacy-policy-routing.module';
import { PrivacyPolicyComponent } from './privacy-policy.component';
import {
  DxButtonModule
} from 'devextreme-angular';

@NgModule({
  imports: [
    SharedModule,
    DxButtonModule,
    PrivacyPolicyRoutingModule
  ],
  declarations: [PrivacyPolicyComponent]
})
export class PrivacyPolicyModule { }