import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators, FormControlName, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import ArrayStore from 'devextreme/data/array_store';
import { alert } from 'devextreme/ui/dialog';
import { Md5 } from 'ts-md5/dist/md5';
import * as moment from 'moment';

import { FormLibraryService, UserProfileService, LoadingService, ContactInfoService } from '../../core/services';
import {
  AuthorizationActionOption,
  DeliveryMethodOption,
  DisclosureInformationOption,
  HighlyConfidentialInformationOption,
  PurposeOption,
  ROIFormResponse,
  UserProfile,
  PatientContactInfo,
  ROIForm,
} from '../../core/services/models';
import * as formHelper from '../../shared/helpers/form.helper';
import { emailOrEmptyValidator } from '../../shared/validators/email-or-empty.validator';
import { dateOrEmptyValidator } from '../../shared/validators/date-or-empty.validator';
import { BaseComponent } from '../../core/component/base.component';
import { getAutofillPhoneNumber, phoneNumberValidator } from '../../home/shared/helpers/phone.helper';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-ask-question-non-medical-complete-roi',
  templateUrl: './ask-question-non-medical-complete-roi.component.html',
  styleUrls: ['./ask-question-non-medical-complete-roi.component.scss']
})
export class AskQuestionNonMedicalCompleteRoiComponent extends BaseComponent implements OnInit, OnDestroy {

  facilitiesData: any;

  disclosureInformationSelectionIsValid: boolean;
  _disclosureInformationOption: Object;
  _disclosureInformationOtherCheckHash = "";
  _disclosureInformationOtherTextHash = "";

  deliveryMethodSelectionIsValid: boolean;
  _deliveryMethodOptions: Object;
  _deliveryMethodPickUpCheckHash = "";
  _deliveryMethodPickUpDateHash = "";

  authorizationActionSelectionIsValid: boolean;
  _authorizationActions: Object;

  purposeSelectionIsValid: boolean;
  _purposeOptions: Object;

  dateNow: Date;
  tomorrow: Date;

  _wrongOrder = true;
  _wrongBeginDate: Boolean = true;
  _wrongPickUpdate: Boolean = false;

  userProfile: UserProfile;
  contactInfo: PatientContactInfo;

  _validationOrder: Array<string>;

  roiAuthorizationForm: FormGroup;

  startTime = 0;

  totalAllowedCharacters: number;
  remainingCharacters: number;


  get deliveryMethods(): FormArray {
    return this.roiAuthorizationForm.get('deliveryMethods') as FormArray;
  }

  get authorizationActions(): FormArray {
    return this.roiAuthorizationForm.get('authorizationActions') as FormArray;
  }

  get purposes(): FormArray {
    return this.roiAuthorizationForm.get('purposes') as FormArray;
  }

  get disclosureInformationList(): FormArray {
    return this.roiAuthorizationForm.get('disclosureInformationList') as FormArray;
  }

  get selectAllDisclosureInformation(): FormControl {
    const ctrl = this.roiAuthorizationForm.get('selectAllDisclosureInformation') as FormControl;
    ctrl.setValue(this.isAllDisclosureInformationSelected());
    return ctrl;
  }

  get isAtleastOneDisclosureInformationSelected(): boolean {
    return this.disclosureInformationSelectionIsValid;
  }

  get isAtleastOneDeliveryMethodSelected(): boolean {
    return this.deliveryMethodSelectionIsValid;
  }

  get isAtleastOneAuthorizationActionSelected(): boolean {
    return this.authorizationActionSelectionIsValid;
  }

  get isAtleastOnePurposeSelected(): boolean {
    return this.purposeSelectionIsValid;
  }

  get highlyConfidentialInformationList(): FormArray {
    return this.roiAuthorizationForm.get('highlyConfidentialInformationList') as FormArray;
  }

  get pickUpDate(): FormControl {
    const ctrl = this.roiAuthorizationForm.get('pickUpDate') as FormControl;
    return ctrl;
  }
  get disclosureInformationOther(): FormControl {
    const ctrl = this.roiAuthorizationForm.get('disclosureInformationOther') as FormControl;
    return ctrl;
  }

  constructor(
    route: ActivatedRoute,
    private fb: FormBuilder,
    private formLibraryService: FormLibraryService,
    private userProfileService: UserProfileService,
    private contactInfoService: ContactInfoService,
    private loadingService: LoadingService,
  ) {
    super();

    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;

    FormControlName.prototype.ngOnChanges = function () {
      let result;
      if (arguments) {
        result = originFormControlNameNgOnChanges.apply(this, arguments);
      }
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };

    this.disclosureInformationSelectionIsValid = false;
    this.deliveryMethodSelectionIsValid = false;
    this.authorizationActionSelectionIsValid = true;
    this.purposeSelectionIsValid = false;

    this.totalAllowedCharacters = 100;
    this.remainingCharacters = 100;

  }

  createRoiAuthorizationForm() {
    this.removeDirtyForm();

    this.formLibraryService.getRoiForm().subscribe((roiForm: ROIForm) => {

      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime - this.startTime) / 1000;
      trackPageLoadingTime(window.location.pathname, elapsedTime);
      this.dateNow = new Date();
      this.dateNow = new Date(this.dateNow.setHours(0, 0, 0, 0));
      this.tomorrow = this.dateNow;
      this.tomorrow.setDate(this.tomorrow.getDate() + 1);
      this.dateNow = new Date();
      this.dateNow = new Date(this.dateNow.setHours(0, 0, 0, 0));
      //Form Group for a ROI Form
      this.roiAuthorizationForm = this.fb.group({
        selectedFacility: [this.userProfile.primaryFacility, Validators.required],
        firstName: [this.userProfile.firstName, Validators.required],
        lastName: [this.userProfile.lastName, Validators.required],
        birthDate: [this.contactInfo.dateOfBirth, Validators.compose([Validators.required, dateOrEmptyValidator])],
        deliveryMethods: this.fb.array(['Mail', 'Fax']),
        authorizationActions: this.fb.array([]),
        facilityOrIndividual: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
        address: ['', Validators.compose([Validators.required, Validators.maxLength(150)])],
        city: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
        state: ['', Validators.compose([Validators.required, Validators.maxLength(25)])],
        zip: ['', Validators.compose([Validators.required, Validators.maxLength(25)])],
        phoneNumber: ['', Validators.compose([Validators.required, phoneNumberValidator])],
        fax: [''],
        email: ['', emailOrEmptyValidator],
        purposes: this.fb.array([]),
        selectAllDisclosureInformation: [false],
        disclosureInformationList: this.fb.array([]),
        highlyConfidentialInformationList: this.fb.array([]),
        beginDate: ['', Validators.compose([Validators.required, dateOrEmptyValidator])],
        endDate: ['', Validators.compose([Validators.required, dateOrEmptyValidator])],
        restrictions: [''],
        signature: ['', Validators.required],
        patientRelation: ['']
      });

      this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
        this.roiAuthorizationForm.get('phoneNumber').setValue(getAutofillPhoneNumber(patientContactInfo) || '');
        this.roiAuthorizationForm.get('email').setValue(patientContactInfo.emailAddress || '');
      });

      this.facilitiesData = new ArrayStore({
        data: roiForm.facilities,
        key: 'key'
      });

      this.setDeliveryMethods(roiForm.deliveryMethods);
      this.setAuthorizationActions(roiForm.authorizationActions);
      this.setPurposesOptions(roiForm.purposes);
      this.setDisclosureInformationListOptions(roiForm.disclosureInformationList);
      this.setHighlyConfidentialInformationListOptions(roiForm.highlyConfidentialInformationList);

      this._validationOrder = [
        "selectedFacility",
        "firstName",
        "lastName",
        "birthDate",
        "deliveryMethods",
        "authorizationActions",
        "facilityOrIndividual",
        "address",
        "city",
        "state",
        "zip",
        "phoneNumber",
        "fax",
        "email",
        "purposes",
        "beginDate",
        "endDate",
        "selectAllDisclosureInformation",
        "disclosureInformationList",
        "highlyConfidentialInformationList",
        "restrictions",
        "signature",
        "patientRelation"
      ];

      setTimeout(() => {
        formHelper.markControlAndChildControlsAsDirty(this.roiAuthorizationForm);

        this.pickupDateDateBoxValueChanged(() => {
          this.beginDateDateBoxValueChanged(() => {
            this.endDateDateBoxValueChanged(() => {
              this.setDisclosureInformationValidation();
              this.setDeliveryMethodSelectionValidation();
              this.setAuthorizationActionSelectionValidation();
              this.setPurposeSelectionValidation();
              this.markAsDirtyForm();
              // call this method after 100 milliseconds to avoid the unsubmitted changes dialog box to appear when no changes occurred.
              setTimeout(() => {
                this.removeDirtyForm();
              }, 100);
            });
          });
        });
      }, 1000);

      const nodes = document.querySelectorAll(".dx-overlay-content.dx-popup-normal.dx-resizable");
      nodes.forEach((node) => {
        const elem = <HTMLElement>node;
        if (elem) {
          elem.style.zIndex = "1499";
        };
      });

    }, error => {
      this.loadingService.loading = false;
      throw (error);
    }
    );
  }

  private setDeliveryMethods(deliveryMethods: string[]) {

    this._deliveryMethodOptions = new Object();
    deliveryMethods.push('Pick-up date field');

    const deliveryMethodFormGroups = deliveryMethods.map((deliveryMethod: string) =>
      this.getDeliveryMethodCheckBoxFormGroup(deliveryMethod)
    );

    this.roiAuthorizationForm.setControl(
      'deliveryMethods',
      this.fb.array(deliveryMethodFormGroups)
    );
  }

  private getDeliveryMethodCheckBoxFormGroup(deliveryMethod: string) {

    const hash: string = Md5.hashStr(deliveryMethod).toString();
    
    const deliveryMethodOption: DeliveryMethodOption = {
      description: deliveryMethod,
      selected: false,
      showPickupdate: deliveryMethod === 'Pick-up date',
      pickUpDateField: deliveryMethod === 'Pick-up date field',
      date:'',
      hash: hash
    };

    this._deliveryMethodOptions[hash] = deliveryMethodOption;

    const option: any = new Object();

    if (deliveryMethodOption.showPickupdate) {

      option[hash] = false;
      this._deliveryMethodPickUpCheckHash = hash;

    } else if (deliveryMethodOption.pickUpDateField) {

      option[hash] = '';
      this._deliveryMethodPickUpDateHash = hash;

    }
    else option[hash] = deliveryMethodOption.selected;

    return this.fb.group(option);
  }

  private setAuthorizationActions(authorizationActions: string[]) {

    this._authorizationActions = new Object();

    const authorizationActionsFormGroups = authorizationActions.map((authorizationAction: string) =>
      this.getRadioButtonAuthorizationActionFormGroup(authorizationAction)
    );

    authorizationActionsFormGroups[0]['controls'][this.getKey(authorizationActionsFormGroups[0]['controls'])].setValue(true);
    this.roiAuthorizationForm.setControl(
      'authorizationActions',
      this.fb.array(authorizationActionsFormGroups)
    );
  }

  private getRadioButtonAuthorizationActionFormGroup(authorizationAction: string) {

    const hash: string = Md5.hashStr(authorizationAction).toString();

    const authorizationActionOption: AuthorizationActionOption = {
      description: authorizationAction,
      selected:false
    };

    this._authorizationActions[hash] = authorizationActionOption;
    const option: any = new Object();
    option[hash] = false;

    return this.fb.group(option);
  }

  private setPurposesOptions(purposes: string[]) {

    this._purposeOptions = new Object();

    const purposesFormGroups = purposes.map((purpose: string) =>
      this.getPurposeCheckBoxFormGroup(purpose)
    );
    this.roiAuthorizationForm.setControl('purposes', this.fb.array(purposesFormGroups));
  }

  private getPurposeCheckBoxFormGroup(purpose: string) {
    const hash: string = Md5.hashStr(purpose).toString();

    const purposeOption: PurposeOption = {
      description: purpose,
      selected:false
    };

    this._purposeOptions[hash] = purposeOption;

    const option: any = new Object();
    option[hash] = false;

    return this.fb.group(option);
  }

  private setDisclosureInformationListOptions(disclosureInformationList: string[]) {

    this._disclosureInformationOption = new Object();
    disclosureInformationList.push("text box");

    const disclosureInformationListFormGroups = disclosureInformationList.map(
      (disclosureInformation: string) =>
        this.getsetDisclosureInformationCheckBoxFormGroup(disclosureInformation)
    );

    this.roiAuthorizationForm.setControl(
      'disclosureInformationList',
      this.fb.array(disclosureInformationListFormGroups)
    );

  }

  private getsetDisclosureInformationCheckBoxFormGroup(disclosureInformation: string) {

    const hash: string = Md5.hashStr(disclosureInformation).toString();
   

    const disclosureInformationOption: DisclosureInformationOption = {
      description: disclosureInformation,
      selected: false,
      disclosureInformationOther:''
    };

    this._disclosureInformationOption[hash] = disclosureInformationOption;

    const option: any = new Object();

    if (disclosureInformationOption.description !== "text box") {

      option[hash] = disclosureInformationOption.selected;

      if (disclosureInformationOption.description === 'Other') {
        this._disclosureInformationOtherCheckHash = hash;
      }

    } else {

      option[hash] = disclosureInformationOption.disclosureInformationOther;
      this._disclosureInformationOtherTextHash = hash;

    }

    return this.fb.group(option);
  }

  public isRegularDisclosureInfoOption(disclosureInformation: FormGroup): boolean {
    return this._disclosureInformationOption[this.getKey(disclosureInformation['controls'])].description !== 'text box' &&
      this._disclosureInformationOption[this.getKey(disclosureInformation['controls'])].description !== 'Other';
  }

  public isOtherSpecifyDisclosureInfoOption(disclosureInformation: FormGroup): boolean {
    return this._disclosureInformationOption[this.getKey(disclosureInformation['controls'])].description === 'Other';
  }

  private setHighlyConfidentialInformationListOptions(highlyConfidentialInformationList: string[]) {
    const highlyConfidentialInformationListFormGroups = highlyConfidentialInformationList.map(
      (highlyConfidentialInformation: string) =>
        this.getHighlyConfidentialInformationCheckBoxFormGroup(highlyConfidentialInformation)
    );
    this.roiAuthorizationForm.setControl(
      'highlyConfidentialInformationList',
      this.fb.array(highlyConfidentialInformationListFormGroups)
    );
  }

  private getHighlyConfidentialInformationCheckBoxFormGroup(
    highlyConfidentialInformationList: string
  ) {
    const highlyConfidentialInformationOption: HighlyConfidentialInformationOption = {
      description: highlyConfidentialInformationList,
      selected: true
    };
    return this.fb.group(highlyConfidentialInformationOption);
  }


  ngOnInit() {
    this.startTime = Date.now();
    this.loadingService.loading = true;
    this.userProfileService.getUserProfileHardCache().subscribe((userProfile: UserProfile) => {
      this.userProfile = userProfile;
      this.contactInfoService.getContactInfo().subscribe((patientContactInfo: PatientContactInfo) => {
        this.contactInfo = patientContactInfo;
        this.createRoiAuthorizationForm();
      }, error => {
        this.loadingService.loading = false;
        throw (error);
      });
    }, error => {
      this.loadingService.loading = false;
      throw (error);
    });
  }

  getKey(obj): string {
    return Object.keys(obj)[0];
  }



  birthDateDateBoxValueChanged() {

    this.roiAuthorizationForm.get('birthDate').updateValueAndValidity();
    this.markAsDirtyForm();
  }

  pickupDateDateBoxValueChanged(callback: Function) {


    const dateFormControl = this.roiAuthorizationForm.controls['deliveryMethods']['controls'].find(x => this.getKey(x.controls) === this._deliveryMethodPickUpDateHash)['controls'][this._deliveryMethodPickUpDateHash] as FormControl;
    const pickUpDate = new Date(dateFormControl.value);


    const deliveryMethodsList = this.roiAuthorizationForm.get('deliveryMethods') as FormArray;
    const selected = deliveryMethodsList.controls.filter(x => this.getKey(x['controls']) === this._deliveryMethodPickUpCheckHash).every(
      opt => opt['controls'][this.getKey(opt['controls'])].value === true
    );

    if (selected) {
      this._wrongPickUpdate = moment(pickUpDate).isBefore(moment(this.tomorrow));
    } else {
      this._wrongPickUpdate = false;
    }

    dateFormControl.updateValueAndValidity();

    setTimeout(() => {

      if (callback) callback();
      this.markAsDirtyForm();

    }, 50);
  }

  getSelectedDeliveryMethods() {
    const selectedDeliveryMethodOptions: string[] = (<FormArray>this.roiAuthorizationForm.get('deliveryMethods')).controls
      .filter(opt => opt['controls'][this.getKey(opt['controls'])].value === true)
      .map(opt => {
        return this._deliveryMethodOptions[this.getKey(opt['controls'])].description;
      });
    return selectedDeliveryMethodOptions;
  }

  getPickupdate() {
    const pickupMethod = (<FormArray>this.roiAuthorizationForm.get('deliveryMethods')).controls
      .find(opt => this.getKey(opt['controls']) === this._deliveryMethodPickUpDateHash);
    return pickupMethod ? pickupMethod['controls'][this.getKey(pickupMethod['controls'])].value : '';
  }

  getselectedPurposes() {
    const selectedPurposes: string[] = (<FormArray>this.roiAuthorizationForm.get('purposes')).controls
      .filter(opt => opt['controls'][this.getKey(opt['controls'])].value === true)
      .map(opt => {
        return this._purposeOptions[this.getKey(opt['controls'])].description;
      });
    return selectedPurposes;
  }

  getSelectedAuthorizationActions() {
    const selectedAuthorizationActions: string[] = (<FormArray>this.roiAuthorizationForm.get('authorizationActions')).controls
      .filter(opt => opt['controls'][this.getKey(opt['controls'])].value === true)
      .map(opt => {
        return this._authorizationActions[this.getKey(opt['controls'])].description;
      });
    return selectedAuthorizationActions;
  }

  getSelectedDisclosureInformationList() {
    const selectedDisclosureInformation: string[] = (<FormArray>this.roiAuthorizationForm.get('disclosureInformationList')).controls
      .filter(opt => opt['controls'][this.getKey(opt['controls'])].value === true)
      .map(opt => {
        return this._disclosureInformationOption[this.getKey(opt['controls'])].description;
      });
    return selectedDisclosureInformation;
  }

  getDisclosureInformationOther() {
    const disclosureInformationOther: string[] = (<FormArray>this.roiAuthorizationForm.get('disclosureInformationList')).controls
      .filter(opt => this._disclosureInformationOption[this.getKey(opt['controls'])].description === "text box")
      .map(opt => {
        return opt['controls'][this.getKey(opt['controls'])].value;
      });
    return disclosureInformationOther[0];
  }



  onSelectAllDisclosureInformationClicked(e) {

    const ctrl = this.roiAuthorizationForm.get('selectAllDisclosureInformation') as FormControl;
    const disclosureInformationList = this.roiAuthorizationForm.get('disclosureInformationList') as FormArray;

    disclosureInformationList.controls
      .filter(opt => this._disclosureInformationOption[this.getKey(opt['controls'])].description !== 'Other' && this._disclosureInformationOption[this.getKey(opt['controls'])].description !== 'text box')
      .forEach((disclosureInformation: any) => {
        disclosureInformation['controls'][this.getKey(disclosureInformation['controls'])].setValue(!ctrl.value);
      });
  }

  isAllDisclosureInformationSelected() {
    const checkList = this.roiAuthorizationForm.get('disclosureInformationList') as FormArray;
    const allSelected = checkList.controls
      .filter(opt => this._disclosureInformationOption[this.getKey(opt['controls'])].description !== 'Other' && this._disclosureInformationOption[this.getKey(opt['controls'])].description !== 'text box')
      .every(opt => opt['controls'][this.getKey(opt['controls'])].value === true);
    return allSelected;
  }

  onDisclosureInformationSelectionChange(e) {

    const disclosureInformationCheckList = this.roiAuthorizationForm.get('disclosureInformationList') as FormArray;

    const noneDisclosureInformationSelected = disclosureInformationCheckList.controls
      .filter(opt => this._disclosureInformationOption[this.getKey(opt['controls'])].description !== 'text box')
      .every(
        opt => opt['controls'][this.getKey(opt['controls'])].value === false
      );

    this.disclosureInformationSelectionIsValid = !noneDisclosureInformationSelected;
    this.markAsDirtyForm();
  }

  onDisclosureInformationOtherSelectionChange(e, disclosureInformation) {

    const selectAllDisclosure = disclosureInformation.parent.controls.find(opt => this.getKey(opt.controls) === this._disclosureInformationOtherTextHash);

    const disclosureInformationCheckList = this.roiAuthorizationForm.get('disclosureInformationList') as FormArray;

    const noneDisclosureInformationSelected = disclosureInformationCheckList.controls
      .filter(opt => this._disclosureInformationOption[this.getKey(opt['controls'])].description !== 'text box')
      .every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);

    this.disclosureInformationSelectionIsValid = !noneDisclosureInformationSelected;

    if (e.value) {

      selectAllDisclosure['controls'][this._disclosureInformationOtherTextHash].setValidators([Validators.required]);

    } else {

      selectAllDisclosure['controls'][this._disclosureInformationOtherTextHash].clearValidators();
    }

    selectAllDisclosure['controls'][this._disclosureInformationOtherTextHash].updateValueAndValidity();
    this.markAsDirtyForm();
  }

  setDisclosureInformationValidation() {
    const checkList = this.roiAuthorizationForm.get('disclosureInformationList') as FormArray;
    const noneSelected = checkList.controls
      .filter(opt => this._disclosureInformationOption[this.getKey(opt['controls'])].description !== 'text box')
      .every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    this.disclosureInformationSelectionIsValid = !noneSelected;
  }

  onHighlyConfidentialSelectionChange(e) {

    const highlyConfidentialCheckList = this.roiAuthorizationForm.get(
      'highlyConfidentialInformationList'
    ) as FormArray;

    const noneHighlyConfidentialSelected = highlyConfidentialCheckList.controls.filter(
      opt => opt.get('selected').value === false
    );

    if (noneHighlyConfidentialSelected.length > 0) {
      const values: Array<string> = noneHighlyConfidentialSelected.map(opt => opt.value.description);
      this.roiAuthorizationForm.get('restrictions').setValue("Withhold: " + values.join(", "));
    } else {
      this.roiAuthorizationForm.get('restrictions').setValue("");
    }
    this.markAsDirtyForm();
  }

  onDeliveryMethodSelectionChange(e, deliveryMethod) {

    const deliveryMethodsList = this.roiAuthorizationForm.get('deliveryMethods') as FormArray;
    const noneSelected = deliveryMethodsList.controls.filter(x => this.getKey(x['controls']) !== this._deliveryMethodPickUpDateHash).every(
      opt => opt['controls'][this.getKey(opt['controls'])].value === false
    );
    const dateFormControl = deliveryMethod.parent.controls.find(x => this.getKey(x.controls) === this._deliveryMethodPickUpDateHash);
    this.deliveryMethodSelectionIsValid = !noneSelected;
    if (this.getKey(deliveryMethod['controls']) === this._deliveryMethodPickUpCheckHash && e.value) {
      dateFormControl['controls'][this._deliveryMethodPickUpDateHash].clearValidators();
      dateFormControl['controls'][this._deliveryMethodPickUpDateHash].setValidators([Validators.required, dateOrEmptyValidator]);
    } else if (this.getKey(deliveryMethod['controls']) === this._deliveryMethodPickUpCheckHash && !e.value) {
      dateFormControl['controls'][this._deliveryMethodPickUpDateHash].clearValidators();
      dateFormControl['controls'][this._deliveryMethodPickUpDateHash].setValue('');
      dateFormControl['controls'][this._deliveryMethodPickUpDateHash].setValidators([dateOrEmptyValidator]);

    }

    dateFormControl['controls'][this._deliveryMethodPickUpDateHash].updateValueAndValidity();

    this.setDeliveryMethodValidators(deliveryMethodsList);
    this.markAsDirtyForm();
  }


  setDeliveryMethodValidators(deliveryMethodsList: FormArray) {
    const addressControl = this.roiAuthorizationForm.get('address') as FormControl;
    const cityControl = this.roiAuthorizationForm.get('city') as FormControl;
    const stateControl = this.roiAuthorizationForm.get('state') as FormControl;
    const zipControl = this.roiAuthorizationForm.get('zip') as FormControl;
    const faxControl = this.roiAuthorizationForm.get('fax') as FormControl;
    const eMailControl = this.roiAuthorizationForm.get('email') as FormControl;
    deliveryMethodsList.controls.forEach((dm: any) => {

      const description = this._deliveryMethodOptions[this.getKey(dm['controls'])].description;

      if (description === 'Fax') {
        if (dm['controls'][this.getKey(dm['controls'])].value) {
          faxControl.clearValidators();
          faxControl.setValidators([Validators.required, phoneNumberValidator]);
        } else {
          faxControl.clearValidators();
        }
      }

      if (description === 'Email') {
        if (dm['controls'][this.getKey(dm['controls'])].value) {
          eMailControl.clearValidators();
          eMailControl.setValidators([Validators.required, emailOrEmptyValidator]);
        } else {
          eMailControl.clearValidators();
          eMailControl.setValidators([emailOrEmptyValidator]);
        }
      }

    });

    addressControl.updateValueAndValidity();
    cityControl.updateValueAndValidity();
    stateControl.updateValueAndValidity();
    zipControl.updateValueAndValidity();
    faxControl.updateValueAndValidity();
    eMailControl.updateValueAndValidity();
  }

  setDeliveryMethodSelectionValidation() {
    const checkList = this.roiAuthorizationForm.get('deliveryMethods') as FormArray;
    const noneSelected = checkList.controls.filter(x => this.getKey(x['controls']) !== this._deliveryMethodPickUpDateHash).every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    this.deliveryMethodSelectionIsValid = !noneSelected;
  }

  onAuthorizationActionSelectionChange(e) {
    const checkList = this.roiAuthorizationForm.get('authorizationActions') as FormArray;
    const noneSelected = checkList.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    this.authorizationActionSelectionIsValid = !noneSelected;
    this.markAsDirtyForm();
  }

  setAuthorizationActionSelectionValidation() {
    const checkList = this.roiAuthorizationForm.get('authorizationActions') as FormArray;
    const noneSelected = checkList.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    this.authorizationActionSelectionIsValid = !noneSelected;
  }

  onPurposeSelectionChange(e) {
    const checkList = this.roiAuthorizationForm.get('purposes') as FormArray;
    const noneSelected = checkList.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    this.purposeSelectionIsValid = !noneSelected;
    this.markAsDirtyForm();
  }

  setPurposeSelectionValidation() {
    const checkList = this.roiAuthorizationForm.get('purposes') as FormArray;
    const noneSelected = checkList.controls.every(opt => opt['controls'][this.getKey(opt['controls'])].value === false);
    this.purposeSelectionIsValid = !noneSelected;
  }


  selectAllHighlyConfidentialInformationClicked(e) {
    const checkList = this.roiAuthorizationForm.get(
      'highlyConfidentialInformationList'
    ) as FormArray;
    checkList.controls.forEach((cb: any) => {
      cb['controls'].selected.setValue(true);
    });
  }

  beginDateDateBoxValueChanged(callback: Function) {

    const fromDateCtrl = this.roiAuthorizationForm.get('beginDate') as FormControl;
    const startDate = new Date(this.roiAuthorizationForm.get('beginDate').value);
    const endDate = new Date(this.roiAuthorizationForm.get('endDate').value);

    this._wrongBeginDate = moment(startDate).isAfter(moment(this.dateNow));
    this._wrongOrder = moment(startDate).isAfter(moment(endDate));
    fromDateCtrl.updateValueAndValidity();

    setTimeout(() => {
      if (callback) callback();
      this.markAsDirtyForm();
    }, 50);
  }

  endDateDateBoxValueChanged(callback: Function) {
    const toDateCtrl = this.roiAuthorizationForm.get('endDate') as FormControl;
    const startDate = new Date(this.roiAuthorizationForm.get('beginDate').value);
    const endDate = new Date(this.roiAuthorizationForm.get('endDate').value);

    this._wrongOrder = moment(startDate).isAfter(moment(endDate));
    toDateCtrl.updateValueAndValidity();

    setTimeout(() => {
      if (callback) callback();
      this.markAsDirtyForm();
    }, 50);
  }

  scrollIntoViewInvalid(top: AbstractControl): boolean {

    if (top instanceof FormControl) {
      if (top.invalid) {
        (<any>top).nativeElement.scrollIntoView();
        return true;
      }
    } else if (top instanceof FormGroup) {
      for (const key of Object.keys(top.controls)) {
        const invalid = this.scrollIntoViewInvalid(top.controls[key]);
        if (invalid) return true;
      }
    } else if (top instanceof FormArray) {
      for (const control of top.controls) {
        const invalid = this.scrollIntoViewInvalid(control);
        if (invalid) return true;
      }
    }
    return false;
  }

  scrollIntoViewInOrder(): boolean {

    for (const key of this._validationOrder) {
      const invalid = this.scrollIntoViewInvalid(this.roiAuthorizationForm.controls[key]);
      if (invalid) return true;
    }

    return false;

  }

  onSubmitClick = () => {

    this.setDisclosureInformationValidation();
    this.setDeliveryMethodSelectionValidation();
    this.setAuthorizationActionSelectionValidation();
    this.setPurposeSelectionValidation();

    this.pickupDateDateBoxValueChanged(() => {

      this.beginDateDateBoxValueChanged(() => {

        this.endDateDateBoxValueChanged(() => {

          if (
            this.roiAuthorizationForm.invalid ||
            !this.isAtleastOneDisclosureInformationSelected ||
            !this.isAtleastOneDeliveryMethodSelected ||
            !this.isAtleastOneAuthorizationActionSelected ||
            !this.isAtleastOnePurposeSelected
          ) {

            formHelper.markControlAndChildControlsAsDirty(this.roiAuthorizationForm);

            this.scrollIntoViewInOrder();

            alert('Please provide the required information, then click Submit.', 'Message');

            return;
          }

          this.loadingService.loading = true;

          const formModel = this.roiAuthorizationForm.getRawValue();

          // Map form values into service model object
          const roiAuthorizationResponse: ROIFormResponse = {
            selectedFacility: formModel.selectedFacility,
            firstName: formModel.firstName,
            lastName: formModel.lastName,
            dateOfBirth: formModel.birthDate,
            selectedDeliveryMethod: this.getSelectedDeliveryMethods(),
            pickupDate: this.getPickupdate(),
            selectedAuthorizationAction: this.getSelectedAuthorizationActions(),
            facilityOrIndividual: formModel.facilityOrIndividual,
            address: formModel.address,
            city: formModel.city,
            state: formModel.state,
            zip: formModel.zip,
            phoneNumber: formModel.phoneNumber,
            fax: formModel.fax,
            emailAddress: formModel.email,
            selectedPurposes: this.getselectedPurposes(),
            beginDate: formModel.beginDate,
            endDate: formModel.endDate,
            selectAllDisclosures: formModel.selectAllDisclosureInformation,
            selectedDisclosureInformation: this.getSelectedDisclosureInformationList(),
            disclosureInformationOther: this.getDisclosureInformationOther(),
            restrictions: formModel.restrictions,
            signature: formModel.signature,
            patientRelation: formModel.patientRelation
          };


          this.formLibraryService.submitROIForm(roiAuthorizationResponse).subscribe(
            response => {
              this.loadingService.loading = false;
              this.removeDirtyForm();
              alert('Your ROI Authorization form was submitted.', 'Information');
              this.close();
            },
            error => {
              this.loadingService.loading = false;
              throw (error);
            }
          );

        });

      });

    });

  };

  markAsDirtyForm() {
    sessionStorage.setItem('dirtyForm', '1');
  }

  removeDirtyForm() {
    sessionStorage.removeItem('dirtyForm');
  }

  // character counter for facility text box
  onFacilityChanged(e) {

    if (e.fullName === "text" && e.value !== undefined) {
      this.remainingCharacters = this.totalAllowedCharacters - e.value.length;
    }

  }

  ngOnDestroy() {
    this.removeDirtyForm();
  }

  close = () => {
    window.history.back();
  }

}
