<div class="app-container" smartlook-safe>
  <div class="layout-container">
    <div class="header">
      <div class="header-inner">
        <div class="mobile-top-bar small">Need technical support? Call 1-800-234-0482</div>
        <div class="header-content">
          <div class="ctca-logo">
            <img src="assets/images/logo.png?1" width="184" alt="CTCA Logo" />
          </div>
          <div class="myctca-logo">
            
          </div>
          <div class="spacer"></div>
        </div>
      </div>
    </div>
    <div class="body" *ngIf="showOutageMessage(); else outageBlock">
      <div class="content-area">
        <div class="panel-left portal-box">
          <h1>myCTCA Portal</h1>
          <div class="left-box">
            <ul>
              <li>Review your lab results</li>
              <li>View &amp; request appointments</li>
              <li>Communicate with your care team</li>
              <li>View your prescriptions and allergies</li>
              <li>View treatment summary</li>
              <li>View historical data</li>
              <li>Read physicians notes</li>
              <li>Share your records</li>
            </ul>
          </div>
          <div class="center">
            <dx-button text="Go to myCTCA Portal" type="default" (click)="goPortalHome()"></dx-button>
            <div class="item">            
              <a class="dead-link" (click)="openUserGuideWindow()">View User Guide</a>
            </div>
          </div>
        </div>
        <div class="panel-right bill-pay-box">
          <h1>Online Bill Pay</h1>
          <div class="right-box">
            <ul>
              <li>Make a secure online payment</li>
              <li>View your balance</li>
              <li>View past invoices</li>
              <li>Get help with billing</li>
            </ul>
          </div>
          <div class="center">
            <dx-button text="Pay Your Bill Online" type="default" (onClick)="openBillPay()"></dx-button>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>Need Immediate Technical Support?
        <br> Call 1-800-234-0482</p>
      <p class="emergency">If you are experiencing a medical emergency, please dial 911.</p>
      <div class="footer-items small">
        <div class="footer-link">{{Copyright}}</div>
      </div>
    </div>
  </div>
</div>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: Window }"
    [(visible)]="loadingService.loading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>

<ng-template #outageBlock>
  <div class="info">
    <i class="fa fa-info-circle fa-3x"></i>
    <p>
      {{outageMessage}}
    </p>
  </div>
</ng-template>
