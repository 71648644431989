import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { maskPhoneNumber } from '../../home/shared/helpers/phone.helper';
import { BaseComponent } from '../component/base.component';

@Component({
    selector: 'app-phone-field',
    templateUrl: './phone-field.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PhoneFieldComponent,
            multi: true
        }
    ]
})
export class PhoneFieldComponent extends BaseComponent implements ControlValueAccessor {

    @Input() inputAttr: any;
    @Input() validationError: any;
    @Input() value: string;
    @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() isValid: any;
    @Output() onFocusOut: any = new EventEmitter<any>();
    @Output() change: any = new EventEmitter<any>();
    @ViewChild(FormControlDirective, { static: true }) formControlDirective: FormControlDirective;
    @Input() formControl: FormControl;
    @Input() formControlName: string;

    constructor(private controlContainer?: ControlContainer) {
        super();
    }

    get control() {
        return this.formControl || this.controlContainer && this.controlContainer.control && this.controlContainer.control.get(this.formControlName);
    }

    registerOnTouched(fn: any): void {
        this.formControlDirective.valueAccessor.registerOnTouched(fn);
    }

    registerOnChange(fn: any): void {
        this.formControlDirective.valueAccessor.registerOnChange(fn);
    }

    writeValue(obj: any): void {
        this.formControlDirective.valueAccessor.writeValue(obj);
    }

    setDisabledState(isDisabled: boolean): void {
        this.formControlDirective.valueAccessor.setDisabledState(isDisabled);
    }

    phoneNumberChanged(e: any) {
        const key = e && e.event && e.event.key || '';
        const value = this.control.value;
        const masked = maskPhoneNumber(value, key === 'Backspace') || '';
        // if (value !== masked) {
        this.control.setValue(masked);
        this.value = masked;
        this.valueChange.emit(this.value);
        // }
    }

}