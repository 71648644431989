import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserNotification } from '../../core/services/models';
import { FormGroup, FormBuilder,  FormArray } from '@angular/forms';
import {Md5} from 'ts-md5/dist/md5';
import { UserNotificationsService, LoadingService } from '../../core/services';
import { alert } from 'devextreme/ui/dialog';
import { trackPageLoadingTime } from '../../../app/shared/helpers/tracking.helper';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  userNotifications:Array<UserNotification>;
  notificationsForm:FormGroup;
  notificationDictionary:Object;

  constructor(
    private route: ActivatedRoute,
    private router: Router,    
    private fb: FormBuilder,
    private userNotificationsService:UserNotificationsService,
    private loadingService:LoadingService) { }

  getKey(obj):string {
    return Object.keys(obj)[0];
  }

  checkBoxName(notification:FormGroup):string {
    return this.notificationDictionary[this.getKey(notification['controls'])].userNotificationDescription.trim();
  }

  ngOnInit() {
    const startTime = parseInt(localStorage.getItem('startTimeOfAccountNotification'), 10) || Date.now();
    this.loadingService.loading = true;
    this.userNotificationsService.getUserNotifications().subscribe((notifications) => {
      this.loadingService.loading = false;
      this.userNotifications = notifications;
      if (this.userNotifications && this.userNotifications.length > 0) {
        this.notificationsForm = this.fb.group({
          notifications: this.fb.array([])
        });
        this.initializeNotifications();
        const endTime = Date.now();
        const elpasedTime = (endTime - startTime) / 1000;
        trackPageLoadingTime(window.location.pathname, elpasedTime);
        localStorage.removeItem('startTimeOfAccountNotification');
      }
    });


  }

  private initializeNotifications():void {

    this.notificationDictionary = new Object();
    const notificationsFormControls = this.userNotifications.map((notification: UserNotification) =>
      this.getNotificationFormControl(notification)
    );    
    this.notificationsForm.setControl('notifications',this.fb.array(notificationsFormControls)); 

  }  

  private getNotificationFormControl(notification: UserNotification):FormGroup {
    
    const hash: string = Md5.hashStr(notification.userNotificationDescription).toString();    
    this.notificationDictionary[hash] = notification;
    const option: any = new Object();        
    option[hash] = notification.selected;
    return this.fb.group(option);

  }

  get notifications(): FormArray {
    return this.notificationsForm.get('notifications') as FormArray;
  }

  updateNotifications() {
    const toUpdate: Array<UserNotification> = new Array<UserNotification>();
    for (const notification of this.notifications.controls) {
      const un: UserNotification = new UserNotification();        
        un.selected = notification['controls'][this.getKey(notification['controls'])].value;
        un.userNotificationDescription = this.notificationDictionary[this.getKey(notification['controls'])].userNotificationDescription;
        un.userNotificationType = this.notificationDictionary[this.getKey(notification['controls'])].userNotificationType;
        toUpdate.push(un);
    }
    this.loadingService.loading = true;
    this.userNotificationsService.saveUserNotifications(toUpdate).subscribe((result:string) => {
      this.loadingService.loading = false;
      alert("Notification Preferences have been saved","Information");
      this.router.navigate(['preferences'], { relativeTo: this.route.parent });  
    },
    error => {
      this.loadingService.loading = false;
      throw(error);
    });
  }

}
