<app-card label="Immunizations">
  <div card-buttons-desktop>
    <dx-button text="Download as PDF" icon="fa fa-download" [disabled]="!canDownload()" (onClick)="download()"></dx-button>
  </div>
  <div card-buttons-mobile>
    <dx-button text="Download as PDF" icon="fa fa-download" [disabled]="!canDownload()" (onClick)="download()"></dx-button>
  </div>

  <dx-data-grid [dataSource]="data" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }"
    [wordWrapEnabled]="true" [rowAlternationEnabled]="true" [showColumnLines]="false" [searchPanel]="{ visible: true, placeholder: 'Search...' }">

    <dxi-column dataField="immunizationName" caption="Immunization"></dxi-column>
    <dxi-column dataField="vaccineName" caption="Vaccine"></dxi-column>
    <dxi-column dataField="performedDate" caption="Performed Date" dataType="date" format="MMMM d yyyy"></dxi-column>
    <dxi-column dataField="performedBy" caption="Performed By"></dxi-column>

  </dx-data-grid>
</app-card>
