import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { FormLibraryComponent } from './form-library.component';

const routes: Routes = [
	{
		path: '',
		component: FormLibraryComponent,
		children: [
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class FormLibraryRoutingModule {}
