import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { FileDownloadService, FormLibraryService, LoadingService } from 'app/core/services';

@Component({
  selector: 'app-ask-question-non-medical-roi',
  templateUrl: './ask-question-non-medical-roi.component.html',
  styleUrls: ['./ask-question-non-medical-roi.component.scss']
})
export class AskQuestionNonMedicalRoiComponent extends BaseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formLibraryService: FormLibraryService,
    private downloadService: FileDownloadService,
    private loadingService: LoadingService,
  ) {
    super();
  }


  ngOnInit(): void {

  }

  onDownloadRoiClick = (e: any) => {
    this.loadingService.loading = true;
    this.formLibraryService
      .getRoiFormAsPDF()
      .subscribe(data => {
        this.loadingService.loading = false;
        this.downloadService.downloadPdf(data, `ROI Authorization Form`)
      });
  }

  onCompleteRoiClick = (e: any) => {
    this.router.navigate(['non-medical', 'complete-roi'], { relativeTo: this.route.parent });
  }

  close = (e: any) => {
    window.history.back();
  }

}
