import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabResult } from '../../core/services/models';
import { DxContextMenuComponent } from 'devextreme-angular';
import { ResizingService } from '../../core/services';
import { Subscription } from 'rxjs';

export enum MoreOption {
	LabResultsSummary
}

@Component({
	selector: 'app-consolidated-lab-report',
	templateUrl: './consolidated-lab-report.component.html',
	styleUrls: ['./consolidated-lab-report.component.scss']
})
export class ConsolidatedLabReportComponent implements OnInit, OnDestroy {
	
	@ViewChild('moreContextMenu', { static: true }) moreContextMenu: DxContextMenuComponent;

	isMobile = false;

  	moreOptions = [
		{ id: MoreOption.LabResultsSummary, 
			text: 'Lab Results Summary', 
			icon: 'fa fa-th-list' 
		}
 	];

  	constructor(private router:Router,private route: ActivatedRoute, private resizingService:ResizingService) {}

	labResults: Array<LabResult>;

	mobileSubscription:Subscription;

	ngOnInit() {
		this.labResults = this.route.snapshot.data.labResults;
		this.mobileSubscription = this.resizingService.IsMobile().subscribe((result:boolean) => {
			this.isMobile = result;
		});
	}

	ngOnDestroy() {
		if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
	}

	showMoreOptions(event: any) {
		this.moreContextMenu.instance.show();
	}

	onMoreContextMenuItemClick(event: any) {
		
		if(event && event.itemData.id === MoreOption.LabResultsSummary ) {
			this.goSummary();
		}
		
	}

	goSummary() {
		this.router.navigate(['app','lab-results']);
	}
}
