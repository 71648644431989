import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LabResultsService } from '../services';
import { LabResult } from '../services/models';
//import { StringHelper }  from '../../shared/helpers/string.helper';
//import * as moment from 'moment';


@Injectable({
	providedIn: 'root'
})
export class ResolveLabSetGuard implements Resolve<LabResult[]> {
	constructor(private labResultsService: LabResultsService) {}

	resolve(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Array<LabResult> | Observable<Array<LabResult>> | Promise<Array<LabResult>> {
		
		const performedDate: string = route.params['datePerformed'];

		/*if(StringHelper.isNumeric(performedDate)) {
			let value = parseInt(performedDate);
			value = Math.floor(value/1000);
			let pDate = moment.unix(value);
			let selectedDateTime = pDate.add(-(moment().utcOffset()), 'm');
			performedDate = selectedDateTime.format('YYYY-MM-DD');
		}*/

		const performedBy = route.params['performedBy'];
		return this.labResultsService.getLabResultsForDate(performedDate, performedBy);
	}
}
