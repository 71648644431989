import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import {
  FileDownloadService,
  HtmlTransformerService,
  MedicalDocumentsService,
  LoadingService
} from '../../core/services';
import { BaseComponent } from '../../core/component/base.component';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.scss']
})
export class CarePlanComponent extends BaseComponent implements  OnInit {
  constructor(
    private docSvc: MedicalDocumentsService,
    private downloadSvc: FileDownloadService,
    private transformer: HtmlTransformerService,
    private loadingService: LoadingService
  ) { super();}

  carePlan:any;
  @ViewChild('content', { static: true }) content: ElementRef;

  ngOnInit() {
    const startTime = Date.now();
    this.loadingService.loading = true;
    this.docSvc.getCarePlan().subscribe((plan: any) => {
      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elapsedTime);
      this.carePlan = plan.documentText ? plan : null;
      this.transformer
        .load(plan.documentText || plan)
        .bindToHashLinksToSpans(hash => document.getElementById(hash).scrollIntoView())
        .appendTo(this.content.nativeElement);
    });
  }

  download() {
    this.loadingService.loading = true;
    this.docSvc
      .getCarePlanReport()
      .subscribe((plan:Blob) => {
        this.loadingService.loading = false;
        this.downloadSvc.downloadPdf(plan, this.carePlan.documentName);
      });
  }
}
