<app-card [label]="documentType">
  <dx-data-grid [dataSource]="documents" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [pager]="{ showPageSizeSelector: true, allowedPageSizes: [5, 10, 20], showInfo: true, showNavigationButtons: true }"
    [wordWrapEnabled]="true" [rowAlternationEnabled]="true" [showColumnLines]="false" [searchPanel]="{ visible: true, placeholder: 'Search...' }"
    (onRowClick)="onDocumentSelected($event.data)">

    <dxi-column dataField="documentAuthoredDate" caption="Created Date" dataType="date" sortOrder="desc" width="100px"></dxi-column>
    <dxi-column dataField="documentName" caption="Document Type" [allowSorting]="false" [allowSearch]="true" cellTemplate="titleTemplate"></dxi-column>
    <dxi-column dataField="documentAuthor" caption="Author" hidingPriority="0" width="140px"></dxi-column>

    <div *dxTemplate="let document of 'titleTemplate'">
      <a (click)="onDocumentSelected(document.data)" class="dead-link">{{ document.data.documentName }}</a>
    </div>

  </dx-data-grid>
</app-card>
<router-outlet></router-outlet>
