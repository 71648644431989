import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Popup } from '../../core/popup/popup';
import { FacilityInfo, Prescription, UserProfile } from '../../core/services/models';
import { UserProfileService, ResizingService, SecureMailService } from '../../core/services';
import { Subscription } from 'rxjs';
import { DxPopupComponent } from 'devextreme-angular';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-presciptions-detail-modal',
  templateUrl: './presciptions-detail-modal.component.html',
  styleUrls: ['./presciptions-detail-modal.component.scss']
})
export class PresciptionsDetailModalComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
  
  prescription: Prescription;
  facilityInfo: FacilityInfo;
  prettyPhone: string;
  cleanPhone: string;
  canRequestRefill:boolean;
  isMobile = false;
  mobileSubscription:Subscription;
  hasCareTeam: boolean;
  
  @ViewChild('popup', { static: true }) popup: DxPopupComponent;

  constructor(router: Router, route: ActivatedRoute, private userProfileService:UserProfileService, 
    resizingService: ResizingService, private secureMailSvc: SecureMailService) {
    super(router, route, resizingService);
    this.secureMailSvc.hasCareTeam().then((hasCareTeam) => {
      this.hasCareTeam = hasCareTeam;
    });
  }

  ngOnInit(): void {
    this.prescription = this.route.snapshot.data.prescription;
    this.facilityInfo = this.route.snapshot.data.facilityInfo;
    this.prettyPhone = this.facilityInfo.careManagementPhone;
    this.cleanPhone = this.prettyPhone ? '+1' + this.prettyPhone.replace(/[^0-9]/g, '') : '';
    this.userProfileService.getUserProfile().subscribe((profile:UserProfile) => {
      const startTime = parseInt(localStorage.getItem('startTimeOfPrescriptionDetail'), 10) || Date.now();
        localStorage.removeItem('startTimeOfPrescriptionDetail');
        const endTime = Date.now();
        const elapsedTime = (endTime-startTime)/1000;
        trackPageLoadingTime('/app/health-history/prescriptions/:id',elapsedTime);
        if( profile.userPermissions.indexOf(this.PermissionLevel.REQUEST_PRESCRIPTION_REFILL) > -1 ) {
          this.canRequestRefill = true;
        } else {
          this.canRequestRefill = false;
        }
    });
          
    this.mobileSubscription = this.resizingService.IsMobile().subscribe((result:boolean) => {
      this.isMobile = result;
    });

  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy() {
    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();
    super.destroy();
  }

  canRenew():boolean {
    return this.prescription.allowRenewal && this.canRequestRefill;
  }
  
  showClose():boolean {
    if(!this.canRenew()) return true;
    else if(!this.isMobile) return true;
    else return false;
  }

  requestPrescriptionRenewal = () => {
    this.router.navigate(['app', 'prescriptions', 'request-renewal', this.prescription.prescriptionId]);
  };
  
}
