import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'app/core/component/base.component';
import { AppointmentsService, ContactUsStateService, LoadingService } from 'app/core/services';
import { AppointmentDetail } from 'app/core/services/models';
import * as moment from 'moment';


@Component({
  selector: 'app-ask-question-non-medical-schedule-range',
  templateUrl: './ask-question-non-medical-schedule-range.component.html',
  styleUrls: ['../../request-appointmentv2/request-appointmentv2.component.scss', './ask-question-non-medical-schedule-range.component.scss']
})
export class AskQuestionNonMedicalScheduleRangeComponent extends BaseComponent implements OnInit {

  ranges: {
    id: string,
    title: string;
    text?: string;
    startDate?: Date;
    endDate?: Date;
    hasEdit?: boolean;
    onEdit?: any;
  }[] = [];

  selectedRange: any;
  showCalendar = false;
  dateSelected?: Date;
  startDate?: Date;
  endDate?: Date;
  selectingDate?: 'start' | 'end' = 'start';
  customDates?: { start: Date, end: Date };
  editing = false;
  appointments: AppointmentDetail[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactUsState: ContactUsStateService,
    private loadingService: LoadingService,
    private appointmentsService: AppointmentsService,
  ) {
    super();
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.editing = params.edit === 'true'
    });


    const range = this.contactUsState.getState('range');
    if (range) {
      this.selectedRange = range;
      if (range.id === 'custom') {
        this.customDates = {
          start: new Date(range.startDate),
          end: new Date(range.endDate),
        }
      }
    }

    this.ranges = this.getRanges();

    this.loadingService.loading = true;

    this.appointmentsService.getAppointments().subscribe((appointments: AppointmentDetail[]) => {
      this.loadingService.loading = false;
      this.appointments = appointments;
      this.ranges = this.getRanges();
    });
  }

  dateTemplate = (o: any) => {
    // const d: Date = o.date;
    // if (o.view === 'month') {
    //   return this.isWeekend(d) || this.isPastCutoff(d);
    // } else {
    //   return false
    // }
  }

  dateClass = (cellData: { text: string, date: Date, view: string }) => {
    if (this.startDate && this.endDate) {
      const startDate = this.startDate.getTime();
      const endDate = this.endDate.getTime();//+86400000;
      if (startDate <= cellData.date.getTime() && endDate >= cellData.date.getTime()) {
        return 'selected';
      }
    }
    return '';
  }

  onSelectRange = (range: any) => {
    this.selectedRange = range;
    this.selectingDate = 'start';

    if (range.id === 'custom' && !this.customDates) {
      this.openCalendar();
    }
  }

  onDateChanged = () => {
    if (this.selectingDate === 'start') {
      this.startDate = this.dateSelected;
      this.selectingDate = 'end';
      this.endDate = undefined;
    } else if (this.selectingDate === 'end') {
      if (this.startDate.getTime() > this.dateSelected.getTime()) {
        this.endDate = this.startDate;
        this.startDate = this.dateSelected;
      } else {
        this.endDate = this.dateSelected;
      }
      this.selectingDate = 'start';
    }
  }

  saveDateRange = () => {
    if (this.startDate && this.endDate) {
      this.showCalendar = false;
      this.customDates = {
        start: this.startDate,
        end: this.endDate
      };
      this.ranges = this.getRanges();
    }
  }

  closeCalendar() {
    this.showCalendar = false;
    if (!this.customDates) {
      this.selectedRange = undefined;
    }
  }

  openCalendar() {
    console.log('openCalendar');
    this.showCalendar = true;
    if (this.customDates) {
      this.startDate = this.customDates.start;
      this.endDate = this.customDates.end;
    }
  }

  getCustomRangeText = () => {
    if (this.customDates) {
      let customRangeText = `${this.getAppointmentsCount(this.customDates.start, this.customDates.end)} appointments, from `;
      customRangeText += moment(this.customDates.start).format("MMM D, YYYY");
      customRangeText += ' to ';
      customRangeText += moment(this.customDates.end).format("dddd, MMMM Do");

      return customRangeText;
    } else {
      return '';
    }

  }

  getAppointmentsCount = (startDate: Date, endDate: Date) => {
    const startMoment = moment(startDate);
    const endMoment = moment(endDate);
    return this.appointments?.filter(a => {
      return moment(a.startDateTime).isBetween(startMoment, endMoment);
    })?.length || 0;
  }

  getRanges = () => {
    const now = new Date();
    const startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const end30 = new Date(startDate.getTime() + 86400000 * 30);
    const end60 = new Date(startDate.getTime() + 86400000 * 60);
    const next10Years = new Date(now.getFullYear() + 10, now.getMonth(), now.getDate());

    return [
      { id: '30days', title: 'Next 30 days', text: `${this.getAppointmentsCount(startDate, end30)} appointments`, startDate, endDate: end30 },
      { id: '60days', title: 'Next 60 days', text: `${this.getAppointmentsCount(startDate, end60)} appointments`, startDate, endDate: end60 },
      { id: 'all', title: 'All', text: `${this.getAppointmentsCount(startDate, next10Years)} appointments`, startDate, endDate: next10Years },
      { id: 'custom', title: 'Custom range', text: this.getCustomRangeText(), startDate: this.customDates?.start, endDate: this.customDates?.end, hasEdit: !!(this.customDates?.start && this.customDates?.end), onEdit: this.openCalendar }
    ];
  }

  getRangeById = (rangeId: string) => {
    return this.getRanges().find(r => r.id === rangeId);
  }

  onNextClick = (e: any) => {
    this.contactUsState.setState({ range: this.getRangeById(this.selectedRange.id) });
    this.router.navigate(['non-medical', this.editing ? 'review' : 'schedule-delivery'], { relativeTo: this.route.parent });
  }

  close = () => {
    window.history.back();
  }

}
