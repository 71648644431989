import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';

import { BaseService } from './base.service';
import { CarePlan, ImagingResult, MedicalDocument, MedicalDocumentType, PatientReportedItem } from './models';
import { SITSurveyLookup } from './models/sit-survey-lookup.model';
import { ServiceEndpoint } from './service-endpoint.enum';

@Injectable()
export class MedicalDocumentsService extends BaseService {
	
	getCarePlan(): Observable<CarePlan> {
		return this.get({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETCAREPLAN,
			cacheInvalidator: interval(50000)
		});
	}

	getCarePlanReport(): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETCAREPLANREPORT
		});
	}

	getMedicalDocuments(docType: MedicalDocumentType): Observable<MedicalDocument[]> {
		return this.get({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETMEDICALDOCUMENTS,
			params: { docType: docType }
		});
	}

	getMedicalDocument(docType: MedicalDocumentType, documentId: string): Observable<MedicalDocument> {
		return this.get({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETMEDICALDOCUMENT,
			params: {
				docType: docType,
				documentId: documentId
			}
		});
	}

	getMedicalDocumentReport(docType: MedicalDocumentType, documentId: string): Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETMEDICALDOCUMENTREPORT,
			params: {
				docType: docType,
				documentId: documentId
			}
		});
	}

	getImagingDocuments(): Observable<ImagingResult[]> {
		return this.get({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETIMAGINGDOCUMENTS,
			cacheInvalidator: interval(50000)
		});
	}

	getPatientReportedDocuments():Observable<Array<PatientReportedItem>> {
		return this.get({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETPATIENTREPORTEDDOCUMENTS,
			cacheInvalidator: interval(50000)
		});
	}

	getSITPDF():Observable<Blob> {
		return this.getBlob({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETSITPDF
		});
	}

	getSITSurvey():Observable<SITSurveyLookup> {
		return this.get({
			endpoint: ServiceEndpoint.USERPROFILE_GETSITSURVEY
		});
	}

	getImagingDocument(id: string): Observable<ImagingResult> {
		return this.post({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETIMAGINGDOCUMENT,
			body: { id: id },
			cacheInvalidator: interval(50000),
			cacheKey: id
		});
	}

	getImagingDocumentReport(documentId: string) {
		return this.getBlob({
			endpoint: ServiceEndpoint.MEDICALDOCUMENTS_GETIMAGINGDOCUMENTREPORT,
			params: {
				documentId: documentId
			}
		});
	}
}
