import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
	selector: 'app-interactive-resource-card',
	templateUrl: './interactive-resource-card.component.html',
	styleUrls: ['./interactive-resource-card.component.scss']
})
export class InteractiveResourceCardComponent extends BaseComponent implements OnInit {
		
	constructor(private router:Router, private route: ActivatedRoute) {
		super();
	}

	ngOnInit() {
		
	}

	loadChatBot() {
		this.router.navigate(['interactive-chatbot'], { relativeTo: this.route });
	}

}
