import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Popup } from '../../core/popup/popup';
import { VitalSignGraphData } from '../../core/services/models';
import { DxPopupComponent } from 'devextreme-angular';
import { ResizingService } from '../../core/services';

@Component({
  selector: 'app-vitals-chart-modal',
  templateUrl: './vitals-chart-modal.component.html',
  styleUrls: ['./vitals-chart-modal.component.scss']
})
export class VitalsChartModalComponent extends Popup implements OnInit, AfterViewInit, OnDestroy {
  title: string;
  series: any;
  chart;

  @ViewChild('popup', { static: true }) popup: DxPopupComponent;

  dataPoints: VitalSignGraphData[];
  constructor(router: Router, route: ActivatedRoute, resizingService: ResizingService) {
    super(router, route, resizingService);
  }

  ngOnInit(): void {
    this.title = this.route.snapshot.params.seriesName;
    this.dataPoints = this.route.snapshot.data.chartData;
  }

  ngAfterViewInit(){
    super.init(this.popup);
  }

  ngOnDestroy(){
    super.destroy();
  }

  popupShown() {
    this.chart.render();
  }

  chartInitialized(e) {
    this.chart = e.component;
  }
}
