import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { SupportService } from '../../core/services';
import { Address, FacilityInfo } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { map } from 'rxjs/operators';
import { trackEvent, trackPageLoadingTime } from '../../../app/shared/helpers/tracking.helper';


interface PhoneCard {
    title: string;
    description?: string;
    address?: Address;
    phone: string;
    secondPhone?: string;
}

interface FacilityGroup {
    title: string;
    address?: Address;
    phoneCards: PhoneCard[];
}
@Component({
    selector: 'app-contact-overlay',
    templateUrl: './contact-overlay.component.html',
    styleUrls: ['./contact-overlay.component.scss']
})
export class ContactOverlayComponent extends BaseComponent implements OnInit {

    @Output() closeOverlay = new EventEmitter<string>();

    facilityGroups: FacilityGroup[] = [];
    isLoading = false;

    constructor(private contactUsService: SupportService) {
        super();
    }

    ngOnInit() {
        trackEvent('Frequently called numbers opened', 'Click');
        const startTime = Date.now();
        this.isLoading = true;
        this.contactUsService
            .getPrimaryFacilityInfo()
            .pipe(map(facilityInfoArr => facilityInfoArr[0]))
            .subscribe(facilityInfo => {
                this.isLoading = false;
                const endTime = Date.now();
                const elapsedTime = (endTime-startTime)/1000;
                trackPageLoadingTime('/important-numbers',elapsedTime);
                if (facilityInfo && facilityInfo.name !== 'OCC') {
                    const facilityGroup: FacilityGroup = {
                        title: facilityInfo.shortDisplayName,
                        address: facilityInfo.address,
                        phoneCards: [],
                    }

                    if (facilityInfo.mainPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'General Inquiries',
                            description: 'For concerns regarding your care, access to medical records and any general questions you might have.',
                            phone: facilityInfo.mainPhone
                        });
                    }
                    facilityGroup.phoneCards.push({
                        title: 'Technical Support',
                        description: 'For questions related to creating a portal account, accessing the portal and password resets.',
                        phone: '(800) 234-0482'
                    });
                    if (facilityInfo.careManagementPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'Care Management',
                            description: 'Connect with a care manager (RN) for questions about your treatment, symptoms or other related concerns you may have.',
                            phone: facilityInfo.careManagementPhone
                        });
                    }
                    if (facilityInfo.schedulingPhone) {
                        const schedulingPhoneCard: PhoneCard = {
                            title: 'Scheduling',
                            description: 'For changes in your appointments, request for new appointments.',
                            phone: facilityInfo.schedulingPhone,
                            secondPhone: facilityInfo.schedulingSecondaryPhone
                        };
                        if (facilityInfo.schedulingSecondaryPhoneCard) {
                            schedulingPhoneCard.secondPhone = facilityInfo.schedulingSecondaryPhoneCard
                        }
                        facilityGroup.phoneCards.push(schedulingPhoneCard);
                    }
                    if (facilityInfo.travelAndAccommodationsPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'Travel & Accommodations',
                            description: 'For help with booking flights, car service, hotel stays, rates.',
                            phone: facilityInfo.travelAndAccommodationsPhone
                        });
                    }
                    if (facilityInfo.himroiPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'Medical Records',
                            description: 'To access patient medical records and help with forms.',
                            phone: facilityInfo.himroiPhone
                        });
                    }
                    if (facilityInfo.financialCounselingPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'Financial Counseling',
                            description: 'For assistance with insurance concerns and financial barriers.',
                            phone: facilityInfo.financialCounselingPhone
                        });
                    }
                    if (facilityInfo.billingPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'Billing',
                            description: 'For questions and concerns about services rendered, insurance and billing statements.',
                            phone: facilityInfo.billingPhone
                        });
                    }
                    if (facilityInfo.pharmacyPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'Pharmacy',
                            description: 'Connect with a pharmacist for prescription refills, requesting supplements or other related questions.',
                            phone: facilityInfo.pharmacyPhone
                        });
                    }
                    if (facilityInfo.patientAdvocatesPhone) {
                        facilityGroup.phoneCards.push({
                            title: 'Patient Advocates',
                            description: 'Connect with a Patient Advocate for any general concerns you have related to your experience.',
                            phone: facilityInfo.patientAdvocatesPhone
                        });
                    }
                    this.facilityGroups = [facilityGroup];
                } else {
                    this.isLoading = true;
                    this.contactUsService.getAllFacilitiesInfoForTreamentFacilitySelect().subscribe((array: Array<FacilityInfo>) => {
                        this.isLoading = false;
                        this.facilityGroups = [];
                        const ctcaLocations: FacilityGroup = {
                            title: 'CTCA LOCATIONS',
                            phoneCards: [],
                        };

                        ctcaLocations.phoneCards.push({
                            title: 'General Inquiries',
                            description: 'For concerns regarding your care, access to medical records and any general questions you might have.',
                            phone: '(866) 801-7097',
                        });

                        ctcaLocations.phoneCards.push({
                            title: 'Technical Support',
                            description: 'For questions related to creating a portal account, accessing the portal and password resets.',
                            phone: '(800) 234-0482',
                        });

                        const phoenix = this.getFacility(array, 'WRMC');
                        if (phoenix.careManagementPhone) {
                            ctcaLocations.phoneCards.push({
                                title: 'Care Management',
                                description: 'Connect with a care manager (RN) for questions about your treatment, symptoms or other related concerns you may have.',
                                phone: phoenix.careManagementPhone
                            });
                        }

                        const atlanta = this.getFacility(array, 'SERMC');
                        ctcaLocations.phoneCards.push({
                            title: 'Atlanta Hospital',
                            address: atlanta.address,
                            phone: atlanta.mainPhone,
                        });

                        const chicago = this.getFacility(array, 'MRMC');
                        ctcaLocations.phoneCards.push({
                            title: 'Chicago Hospital',
                            address: chicago.address,
                            phone: chicago.mainPhone,
                        });

                        ctcaLocations.phoneCards.push({
                            title: 'Phoenix Hospital',
                            address: phoenix.address,
                            phone: phoenix.mainPhone,
                        });

                        const chicagoOCC: FacilityGroup = {
                            title: 'CHICAGO OUTPATIENT CARE CENTERS',
                            phoneCards: [
                                {
                                    title: 'Downtown Chicago',
                                    phone: '(312) 535-7863',
                                },
                                {
                                    title: 'Gurnee',
                                    phone: '(847) 332-0936',
                                }
                            ],
                        };

                        const phoenixOCC: FacilityGroup = {
                            title: 'PHOENIX OUTPATIENT CARE CENTERS',
                            phoneCards: [
                                {
                                    title: 'North Phoenix',
                                    phone: '(847) 872-4561',
                                },
                                {
                                    title: 'Scottsdale',
                                    phone: '(928) 482-4670',
                                }
                            ],
                        };

                        this.facilityGroups = [ctcaLocations, chicagoOCC, phoenixOCC];
                    });
                }
            });
    }

    getFacility = (facilityInfoArray: FacilityInfo[], facilityName: string): FacilityInfo => {
        return facilityInfoArray.find(facilityInfo => {
            return facilityInfo.name === facilityName;
        })
    }

    get Window() {
        return window;
    }

    onSecureMailLinkClick() { }

    close() {
        this.closeOverlay.emit();
    }

    call = (phoneCard: PhoneCard, isSecondary: boolean = false) => {
        trackEvent('Frequently called numbers - Phone number clicked', 'Click', phoneCard.title + (isSecondary ? ' Secondary phone' : ''));
        window.location.href = 'tel:+1' + (isSecondary ? phoneCard.secondPhone : phoneCard.phone).replace(/[^0-9]/g, '');
    }

    openGoogleMaps = (address: Address) => {
        let addressString = address.line1;
        if (address.line2) addressString += ', ' + address.line2;
        if (address.line2) addressString += ', ' + address.line2;
        if (address.city) addressString += ', ' + address.city;
        if (address.state) addressString += ', ' + address.state;
        if (address.postalCode) addressString += ', ' + address.postalCode;
        const url = 'https://maps.google.com/?q=' + encodeURIComponent(addressString);
        window.open(url);

    }
}
