import { NgModule } from '@angular/core';
import { PhoneFieldModule } from '../core/phone-field/phone-field.module';
import { SharedModule } from '../shared/shared.module';
import {
  DxButtonModule,
  DxDateBoxModule,
  DxListModule,
  DxLoadPanelModule,
  DxPopupModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTextBoxModule,
  DxTextAreaModule,
  DxTooltipModule,
  DxValidatorModule,
} from 'devextreme-angular';

import { ContactCareTeamComponent } from './contact-care-team/contact-care-team.component';
import { ContactTechnicalSupportComponent } from './contact-technical-support/contact-technical-support.component';
import { ContactTreatmentFacilityComponent } from './contact-treatment-facility/contact-treatment-facility.component';
import { ContactUsRoutingModule } from './contact-us-routing.module';
import { ContactUsComponent } from './contact-us.component';
import {SendMessageTechnicalSupportModalComponent} from './send-message-to-technical-support-modal/send-message-to-technical-support-modal.component';

@NgModule({
    imports: [
        SharedModule,
        DxPopupModule,
        DxScrollViewModule,
        DxTextBoxModule,
        DxButtonModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        DxTextAreaModule,
        DxTooltipModule,
        DxLoadPanelModule,
        DxListModule,
        DxValidatorModule,
        ContactUsRoutingModule,
        PhoneFieldModule
    ],
    declarations: [
        ContactUsComponent,
        ContactCareTeamComponent,
        ContactTechnicalSupportComponent,
        ContactTreatmentFacilityComponent,
        SendMessageTechnicalSupportModalComponent
    ],
    exports: [SendMessageTechnicalSupportModalComponent]
})
export class ContactUsModule { }
