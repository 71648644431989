<app-card label="Registrations By Site">

    <form *ngIf="registrationForm" [formGroup]="registrationForm" novalidate>
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label"><label for="startDate">Start Date:</label></div>
          <div class="dx-field-value">
            <div style="display:table-cell">
              <dx-date-box #startDate [inputAttr]="{ id: 'startDate' }" formControlName="startDate" [max]="max" [min]="min" (onFocusOut)="startDateValueChanged()" placeholder="mm/dd/yyyy" displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format" [isValid]="registrationForm.get('startDate').valid || registrationForm.get('startDate').pristine"></dx-date-box>
            </div>  
          </div>                      
        </div>
        <div class="dx-field">
          <div class="dx-field-label"><label for="endDate">End Date:</label></div>
          <div class="dx-field-value">
            <div style="display:table-cell">
              <dx-date-box #endDate [inputAttr]="{ id: 'endDate' }" formControlName="endDate" [max]="max" [min]="min" (onFocusOut)="endDateValueChanged()" placeholder="mm/dd/yyyy" displayFormat="MM/dd/yyyy" invalidDateMessage="Must be in mm/dd/yyyy format" [isValid]="registrationForm.get('endDate').valid || registrationForm.get('endDate').pristine"></dx-date-box>
            </div>
          </div>                      
        </div>
        <div *ngIf="wrongOrder()" class="dx-field">
            <div class="dx-field-label"></div>
            <div class="dx-field-value">
                <span class="red">
                    Start Date can't be after End Date
                </span>
            </div>
        </div>  
        <div *ngIf="!inRange()" class="dx-field">
            <div class="dx-field-label"></div>
            <div class="dx-field-value">
                <span class="red">
                    Date value outside range {{minDate()}} - {{maxDate()}}
                </span>
            </div>
        </div>      
        <div class="dx-field">
          <div class="dx-field-label"></div>
          <div class="dx-field-value">
              <dx-button class="left" text="Lookup Registrations" (onClick)="lookupRegistrations()"></dx-button>
          </div> 
        </div>
      </div>
    </form>

    <div *ngIf="registrations.length > 0">
      <dx-data-grid [dataSource]="registrations" [hoverStateEnabled]="true" [paging]="{ pageSize: 10 }" [searchPanel]="{ visible: true, placeholder: 'Search...' }" [wordWrapEnabled]="false" [columnAutoWidth]="true" [rowAlternationEnabled]="true" [showColumnLines]="false">
    
        <dxi-column dataField="location" [fixed]="true" fixedPosition="left" caption="Location"></dxi-column>
        <dxi-column dataField="year" [fixed]="true" fixedPosition="left" caption="Year"></dxi-column>
        <dxi-column dataField="january" caption="Jan"></dxi-column>
        <dxi-column dataField="february" caption="Feb"></dxi-column>
        <dxi-column dataField="march" caption="Mar"></dxi-column>
        <dxi-column dataField="april" caption="Apr"></dxi-column>
        <dxi-column dataField="may" caption="May"></dxi-column>
        <dxi-column dataField="june" caption="Jun"></dxi-column>
        <dxi-column dataField="july" caption="Jul"></dxi-column>
        <dxi-column dataField="august" caption="Aug"></dxi-column>
        <dxi-column dataField="september" caption="Sep"></dxi-column>
        <dxi-column dataField="october" caption="Oct"></dxi-column>
        <dxi-column dataField="november" caption="Nov"></dxi-column>
        <dxi-column dataField="december" caption="Dec"></dxi-column>       
    
      </dx-data-grid>
    </div>

</app-card>
<router-outlet></router-outlet>