export class ContactUsQuestionsResponse {
    constructor(
        public questions: ContactUsQuestion[],
    ) { }
}

export enum ContactUsFormType {
    Basic = 'Basic',
    RescheduleAppointment = 'RescheduleAppointment',
    RequestAppointment = 'RequestAppointment',
    CancelAppointment = 'CancelAppointment',
    RequestAppointmentSchedule = 'RequestAppointmentSchedule',
    ROI = 'ROI',
    Secure = 'Secure',
}

export class ContactUsQuestion {
    constructor(
        public id: string,
        public value: string,
        public hasQuestion: boolean,
        public hasAttachment: boolean,
        public formType: ContactUsFormType,
        public subjects: ContactUsSubject[],
    ) { }
}

export class ContactUsSubject {
    constructor(
        public id: string,
        public value: string,
        public hasQuestion: string,
        public hasAttachment: string,
        public formType: ContactUsFormType,
    ) { }
}
