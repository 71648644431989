import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VitalsComponent } from '../health-history/vitals/vitals.component';

import {
	ResolveCareTeamsGuard,
	ResolvePrescriptionGuard,
	ResolveVitalsChartDataGuard
} from '../core/guards';
import { AllergiesComponent } from './allergies/allergies.component';
import { HealthIssuesComponent } from './health-issues/health-issues.component';
import { ImmunizationsComponent } from './immunizations/immunizations.component';
import { PresciptionsDetailModalComponent } from './presciptions-detail-modal/presciptions-detail-modal.component';
import { PrescriptionsComponent } from './prescriptions/prescriptions.component';
import { VitalsChartModalComponent } from './vitals-chart-modal/vitals-chart-modal.component';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { ResolveFacilityInfoGuard } from '../core/guards';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: 'vitals',
				component: VitalsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_VITAL_SIGNS },
				children: [
					{
						path: ':seriesName',
						resolve: { chartData: ResolveVitalsChartDataGuard },
						component: VitalsChartModalComponent,
						data: { permissionLevel: PermissionLevel.VIEW_VITAL_SIGNS }
					}
				]
			},
			{
				path: 'prescriptions',
				component: PrescriptionsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_PRESCRIPTIONS },
				resolve: { careteams: ResolveCareTeamsGuard, facilityInfo: ResolveFacilityInfoGuard },
				children: [
					{
						path: ':id',
						resolve: { prescription: ResolvePrescriptionGuard, careteams: ResolveCareTeamsGuard, facilityInfo: ResolveFacilityInfoGuard },
						component: PresciptionsDetailModalComponent,
						data: { permissionLevel: PermissionLevel.VIEW_PRESCRIPTIONS },
					}
				]
			},
			{
				path: 'allergies',
				component: AllergiesComponent,
				data: { permissionLevel: PermissionLevel.VIEW_ALLERGIES }
			},
			{
				path: 'immunizations',
				component: ImmunizationsComponent,
				data: { permissionLevel: PermissionLevel.VIEW_IMMUNIZATIONS }
			},
			{
				path: 'health-issues',
				component: HealthIssuesComponent,
				data: { permissionLevel: PermissionLevel.VIEW_HEALTH_ISSUES }
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class HealthHistoryRoutingModule {}
