import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { MedicalDocument, MedicalDocumentType } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-medical-document-list',
  templateUrl: './medical-document-list.component.html',
  styleUrls: ['./medical-document-list.component.scss']
})
export class MedicalDocumentListComponent extends BaseComponent implements  OnInit {
  constructor(private router: Router, private route: ActivatedRoute) { super();}

  label: string;
  documentType: MedicalDocumentType;
  documents: MedicalDocument[];

  ngOnInit() {
    this.documentType = this.route.snapshot.data.documentType;
    this.documents = this.route.snapshot.data.documents;
    const startTime = parseInt(localStorage.getItem(`startTimeOf${this.documentType}`), 10) || Date.now();
    localStorage.removeItem(`startTimeOf${this.documentType}`);
    const endTime = Date.now();
    const elapsedTime = (endTime-startTime)/1000;
    if(window.location.pathname.split('/').length<=4 && 
    (window.location.pathname.includes('clinical') || 
    window.location.pathname.includes('radiation') ||
    window.location.pathname.includes('integrative') ))
    trackPageLoadingTime(window.location.pathname,elapsedTime);
  }

  onDocumentSelected = (data:any) => {
    this.router.navigate([data.documentId], { relativeTo: this.route });
  };
}
