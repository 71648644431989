import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../core/component/base.component';
import { FileDownloadService, MedicalDocumentsService, LoadingService } from '../../core/services';
import { PatientReportedItem } from '../../../app/core/services/models';
import { trackPageLoadingTime } from '../../../app/shared/helpers/tracking.helper';

@Component({
  selector: 'app-symptom-inventory',
  templateUrl: './symptom-inventory.component.html',
  styleUrls: ['./symptom-inventory.component.scss']
})
export class SymptomInventoryComponent extends BaseComponent implements OnInit {

  patientReportedItems:Array<PatientReportedItem> = new Array<PatientReportedItem>();

  constructor(private downloadService: FileDownloadService,private medicalDocumentsService:MedicalDocumentsService,
    private loadingService:LoadingService) { 
    super();
  }

  ngOnInit() {
    const startTime = Date.now();
    this.medicalDocumentsService.getPatientReportedDocuments().subscribe((data:Array<PatientReportedItem>)=>{
      this.patientReportedItems = data;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elapsedTime);
    });
  }

  downloadSIT() {
    this.loadingService.loading = true;
    this.medicalDocumentsService.getSITPDF().subscribe((data:Blob) => {
      this.downloadService.downloadPdf(data,'SIT Patient Education Document.pdf');
      this.loadingService.loading = false;
    }, (error) =>{
      this.loadingService.loading = false;
      throw(error);
    });
  }

}
