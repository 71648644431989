<dx-popup #popup [visible]="visible$ | async" (onHidden)="navigateToParent()" (onShown)="loadContent($event)" [title]="title"
  height="600" width="800">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Download', type: 'default', onClick: download }">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view>
      <div id="modalContent"></div>
    </dx-scroll-view>
  </div>
</dx-popup>
