import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { ImagingResult } from '../services/models';
import { MedicalDocumentsService } from '../services';

@Injectable()
export class ResolveImagingDocumentGuard implements Resolve<ImagingResult> {
  constructor(private docSvc: MedicalDocumentsService) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): ImagingResult | Observable<ImagingResult> | Promise<ImagingResult> {
    const id = route.params.id;
    return this.docSvc.getImagingDocument(id);
  }
}
