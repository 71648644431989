<dx-popup #popup [visible]="visible$ | async" [title]="title" height="800" width="800" (onShown)="popupShown()" (onHidden)="navigateToParent()"
  [deferRendering]="false">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-chart [dataSource]="dataPoints" palette="green" style="height:100%" (onInitialized)="chartInitialized($event)">
      <dxo-common-series-settings argumentField="enteredDate" valueField="value" type="line"></dxo-common-series-settings>
      <dxo-series-template nameField="observationItem"></dxo-series-template>
      <dxi-value-axis valueType="numeric">
      </dxi-value-axis>
      <dxo-argument-axis [valueMarginsEnabled] discreteAxisDivisionMode="crossLabels">
        <dxo-grid [visible]="true"></dxo-grid>
        <dxo-label format="date"></dxo-label>
      </dxo-argument-axis>
      <dxo-legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="bottom">
      </dxo-legend>
    </dx-chart>
  </div>
</dx-popup>
