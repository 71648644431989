import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../component/base.component';
import { SupportService, UserProfileService } from '../services';
import { Badges, UserProfile } from '../services/models';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent extends BaseComponent implements OnInit {

  @Output() onToggleMobileSideMenuClick = new EventEmitter();
  @Input() sideMenuOpen: boolean;

  userProfile: UserProfile;
  labResultsBadgeCount = 0;
  secureMessagesBadgeCount = 0;
  badgeCount = 0;

  constructor(private profileSvc: UserProfileService, private supportService: SupportService) {
    super();
    this.userProfile = null;
  }

  toggleMobileSideMenu() {
    this.onToggleMobileSideMenuClick.emit();
  }

  ngOnInit() {
    this.profileSvc.getUserProfileHardCache().subscribe((profile: UserProfile) => {
      this.userProfile = profile;

      this.supportService.getBadgesCount().subscribe((badges: Badges) => {
        this.secureMessagesBadgeCount = badges.secureMessages;
        this.labResultsBadgeCount = badges.labResults;
        this.badgeCount = badges.secureMessages + badges.labResults;
      });
    });
  }

}
