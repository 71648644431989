import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { UserProfileService,/* Disabled per DPE-6780 MedicalDocumentsService,*/ AuthenticationService, SupportService, LaunchDarklyService, FEATURE_FLAG_PORTAL_CLAIMS_ENABLED } from '../services';
import { BaseComponent } from '../component/base.component';
import { Badges, UserProfile } from '../services/models';
import { environment } from 'environments/environment';
import { SITSurveyCommunicationService } from '../../patient-reported/sit-survey/sit-survey-communication.service';
import { Observable } from 'rxjs';
import { SITSurveyLookup } from '../services/models';
import { Router } from '@angular/router';
import { PermissionLevel } from '../permissions/permission-levels.enum';
import { logger } from '../../../app/shared/helpers/logging.helper';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';

@Component({
	selector: 'app-nav-menu',
	templateUrl: './nav-menu.component.html',
	styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent extends BaseComponent implements OnInit {

	@Input() showContactOverlay = false;
	@Output() showContactOverlayChange = new EventEmitter<boolean>();

	unreadMessageCount: number;
	unreadLabResultsCount: number;
	patientReported = false;
	userProfile:UserProfile;
	sitSurveyUrl = '';
	sitSurveyLookup$: Observable<SITSurveyLookup>;
	fullName = '';
	role = '';
	userMenuExpanded = false;
	router: Router;
	hasActiveProxy = false;
	hasProxies = false;
	claimsEnabled = false;

	constructor(private profileSvc: UserProfileService, 
		private supportService: SupportService,
		/*Disabled per DPE-6780
		private medicalDocumentsService: MedicalDocumentsService,*/
		private sitSurveyCommunicationService: SITSurveyCommunicationService,
		private auth: AuthenticationService, router: Router,
		private ld: LaunchDarklyService) {
		super();
		this.userProfile = null;
		this.router = router;
	}



	ngOnInit() {
		this.profileSvc.getUserProfileHardCache().subscribe((profile:UserProfile) => {
			this.userProfile = profile;
			this.fullName = `${profile.firstName} ${profile.lastName}`;
			if (profile.proxies && profile.proxies.length > 0) {
				const proxy = this.userProfile.proxies.find(p => p.relationshipType !== "Self" && p.isImpersonating);
				if (proxy) {
					this.fullName = `${proxy.firstName} ${proxy.lastName}`;
				}
				this.hasActiveProxy = !!proxy;
				this.hasProxies = profile.proxies.filter(p => p.relationshipType !== "Self").length > 0 || profile.isSuperUser;
			} else {
				this.hasActiveProxy = false;
				this.hasProxies = false;
			}

			this.role = profile.roles[0];
			if (this.role === 'Portal Proxy User') {
				this.role = 'Patient';
			} else if (this.role) {
				this.role = this.role.replace('Portal ', '');
			}

			this.supportService.getBadgesCount().subscribe((badges: Badges) => {
				this.unreadMessageCount = badges.secureMessages;
				this.unreadLabResultsCount = badges.labResults;
			});
			
			/* Disabled per DPE-6780
			if(this.userProfile.userPermissions.indexOf(this.PermissionLevel.SUBMIT_PATIENT_REPORTED_DOCUMENTS) > -1){
				this.sitSurveyLookup$ = this.medicalDocumentsService.getSITSurvey();
				this.sitSurveyLookup$.subscribe((sitSurvey:SITSurveyLookup) => {
					this.setSurveyRoute(sitSurvey)
				});			
			}*/					
		});

		this.ld.flagChange.subscribe((flags: LDFlagSet) => {
			this.claimsEnabled = flags[FEATURE_FLAG_PORTAL_CLAIMS_ENABLED];
		});
		this.ld.flagValue(FEATURE_FLAG_PORTAL_CLAIMS_ENABLED, false).then(claimsEnabled => {
			this.claimsEnabled = claimsEnabled;
		});

	}
	setSurveyRoute(sitSurvey:SITSurveyLookup){
		if(sitSurvey && sitSurvey.surveyDue){
			this.sitSurveyUrl = `${environment.sitSurveyUrl}?MRN=${sitSurvey.mrn}&DeviceID=${sitSurvey.deviceId}&VisitId=` +
			`${sitSurvey.visitId}&ProviderID=${sitSurvey.providerId}`;			
			this.sitSurveyCommunicationService.raiseEvent(this.sitSurveyUrl);
			const sitPage = this.SITPages.find((_sitPage) => _sitPage.label === 'SIT Survey');
			sitPage.route = this.sitSurveyUrl;
			sitPage.target = '_blank';
		} else{
			this.sitSurveyCommunicationService.raiseEvent('');
			const sitPage = this.SITPages.find(_sitPage => _sitPage.label === 'SIT Survey');
			sitPage.route = '/app/patient-reported/sit-survey';
		}
	}

	toggleContactOverlay() {
		this.showContactOverlay = !this.showContactOverlay;
		this.showContactOverlayChange.emit(this.showContactOverlay);
	}

	toggleUserMenu() {
		this.userMenuExpanded = !this.userMenuExpanded;
	}

	userMenuClasses() {
		let classes = 'user-menu';
		if (this.userMenuExpanded) {
			classes += ' expanded'
		}
		return classes;
	}

	closeOverlay() {
		this.toggleContactOverlay();
	}

	openMyAccount() {
		window.open(environment.ctcaIdentityUrl, '_blank');
	}

	openShareRecords() {
		window.open(environment.ctcaIdentityUrl + "?active=" + environment.securityTab, '_blank');
	}

	selectPatient() {
		this.router.navigate(['app', 'home', 'select-patient']);
	}

	backToMyAccount() {
		this.auth.revertAuthenticatedProxy();
	}

	logout() {
		logger.addContext('user', {});
		logger.addContext('proxy', {});
		this.auth.logout();
	}

	hasPermission(permission: PermissionLevel) {
		if (this.userProfile && this.userProfile.userPermissions) {
			return this.userProfile.userPermissions.indexOf(permission) > -1;
		}
		return false;
	}

	SITPages = [
		{
			label: 'SIT Survey',
			permission: this.PermissionLevel.SUBMIT_PATIENT_REPORTED_DOCUMENTS,
			target: null
		},
		{
			label: 'SIT Results',
			route: '/app/patient-reported/symptom-inventory',
			permission: this.PermissionLevel.VIEW_PATIENT_REPORTED_DOCUMENTS
		},
		{
			label: 'SIT Data Graphs',
			route: '/app/patient-reported/sit-data-graphs',
			permission: this.PermissionLevel.VIEW_PATIENT_REPORTED_DOCUMENTS
		}
	];


	medicalDocumentsPages = [
		{
			label: 'Clinical Summaries',
			route: '/app/medical-documents/clinical-summaries',
			permission: this.PermissionLevel.VIEW_CCDA_DOCUMENTS
		},
		{
			label: 'Clinical Documents',
			route: '/app/medical-documents/clinical',
			permission: this.PermissionLevel.VIEW_CLINICAL_DOCUMENTS
		},
		{
			label: 'Radiation',
			route: '/app/medical-documents/radiation',
			permission: this.PermissionLevel.VIEW_RADIATION_DOCUMENTS
		},
		{
			label: 'Imaging',
			route: '/app/medical-documents/imaging',
			permission: this.PermissionLevel.VIEW_IMAGING_DOCUMENTS
		},
		{
			label: 'Integrative',
			route: '/app/medical-documents/integrative',
			permission: this.PermissionLevel.VIEW_INTEGRATIVE_DOCUMENTS
		},
		{
			label: 'Care Plan',
			route: '/app/medical-documents/care-plan',
			permission: this.PermissionLevel.VIEW_CARE_PLAN
		},
	];

	healthHistoryPages = [
		{
			label: 'Vitals',
			route: '/app/health-history/vitals',
			permission: this.PermissionLevel.VIEW_VITAL_SIGNS
		},
		{
			label: 'Prescriptions',
			route: '/app/health-history/prescriptions',
			permission: this.PermissionLevel.VIEW_PRESCRIPTIONS
		},
		{
			label: 'Allergies',
			route: '/app/health-history/allergies',
			permission: this.PermissionLevel.VIEW_ALLERGIES
		},
		{
			label: 'Immunizations',
			route: '/app/health-history/immunizations',
			permission: this.PermissionLevel.VIEW_IMMUNIZATIONS
		},
		{
			label: 'Health Issues',
			route: '/app/health-history/health-issues',
			permission: this.PermissionLevel.VIEW_HEALTH_ISSUES
		}
	];

	interactivePages = [
		{
			label: 'COVID-19',
			route: '/app/home/interactive-chatbot',
			permission: this.PermissionLevel.VIEW_INTERACTIVE
		}
	];

	myAccountPages = [
		{
			label: 'User Profile',
			route: environment.ctcaIdentityUrl,
			permission: this.PermissionLevel.VIEW_MY_ACCOUNT,
			target: '_blank'
		},
		{
			label: 'Preferences',
			route: '/app/account/preferences',
			permission: this.PermissionLevel.MANAGE_PREFERENCES
		},
		{
			label: 'Notifications',
			route: '/app/account/notifications',
			permission: this.PermissionLevel.MANAGE_NOTIFICATIONS
		},
		{
			label: 'Activity Logs',
			route: '/app/account/user-logs',
			permission: this.PermissionLevel.VIEW_USER_LOGS
		}
	];

	billingPages = [
		{
			label: 'Claims & Statements',
			route: '/app/claims',
			permission: this.PermissionLevel.VIEW_CLAIMS,
		}, {
			label: 'Bill Pay',
			route: '/app/bill-pay',
			permission: this.PermissionLevel.VIEW_BILL_PAY,
		},
	];

	adminPages = [
		{
			label: 'Alert Messages',
			route: '/app/admin/alert-messages',
			permission: this.PermissionLevel.VIEW_SYSTEM_ALERT_MESSAGES
		},
		{
			label: 'White List',
			route: '/app/admin/documents-whitelist',
			permission: this.PermissionLevel.VIEW_DOCUMENTS_WHITELIST
		},
		{
			label: 'Activity Logs',
			route: '/app/admin/activity-logs',
			permission: this.PermissionLevel.VIEW_ACTIVITY_LOGS
		},
		{
			label: 'Load NPI Files',
			route: '/app/admin/load-npi-files',
			permission: this.PermissionLevel.LOAD_NPI_FILES
		}
	];

	reportingPages = [
		{
			label:'Active Accounts',
			route: '/app/reporting/active-accounts',
			permission: this.PermissionLevel.VIEW_REPORTING
		},
		{
			label:'Event Counts',
			route: '/app/reporting/event-counts',
			permission: this.PermissionLevel.VIEW_REPORTING
		},
		{
			label:'Event Counts Pivot',
			route: '/app/reporting/event-counts-pivot',
			permission: this.PermissionLevel.VIEW_REPORTING
		},
		{
			label:'Registrations By Site',
			route: '/app/reporting/registrations-by-site',
			permission: this.PermissionLevel.VIEW_REPORTING
		},
		{
			label:'MU Location Visits',
			route: '/app/reporting/location-visits',
			permission: this.PermissionLevel.VIEW_REPORTING
		},
		{
			label:'MU Location Events',
			route: '/app/reporting/location-events',
			permission: this.PermissionLevel.VIEW_REPORTING
		},
		{
			label:'MU Events By Patient',
			route: '/app/reporting/events-by-patient',
			permission: this.PermissionLevel.VIEW_REPORTING
		}
	];
}


