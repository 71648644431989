import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { custom } from "devextreme/ui/dialog";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HealthHistoryService, SecureMailService, SupportService } from "../services";

class CanActivateCareTeamsGuard {

    secureMailSvc: SecureMailService;
    contactUsService: SupportService

    constructor(secureMailSvc: SecureMailService, contactUsService: SupportService) {
        this.secureMailSvc = secureMailSvc;
        this.contactUsService = contactUsService;
    }

    canActivateExtra(
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot, message: string
    ): Promise<boolean> {
        return this.secureMailSvc.hasCareTeam().then((hasCareTeam) => {
            if (!hasCareTeam) {
                this.contactUsService
                    .getPrimaryFacilityInfo()
                    .pipe(map(facilityInfoArr => facilityInfoArr[0]))
                    .subscribe(facilityInfo => {
                        const cleanPhone = '+1' + facilityInfo.careManagementPhone.replace(/[^0-9]/g, '');
                        const title = facilityInfo.shortDisplayName;
                        const isMobile = window.innerWidth <= 640;
                        const customDialog = custom({
                            messageHtml: `<div class="dialogText center"><img src="/assets/images/icons/SVG/warning.svg" alt="Warning icon"/><h1>Care Team - ${title}</h1>${message} Please contact the Care Management Team for your treatment facility at <a href="tel:${cleanPhone}">${facilityInfo.careManagementPhone}</a> for assistance.</div>`,
                            showTitle: false,
                            popupOptions: {
                                elementAttr: { class: isMobile ? 'customAlertMobile' : 'customAlertDesktop' }
                            },
                            position: isMobile ? 'bottom' : 'center',
                            buttons: [
                                {
                                    text: "Ok",
                                    elementAttr: { class: 'greenDialogButton' },
                                }
                            ]
                        } as any);
                        customDialog.show();
                    });
            }
            return hasCareTeam;
        });

    }
}


@Injectable()
export class CanActivatePrescriptionGuard extends CanActivateCareTeamsGuard implements CanActivate {
    constructor(secureMailSvc: SecureMailService, contactUsService: SupportService, private hhSvc: HealthHistoryService) {
        super(secureMailSvc, contactUsService);
    }
    canActivate(
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Promise<boolean> {
        const hasCareTeam = super.canActivateExtra(route, state, 'We are unable to determine the appropriate clinical personnel to renew your prescription.');
        return new Promise(async (resolve) => {
            if (await hasCareTeam) {
                this.hhSvc
                    .getPrescriptions()
                    .subscribe(async (prescriptions) => {
                        const hasRenewablePrescriptions = !!prescriptions.find(p => p.allowRenewal);
                        if (hasRenewablePrescriptions) {
                            resolve(true);
                        } else {
                            const isMobile = window.innerWidth <= 640;
                            const customDialog = custom({
                                messageHtml: `<div class="dialogText center"><img src="/assets/images/icons/SVG/warning.svg" alt="Warning icon"/><h1>We couldn't find any CTCA prescriptions.</h1> If you need help, please contact your Care Team for assistance.</div>`,
                                showTitle: false,
                                popupOptions: {
                                    elementAttr: { class: isMobile ? 'customAlertMobile' : 'customAlertDesktop' }
                                },
                                position: isMobile ? 'bottom' : 'center',
                                buttons: [
                                    {
                                        text: "Ok",
                                        elementAttr: { class: 'greenDialogButton' },
                                    }
                                ]
                            } as any);
                            customDialog.show();
                            resolve(false);
                        }
                    });
            } else {
                resolve(false);
            }
        });
    }
}

@Injectable()
export class CanActivateSHMGuard extends CanActivateCareTeamsGuard implements CanActivate {
    constructor(secureMailSvc: SecureMailService, contactUsService: SupportService) {
        super(secureMailSvc, contactUsService);
    }
    canActivate(
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return super.canActivateExtra(route, state, 'We are unable to determine the appropriate clinical personnel to route your message to.')
    }
}