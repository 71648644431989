<div class="card" [style.height]="height" [style.width]="width">
  <div class="card-header">
    <div class="card-label">{{ label }}</div>
    <div class="card-buttons">
      <div class="card-buttons-desktop">
        <ng-content select="[card-buttons-desktop]"></ng-content>
      </div>
      <div class="card-buttons-mobile">
        <ng-content select="[card-buttons-mobile]"></ng-content>
      </div>
      <div class="card-text-link" *ngIf="actionLabel" (click)="clicked()">
        <span class="card-text-link-desktop">{{actionLabel}}</span>
        <span class="card-text-link-mobile">{{actionLabelMobile || actionLabel}}</span>
      </div>
    </div>
  </div>
  <div class="card-body">
    <ng-content></ng-content>
  </div>
</div>
