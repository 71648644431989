import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BaseComponent } from '../component/base.component';
import { ResizingService } from '../services';
import { DxPopupComponent } from 'devextreme-angular';

export abstract class Popup extends BaseComponent {

	visible$: BehaviorSubject<boolean>;
	private mobilePopupSubscription:Subscription;
	private instance:DxPopupComponent;

	constructor(protected router?: Router, protected route?: ActivatedRoute, protected resizingService?: ResizingService) {
		super();
		this.visible$ = new BehaviorSubject<boolean>(true);
	}

	navigateToParent(levels = 1) {
		let navigatePath = '';
		for (let i = 0; i < levels; i++) {
			navigatePath += '../';
		}
		this.router
			.navigate([navigatePath], { relativeTo: this.route })
			.catch(() => this.navigateToParent(levels + 1));
	}

	close = () => {
		this.visible$.next(false);
	};

	init(popup : DxPopupComponent) {
		this.instance = popup;
		if(!this.mobilePopupSubscription){
			this.mobilePopupSubscription = this.resizingService?.IsMobile().subscribe((isMobile: boolean) => {
				this.instance.fullScreen = isMobile;			
			});
		}		
	}

	destroy() {
		if(this.mobilePopupSubscription) this.mobilePopupSubscription.unsubscribe();
	}

}
