import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

import { BaseComponent } from '../component/base.component';
import { PermissionLevel } from '../permissions/permission-levels.enum';
import { expandMenu } from './nav-menu-item.expand.animation';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-nav-menu-item',
	templateUrl: './nav-menu-item.component.html',
	styleUrls: ['./nav-menu-item.component.scss'],
	animations: [expandMenu]
})
export class NavMenuItemComponent extends BaseComponent implements OnInit, OnDestroy {
	
	private static _togglingSubMenu$ = new Subject();
	private toggleSubscription: Subscription;
	@Input() label: string;
	@Input() icon: string;
	@Input() route: string;
	@Input() badgeText: string;
	@Input() subItems: [{ label: string; route: string; permission: PermissionLevel; target: string; }];
	menuState = 'void';

	constructor(private router:Router, private aroute: ActivatedRoute) {
		super();
	}

	ngOnInit() {
		this.toggleSubscription = NavMenuItemComponent._togglingSubMenu$.subscribe(label => {
			if (this.label === label) {
				this.menuState = this.menuState === '*' ? 'void' : '*';
			} else {
				this.menuState = 'void';
			}
		});
	}

	ngOnDestroy() {
		if (this.toggleSubscription) {
			this.toggleSubscription.unsubscribe();
		}
	}

	toggleSubItems() {
		NavMenuItemComponent._togglingSubMenu$.next(this.label);
	}

	navigate() {
		this.router.navigate([this.route],{relativeTo:this.aroute});
	}

	navigateTo(subitemRoute:string) {
		this.router.navigate([subitemRoute],{relativeTo:this.aroute});
	}

	navigateAway(subitemRoute:string, subItemTarget:string){
		window.open(subitemRoute, subItemTarget);
	}

	navigateAwayAndReload(subItemRoute:string, subItemTarget:string){
		window.open(subItemRoute, subItemTarget);
	}
}
