import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../component/base.component';
import { AuthenticationService, LoadingService } from '../services';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Event } from '@angular/router';
import { ResizingService, UserProfileService, NavigationService } from '../services';
import { Subscription } from 'rxjs';
import { UserProfile } from '../services/models';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends BaseComponent implements  OnInit, OnDestroy {

  isMobileSideMenuVisible: Boolean;
  mobileSubscription:Subscription;
  navigationSubscription:Subscription;
  userProfile:UserProfile;
  greeting = '';
  isImpersonating = false;
  proxyName = '';
  showContactOverlay = false;

  constructor(public loadingService: LoadingService, private router: Router, private resizingService: ResizingService, private userProfileService: UserProfileService, private navigationService: NavigationService, private auth: AuthenticationService) {
    super();
    this.userProfile = null;
    this.navigationSubscription = this.router.events.subscribe((value:Event) => {
      this.checkRouterEvent(value);
    });
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
        this.loadingService.loading = true;        
    }

    if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError) {
        if(routerEvent instanceof NavigationEnd)  {         
          this.loadingService.setLoading(false,() => {            
            this.navigationService.InformRouteChanged(routerEvent.urlAfterRedirects);
          });
        } else {      
          this.loadingService.loading = false;
        }
    }
  }

  buildGreeting() {
    this.isImpersonating = false;
    if (this.userProfile.proxies && this.userProfile.proxies.length > 0) {
      const proxy = this.userProfile.proxies.find(p => p.relationshipType !== "Self" && p.isImpersonating);
      if (proxy) {
        this.isImpersonating = true;
        this.greeting = 'Welcome to ' + proxy.firstName + "'";
        this.proxyName = proxy.firstName + ' ' + proxy.lastName;
        if (proxy.firstName && proxy.firstName.length > 0 && proxy.firstName[proxy.firstName.length - 1].toLowerCase() !== 's') {
          this.greeting += 's';
        }
        if (this.proxyName[this.proxyName.length - 1].toLowerCase() === 's') {
          this.proxyName += "'";
        } else {
          this.proxyName += "'s";
        }
        this.greeting += ' Patient Portal.';
      }
    }

    if (!this.isImpersonating) {
      const h = new Date().getHours();
      if (h >= 18) {
        this.greeting = 'Good evening';
      } else if (h >= 12) {
        this.greeting = 'Good afternoon';
      } else {
        this.greeting = 'Good morning';
      }
      if (this.userProfile && this.userProfile.firstName) {
        this.greeting += ', ' + this.userProfile.firstName + '.';
      } else {
        this.greeting += '.'
      }
    }
  }

  ngOnInit() {

      this.userProfileService.getUserProfileHardCache().subscribe((profile:UserProfile) => {
        this.userProfile = profile;
        this.buildGreeting();
      });
    
    this.mobileSubscription = this.resizingService.IsTabletOrSmaller().subscribe((isTabletOrSmaller: boolean) => {
      if (!isTabletOrSmaller && this.isMobileSideMenuVisible) {
          this.isMobileSideMenuVisible = false;
        }
      });       
  }

  ngOnDestroy() {
    if (this.mobileSubscription) this.mobileSubscription.unsubscribe();    
    if (this.navigationSubscription) this.navigationSubscription.unsubscribe();
  }

  get Window() {
    return window;
  }

  onMobileSideMenuToggleVisibility() {
    this.isMobileSideMenuVisible = !this.isMobileSideMenuVisible;
    if (!this.isMobileSideMenuVisible) {
      this.showContactOverlay = false;
    }
  }

  showContactOverlayChanged(e) {
    this.showContactOverlay = e;
  }

  onMobileSideMenuClose() {
    this.isMobileSideMenuVisible = false;
  }

  switchAccount() {
    this.loadingService.loading = true;
    this.auth.revertAuthenticatedProxy().then(() => {
      this.loadingService.loading = false;
    }).catch(() => {
      this.loadingService.loading = false;
    });
  }
}
