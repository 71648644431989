import { Injectable } from '@angular/core';
import { NEVER, Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from './base.service';
import { MailFolder, PrescriptionRenewalRequest, SecureMailMessage, ShmRecipient } from './models';
import { ServiceEndpoint } from './service-endpoint.enum';
import { tap } from 'rxjs/operators';

@Injectable()
export class SecureMailService extends BaseService {

	refreshMessages = new BehaviorSubject<number>(0);
	willRefreshMessages: any;
	getCareTeams(): Observable<ShmRecipient[]> {
		return this.get({
			endpoint: ServiceEndpoint.SECUREMAIL_GETCARETEAMS,
			cacheInvalidator: NEVER
		});
	}

	hasCareTeam(): Promise<boolean> {
		return new Promise((resolve) => {
			this.getCareTeams().subscribe((careteams) => {
				resolve(careteams && careteams.length > 0);
			});
		});
	}

	getSecureMessages(folder: MailFolder): Observable<Array<SecureMailMessage>> {
		clearTimeout(this.willRefreshMessages);
		return this.get<Array<SecureMailMessage>>({
			endpoint: ServiceEndpoint.SECUREMAIL_GETSECUREMESSAGES,
			params: { mailFolder: folder }
		}).pipe(tap((array:Array<SecureMailMessage>) => {
			if (folder === MailFolder.Inbox) {
				const count = array.filter(a => !a.isRead).length;
				this.refreshMessages.next(count);
			}
		}));
	}

	getSecureMessage(mailMessageId: string): Observable<SecureMailMessage> {
		return this.post({
			endpoint: ServiceEndpoint.SECUREMAIL_GETSECUREMESSAGE,
			body: {
				MailMessageId: mailMessageId
			}
		});
	}

	setSecureMessageRead(mailMessageId: string): Observable<any> {
		return this.post({
			endpoint: ServiceEndpoint.SECUREMAIL_SETSECUREMESSAGEREAD,
			body: {
				MailMessageId: mailMessageId
			}
		}).pipe(tap((count: string) => this.refreshMessages.next(parseInt(count, 10))));
	}

	sendSecureMessage(message: SecureMailMessage): Observable<any> {
		return this.post({
			endpoint: ServiceEndpoint.SECUREMAIL_SENDSECUREMESSAGE,
			body: message
		});
	}

	archiveSecureMessage(mailMessageId: string): Observable<any> {
		return this.post({
			endpoint: ServiceEndpoint.SECUREMAIL_ARCHIVESECUREMESSAGE,
			body: {
				MailMessageId: mailMessageId
			}
		});
	}

	unArchiveSecureMessage(mailMessageId:string):Observable<any> {
		return this.post({
			endpoint: ServiceEndpoint.SECUREMAIL_UNARCHIVESECUREMESSAGE,
			body: {
				MailMessageId: mailMessageId
			}
		});
	}

	sendPrescriptionRenewalRequest(request: PrescriptionRenewalRequest) {
		return this.post({
			endpoint: ServiceEndpoint.SECUREMAIL_SENDPRESCRIPTIONRENEWALREQUEST,
			body: request
		});
	}

	getSecureMessageReport(mailMessageId: string): Observable<Blob> {
		return this.getBlobPOST({
			endpoint: ServiceEndpoint.SECUREMAIL_GETSECUREMESSAGEREPORT,
			body: {mailMessageId:mailMessageId}
		});
	}
}
