<dx-popup [visible]="visible$ | async" title="Information" height="270" (onHidden)="navigateToParent()">

  
  <dxi-toolbar-item [toolbar]="'bottom'" [location]="'after'" [widget]="'dxButton'" [options]="{ text: 'Close', onClick: close}">
  </dxi-toolbar-item>  
  

  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-scroll-view [useNative]="true">

      <div class="dx-fieldset red">
        This appointment is less than {{ appointmentActionDisclaimer }} hours away.  If you wish to reschedule or cancel, please call the Scheduling department at the number listed on the Appointment Detail form.
      </div>

    </dx-scroll-view>
  </div>
</dx-popup>
