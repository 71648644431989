import { Component, OnInit } from '@angular/core';

import { FileDownloadService, HealthHistoryService, LoadingService } from '../../core/services';
import { BaseComponent } from '../../core/component/base.component';
import { VitalSign, VitalSignDetail } from '../../core/services/models';

@Component({
  selector: 'app-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.scss']
})
export class VitalsComponent extends BaseComponent implements  OnInit {
  constructor(
    private healthHistorySvc: HealthHistoryService,
    private downloadSvc: FileDownloadService,
    private loadingService:LoadingService
  ) { super();}
  
  vitals:Array<VitalSignDetail>;

  ngOnInit() {
    this.loadingService.loading = true;
    this.healthHistorySvc.getVitals().subscribe( (vitals:Array<VitalSign>) => {
      this.loadingService.loading = false;
      const fvitals = vitals.filter(v => v && v.details && v.details.length > 0);
      this.vitals = new Array<VitalSignDetail>();
      for(let i = 0; i < fvitals.length; i++) {
        for(let j = 0; j < fvitals[i].details.length; j++) {
          fvitals[i].details[j].enteredDate = fvitals[i].enteredDate;
          this.vitals.push(fvitals[i].details[j]);
        }
      }        
    }); 
  }

  download = () => {
    this.loadingService.loading = true;
    this.healthHistorySvc
      .getVitalsReport()
      .subscribe((report:Blob) => {
        this.loadingService.loading = false;
        this.downloadSvc.downloadPdf(report, 'Vitals');
      });
  };

  canDownload = () => {
    return this.vitals && this.vitals.length > 0;
  }
}
