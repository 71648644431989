<app-card label="Load Latest NPI Data File">
    <form *ngIf="urlForm" [formGroup]="urlForm" novalidate>
      <div class="dx-fieldset">
        <div class="dx-field">
          <div class="dx-field-label">Url for the latest npi file:</div>
          <div class="dx-field-value">
            <dx-text-box formControlName="url" [isValid]="urlForm.get('url').valid"></dx-text-box>
          </div>                      
        </div>
        <div class="info-container">          
          <p>
              Enter full URL path of the current NPI dataset file.
              The file can be located on http://download.cms.gov/nppes/NPI_Files.html.
              Enter the current link on the page something like ... http://download.cms.gov/nppes/NPPES_Data_Dissemination_March_2017.zip                
          </p>
        </div>
        <div class="dx-field">
            <div class="dx-field-label"></div>
            <div class="dx-field-value">
                <dx-button class="left" text="Upload NPI Data" (onClick)="uploadNPIData()"></dx-button>
            </div> 
        </div>
      </div>
    </form>
    <form *ngIf="dataForm" [formGroup]="dataForm" novalidate>
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">Directory Name:</div>
            <div class="dx-field-value">
              <dx-text-box formControlName="directoryName" [isValid]="dataForm.get('directoryName').valid"></dx-text-box>
            </div>                      
          </div>
          <div class="dx-field">
              <div class="dx-field-label">File Name:</div>
              <div class="dx-field-value">
                <dx-text-box formControlName="fileName" [isValid]="dataForm.get('fileName').valid"></dx-text-box>
              </div>                      
            </div>
          <div class="info-container">          
            <p>
                Enter DirectoryName and FileName of the current NPI dataset file.
                The file can be located on the server and will need to be extracted from the zip file first.                
            </p>
          </div>
          <div class="dx-field">
              <div class="dx-field-label"></div>
              <div class="dx-field-value">
                  <dx-button class="left" text="Split NPI Data" (onClick)="splitNPIData()"></dx-button>
              </div> 
          </div>
        </div>
      </form>    
</app-card>
<router-outlet></router-outlet>
