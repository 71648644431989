import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserNotification } from '../services/models';
import { UserNotificationsService } from '../services';

@Injectable()
export class ResolveUserNotificationsGuard implements Resolve<Array<UserNotification>> {
  constructor(private userNotificationsService: UserNotificationsService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Array<UserNotification> | Observable<Array<UserNotification>> | Promise<Array<UserNotification>> {
    // return this.userNotificationsService.getUserNotifications();
    const notification =  this.userNotificationsService.getUserNotifications();
    return notification.pipe(()=>{
      localStorage.setItem("startTimeOfAccountNotification",Date.now().toString());
      return notification;
    })
  }
}
