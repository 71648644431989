import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService, UserPreferencesService } from '../services';
import { ActiveToken, UserPreference } from '../services/models';

@Injectable()
export class LoggedInGuard implements CanActivate, CanActivateChild {

  static ACCEPTEDTERMSOFUSE = "AcceptedTermsOfUse";

  constructor(private auth: AuthenticationService, private userPrefrerencesService:UserPreferencesService, private router:Router ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.doActivationCheck(route, state);    
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.doActivationCheck(route, state);
  }

  private doActivationCheck(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    document.body.scrollTo(0, 0);
    if (state.url === '/auth' || state.url==='/silent-renew' || state.url.startsWith('/app/terms-of-use')) {

      return of(true);

    } else {      

          if (this.auth.active_token && this.auth.active_token.id_token) {

            try {                
    
                if (this.auth.isTokenValid()) { // token is still valid
                  
                  localStorage.removeItem('redirectAfterAuth');

                  return  this.userPrefrerencesService.getUserPreferences().pipe(map((preferences:Array<UserPreference>):boolean => {

                    const termsOfUse: UserPreference = preferences.find(p => p.userPreferenceType === LoggedInGuard.ACCEPTEDTERMSOFUSE);
            
                    if (termsOfUse && termsOfUse.userPreferenceValue === "True") {

                      return true;

                    } else {

                      if(this.auth.activeToken === ActiveToken.Primary)
                      {
                        this.router.navigateByUrl('/app/terms-of-use/terms-of-use-agreement');  

                      } else if (this.auth.activeToken === ActiveToken.Proxy || this.auth.activeToken === ActiveToken.None) {

                        this.router.navigateByUrl('/app/terms-of-use/terms-of-use-rejected');

                      }

                      return false;

                    }

                  }));
                }            
  
            } catch(e) {
              // not doing anthing
            }
          }
  
          if(this.auth.activeToken === ActiveToken.Primary || this.auth.activeToken === ActiveToken.None) {
            localStorage.setItem('redirectAfterAuth', state.url);
            this.auth.authenticatePrimary();
          } else if (this.auth.activeToken === ActiveToken.Proxy) {
            localStorage.setItem('redirectAfterAuth', state.url);
            this.auth.renewProxy();
          }
          
          return of(false);          
                   
    }
  }
}
