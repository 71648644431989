<dx-popup #popup [visible]="visible$ | async" (onHidden)="close()" title="Transmit Document" height="620" width="550">
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Close', onClick: close }">
  </dxi-toolbar-item>
  <dxi-toolbar-item toolbar="bottom" location="after" widget="dxButton" [options]="{ text: 'Transmit', type: 'default', onClick: transmit }">
  </dxi-toolbar-item>
  <div *dxTemplate="let data of 'content'" class="popup-content">
    <dx-tabs
      #apiTabs
      [dataSource]="tabs"
      [selectedIndex]="0">
    </dx-tabs>
    <div *ngIf="apiTabs.selectedIndex==0; then regularEmail"></div>    
    <ng-template #regularEmail>
      <dx-scroll-view>
          <p>You may password protect the attachment file by entering a password below.</p>
          <p><span class="red">Warning:</span> You will have to provide the password to your email recipient separately for it to be opened.</p>
          <p>Your health data will be attached as a ZIP file containing a human readable HTML file, as well as a machine readable XML file for importing into other systems.</p>
          
          <form *ngIf="transmitRegularEmailForm" [formGroup]="transmitRegularEmailForm" novalidate>
            <div class="dx-fieldset">

              <div class="label">Security:</div>
              <br />

              <div class="dx-field shorter">              
                  
                <dx-check-box formControlName="passwordProtect"  text="Add password protection" (onValueChanged)="passwordProtectChanged()">
                </dx-check-box>

              </div>  
              
              <div class="dx-field shorter">

                <dx-check-box formControlName="useBarracuda"  text="Send Secure" (onValueChanged)="sendSecureChanged()">
                </dx-check-box>

              </div>  

              <div class="dx-field shorter">

                <dx-check-box formControlName="sendUnsecure"  text="Send Non-Secure (via normal email delivery)" (onValueChanged)="sendUnsecureChanged()">
                </dx-check-box>

              </div>
              
              <p *ngIf="filePassRequired">The encryption method used to password-protect the file(s) uses a more secure method that operating systems like Microsoft Windows may not support natively.  Many zip utility programs do support this method - an example of one that is available to download and install for free is called 7-Zip.  Your recipient will need to have the more advanced version of this technology to open your password-protected file(s).</p>                            
              
              <div class="dx-field shorter" *ngIf="filePassRequired">
                <label for="password" class="label">File Password:</label>
                <dx-text-box [inputAttr]="{ id: 'password' }" mode="password" formControlName="password" [isValid]="transmitRegularEmailForm.get('password').valid && transmitRegularEmailForm.get('password').value.trim().length > 0"
                [validationError]="{ message: 'Password is required' }"></dx-text-box>
              </div>

              <div class="dx-field shorter">
                <label for="email" class="label">Regular Email:</label>
                <dx-text-box [inputAttr]="{ id: 'email' }" formControlName="email" [isValid]="transmitRegularEmailForm.get('email').valid"
                [validationError]="{ message: 'Valid email address is required' }"></dx-text-box>
              </div>
              <p *ngIf="!securityOptionSelected"><span class="red">You must select a Security option.</span></p>
            </div>  
          </form>
      </dx-scroll-view>
    </ng-template>
  </div>
</dx-popup>
