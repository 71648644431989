import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailOrEmptyValidator(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
        if (!control.value.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            return { email: true };
        }
    }
    return null;
}
