<app-card *ngIf="showView" label="My Resources" (mouseenter)="mouseEnteredResources()" (click)="clickedInsideResources($event)" smartlook-safe>
  <div class="quick-link-resources" smartlook-safe>
    <dx-list title="My Resources" [dataSource]="externalLinks" [grouped]="true" [collapsibleGroups]="true" [focusStateEnabled]="false" [activeStateEnabled]="false"
      (onContentReady)="onListReady($event)" smartlook-safe>
      <div *dxTemplate="let item of 'group'" smartlook-safe>
        {{item.title}}
        <img class="chevron" src="assets/images/icons/SVG/nav-chevron.svg" alt="Chevron"/>
      </div>
      <div *dxTemplate="let item of 'item'" smartlook-safe>
        <a [href]="item.url" (click)="onOpenLink(item)" target="_blank" *ngIf="!item.isDownload" smartlook-safe>{{item.title}}</a>
        <a class="dead-link" (click)="downloadPdf(item)" *ngIf="item.isDownload" smartlook-safe>{{item.title}}</a>
      </div>
    </dx-list>
  </div>
</app-card>
