import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecureMailListComponent } from './secure-mail-list/secure-mail-list.component';
import { ViewMessageModalComponent } from './view-message-modal/view-message-modal.component';
import { PermissionLevel } from '../core/permissions/permission-levels.enum';
import { ResolveCareTeamsGuard } from '../core/guards';
import { ResolveFacilityInfoGuard } from '../core/guards';

const routes: Routes = [
	{
		path: '',
		children: [
			{
				path: ':view',
				component: SecureMailListComponent,				
				data: { permissionLevel: PermissionLevel.VIEW_SECURE_MESSAGES },	
				resolve: { careteams: ResolveCareTeamsGuard, facilityInfo: ResolveFacilityInfoGuard },
				children: [
					{
						path: ':id',
						component: ViewMessageModalComponent,
						data: { permissionLevel: PermissionLevel.VIEW_SECURE_MESSAGES },
						resolve: { careteams: ResolveCareTeamsGuard, facilityInfo: ResolveFacilityInfoGuard },
					}
				]
			},
			{
				path: '',
				redirectTo: 'inbox',
				pathMatch: 'prefix'
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class SecureMailRoutingModule {}
