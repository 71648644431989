import { Component, OnInit } from '@angular/core';

import { FileDownloadService, HealthHistoryService, LoadingService } from '../../core/services';
import { HealthIssue } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { trackPageLoadingTime } from '../../shared/helpers/tracking.helper';

@Component({
  selector: 'app-health-issues',
  templateUrl: './health-issues.component.html',
  styleUrls: ['./health-issues.component.scss']
})
export class HealthIssuesComponent extends BaseComponent implements  OnInit {
  constructor(private hhSvc: HealthHistoryService, 
              private downloadSvc: FileDownloadService,
              private loadingService:LoadingService) {super();}

  data: HealthIssue[];
  ngOnInit() {
    const startTime = Date.now();
    this.loadingService.loading = true;
    this.hhSvc.getHealthIssues().subscribe((data:Array<HealthIssue>) => {
      this.loadingService.loading = false;
      const endTime = Date.now();
      const elapsedTime = (endTime-startTime)/1000;
      trackPageLoadingTime(window.location.pathname,elapsedTime);
      this.data = data;
    });
  }

  download = () => {
    this.loadingService.loading = true;
    this.hhSvc
      .getHealthIssuesReport()
      .subscribe((report:Blob) => {
        this.loadingService.loading = false;
        this.downloadSvc.downloadPdf(report, 'health-issues')
      });
  };

  canDownload = () => {
    return this.data && this.data.length > 0;
  }
}
