import { NgModule } from '@angular/core';
import { AlertV2Module } from '../core/alert/alertv2.module';
import { ButtonModule } from '../core/button/button.module';
import { PhoneFieldModule } from '../core/phone-field/phone-field.module';
import { ProgressBarModule } from '../core/progress-bar/progress-bar.module';
import { SelectableItemModule } from '../core/selectable-item/selectable-item.module';
import { SharedModule } from '../shared/shared.module';
import { DxCalendarModule, DxDateBoxModule, DxTextAreaModule, DxTextBoxModule } from 'devextreme-angular';
import { CancelAppointmentV2RoutingModule } from './cancel-appointmentv2-routing.module';
import { CancelAppointmentV2Component } from './cancel-appointmentv2.component';

@NgModule({
    imports: [
        SharedModule,
        CancelAppointmentV2RoutingModule,
        DxDateBoxModule,
        DxTextBoxModule,
        DxTextAreaModule,
        PhoneFieldModule,
        ProgressBarModule,
        DxCalendarModule,
        SelectableItemModule,
        ButtonModule,
        AlertV2Module
    ],
    declarations: [
        CancelAppointmentV2Component,
    ],
    exports: []
})
export class CancelAppointmentV2Module { }
