import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Appointment, AppointmentDetail } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { ResizingService, AppointmentsService, LoadingService } from '../../core/services';
import { Subscription } from 'rxjs';
import { trackEvent, trackPageLoadingTime } from '../../shared/helpers/tracking.helper';
import * as moment from 'moment';

@Component({
	selector: 'app-appointments-list',
	templateUrl: './appointments-list.component.html',
	styleUrls: ['./appointments-list.component.scss']
})
export class AppointmentsListComponent extends BaseComponent implements OnInit, OnDestroy {

	teleHealthUrl: string;
	showTeleJoinLink = false;

	constructor(private router: Router, private route: ActivatedRoute, 
				private resizingService:ResizingService,private appointmentsService: AppointmentsService,
		private loadingService: LoadingService) {
		super();		
	}

	ngOnInit(): void {
		const startTime = Date.now();
		this.loadingService.loading = true;

		this.appointmentsService.getAppointments().subscribe((appointmentsDetail: Array<AppointmentDetail>) => {
			const endTime = Date.now();
			this.loadingService.loading = false;
			const elapsedTime = (endTime-startTime)/1000;
			if(window.location.pathname.split('/').length<=3 && window.location.pathname.includes('appointments'))
			trackPageLoadingTime(window.location.pathname,elapsedTime);
			this.appointmentsData =	
				appointmentsDetail.map(appt => {
					return {
						id: appt.appointmentId,
						text: appt.description,
						location: appt.location,
						startDate: appt.startDateTime,
						endDate: appt.endDateTime,
						patientInstructions: appt.patientInstructions,
						schedulerNotes: appt.schedulerNotes,
						isTeleHealth: appt.isTeleHealth,
						isTelehealthAppointment: appt.isTelehealthAppointment,
						isUpcoming: appt.isUpcoming,
						facilityTimeZone: appt.facilityTimeZone,
						teleHealthUrl: appt.teleHealthUrl,
						treatmentFacility: {
							name: appt.facilityName,
							address: appt.facilityAddress1,
							city: appt.facilityCity,
							state: appt.facilityState,
							zip: appt.facilityPostalCode,
							phoneNumbers: [
								{ name: 'Main', phoneNumber: appt.facilityMainPhone },
								{ name: 'Scheduling', phoneNumber: appt.facilitySchedulingPhone },
								{ name: 'Accommodations', phoneNumber: appt.facilityAccommodationsPhone },
								{ name: 'Transportation', phoneNumber: appt.facilityTransportationPhone }
							]
						}
					};
				});
			this.teleHealthApptExists = (this.appointmentsData.find(function(appt){
				//return appt.isTeleHealth;
				return appt.isTelehealthAppointment && appt.isUpcoming
			})) ? true : false;
			this.getTeleHealthUrls();
		});
		
	}

	ngOnDestroy():void {
		if (this.mobileSubscription) this.mobileSubscription.unsubscribe();		
	}

	getTeleHealthUrls(){
		if(this.appointmentsData){
			const urls = this.appointmentsData.filter(app =>
				app.teleHealthUrl !== '');
			if(urls.length > 0){
				this.teleHealthUrl = urls[0].teleHealthUrl;
				if(moment(urls[0].startDate).toDate().toDateString() === moment(Date.now()).toDate().toDateString())
				{
					this.showTeleJoinLink = true;
				}
			}
		}
	}

	appointmentsData: Array<Appointment>;
	isMobile = false;
	selectedAppointment: any;
	mobileSubscription:Subscription;
	teleHealthApptExists = false;

	// Option order is important and an exception is thrown if we're changing to a new set of views
	// and the currently selected vew is not in the list of new views
	schedulerDesktopOptions = {
		currentView: 'agenda',
		views: [{ type: 'agenda', agendaDuration: 30 }, 'day', 'week', 'month'],
		height: '650px',
		useDropDownViewSwitcher: false
	};
	schedulerMobileOptions = {
		currentView: 'agenda',
		views: [{ type: 'agenda', agendaDuration: 30 }, 'day'],
		height: '650px',
		useDropDownViewSwitcher: false
	};

	onSchedulerInitialized(event: any) {
		// Set the scheduler options based on current screen size (mobile or desktop)
		this.mobileSubscription = this.resizingService.IsMobile().subscribe((result:boolean) => {
			this.isMobile = result;
			this.setSchedulerOptions(event.component);
		});
	}

	onSchedulerContentReady(event: any) {
		// TODO: use getStartViewDate() to get the currently displayed day and then find the first appointment and scroll to it
		const startViewDate = event.component.getStartViewDate();
		startViewDate.setHours(6);
		try {
			event.component.scrollTo(startViewDate);
		} catch (e) {
			console.log(e);
		}

		// Force the scrollbar to always be visible and use the native scrollbar
		// NOTE: not using the native scrollbar and setting "alwaysShow" to true didn't work,
		// had a bug where the scrollbar would not reset when changing from mobile to desktop views
		// event.component._$element.find('.dx-scrollable').dxScrollable({ useNative: true });
	}

	onAppointmentClick(event: any) {		
		// There are bugs with the appointments collector drop-down that we've logged with DevExpress:
		// https://github.com/DevExpress/DevExtreme/issues/2149 -- fixed in 17.2.4
		// https://github.com/DevExpress/DevExtreme/issues/2150 -- fixed in 17.2.4
		event.cancel = true; // Prevent the default event detail popup from showing
		this.router.navigate([event.appointmentData.id], { relativeTo: this.route });
	}

	onAppointmentDblClick(event: any) {
		event.cancel = true; // Prevent the default event detail popup from showing
	}

	setSchedulerOptions(schedulerInstance: any) {
		if (this.isMobile) {
			schedulerInstance.option(this.schedulerMobileOptions);
		} else {
			schedulerInstance.option(this.schedulerDesktopOptions);
		}
	}

	downloadSchedule() {
		this.router.navigate(['download-schedule'], { relativeTo: this.route });
	}

	scheduleAppointment() {
		this.router.navigate(['app', 'request-appointmentv2']);
	}

	openTelehealthAppointment(){
		trackEvent('Appointments - Join telehealth call', 'Click');
		window.open(this.teleHealthUrl);
	}
}
