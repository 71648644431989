import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../component/base.component';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent extends BaseComponent implements OnInit {

    @Input() totalSteps: number;
    @Input() currentStep: number;
    steps: number[] = [];

    constructor() {
        super();
    }
    ngOnInit(): void {
        for (let i = 1; i <= this.totalSteps; i++) {
            this.steps.push(i);
        }
    }

    classesForStep(step: number) {
        let classes = 'step';
        if (step === this.currentStep) {
            classes += ' current';
        } else if (step < this.currentStep) {
            classes += ' past';
        }
        return classes;
    }
}