import { Component, OnInit } from '@angular/core';
import { EventCounter } from '../../core/services/models';
import { BaseComponent } from '../../core/component/base.component';
import { FormGroup, FormControl, Validators, FormControlName } from '@angular/forms';
import { dateOrEmptyValidator } from '../../shared/validators/date-or-empty.validator';
import * as formHelper from '../../shared/helpers/form.helper';
import * as moment from 'moment';
import { ReportingService, LoadingService } from '../../core/services';

@Component({
  selector: 'app-event-counts',
  templateUrl: './event-counts.component.html',
  styleUrls: ['./event-counts.component.scss']
})
export class EventCountsComponent extends BaseComponent implements OnInit {

  events:Array<EventCounter> = new Array<EventCounter>();
  max:Date = new Date();
  min:Date = new Date('January 1, 1900');
  userTypes = ['Patient','Physician','Caregiver','Stakeholder','Proxy User',
                'Professional Relationship','Helpdesk','Other','All'];
  eventsForm = new FormGroup({
    startDate : new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator])),
    endDate: new FormControl('',Validators.compose([Validators.required,dateOrEmptyValidator])) ,
    userType: new FormControl('',Validators.required) 
  });

  constructor(private reportingService:ReportingService, private loadingService:LoadingService) { 
    super();
    const originFormControlNameNgOnChanges = FormControlName.prototype.ngOnChanges;
  
    FormControlName.prototype.ngOnChanges = function () {
      let result;
      if (arguments) {
        result = originFormControlNameNgOnChanges.apply(this, arguments);
      }
      if (this.control && this.valueAccessor && this.valueAccessor.element) {
        this.control.nativeElement = this.valueAccessor.element.nativeElement;
      }
      return result;
    };
  }

  ngOnInit() {
    formHelper.markControlAndChildControlsAsDirty(this.eventsForm);
  }

  wrongOrder():boolean {

    const startDate = new Date(this.eventsForm.get('startDate').value);
    const endDate = new Date(this.eventsForm.get('endDate').value);

    return moment(startDate).isAfter(moment(endDate));

  }

  inRange():boolean {
    
    const startDate = new Date(this.eventsForm.get('startDate').value);
    const endDate = new Date(this.eventsForm.get('endDate').value);

    return moment(startDate).isValid() && moment(startDate).isBetween(this.min,this.max) && moment(endDate).isValid() && moment(endDate).isBetween(this.min,this.max);

  }

  minDate():string {
    return moment(this.min).format("MM[/]DD[/]YYYY");
  }

  maxDate():string {
    return moment(this.max).format("MM[/]DD[/]YYYY");
  }

  startDateValueChanged() {
    this.eventsForm.get('startDate').updateValueAndValidity();
  }

  endDateValueChanged() {
    this.eventsForm.get('endDate').updateValueAndValidity();    
  }

  lookupEvents() {
    
    if(this.eventsForm.valid && !this.wrongOrder() && this.inRange()) {
      this.loadingService.loading = true;
      const startDate: string = new Date(this.eventsForm.get('startDate').value).toDateString();
      const endDateAfter: Date = new Date(this.eventsForm.get('endDate').value);
      let userType:string = this.eventsForm.get('userType').value;
      if(userType.split(' ').length>1)
      {
        const arr = userType.split(' ');
        userType = arr.join('_');
      }
      endDateAfter.setDate(endDateAfter.getDate() + 1 );
      const endDate: string = endDateAfter.toDateString();
      this.reportingService.getEventCounts(startDate,endDate,userType).subscribe((data:Array<EventCounter>) => {
        this.loadingService.loading = false;
        this.events = data;        
      }, error => {
        this.loadingService.loading = false;
        throw(error);
      });
    }
  }

}
