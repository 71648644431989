import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BillPayComponent } from '../bill-pay/bill-pay.component';

const routes: Routes = [
  {
    path: '',
    component: BillPayComponent,
    children: []
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillPayRoutingModule {}
