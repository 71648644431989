import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
    transform(phoneNumber: number | string): string {
        if (typeof phoneNumber === 'number') {
            phoneNumber = phoneNumber.toString();
        } else {
            phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
        }

        if (phoneNumber.length === 10) {
            return `(${ phoneNumber.slice(0, 3) }) ${ phoneNumber.slice(3, 6) }-${ phoneNumber.slice(6) }`;
        } else if (phoneNumber.length === 7) {
            return `${ phoneNumber.slice(0, 3) }-${ phoneNumber.slice(3) }`;
        } else {
            return phoneNumber;
        }
    }
}
