import { ErrorHandler, Injectable,  Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorNotificationService, AuthenticationService } from '../core/services';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { logError } from 'app/shared/helpers/logging.helper';

@Injectable()
export class ErrorsHandler implements ErrorHandler {

  private version:number;
  
  constructor(private injector: Injector,private zone:NgZone) {
    this.version = 1;
  }
  
  handleError(error: any) {

    let message = "";
    const router = this.injector.get(Router); 
    if(error.rejection && error.rejection instanceof HttpErrorResponse) {
      error = error.rejection;
    }

   
      
    if (error instanceof HttpErrorResponse) {          
      if (!navigator.onLine) {
        console.log('No Internet Connection'); 
        /*Error handler not in the angular zone, injecting the zone to be able to 
        provide Angular features to the error component*/       
        this.zone.run(() => router.navigate(['connection-error']));
        return;          
      } else if (error.status === 401) {
        const authService = this.injector.get(AuthenticationService);
        if (authService.active_token) {
          authService.logout();
          return;
        }
      } else if (error.status === 0) {
        this.zone.run(() => router.navigate(['connection-error']));
        return;
      }

      if(error.error) {
        const details = error.error;
        if(typeof details === 'string' || typeof details === 'number' || typeof details === 'boolean') {
          message = details.toString();
        } else {
          message = JSON.stringify(details);
        }
      }
      
      message = message + ' - '+ error.status + ' - ' + (error.statusText || '') + ' - ' + error.message;
            
    }
    else {
      message = error.message ? error.message : error.toString();
    }

    if(error.stack) {
      message = message + ' - ' + error.stack;
    }

    message = "version " + this.version.toString() + " " + message;

    logError(message);

    if(!environment.production) {
      console.log(message);
    }

    if(message.indexOf('handleMove') >= 0) {
      // Patch to ignore error when scrolling horizontal on mobile
      return;
    }

    if (message.indexOf('Cannot match any routes') >=0 && message.indexOf('myctca_portal') >=0 ) {
      this.zone.run(() => router.navigate(['app','home']));
      return;
    }

    if(message.indexOf('Error: Loading chunk') >=0) {
      this.zone.run(() => router.navigate(['connection-error']));
      return;
    }

    const errorNotificationService = this.injector.get(ErrorNotificationService);
    errorNotificationService.notify(message).subscribe((result:string) => {
      /*Error handler not in the angular zone, injecting the zone to be able to 
      provide Angular features to the error component*/
      this.zone.run(() => router.navigate(['error']));      
    }); 
    
  }
}